import * as Dto from "actions/Dto";
import logger from "actions/Logger";

const AUTH_TOKEN_KEY = "auth-token";

class SessionStore {

	private _session: Dto.SettingsDto|null = null;
	private _authToken: string|null = null;


	get authenticated() {
		logger.debug("SessionStore.authenticated?", this._session, this.authToken, (this._session && this._session.user && this.authToken ? true : false));
		return this._session && this._session.user && this._authToken ? true : false;
	}

	get authToken() {
		if (!this._authToken) {
			this._authToken = localStorage.getItem(AUTH_TOKEN_KEY)
		}
		return this._authToken;
	}

	set authToken(authToken: string|null) {
		logger.debug("SessionStore.setAuthToken:" + authToken);
		if (authToken) {
			localStorage.setItem(AUTH_TOKEN_KEY, authToken);
		} else {
			localStorage.removeItem(AUTH_TOKEN_KEY);
		}
		this._authToken = authToken;
	}

	get userId() : number|null {
		return this._session && this._session.user.id ? this._session.user.id : null;
	}

	get accountId() : number|null {
		return this.getAccountId();
	}

	get user() : Dto.UserDto|null {
		return this._session ? this._session.user : null;
	}

	load(data: Dto.SettingsDto|null) {
		logger.debug("SessionStore.load", data);
		this._session = data;
	}
	
	clear() {
		this._session = null;
		this.authToken = null;
	}

	get navigateToCache(): string|null {
		return localStorage.getItem("talentedteam-navigate-to-cache");
	}

	set navigateToCache(url: string|null) {
		if (url) {
			localStorage.setItem("talentedteam-navigate-to-cache", url);
		} else {
			localStorage.removeItem("talentedteam-navigate-to-cache");
		}
	}

	isAuthenticated() {
		return this.authenticated;
	}

	getAccountId(): number|null {
		if (!this._session || !this._session.account) return null;
		return this._session.account.id;
	}

	getAccount() {
		if (!this._session || !this._session.account) return null;
		return this._session.account;
	}

	getAuthType() {
		if (!this._session || !this._session.account) return null;
		return this._session.account.authType;
	}

	getUserId(): number|null {
		if (!this._session || !this._session.user) return null;
		return this._session.user.id;
	}

	isProjectsEnabled() {
		return (this._session && this._session.account ? this._session.account.projectsEnabled : false);
	}

	isTimesheetsEnabled() {
		return (this._session && this._session.account ? this._session.account.timesheetsEnabled : false);
	}

	getUser() {
		if (!this._session || !this._session.user) return null;
		return this._session.user;
	}

	isUserRole() {
		if (!this._session || !this._session.user) return null;
		return this._session.user.userRole;
	}


	isManagerRole() {
		return this.isRole(Dto.UserRoleType.MANAGER) || this.isAdminRole();
	}

	isProjectManagerRole(project: Dto.ProjectDto) {
		return this.isRole(Dto.UserRoleType.PROJECT_MANAGER, project) || this.isManagerRole();
	}

	getTimeUnits() {
		return "Days";	//future, make this configurable
	}

	isAdminRole() {
		return this.isRole(Dto.UserRoleType.ADMIN);
	}

	isRootRole() {
		return this.isRole(Dto.UserRoleType.ROOT);
	}

	isRole(roleType: Dto.UserRoleType, project?: Dto.ProjectDto) {
		if (!this._session || !this._session.user) return false;
		return this.isUserInRole(this._session.user, roleType, project)
	}

	isUserInRole(user: Dto.UserDto, roleType: Dto.UserRoleType, project?: Dto.ProjectDto) {

		if (user == null || user.roles == null) return false;
		var matched = false;

		if (!this._session || !this._session.user) return false;
		this._session.user.roles.forEach(function (role) {
			if (role.type === roleType && (!role.projectId || (project && role.projectId === project.id))) matched = true;
		});
		return matched;

	}

	hasRoles() {
		if (!this._session || !this._session.user) return false;
		return (this._session.user != null && this._session.user.roles != null && this._session.user.roles.length > 0);
	}

	isRoleSeniority(roleType) {

		switch(roleType) {
			case Dto.UserRoleType.ADMIN: {
				return this.isAdminRole();
			}

			case Dto.UserRoleType.PROJECT_MANAGER: {
				return this.isManagerRole();
			}
			case Dto.UserRoleType.MANAGER: {
				return this.isManagerRole();
			}
			default: {
				return false;
			}
		}

	}

	isWriteAccess(user: Dto.UserDto) {
		if (this.isManagerRole()) return true;
		if (this._session && this._session.user != null && user.id === this._session.user.id) return true;
		return false;
	}

	isWriteAccessToProject(project: Dto.ProjectDto) {
		if (this.isManagerRole()) return true;
		if (this.isProjectManagerRole(project)) return true;
		//if (this._session.user != null && project.owner != null && project.owner.id === this._session.user.id) return true;
		return false;
	}

	getData() {
		return this._session;
	}

	getTemplates() {
		if (!this._session) return [];
		return this._session.templates;
	}

	getTags() {
		if (!this._session) return [];
		return this._session.tags;
	}

	getOffices() {
		if (!this._session) return [];
		return this._session.offices;
	}

	getDepartments() {
		if (!this._session) return [];
		return this._session.departments;
	}

	getFeatures() {
		if (!this._session) return [];
		return this._session.features;
	}

	getTemplateById(templateId: number) {

		let template = this.getTemplates().find(template => {
			//logger.debug("template.id === templateId", template.id === templateId, template.id, templateId)
			return template.id == templateId;
		});
		
		if (template) return template;

		return null;

	}

	getSetting(key: string) {
		return this._session?.settings[key];
	}

	getLoginUrl() {
		return this._session;
	}

	processRecievedSettings(data: Dto.SettingsDto) {
		this._session = data;
	}

	isSkillTypeCreatePermitted() {

		if (this.isAdminRole()) return true;

		if (this._session && this._session.account && this._session.account.skillTypeCreateMinimumRole === 'MANAGER') {
			if (this.isManagerRole()) return true;
			return false;
		}

		/*if (this._session && this._session.account && this._session.account.skillTypeCreateMinimumRole === 'SALES') {
			if (this.isManagerRole()) return true;
			return false;
		}*/

		if (this._session && this._session.account && this._session.account.skillTypeCreateMinimumRole === 'USER') {
			if (this.isManagerRole()) return true;
			if (this.isUserRole()) return true;
			return false;
		}
		if (this._session && this._session.account && this._session.account.skillTypeCreateMinimumRole === 'GUEST') {
			return true;
		}

		if (this._session && this._session.account && this._session.account.skillTypeCreateMinimumRole == null) {
			return true;
		}

		return false;

	}


}

const sessionStore = new SessionStore();
export default sessionStore;
