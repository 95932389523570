import { ErrorDto, SubscriberReviewStatus, TimesheetLogDto, TimesheetStatus, TimesheetSubscriptionDto } from "actions/Dto"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import * as Icons from 'react-bootstrap-icons';
import * as TimesheetsActions from "actions/TimesheetsActions";
import ErrorMessages from "components/general/ErrorMessages"
import * as Utils from "components/general/Utils";

import "./Subscription.css"
import Spinner from "components/general/Spinner"
import { IconButton } from "components/general/IconButton"
import { Model, TextArea } from "components/forms/FormFields"

const ACTION_ACCEPTANCE = "accept";

export const SubscriberPage: React.FunctionComponent<{}> = (props) => {

	let params = useParams();
	const history = useHistory();

	const [ errors, setErrors ] = useState<ErrorDto[] | null >(null);

	const [ initialToken, setInitialToken ] = useState<string>();
	const [ token, setToken ] = useState<string>();
	const [ subscription, setSubscription ] = useState<TimesheetSubscriptionDto>();

	const [ showRejectDialog, setShowRejectDialog ] = useState(false);
	const [ rejectNotes, setRejectNotes ] = useState<string>();


	const acceptTimesheet = (status: SubscriberReviewStatus) => {
		if (token) {
			TimesheetsActions.timesheetAcceptBySubsciberToken(token, { status: status, notes: rejectNotes || ""})
				.then((subscription) => {
					setSubscription(subscription);
					setShowRejectDialog(false);
					setRejectNotes("");
				})
				.catch(setErrors);
		}
	}

	useEffect(() => {

		setInitialToken(params["token"]);
		setToken(params["token"]);

	}, [params]);

	useEffect(() => {

		setRejectNotes("");

		const query = new URLSearchParams(history.location.search);
		const action = query.get("action");

		if (token) {

			TimesheetsActions.timesheetBySubsciberToken(token)
				.then(sub => {

					setSubscription(sub);

					if (token === initialToken && action === ACTION_ACCEPTANCE && sub.timesheet.status === TimesheetStatus.APPROVED) {
						acceptTimesheet(SubscriberReviewStatus.ACCEPTED);
					}
				})
				.catch(setErrors);
		
		}

	}, [token, initialToken]);





	const handlePrev = () => {
		if (subscription?.prevToken) {
			//history.push(Routes.timesheetSubscriptions(subscription.prevToken));
			setToken(subscription.prevToken);
		}
	}

	const handleNext = () => {
		if (subscription?.nextToken) {
			//history.push(Routes.timesheetSubscriptions(subscription.nextToken));
			setToken(subscription.nextToken);
		}
	}

	const handleShowRejectDialog = () => {
		setRejectNotes("");
		setShowRejectDialog(true);
	}

	const latestReview = () => {

		if (!subscription || !subscription.reviews || subscription.reviews.length === 0) return null;

		return subscription.reviews.reduce((a, b) => {
			return new Date(a.updated) > new Date(b.updated) ? a : b;
		});

	}

	const reviewStatus = () => {
		const _latestReview = latestReview();
		if (!_latestReview) return <div className="review-status">Not accepted</div>;

		return <div className={"review-status " + _latestReview.subscriberReview}>{_latestReview.subscriberReview.toLocaleLowerCase()}</div>

	}

	const sortReviews = (reviews: TimesheetLogDto[]) => {
		return [...reviews].sort((a, b) => {
			if (a.updated < b.updated) return 1;
			if (a.updated > b.updated) return -1;
			return 0;
		});
	}

	if (!subscription) {
		return (
			<div className="spinner-container">
				<Spinner />
			</div>
		)
	}

	return (
		<section className="subscription-layout" >

			<div className="container">
				<header>
					<img className="brand" alt="Logo" src={"/api/accounts/" + subscription.accountId + "/logo.png"} />
				</header>
			</div>
			<div className="container main">
				<ErrorMessages errors={errors} />
				
				<div className="timesheet-header">
					<h1>Timesheet</h1>
					<div className="timesheet-header__details">
						<div className="project">
							{subscription.subscriber.assignment.project.organisation ? subscription.subscriber.assignment.project.organisation + " - " : ""}{subscription.subscriber.assignment.project.title}
						</div>
						<div className="timesheet-name">{subscription.timesheet.user.fullname} / {subscription.subscriber.assignment.title}</div>
						<div className="timesheet-status"><div className={"badge badge-pill status-" + subscription.timesheet.status}>{Utils.titleCase(subscription.timesheet.status)}</div></div>
						<div className="timesheet-date">
							<div className="skip">{ subscription.prevToken ? <IconButton icon={<Icons.SkipStartFill />} onClick={() => handlePrev()}/> : null }</div>
							<div className="date">{ Utils.formatDateWithMonth(subscription.timesheet.date) }</div>
							<div className="skip">{ subscription.nextToken ? <IconButton icon={<Icons.SkipEndFill />} onClick={() => handleNext()}/> : null }</div>
						</div>
					</div>

					{reviewStatus()}
					
				</div>

				
				<div className="timesheet-table">
					{ subscription.timesheet.entries.length === 0 ? <div>No timesheet entries</div> : null }
					<table>
						<tbody>
							{subscription.timesheet.entries.map(entry => {

								return (
									<tr key={entry.id} className="timesheet-entry">
										<td width="60%" className="timesheet-entry__date">
											<div>{Utils.dayOfWeek(entry.date)}</div>
											<small>{Utils.formatDateWithMonth(entry.date)}</small>
										</td>
										<td width="40%" className="timesheet-entry__value">
											<div>{entry.quantity} day{entry.quantity > 1 ? "s" : ""}</div>
											{entry.billable ? null : <small>Not billable</small>}
										</td>
									</tr>
								);

							})}
							
						</tbody>
					</table>

					
				</div>
				<div className="subscriber">
					<h5>Subscriber:</h5>
					<div className="subscriber-name">{subscription.subscriber.user.fullname}</div>

					<div className="subscriber-actions">
						
							{subscription.timesheet.status === TimesheetStatus.APPROVED ?
								<>
									<div className="acceptance">
										<button className="btn btn-default" onClick={() => acceptTimesheet(SubscriberReviewStatus.ACCEPTED)}>Accept</button>
										<button className="btn" onClick={handleShowRejectDialog}>Reject</button>
									</div>
									<div className="download">
										
									</div>
								</>
								: <div className="acceptance">This timesheet has not been finalised and is not yet ready for review.</div>
							}
						
						
					</div>
					<div className="timesheet-logs">
						<div>Review Log:</div>
						<table><tbody>
						{ subscription.reviews && subscription.reviews.length > 0 ? 
							sortReviews(subscription.reviews).map((timesheetLog, index) => {
								return (
									<tr key={"log-" + index} className="timesheet-log">
										<td>{Utils.formatDateTime(timesheetLog.updated)}</td>
										<td>{timesheetLog.updatedBy.fullname}</td>
										<td>{timesheetLog.totalQuantity} days</td>
										<td>{timesheetLog.subscriberReview}</td>
										<td>{timesheetLog.notes}</td>
									</tr>
								);
							})
							: <tr><td>(none)</td></tr>
						}
						</tbody>
						</table>
					</div>
					
					<Model title="Reject timesheet" isOpen={showRejectDialog} onConfirm={() => acceptTimesheet(SubscriberReviewStatus.REJECTED)}
						onCancel={() => setShowRejectDialog(false)} confirmLabel="Reject">
							
							<TextArea title="Message" 
								value={rejectNotes} rows={5}
								onChange={(e) => setRejectNotes(e.target.value)}
								helpText={`Please enter a summary of why the timesheet is rejected. This message will be sent to ${subscription.timesheet.user.firstname}.`}
							/>

					</Model>

				</div>

			</div>

		</section>

		

	)

}