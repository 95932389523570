import React from 'react';
import { Route, Link, Switch } from "react-router-dom"

import Routes from "actions/Routes";

import JoinPage from "../pages/users/JoinPage";
import LoginPage from "../pages/LoginPage"
import AccountCreatePage from "../pages/AccountCreatePage"

import Footer from './Footer'

import './Layout.css';
import { SubscriberPage } from "components/pages/timesheets/SubscriberPage";

export default class UnauthenticatedLayout extends React.Component {

	render() {
		return (
			<div className="console-layout unauthenticated-layout">

				<div className="console-body">

					<section>

						<nav className="console-top-nav">
							<Link to="/splash" className="nav-logo"></Link>
							<ul className="splash-menu">
								<li><Link to={Routes.home()}>Home</Link></li>
								<li><Link to={Routes.login()}>Sign-in</Link></li>
								<li><Link to={Routes.accountCreate()} >Create Account</Link></li>
							</ul>
						</nav>

						<div className="main-content container">

							<Switch>
								<Route path="/join/:token" component={JoinPage} />
								<Route path="/subscription" component={SubscriberPage} />
								<Route path={Routes.accountCreate()} component={AccountCreatePage} />
								
								<Route component={LoginPage} />
							</Switch>

						</div>

					</section>

				</div>

				<Footer />

			</div>
		);
	}

}