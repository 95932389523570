import React from "react";
import { Link } from "react-router-dom";

import * as Dto from "actions/Dto";
import logger from 'actions/Logger';
import Routes from "actions/Routes";
import * as TimesheetsActions from "actions/TimesheetsActions";
import * as FormFields from "components/forms/FormFields";
import { IconButton } from "components/general/IconButton";
import * as Utils from "components/general/Utils";
import UserAvatar from "components/pages/users/UserAvatar";
import * as Icons from 'react-bootstrap-icons';
import SessionStore from "store/SessionStore";
import { TimesheetDay, isTimesheetEntryRangeError, isTimesheetEntryRangeWarning } from "./TimesheetDay";
import { briefStatusPill } from "./TimesheetsPage";


export const TIME_RANGE_WARNING = 1.4;
export const TIME_RANGE_ERROR = 2;

interface TimesheetProps {
	assignments: Dto.TimesheetAssignmentDto[]
	leaveTypes: any
	user: Dto.UserDto
	date: Date
	onError: (error: Dto.ErrorDto[]) => void
	onMessage: (messages: Dto.MessageDto[]) => void
	onDateChanged: (date: Date) => void
}

interface TimesheetState {
	timesheet: Dto.TimesheetDto|null
	timesheetLogs: Dto.PagedListDto<Dto.TimesheetLogDto>|null
	subscribers: Dto.PagedListDto<Dto.SubscriberDto>|null
	showSubmitConfirm: boolean
}

export default class Timesheet extends React.Component<TimesheetProps, TimesheetState> {

	constructor(props: TimesheetProps) {
		super(props);
		this.state = {timesheet: null, timesheetLogs: null, showSubmitConfirm: false, subscribers: null}
		this.reload();
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *		H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	reload() {

		if (SessionStore.accountId) {
			if (this.props.date) {
				
				TimesheetsActions.timesheetForDate(SessionStore.accountId, this.props.user.id, new Date(this.props.date),
					(timesheet) => {
						this.setState({timesheet: timesheet, timesheetLogs: null});
						this.loadTimesheetLogs(timesheet.id);
						//this.props.onMessage(["Next timesheet loaded"])
					},
					(errors) => this.props.onError(errors)
				);
				

			} else {

				TimesheetsActions.timesheetCurrent(SessionStore.accountId, this.props.user.id,
					(timesheet) => {
						this.setState({timesheet: timesheet, timesheetLogs: null});
						this.loadTimesheetLogs(timesheet.id);
					},
					(errors) => this.props.onError(errors)
				);

			}
		}
	}

	loadTimesheetLogs(timesheetId: number) {
		if (SessionStore.accountId && timesheetId) {
			this.setState({timesheetLogs: null});
			TimesheetsActions.timesheetLogs(SessionStore.accountId, timesheetId,
				(timesheetLogs) => this.setState({timesheetLogs: timesheetLogs}),
				(errors) => this.props.onError(errors)
			);
			TimesheetsActions.timesheetSubscribers(SessionStore.accountId, timesheetId,
				(subscribers) => this.setState({subscribers: subscribers}),
				(errors) => this.props.onError(errors)
			);
		}
	}

	handleEntryUpdate(entry: Dto.TimesheetEntryDto) {

		let timesheet = this.state.timesheet;
		logger.debug("entry", entry, timesheet);

		if (!timesheet) return;

		if (entry.assignment) {

			//for assignment
			let entryFound = timesheet.entries.find((nextEntry) => entry.assignment && nextEntry.assignment && nextEntry.assignment.id === entry.assignment.id && nextEntry.date === entry.date && nextEntry.billable === entry.billable);
			if (!entryFound) {
				timesheet.entries.push(entry);
			} else { //entry object already in tree
				//entryFound.quantity = entry.quantity
			}	

		} else if (entry.leaveType) {

			//for leave
			let entryFound = timesheet.entries.find((nextEntry) => entry.leaveType && nextEntry.leaveType && nextEntry.leaveType.id === entry.leaveType.id && nextEntry.date === entry.date);
			if (!entryFound) {
				timesheet.entries.push(entry);
			} else {	//entry object already in tree
				//entryFound.quantity = entry.quantity
			}

		}
		//console.log(timesheet);
		this.setState({timesheet: timesheet}, () => logger.debug("timesheet updated", this.state.timesheet));

	}

	calculateTotals(entries: Dto.TimesheetEntryDto[]) {

		let total = entries.reduce((total, entry) => {
			let quantity = entry.quantity;
			return total + (isNaN(quantity) ? 0 : quantity);
		}, 0);
		return total;

	}

	rangeErrorCheck(entries: Dto.TimesheetEntryDto[]) {
		return entries.findIndex(entry => isTimesheetEntryRangeError(entry.quantity)) >= 0;
	}

	rangeWarningCheck(entries: Dto.TimesheetEntryDto[]) {
		return entries.findIndex(entry => isTimesheetEntryRangeWarning(entry.quantity)) >= 0;
	}

	handleAddAssignment(assignment: Dto.TimesheetAssignmentDto, billable: boolean) {
		let timesheet = this.state.timesheet;
		if (timesheet) {
			timesheet.entries.push(
				{assignment: assignment, date: timesheet.date, billable: billable, quantity: 0} as Dto.TimesheetEntryDto
			);
			this.setState({timesheet: timesheet});
		}
	}

	handleAddLeave(leaveType) {
		let timesheet = this.state.timesheet;
		if (timesheet) {
			timesheet.entries.push(
				{leaveType: leaveType, date: timesheet.date, billable: false, quantity: 0} as Dto.TimesheetEntryDto
			);
			this.setState({timesheet: timesheet});
		}
	}


	handleNext() {

		if (!this.state.timesheet || !SessionStore.accountId) return;

		let date = new Date(this.state.timesheet.date);
		date.setDate(date.getDate() + 7);

		TimesheetsActions.timesheetForDate(SessionStore.accountId, this.props.user.id, date,
			(timesheet) => {
				this.setState({timesheet: timesheet, timesheetLogs: null});
				this.loadTimesheetLogs(timesheet.id);
				this.props.onDateChanged(date);
				//this.props.history.push(Routes.timesheetsForUser(this.props.user.id, Utils.formatDateISO(date)));
			},
			(errors) => this.props.onError(errors)
		);

	}

	handlePrev() {

		if (!this.state.timesheet || !SessionStore.accountId) return;

		let date = new Date(this.state.timesheet.date);
		date.setDate(date.getDate() - 7);

		TimesheetsActions.timesheetForDate(SessionStore.accountId, this.props.user.id, date,
			(timesheet) => {
				this.setState({timesheet: timesheet, timesheetLogs: null});
				this.loadTimesheetLogs(timesheet.id);
				this.props.onDateChanged(date);
				//this.props.history.push(Routes.timesheetsForUser(this.props.user.id, Utils.formatDateISO(date)));
			},
			(errors) => this.props.onError(errors)
		);

	}

	handleApprove() {

		if (!this.state.timesheet || !SessionStore.accountId) return;

		TimesheetsActions.timesheetApprove(SessionStore.accountId, this.state.timesheet.id,
			(timesheet) => {
				this.setState({timesheet: timesheet});
				this.loadTimesheetLogs(timesheet.id);
			},
			(errors) => this.props.onError(errors)
		);
	}

	handleReject() {

		if (!this.state.timesheet || !SessionStore.accountId) return;

		TimesheetsActions.timesheetReject(SessionStore.accountId, this.state.timesheet.id,
			(timesheet) => {
				this.setState({timesheet: timesheet});
				this.loadTimesheetLogs(timesheet.id);
			},
			(errors) => this.props.onError(errors)
		);
	}

	handleStatusRatingChange(value: Dto.TimesheetRating) {
		let timesheet = this.state.timesheet;
		if (timesheet) {
			timesheet.statusRating = value;
			this.setState({timesheet: timesheet});
		}
	}

	handleStatusReportChange(event) {
		let timesheet = this.state.timesheet;
		if (timesheet) {
			timesheet.statusReport = event.target.value;
			this.setState({timesheet: timesheet});
		}
	}

	handleNotifySubscribers() {
		if (!this.state.timesheet || !SessionStore.accountId) return;

		TimesheetsActions.timesheetNotifySubscribers(SessionStore.accountId, this.state.timesheet.id,
			(timesheetLogs) => this.setState({timesheetLogs: timesheetLogs}),
			(errors) => this.props.onError(errors)
		);
	}

	handleSave(status: Dto.TimesheetStatus) {

		if (!this.state.timesheet || !SessionStore.accountId) return;

		//validate entries
		for(let entry of this.state.timesheet.entries) {
			if (isTimesheetEntryRangeError(entry.quantity)) {
				this.props.onError([{code: "error", message: `The timesheet entry for ${ Utils.dayOfWeek(entry.date)} is not a valid number. Please enter a value as a unit of one day.` }]);
				return;
			}
		}
		

		//show commit confirmation
		if (status === Dto.TimesheetStatus.SUBMITTED && !this.state.showSubmitConfirm) {
			this.handleShowSubmitConfirm();
			return;
		}

		//SUBMIT VALIDATION OFF FOR NOW: see false in if
		if (false) {
			/* && status === Dto.TimesheetStatus.SUBMITTED &&
			this.calculateTotals(this.state.timesheet.entries) < this.state.timesheet.minimumMinutes) {

			//Min hours not added
			this.props.onError([{code: "error", message:"You must enter " + this.state.timesheet.minimumMinutes/60 + " hours before you can submit your timesheet. In the meantime, you can hit save to save your latest changed."}]);
			*/
		} else {

			let timesheet = this.state.timesheet;

			//Create Entry Forms
			let entries: Dto.TimesheetEntryForm[] = [];
			timesheet.entries.forEach((entry) => entries.push( 
				{
					id: entry.id, 
					assignmentId: (entry.assignment ? entry.assignment.id : undefined), 
					leaveTypeId: (entry.leaveType ? entry.leaveType.id : undefined), 
					date: entry.date, 
					billable: entry.billable, 
					quantity: entry.quantity
				}
			));

			//Create Timesheet Forms
			let timesheetForm : Dto.TimesheetForm = {
				date: timesheet.date,
				status: status,
				notes: 'updated',
				statusRating: timesheet.statusRating,
				statusReport: timesheet.statusReport,
				entries: entries
			};

			let message = (status === Dto.TimesheetStatus.SUBMITTED ? "Timesheet submitted" : "Timesheet saved")

			TimesheetsActions.timesheetSave(SessionStore.accountId, this.props.user.id, timesheetForm,
				(timesheet) => {
					this.setState({timesheet: timesheet, showSubmitConfirm: false});
					this.loadTimesheetLogs(timesheet.id);
					this.props.onMessage([{message: message}]);
				 },
				(errors) => this.props.onError(errors)
			);
		}

	}

	handleClear() {

		if (!this.state.timesheet || !SessionStore.accountId) return;

		if(window.confirm("Remove all times from timesheet?")) {
			let timesheet = this.state.timesheet;
			timesheet.entries.forEach((entry) => {
				if (!entry.holiday) entry.quantity = 0;
			});
			this.setState({timesheet: timesheet});
		}
	}

	handleShowSubmitConfirm() {
		this.setState({showSubmitConfirm: true});
	}

	handleHideSubmitConfirm() {
		this.setState({showSubmitConfirm: false});
	}

	newestSubscriberTimesheetLog(subscriber: Dto.SubscriberDto) {

		if (!this.state.timesheetLogs || this.state.timesheetLogs.values.length === 0) return null;

		const filteredLogs = this.state.timesheetLogs.values.filter(log => log.subscriberId === subscriber.id);

		if (filteredLogs.length === 0) return null;

		//Return the latest
		return filteredLogs.reduce((previous, current) => {
			return new Date(previous.updated) > new Date(current.updated) ? previous : current;
		});
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
	renderStatus() {
		if (this.state.timesheet) {

			if (!this.state.timesheet.id) {
				return "Unsaved";
			}
			return Utils.titleCase(this.state.timesheet.status);
			
		}
	}

	renderStatusMessage() {

		if (this.state.timesheet) {



			/*
			if (this.rangeErrorCheck(this.state.timesheet.entries)) {
				return (
					<FormFields.InfoBox className="error">
						Your timesheet contains values that are out of range. Please check that each cell below specifies your time in Day units.
					</FormFields.InfoBox>
				);
			}

			if (this.rangeWarningCheck(this.state.timesheet.entries)) {
				return (
					<FormFields.InfoBox className="warning">
						Your timesheet contains values that are out of range. Please check that each cell below specifies your time in Day units.
					</FormFields.InfoBox>
				);
			}
			*/

			if (!this.state.timesheet.id) {
				return (
					<FormFields.InfoBox className="warning">
						The timesheet is unsaved. Click save to save the timesheet or when complete, click submit for approval.
					</FormFields.InfoBox>
				);
			}

			switch (this.state.timesheet.status) {
				case Dto.TimesheetStatus.DRAFT:
					return ( <FormFields.InfoBox className="plain"></FormFields.InfoBox> );
					/*
					eturn (
						<FormFields.InfoBox className="warning">
							The timesheet is in a Draft. When complete, submit for approval.
						</FormFields.InfoBox>
					);
					*/
				case Dto.TimesheetStatus.APPROVED:
					return (
						<FormFields.InfoBox className="plain"></FormFields.InfoBox>
					);
				case Dto.TimesheetStatus.REJECTED:
					return (
						<FormFields.InfoBox className="error">
							The timesheet has been rejected. Update your timesheet and resubmit.
						</FormFields.InfoBox>
					);
				case Dto.TimesheetStatus.SUBMITTED:
					return (
						<FormFields.InfoBox className="info">
							The timesheet is submitted and waiting for approval.
						</FormFields.InfoBox>
					);
				default:
					return (<FormFields.InfoBox className="plain"></FormFields.InfoBox>);

			}
		}

		return null;

	}

	renderLogs() {

		if (this.state.timesheetLogs) {
			return (
				<div className="timesheet-logs">

					<table>
						<tbody>
						{this.state.timesheetLogs.values.map((timesheetLog, index) => {
							if (index > 10) return null;
							return (
								<tr key={"log-" + index} className="timesheet-log">
									<td>{Utils.formatDateTime(timesheetLog.updated)}</td>
									<td>{timesheetLog.subscriberId ? " Subscriber: " : ""}{timesheetLog.updatedBy.fullname}</td>
									<td>{timesheetLog.totalQuantity} units</td>
									<td>{timesheetLog.subscriberId ?  timesheetLog.subscriberReview : timesheetLog.status}</td>
								</tr>
							);
						})}
						</tbody>
					</table>
				</div>
			);
		}

		return (
			<div className="timesheet-logs"></div>
		);

	}

	renderSubscriberStatus(subscriber: Dto.SubscriberDto) {

		const latest = this.newestSubscriberTimesheetLog(subscriber);
		if ( latest ) return <div className={"badge badge-pill status-" + latest.subscriberReview}>{latest.subscriberReview}</div>;

		return <div className="badge badge-pill status-NONE">Not sent</div>

	}

	renderSubscribers() {

		if (this.state.subscribers) {
			return (
				<div className="timesheet-subscribers" title="Subscribers">
					<div className="timesheet-subscribers-icon"><Icons.BellFill size={16} /></div>
					<table className="timesheet-subscribers-table"><tbody>
					{this.state.subscribers.values.map((subscriber, index) => {
						if (index > 10) return null;
						return (
							<tr key={"log-" + index} className="timesheet-log">
								<td>{subscriber.user.fullname}</td>
								<td>{this.renderSubscriberStatus(subscriber)}</td>
							</tr>
						);
					})}
					</tbody></table>
				</div>
			);
		}

		return <div className="timesheet-logs"></div>;

	}

	renderStatusRating() {
		if (this.state.timesheet?.statusRating) {
			switch (this.state.timesheet?.statusRating) {
				case Dto.TimesheetRating.GREEN:
					return (<div className="smiley happy selected"><Icons.EmojiSmile /></div>);
				case Dto.TimesheetRating.AMBER:
					return (<div className="smiley frown selected"><Icons.EmojiNeutral /></div>);
				case Dto.TimesheetRating.RED:
					return (<div className="smiley sad selected"><Icons.EmojiFrown /></div>);
			}
		}
		return null;
	}

	renderStatusReport() {

		if (this.state.timesheet?.statusReport) {
			return (
				<div className="timesheet-status-report">
					<div className="timesheet-status-report-header">
						<label>Status Report:</label>
						<div>
							{this.renderStatusRating()}
						</div>
					</div>
					<div className="timesheet-status-report-body">
						<div dangerouslySetInnerHTML={{__html: Utils.renderMarkdown(this.state.timesheet.statusReport)}} />
					</div>
				</div>
			);
		}

		return null;

	}

	renderButtons() {

		if (this.state.timesheet) {

			switch (this.state.timesheet.status) {
				case Dto.TimesheetStatus.APPROVED:
					if (SessionStore.isManagerRole()) {
						return (
							<React.Fragment>
								<button className="btn btn-warning" onClick={() => this.handleSave(Dto.TimesheetStatus.DRAFT)}>Unapprove</button>
								{ this.state.subscribers && this.state.subscribers.values.length > 0 ? <button className="btn" onClick={() => this.handleNotifySubscribers()}>Notify Subscribers</button> : null }
							</React.Fragment>
						);
					} else {
						return null;
					}
				case Dto.TimesheetStatus.DRAFT:
					return (
						<React.Fragment>
							<button className="btn btn-default" onClick={() => this.handleSave(Dto.TimesheetStatus.SUBMITTED)}>Submit</button>
							<button className="btn" onClick={() => this.handleSave(Dto.TimesheetStatus.DRAFT)}>Save</button>
							<button className="btn" onClick={() => this.handleClear()}>Clear</button>
						</React.Fragment>
					);
				case Dto.TimesheetStatus.REJECTED:
					return (
						<React.Fragment>
							<button className="btn btn-default" onClick={() => this.handleSave(Dto.TimesheetStatus.SUBMITTED)}>Submit</button>
							<button className="btn" onClick={() => this.handleSave(Dto.TimesheetStatus.DRAFT)}>Save</button>
							<button className="btn" onClick={() => this.handleClear()}>Clear</button>
						</React.Fragment>
					);
				case Dto.TimesheetStatus.SUBMITTED:
					return (
						<React.Fragment>
							{SessionStore.isManagerRole() ?
								<React.Fragment>
									<button className="btn btn-success" onClick={() => this.handleApprove()}>Approve</button>
									<button className="btn" onClick={() => this.handleReject()}>Reject</button>
								</React.Fragment>
								:null
							}
							<button className="btn" onClick={() => this.handleSave(Dto.TimesheetStatus.DRAFT)}>Unsubmit</button>
						</React.Fragment>
					);
				default:
					return null;
			}

		}

		return null;

	 }

	render() {

		if (!this.state.timesheet) return null;

		return (

			<div className="timesheet">

				<div className="timesheet-header">
					<div className="title">
						<h1><span className="firstname-title">{this.props.user.firstname}</span> <span className="surname-title">{this.props.user.surname}</span></h1>
					</div>
					<div className="info">
						{this.renderStatusMessage()}
					</div>
					<div className="status">
						<div className="details">
							<h4>Timesheet</h4>
							<div className={"badge badge-pill status-" + this.state.timesheet.status}>{this.renderStatus()}</div>
						</div>
						<UserAvatar user={this.props.user} />
					</div>
				</div>

				<div className="timesheet-editor">
					<TimesheetHeader timesheet={this.state.timesheet} onPrev={() => this.handlePrev()} onNext={() => this.handleNext()}/>
					<TimesheetDivider title="Billable" />
					<TimesheetAssignments timesheet={this.state.timesheet} billable={true} onEntryUpdate={(entry) => this.handleEntryUpdate(entry)}/>
					{
						isTimesheetEditable(this.state.timesheet) ?
							<TimesheetAddRow timesheet={this.state.timesheet} title="assignments" assignments={this.props.assignments} billable={true} onAddAssignment={(assignment) => this.handleAddAssignment(assignment, true)} />
							: null
					}
					<TimesheetDivider title="Non-billable" />
					<TimesheetAssignments timesheet={this.state.timesheet} billable={false} onEntryUpdate={(entry) => this.handleEntryUpdate(entry)} />
					{
						isTimesheetEditable(this.state.timesheet) ?
							<TimesheetAddRow timesheet={this.state.timesheet} title="items" assignments={this.props.assignments} leaveTypes={this.props.leaveTypes} billable={false} onAddAssignment={(assignment) => this.handleAddAssignment(assignment, false)} onAddLeave={(leaveType) => this.handleAddLeave(leaveType)} />
							: null
					}
					<TimesheetTotals total={this.calculateTotals(this.state.timesheet.entries)} />
				</div>

				<div className="timesheet-controls">
					<div className="timesheet-buttons">
						{this.renderButtons()}
					</div>
					{this.renderSubscribers()}
				</div>

				<div className="timesheet-footer">
					{this.renderLogs()}
					
					{this.renderStatusReport()}
				</div>

				<FormFields.Model isOpen={this.state.showSubmitConfirm} title="Submit Timesheet"
					onConfirm={() => this.handleSave(Dto.TimesheetStatus.SUBMITTED)}
					onCancel={() => this.handleHideSubmitConfirm() }
					confirmLabel="Submit"
					>


					<FormFields.TextArea title="Status Report" 
						value={this.state.timesheet.statusReport} rows={5}
						onChange={(e) => this.handleStatusReportChange(e)}
						helpText="Please enter a summary of your progress for last week and plan for next week."
					/>
					
					<FormFields.SmileySelector 
						title="Status" ratings={Object.keys(Dto.TimesheetRating)} 
						value={this.state.timesheet.statusRating}  
						onClick={(value) => this.handleStatusRatingChange(value)}
					/>

				</FormFields.Model>

			</div>
		)
	}

}

function isTimesheetEditable(timesheet: Dto.TimesheetDto) {
	return timesheet.status === Dto.TimesheetStatus.DRAFT || timesheet.status === Dto.TimesheetStatus.REJECTED;
}

interface TimesheetPeriodProps {
	dateLabel: string
	date: Date
	onNext: () => void
	onPrev: () => void
}
function TimesheetPeriod(props: TimesheetPeriodProps) {

	return (
		<div className="period-selector">
			<div className="skip"><IconButton icon={<Icons.SkipStartFill />} onClick={() => props.onPrev()} /></div>
			<button className="date"><label>{props.dateLabel ? props.dateLabel : "Period"}</label><strong>{Utils.formatDateWithMonth(props.date)}</strong></button>
			<div className="skip"><IconButton icon={<Icons.SkipEndFill />} onClick={() => props.onNext()} /></div>
		</div>
	)

}

interface TimesheetHeaderProps {
	timesheet: Dto.TimesheetDto
	onNext: () => void
	onPrev: () => void
}
function TimesheetHeader(props: TimesheetHeaderProps) {

	return (
		<div className="timesheet-row header">
			<div className="timesheet-cell detail">
				<TimesheetPeriod date={new Date(props.timesheet.date)} dateLabel={props.timesheet.dateLabel} onPrev={props.onPrev} onNext={props.onNext} />
			</div>
			<div className="timesheet-cell brief">
			</div>
			<TimesheetHeaderDay day={0} timesheet={props.timesheet} />
			<TimesheetHeaderDay day={1} timesheet={props.timesheet} />
			<TimesheetHeaderDay day={2} timesheet={props.timesheet} />
			<TimesheetHeaderDay day={3} timesheet={props.timesheet} />
			<TimesheetHeaderDay day={4} timesheet={props.timesheet} />
			<TimesheetHeaderDay day={5} timesheet={props.timesheet} />
			<TimesheetHeaderDay day={6} timesheet={props.timesheet} />

			<div className="timesheet-cell total">
				Total
				<div className="units">Days</div>
			</div>
		</div>
	)

}

interface TimesheetHeaderDay {
	timesheet: Dto.TimesheetDto
	day: number
}
function TimesheetHeaderDay(props: TimesheetHeaderDay) {

		var date = new Date(props.timesheet.date);
		date.setDate(date.getDate() + props.day);

		return (
			<div className={"timesheet-cell day day" + props.day}>
				<div className="day-of-week">{Utils.dayOfWeek(date).substring(0,3)}</div>
				<div className="date">{(date.getDate())}</div>
			</div>
		);

}

function findUniqueAssignmentsUsed(timesheet: Dto.TimesheetDto, billable: boolean): Dto.TimesheetAssignmentDto[] {

	var assignments: Dto.TimesheetAssignmentDto[] = [];

	//find unique assignments
	timesheet.entries.forEach((entry) => {
		if (entry.assignment && !(entry.assignment.id in assignments) && entry.billable === billable) assignments[entry.assignment.id] = entry.assignment;
	});

	return assignments;

}

function findUniqueLeaveTypesUsed(timesheet: Dto.TimesheetDto): Dto.LeaveTypeDto[] {

	var leaveTypes: Dto.LeaveTypeDto[] = [];

	//find unique assignments
	timesheet.entries.forEach((entry) => {
		if (entry.leaveType && !(entry.leaveType.id in leaveTypes)) leaveTypes[entry.leaveType.id] = entry.leaveType;
	});

	return leaveTypes;
}

interface TimesheetAssignmentsProps {
	timesheet: Dto.TimesheetDto
	billable: boolean
	onEntryUpdate: (entry: Dto.TimesheetEntryDto) => void
}

function TimesheetAssignments(props: TimesheetAssignmentsProps) {

	if (!props.timesheet) return null;

	let assignmentsUsed = findUniqueAssignmentsUsed(props.timesheet, props.billable);
	let leaveTypesUsed = findUniqueLeaveTypesUsed(props.timesheet);

	let assignments = assignmentsUsed.map((assignment) => {

		let entries = props.timesheet.entries.filter((entry) =>
			entry.billable === props.billable && entry.assignment && entry.assignment.id === assignment.id
		);

		//logger.debug("Entries", entries);

		return (
			<TimesheetAssignment key={"assignment-" + assignment.id}
					timesheet={props.timesheet}
					assignment={assignment} entries={entries}
					billable={props.billable}
					onEntryUpdate={props.onEntryUpdate}
				/>
		);

	});

	let leaveTypes = leaveTypesUsed.map((leaveType) => {

		let entries = props.timesheet.entries.filter((entry) =>
			entry.billable === false && entry.leaveType && entry.leaveType.id === leaveType.id
		);

		return (
			<TimesheetAssignment key={"leave-" + leaveType.id}
					timesheet={props.timesheet}
					leaveType={leaveType} entries={entries}
					billable={false}
					onEntryUpdate={props.onEntryUpdate}
				/>
		);

	});

	return (
		<React.Fragment>
			{assignments}
			{!props.billable ? leaveTypes : null}
		</React.Fragment>
	)

}

interface TimesheetAssignmentProps {
	timesheet: Dto.TimesheetDto
	entries: Dto.TimesheetEntryDto[]
	assignment?: Dto.TimesheetAssignmentDto
	leaveType?: Dto.LeaveTypeDto
	billable: boolean
	onEntryUpdate: (entry: Dto.TimesheetEntryDto) => void
}

class TimesheetAssignment extends React.Component<TimesheetAssignmentProps> {

	calculateTotal(entries) {
		//console.log(entries);
		let total = entries.reduce((total: number, entry: Dto.TimesheetEntryDto) => {
			let quantity = entry.quantity;
			return total + (isNaN(quantity) ? 0 : quantity);
		}, 0);

		return parseFloat((total).toFixed(1))
	}

	render() {

		var title: JSX.Element|null = null;

		if (this.props.assignment) {
			title = (
				<>
				<div className="timesheet-cell detail">
					<div className="client">{this.props.assignment.project.organisation}</div>
					<div className="assignment"><Link to={Routes.project(this.props.assignment.project.id)}>{this.props.assignment.project.title} - {this.props.assignment.title}</Link></div>
				</div>
				<div className="timesheet-cell brief">
					<span className="brief-status">{briefStatusPill(this.props.assignment)}</span>
				</div>
				</>
			)
		} else if (this.props.leaveType) {
			title = (
				<>
				<div className="timesheet-cell detail">
					<div className="client">{this.props.leaveType.title}</div>
					{ this.props.leaveType.description ? <div className="assignment">{this.props.leaveType.description}</div> : null }
				</div>
				<div className="timesheet-cell brief">
					<span className="brief-status"></span>
				</div>
				</>
			)
		} else {
			return null;
		}

		return (
			<div className="timesheet-row assignment">

				{title}

				{[0, 1, 2, 3, 4, 5, 6].map((day) => {

					//find entry for day
					var date = new Date(this.props.timesheet.date);
					date.setDate(date.getDate() + day);

					let entry = this.props.entries.find( (entry) =>  Utils.isDatesEqual(date, new Date(entry.date)) );
					
					//logger.debug("Find entry for day", day, date, entry);

					let key = (this.props.assignment ? this.props.assignment.id : this.props.leaveType?.id) + "-day" + day;

					//return timesheet component for entry
					return <TimesheetDay key={key} day={day}
						timesheet={this.props.timesheet}
						assignment={this.props.assignment} leaveType={this.props.leaveType}
						date={Utils.formatDateISO(date)}
						entry={entry} billable={this.props.billable}
						onUpdate={this.props.onEntryUpdate}
						/>

				})}

				<div className="timesheet-cell total">
					<div className="time">{this.calculateTotal(this.props.entries)}</div>
					<div className="units">Days</div>
				</div>
			</div>
		);

	}
}

interface TimesheetAddRowProps {
	title: string
	timesheet: Dto.TimesheetDto
	assignments: Dto.TimesheetAssignmentDto[]
	billable: boolean
	leaveTypes?: Dto.LeaveTypeDto[]
	onAddAssignment?: (assignment: Dto.TimesheetAssignmentDto) => void
	onAddLeave?: (leave: Dto.LeaveTypeDto) => void
}

class TimesheetAddRow extends React.Component<TimesheetAddRowProps> {


	render() {

		if (!this.props.assignments) return (
			<div className="timesheet-row add-row">
				<div className="timesheet-cell detail">(no available {this.props.title})</div>
			</div>
		)

		var leaveTypes: (JSX.Element|null)[] = [];
		var assignments: (JSX.Element|null)[] = [];

		if (this.props.assignments) {

			let assignmentsUsed = findUniqueAssignmentsUsed(this.props.timesheet, this.props.billable);

			assignments = this.props.assignments.map((assignment) => {
				
				//Check if assignment dates are valid
				if (assignment.id in assignmentsUsed || new Date(assignment.endDate) < new Date(this.props.timesheet.date)) return null;

				//Check if billable has a rate
				//REMOVED -> if (this.props.billable && !assignment.billable) return null;
				//if (this.props.billable) return null;

				return (
					<button key={"add-item-" + assignment.id + "-" + this.props.billable} className="dropdown-item assignment" onClick={() => (this.props.onAddAssignment ? this.props.onAddAssignment(assignment) : null)}>
						<div className="client">{assignment.project.organisation}</div>
						<div className="assignment">{assignment.project.title} - {assignment.title}</div>
					</button>
				);

			}).filter((assignment) => assignment != null);

		}

		if (this.props.leaveTypes) {

			let leaveTypeUsed = findUniqueLeaveTypesUsed(this.props.timesheet);

			leaveTypes = this.props.leaveTypes.map((leaveType) => {

				if (leaveType.id in leaveTypeUsed) return null;

				return (
					<button key={"add-item-" + leaveType.id} className="dropdown-item assignment" onClick={() => (this.props.onAddLeave ? this.props.onAddLeave(leaveType) : null)}>
						<div className="client">{leaveType.title}</div>
						{leaveType.description ? <div className="assignment">{leaveType.description}</div> : null }
					</button>
				);

			}).filter((leaveType) => leaveType != null);

		}

		return (
			<div className="timesheet-row add-row">
				<div className="timesheet-cell detail">
					<div className="dropdown">
						<button className="btn dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							Add {this.props.title}
						</button>


						<div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
							{assignments && assignments.length > 0 ? assignments : <div className="dropdown-item">No {this.props.title} available to add</div>}
							{leaveTypes && leaveTypes.length > 0 ? <><div className="dropdown-divider"></div>{leaveTypes}</> : null}
						</div>
					</div>
				</div>
			</div>
		)
	}

}

interface TimesheetDividerProps {
	title: string
}

class TimesheetDivider extends React.Component<TimesheetDividerProps> {

	render() {
		return (
			<div className="timesheet-row divider">
				<div className="timesheet-cell detail">
					{this.props.title}
				</div>
			</div>
		)
	}

}

interface TimesheetTotalsProps {
	total: number
}

function TimesheetTotals(props: TimesheetTotalsProps) {

	return (
		<div className="timesheet-row totals">
			<div className="timesheet-cell detail">
				TOTAL
			</div>
			<div className="timesheet-cell total">
				<div className="time">{Utils.formatNumber(props.total, "#.#")}</div>
				<div className="units">Days</div>
			</div>
		</div>
	);

}