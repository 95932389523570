import React from 'react';
import * as FormFields from "components/forms/FormFields"


export default class SummaryStep extends React.Component {


	handleChange(event) {
		var profile = this.props.profile;
		profile[event.target.name] = event.target.value;
		this.props.onChange(profile);
	}

	render() {

		if (this.props.step !== this.props.index) return null;

		return (

			<React.Fragment>

				<h4>Summary</h4>

				<FormFields.Text name="headline" title="Headline" placeholder="Headline about your experience and skills" value={this.props.profile.headline } onChange={(e) => this.handleChange(e)} />
				<FormFields.TextArea name="summary" title="Career Summary" rows={5}
					placeholder="A short elevator pitch summary about yourself. This should be a paragraph or two about your experience, skills and most importantly the achievements that you are the most proud of and which will highlight your value."
					value={this.props.profile.summary} onChange={(e) => this.handleChange(e)} />


			</React.Fragment>

		);
	}

}