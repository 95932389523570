import * as Http from "./Http";
import * as Dto from "actions/Dto";


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

P R O J E C T S

* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function projects(accountId, page, filter, success, failure) {
	Http.get( "/api/accounts/" + accountId + "/projects/page/" + page + (filter ? "?filter=" + filter : "")).then(success).catch(failure);
}

export function project(accountId, projectId, success, failure) {
	Http.get( "/api/accounts/" + accountId + "/projects/" + projectId).then(success).catch(failure);
}

export function projectAdd(accountId, data, success, failure) {
	Http.post( "/api/accounts/" + accountId +"/projects/add", data).then(success).catch(failure);
}

export function projectUpdate(accountId, projectId, data, success, failure) {
	Http.post( "/api/accounts/" + accountId + "/projects/" + projectId, data).then(success).catch(failure);
}

export function projectDelete(accountId, projectId, success, failure) {
	Http.deleteElement( "/api/accounts/" + accountId + "/projects/" + projectId).then(success).catch(failure);
}

export function projectTimeEntrySummary(accountId, projectId, success, failure) {
	Http.get( "/api/accounts/" + accountId + "/projects/" + projectId + "/time").then(success).catch(failure);
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

A S S I G N M E N T S

* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function assignmentsByUser(accountId, userId, page, filter, success, failure) {

	Http.get( "/api/accounts/" + accountId + "/users/" + userId + "/assignments/page/" + page + (filter ? "?filter=" + filter : "")).then(success).catch(failure);

}

export function assignmentsByProject(accountId, projectId, page, success, failure) {

	Http.get( "/api/accounts/" + accountId + "/projects/" + projectId + "/assignments/page/" + page).then(success).catch(failure);

}

export function assignmentAdd(accountId, projectId, data, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/projects/" + projectId + "/assignments/add", data).then(success).catch(failure);

}

export function assignmentUpdate(accountId, assignmentId, data: Dto.AssignmentForm, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/assignments/" + assignmentId, data).then(success).catch(failure);

}

export function assignmentBriefUpdate(accountId: number, assignmentId: number, form: Dto.AssignmentBriefForm, success: Http.SuccessResponse<Dto.AssignmentDto>, failure: Http.FailureResponse) {

	Http.post<Dto.AssignmentBriefForm, Dto.AssignmentDto>( "/api/accounts/" + accountId + "/assignments/" + assignmentId + "/brief", form).then(success).catch(failure);

}

export function assignmentDelete(accountId, assignmentId, success, failure) {

	Http.deleteElement( "/api/accounts/" + accountId + "/assignments/" + assignmentId).then(success).catch(failure);

}

export function assignmentStatusUpdate(accountId, assignmentId, status, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/assignments/" + assignmentId + "/status", {status: status}).then(success).catch(failure);

}


export function assignmentSubscribers(accountId: number, assignmentId: number) {
	return Http.get<Dto.PagedListDto<Dto.SubscriberDto>>( "/api/accounts/" + accountId + "/assignments/" + assignmentId + "/subscribers")
}

export function assignmentSubscribersAdd(accountId: number, assignmentId: number, form: Dto.SubscriberCreateForm) {
	return Http.post<Dto.SubscriberCreateForm, Dto.PagedListDto<Dto.SubscriberDto>>( "/api/accounts/" + accountId + "/assignments/" + assignmentId + "/subscribers/create", form)
}

export function assignmentSubscribersDelete(accountId: number, assignmentId: number, subscriberId: number) {
	return Http.deleteElement<Dto.PagedListDto<Dto.SubscriberDto>>( "/api/accounts/" + accountId + "/assignments/" + assignmentId + "/subscribers/" + subscriberId)
}