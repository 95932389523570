import React from "react";
import * as Icons from 'react-bootstrap-icons';

import './FileUploader.css';

/***********************************************************************************************
 * 
 * 	F I L E  U P L O A D E R
 * 
 ***********************************************************************************************/
type FileUploaderState = {
	dragging: boolean,
	file: File | null
}

type FileUploaderProps = {
	onFileChanged: (files: FileList) => void
	instruction?: string
};

export class FileUploader extends React.Component<FileUploaderProps, FileUploaderState> {

	static counter = 0;
	fileUploaderInput: HTMLElement | null = null;

	/***********************************************************************************************
	 * 
	 * 	 C O N S T R U C T O R S
	 * 
	***********************************************************************************************/

	constructor(props: FileUploaderProps) {
		super(props);
		this.state = { dragging: false, file: null };
	}

	/***********************************************************************************************
	 * 
	 * 	 C O N S T R U C T O R S
	 * 
	***********************************************************************************************/
	dragEventCounter = 0;
	dragEnterListener = (event: React.DragEvent<HTMLDivElement>) => {
		this.overrideEventDefaults(event);
		this.dragEventCounter++;
		if (event.dataTransfer.items && event.dataTransfer.items[0]) {
			this.setState({ dragging: true });
		} else if (
			event.dataTransfer.types &&
		event.dataTransfer.types[0] === "Files"
		) {
			// This block handles support for IE - if you're not worried about
			// that, you can omit this
			this.setState({ dragging: true });
		}
	};

	/***********************************************************************************************
	 * 
	 * 	 L I S T E N E R S
	 * 
	***********************************************************************************************/
	dragLeaveListener = (event: React.DragEvent<HTMLDivElement>) => {
		this.overrideEventDefaults(event);
		this.dragEventCounter--;

		if (this.dragEventCounter === 0) {
			this.setState({ dragging: false });
		}
	};

	dropListener = (event: React.DragEvent<HTMLDivElement>) => {
		this.overrideEventDefaults(event);
		this.dragEventCounter = 0;
		this.setState({ dragging: false });

		if (event.dataTransfer.files && event.dataTransfer.files[0]) {
			this.setState({ file: event.dataTransfer.files[0] });
			this.props.onFileChanged(event.dataTransfer.files);
		}
	};

	overrideEventDefaults = (event: Event | React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();
	};

	/***********************************************************************************************
	 * 
	 * 	 H A N D L E R S
	 * 
	***********************************************************************************************/
	onSelectFileClick = () => {
		if (this.fileUploaderInput) {
			this.fileUploaderInput.click();
		}
	};

	handleFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

		if (event.target.files && event.target.files.length > 0) {

			this.props.onFileChanged(event.target.files);
			this.setState({ file: event.target.files[0] });

		}

	};

	componentDidMount() {
		window.addEventListener("dragover", (event: Event) => {
			this.overrideEventDefaults(event);
		});
		window.addEventListener("drop", (event: Event) => {
			this.overrideEventDefaults(event);
		});
	}

	componentWillUnmount() {
		window.removeEventListener("dragover", this.overrideEventDefaults);
		window.removeEventListener("drop", this.overrideEventDefaults);
	}

	/***********************************************************************************************
	 * 
	 * 	 R E N D E R E R S
	 * 
	***********************************************************************************************/
	render() {
		return (
			<FileUploaderPresentationalComponent
				instruction={this.props.instruction}
				dragging={this.state.dragging}
				file={this.state.file}
				fileName={this.state.file ? this.state.file.name : ""}
				onSelectFileClick={this.onSelectFileClick}
				onDrag={this.overrideEventDefaults}
				onDragStart={this.overrideEventDefaults}
				onDragEnd={this.overrideEventDefaults}
				onDragOver={this.overrideEventDefaults}
				onDragEnter={this.dragEnterListener}
				onDragLeave={this.dragLeaveListener}
				onDrop={this.dropListener}
			>
				<input
					ref={el => (this.fileUploaderInput = el)}
					type="file"
					className="file-uploader__input"
					style={{display: "none"}}
					onChange={this.handleFileChanged}
				/>
			</FileUploaderPresentationalComponent>
		);
	}
}
/***********************************************************************************************
 * 
 * F I L E  U P L O A D E R 	P R E S E N T A T I O N A L
 * 
 ***********************************************************************************************/
type PresentationalProps = {
	dragging: boolean;
	file: File | null;
	fileName: string;
	instruction?: string
	onSelectFileClick: () => void;
	onDrag: (event: React.DragEvent<HTMLDivElement>) => void;
	onDragStart: (event: React.DragEvent<HTMLDivElement>) => void;
	onDragEnd: (event: React.DragEvent<HTMLDivElement>) => void;
	onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
	onDragEnter: (event: React.DragEvent<HTMLDivElement>) => void;
	onDragLeave: (event: React.DragEvent<HTMLDivElement>) => void;
	onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
};

export const FileUploaderPresentationalComponent: React.FunctionComponent<PresentationalProps> = props => {

	const {
		dragging,
		onSelectFileClick,
		onDrag,
		onDragStart,
		onDragEnd,
		onDragOver,
		onDragEnter,
		onDragLeave,
		onDrop
	} = props;

	let uploaderClasses = "file-uploader clickable";
	if (dragging) {
		uploaderClasses += " file-uploader--dragging";
	}

	return (
		<div className="form__row" > 
			<div onClick={onSelectFileClick}
				className={uploaderClasses}
				onDrag={onDrag}
				onDragStart={onDragStart}
				onDragEnd={onDragEnd}
				onDragOver={onDragOver}
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
				onDrop={onDrop}
			>
				<DropDocument icon={<Icons.CloudArrowUp size={64} />} fileName={props.fileName}/>

				
			</div>
			{props.children}
			
		</div>
	);
};


/***********************************************************************************************
 * 
 * D R O P   D O C U M E N T S
 * 
 ***********************************************************************************************/
interface DropDocumentProps {
	icon: JSX.Element
	fileName?: string
}
export const DropDocument: React.FunctionComponent<DropDocumentProps> = (props) => {

	return (
		<div className={"file-uploader__contents"}>
			{props.icon}
			<div className="card__heading__subtext">
				<p className="desktop-text">Drag a file here</p>

			</div>
			
			<span className="file-uploader__file-name">{props.fileName}</span>
		</div>
	);
	
};