import React from 'react';
import Routes from "actions/Routes";
import { TopMenuItem } from '../../layouts/navigation/TopMenuItem';


interface SettingsContextMenuProps {
	path: string
}

export const SettingsContextMenu: React.FunctionComponent<SettingsContextMenuProps> = (props) => {

//export default class SettingsContextMenu extends React.Component {

	const isActive = (currentPath, match) => {

		if (!currentPath) return false;
		return currentPath.match(match);

	}

	return (
		<>
			<TopMenuItem label="Settings" to={Routes.settings()} />
			<TopMenuItem label="/ Settings" to={Routes.settings()} active={props.path === Routes.settings()} />
			<TopMenuItem label="Timesheets" to={Routes.settingsForTimesheets()} active={isActive(props.path, Routes.settingsForTimesheets())} />
			<TopMenuItem label="Templates" to={Routes.settingsForTemplates()} active={isActive(props.path, Routes.settingsForTemplates())} />
		</>
	);

	
}