import React from 'react';

import * as AccountActions from "actions/AccountActions"
import * as Icons from 'react-bootstrap-icons';
import { IconButton } from 'components/general/IconButton';

import SessionStore from "../../../store/SessionStore"

export default class UserResumeUpload extends React.Component {

	constructor(props) {
		super();
		this.state = { uploading: false};
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


	handleResumeUploadChange(e) {

		var files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}

		var fd = new FormData();
		fd.append('file', files[0]);

		this.setState({uploading: true});

		AccountActions.resumeUpload(SessionStore.getAccountId(), this.props.user.id, fd,
			() => {
				this.setState({uploading: false});
				if (this.props.onUploadSuccess) this.props.onUploadSuccess("Resume uploaded");
			},
			(errors) => {
				this.setState({uploading: false});
				if (this.props.onUploadFail) this.props.onUploadFail(errors);
			}
		);

	}

	unedit() {
		this.setState({editType: null, edit: null});
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	render() {

		if (!this.props.user) return null;

		return (

			<div className="settings-tile">
				<h4>Resume</h4>
				{this.props.user && this.props.user.resumeFilename ?
					<a target="_blank" rel="noopener noreferrer" href={AccountActions.resumeUrl(SessionStore.getAccountId() , this.props.user.id, this.props.user.resumeFilename)}>{this.props.user.resumeFilename}</a>
					: <span className="setting-value"><span className="placeholder">(none)</span></span>
				}
				<div className="controls">
					<form className="form-group" style={{display: "none"}} encType="multipart/form-data">
						<input type="file" ref={(input) => this.uploadResumeInput = input} className="form-control" name="file" onChange={(e) => this.handleResumeUploadChange(e)} />
					</form>
					<IconButton icon={<Icons.PlusLg />} title="Upload Resume" className="upload-link" onClick={(e) => this.uploadResumeInput.click()} />
				</div>
			</div>

		);
	}
}