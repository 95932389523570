import React from 'react';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import { Link } from "react-router-dom"
import * as FormFields from "components/forms/FormFields"
import * as ProfileActions from "actions/ProfileActions"
import { IconButton } from 'components/general/IconButton'
import * as Icons from 'react-bootstrap-icons';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SessionStore from "../../../store/SessionStore"


//https://github.com/ericgio/react-bootstrap-typeahead


export default class Skills extends React.Component {

	constructor(props) {
		super();
		this.state = {skills: props.skills, add: null, isSearchLoading: false, skillsValidated: false}
		this.typeahead = React.createRef();
	}

	componentWillReceiveProps(nextProps, nextState){
		this.setState({skills: nextProps.skills});
	}

	handleChange(event, skill) {

		//console.log(event);

		skill[event.target.name] = parseInt(event.target.value, 10);

		var index = -1;
		var skills = this.state.skills;
		skills.forEach(function(value, i) {
		    if (value.id === skill.id) {
		    	index = i;
		    }
		});

		if (index >= 0) {
			skills[index] = skill;
			this.setState({skills: skills});
		}

		ProfileActions.skillUpdate(this.props.accountId, this.props.profile.id, skill,
			(profile) => this.props.onUpdated(profile),
			(errors) => this.setState({errors: errors})
		);

	}

	handleAddSubmit(event) {
		//console.log(this.state.add);
		ProfileActions.skillAdd(this.props.accountId, this.props.profile.id, this.state.add,
			(profile) => {
				this.setState({add: null, editId: null, errors: null});
				this.props.onUpdated(profile);
			},
			(errors) => this.setState({errors: errors})
		);
	}

	handleSkillsChange(value) {

		if (value == null || value.length === 0) return;

		var add = this.state.add;
		var title = "";
		var id = null;

		if (value[0].customOption) {

			//create new skill

			//console.log("New Skill", value);

			title = value[0].title;

			if (!title) return;

			//add each seperated by string
			title.split(",").forEach((string) => add.push({id: id, title: string.trim(), strength: 0}));

		} else {

			//existing
			title = value[0].title;
			id = value[0].id;

			if (!title) return;
			add.push({id: id, title: title, strength: 0});
		}

		this.setState({add: add});

		this.typeahead.current.clear();
		this.typeahead.current.focus();


	}

	edit(id) {
		this.setState({editId: id});
	}

	unedit() {
		this.setState({editId: null});
	}

	add() {
		this.setState({add: []});
	}

	unadd() {
		this.setState({add: null});
	}

	removeAdd(skill) {
		var add = this.state.add;
		var i = add.indexOf(skill);

		if(i !== -1) {
			add.splice(i, 1);
		}
		this.setState({add : add});
	}

	delete(skill) {
		if (window.confirm('Delete skill?')) {
			ProfileActions.skillDelete(this.props.accountId, this.props.profile.id, skill.id,
				(profile) => this.props.onUpdated(profile),
				(errors) => this.setState({errors: errors})
			);
		}
	}

	renderSkills() {

		return this.state.skills.map((skill) => {
			return (
				<li className={"skill" + (skill.type.deleted ? " disabled" : "")} key={"skill-" + skill.id}>
					<h3><Link to={"/skills/" + skill.type.id + "/edit"}>{skill.type.title}</Link></h3>
					<FormFields.FiveStars id={skill.id} name="strength" value={skill.strength} onChange={(e) => this.handleChange(e, skill)} tooltips={['Beginner', 'Experienced', 'Senior', 'Leader', 'Industry Expert']} starValues={[20, 40, 60, 80, 100]}/>
					<div className="controls">
						<IconButton title="Delete" className="delete-link" onClick={(e) => this.delete(skill)} icon={<Icons.Trash />}  />
					</div>
				</li>
			);
		});
	}

	renderAdd() {

		return (
				<Modal isOpen={this.state.add !== null} >
					<ModalHeader>
						Add a Skill
					</ModalHeader>
					<ModalBody>
						<div className="form-group">

							<label>Skills: </label>

								{
									this.state.add != null ?
										this.state.add.map((skill) =>
											<span key={skill.id + "-" + skill.title} className={"pill" + (skill.id ? "" : " new")}>{skill.title} <button className="badge badge-pill badge-light" onClick={ () => this.removeAdd(skill) }><Icons.X color="white" /></button></span>
										)
										: null
								}

								<AsyncTypeahead isLoading={this.state.isSearchLoading} minLength={3} inputProps={{name: "title", maxLength: 60}} title="Skill"
									placeholder="Search Skills" autoFocus={true} allowNew={SessionStore.isSkillTypeCreatePermitted()} newSelectionPrefix="NEW: "
									caseSensitive={false} filterBy={['title', 'category']} ref={this.typeahead}
									onSearch={query => {
										this.setState({isSearchLoading: true})
										ProfileActions.skillsSearch(this.props.accountId, query,
											(data) => {
												this.setState({search: data, isSearchLoading: false});
											}
										);
									}}
									onChange={(value) => this.handleSkillsChange(value)}
									labelKey="title" multiple={false}
								  	options={this.state.search}

								/>
						</div>
					</ModalBody>
					<ModalFooter>
						<button className="btn" onClick={(e) => this.unadd()}>Cancel</button>
						<button className={this.state.add == null || this.state.add.length === 0 ? "btn btn-default disabled" : "btn btn-default"} onClick={(e) => this.handleAddSubmit(e)}>Add</button>
					</ModalFooter>
				</Modal>
			);
	}

	render() {
		return (
			<div className="profile-tile">
				<h2>Skills</h2>
				<div className="controls"><IconButton onClick={(e) => this.add()}  icon={<Icons.PlusLg />} /></div>

				<ul className="skills">
					{this.renderSkills()}
				</ul>

				{this.renderAdd()}
			</div>
		);
	}

}