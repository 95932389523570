import * as AccountActions from "actions/AccountActions";
import Routes from "actions/Routes";
import { IconButton } from 'components/general/IconButton';
import React, { useState } from 'react';
import * as Icons from 'react-bootstrap-icons';
import { RouteComponentProps } from 'react-router';
import { Link } from "react-router-dom";
import SessionStore from "store/SessionStore";

interface NavProps {

}
export const Nav: React.FunctionComponent<RouteComponentProps<NavProps>> = (props) => {

	const [menuToggle, setMenuToggle] = useState(false);
	//const [errors, setErrors] = useState<Dto.ErrorDto[]|null>(null);


	const isActive = (...paths) => {

		var active = false;
		paths.forEach((path) => {
			if (props.location.pathname.match(path)) {
				active = true;
				return;
			}
		});
		return active;

	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	const handleLogout = () => {

		SessionStore.clear();
		AccountActions.logout(
			() => props.history.push(Routes.login()),
			(errors) => {}
		);
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	const renderUserMenu = () => {

		if (!SessionStore.getUser()) return null;
		let profilePhotoUrl = "/media/user@4x.png";
		if (SessionStore.getUser() && SessionStore.getUser()?.photoThumbnailUrl) {
			profilePhotoUrl = SessionStore.getUser()?.photoThumbnailUrl + '';
		}
			

		return (
			<div className="user-menu">
				<ul className={"nav-menu" + (menuToggle ? " show-menu" : "")}>

					<li className="nav-menu">
						{SessionStore.getUser() ?
							<div className="nav-menu-item">
								<Link className="dropdown-item" to={"/talent/" + SessionStore.getUserId() + "/profile"}>
								<img className="navbar-photo" src={profilePhotoUrl} alt="User" />
								</Link>
							</div>
							: null
						}
						<div className="hover-menu">
							<Link className="dropdown-item minor" to={"/talent/" + SessionStore.getUserId() + "/profile"}>My Profile</Link>
							<Link className="dropdown-item minor" to={"/talent/" + SessionStore.getUserId() + "/edit"}>My Settings</Link>
							<a className="dropdown-item minor" href="http://site.talentedteam.com/documentation/">Help</a>
							<div className="dropdown-divider minor"></div>
							<button className="dropdown-item" onClick={() => handleLogout()}>Logout</button>
						</div>
					</li>

				</ul>

			</div>
		)

	}

	if (!SessionStore.isUserRole()) {
		return (
			<nav className="console-nav">

				<Link className="nav-logo" to={Routes.splash()}></Link>

				<ul className={"nav-menu" + (menuToggle ? " show-menu" : "")}>

					<NavMenuItem label="Profile" linkTo={Routes.userProfile(SessionStore.getUserId())} icon={<Icons.People />} active={isActive("^/talent", "^/console")} />
					{ SessionStore.isTimesheetsEnabled() ? <NavMenuItem label="Timesheets" linkTo={Routes.timesheets()} icon={<Icons.Stopwatch />} active={isActive(".*/timesheets")} /> : null }
					{ SessionStore.isProjectsEnabled() ? <NavMenuItem label="Assignments" linkTo={Routes.userAssignments(SessionStore.getUserId())} icon={<Icons.Clipboard />} active={isActive("^/assigments")} /> : null }
				</ul>

				<button className="navbar-toggler" type="button" onClick={() => setMenuToggle(!menuToggle)}>
					<span className="navbar-toggler-icon"></span>
				</button>

				{renderUserMenu()}

			</nav>
		);
	}

	return (
		<nav className="console-nav">

			<Link className="nav-logo" to={Routes.home()}></Link>

			<ul className={"nav-menu" + (menuToggle ? " show-menu" : "")}>

				<NavMenuItem label="Talent" linkTo={Routes.talent()} icon={<Icons.People />} active={isActive("(?!.*timesheets)^/talent", "^/console")} />

				{ SessionStore.isTimesheetsEnabled() ? <NavMenuItem label="Timesheets" linkTo={Routes.timesheets()} icon={<Icons.Stopwatch />} active={isActive(".*/timesheets")} /> : null }
				{ SessionStore.isProjectsEnabled() ? <NavMenuItem label="Assignments" linkTo={Routes.userAssignments(SessionStore.getUserId())} icon={<Icons.Clipboard />} active={isActive("^/assigments")} /> : null }
				{ SessionStore.isProjectsEnabled() ? <NavMenuItem label="Projects" linkTo={Routes.projects()} icon={<Icons.Briefcase />} active={isActive("^/projects")} /> : null }

				<NavMenuItem label="Skills" linkTo={Routes.skills()} icon={<Icons.Tools />} active={isActive("^/skills")} />
				{
					//<NavMenuItem label="Community" linkTo={Routes.community()} icon="neighborhood" active={this.isActive("/community")} />
				}
				
				
				
			</ul>

			<button className="navbar-toggler" type="button" onClick={() => setMenuToggle(!menuToggle)}>
				<span className="navbar-toggler-icon"></span>
			</button>

			{renderUserMenu()}

		</nav>
	);
	

}

function NavMenuItem(props) {
	return (
		<li className={props.active ? "active" : ""}>
			<IconButton icon={props.icon} to={props.linkTo} label={props.label} className={"big" + (props.active ? " blue" : "")} />
			
			{props.children ?
				<div className="hover-menu">{props.children}</div>
				: null
			}
		</li>
	);
}