import React from 'react';
import { Route, Switch } from "react-router-dom"

import Routes from "actions/Routes";
import JoinPage from "../pages/users/JoinPage";
import SessionStore from "store/SessionStore"
import * as AccountActions from "actions/AccountActions"
import LoginPage from "../pages/LoginPage"
import AccountCreatePage from "../pages/AccountCreatePage"
import MasqueradePage from "../pages/MasqueradePage"
import SplashPage from "../pages/SplashPage"
import Spinner from "components/general/Spinner"
import ReactGA from 'react-ga';
import UnauthenticatedLayout from './UnauthenticatedLayout'
import PasswordResetPage from '../pages/users/PasswordResetPage'
import AuthenticatedLayout from './AuthenticatedLayout'
import ApplicationPage from "../pages/users/application/ApplicationPage"

import './Layout.css';
import UnauthenticatedSplashLayout from './UnauthenticatedSplashLayout';
import OAuthVerifyPage from 'components/pages/OAuthVerifyPage';
import { SubscriberPage } from "components/pages/timesheets/SubscriberPage";


export default class Layout extends React.Component {

	constructor(props) {
		super(props);
		this.state = {accountId: SessionStore.getAccountId(), gaInitialised: false, loading: true};
	}

	componentDidMount() {

		document.title = "TalentedTeam";

		//if not authenticated
		if (!SessionStore.isAuthenticated() &&
			this.props.location.pathname !== Routes.login() &&
			!this.props.location.pathname.startsWith("/password") &&
			!this.props.location.pathname.startsWith(Routes.join()) &&
			!this.props.location.pathname.startsWith("/jobs/") && 
			!this.props.location.pathname.startsWith("/timesheets/subscriptions")) {

			//save navigation to later
			if (!SessionStore.navigateToCache) SessionStore.navigateToCache = this.props.location.pathname + "";

			AccountActions.settingsLoad(
				(settings) => {
					this.setState({loading: false});

					//account settings returned
					if (settings.account == null) {
						//no account, not logged in
						this.props.history.push(Routes.login());
					} else {
						//logged in ok...
						if (SessionStore.navigateToCache) {
							this.props.history.push(SessionStore.navigateToCache);
							SessionStore.navigateToCache = "";
						}
					}
				},
				(error) => {
					this.setState({loading: false});
					this.props.history.push(Routes.splash());
				}
			);
		} else {
			this.setState({loading: false});
		}


	}

	initialiseGa() {
		ReactGA.initialize('UA-3892300-22');
		//() => this.setState({gaInitialised: true});	//this runs async so setState can update
	}

	render() {

		if (!this.state.gaInitialised) {
			this.initialiseGa();
		}

		if (this.state.loading) {

			return (
				<div className="spinner-container">
					<Spinner />
				</div>
			);

		} else if (!SessionStore.isAuthenticated()) {

			//Unauthenticated paths
			 return (
				<Switch>

					<Route path={Routes.splash()} component={SplashPage} />
					<Route path={Routes.accountCreate()} component={AccountCreatePage} />
					
					<Route path="/password/reset/:token" component={PasswordResetPage} />

					<Route path={Routes.timesheetSubscriptions(":token")} component={SubscriberPage} />

					<Route path={Routes.accountJobApplicationEmbeddable(":token")} component={ApplicationPage} />
					<Route path={Routes.accountJobApplication(":token")} component={UnauthenticatedSplashLayout} />

					<Route path="/account" component={UnauthenticatedLayout} />

					<Route path={Routes.join(":token")} component={JoinPage} />
					<Route path={Routes.login(":email")} component={LoginPage} />
					<Route path={Routes.login()} component={LoginPage} />
					<Route path={Routes.oauth2Verify(":provider")} component={OAuthVerifyPage} />
					<Route component={SplashPage} />
				</Switch>
			);

		} else {

			//USER - Authenticated paths
			return (
				<Switch>
					<Route path={Routes.splash()} component={SplashPage} />
					<Route path={Routes.accountCreate()} component={AccountCreatePage} />

					<Route path="/password/reset/:token" component={PasswordResetPage} />

					<Route path={Routes.accountJobApplicationEmbeddable(":token")} component={ApplicationPage} />
					<Route path={Routes.accountJobApplication(":token")} component={UnauthenticatedSplashLayout} />

					<Route path={Routes.join(":token")} component={JoinPage} />
					<Route path={Routes.masquerade(":accountId")} component={MasqueradePage} />
					<Route path={Routes.login(":email")} component={LoginPage} />
					<Route path={Routes.login()} component={LoginPage} />
					<Route component={AuthenticatedLayout} />
				</Switch>
			);

		}

	}

}