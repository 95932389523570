import React from "react";
import { Link } from "react-router-dom"
import Routes from "actions/Routes"
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import * as FormFields from "components/forms/FormFields"
import ErrorMessages from "components/general/ErrorMessages"

import SessionStore from "store/SessionStore"
import * as SkillActions from "actions/SkillActions"
import * as AccountActions from "actions/AccountActions"
import * as Utils from "components/general/Utils"
import Glyphicon from "components/general/Glyphicon"


export default class SkillChat extends React.Component {

	constructor(props) {

		super();
		this.state = { accountId: SessionStore.getAccountId(), showPost: false, replyToId: null, post: {},
                skillId: props.skillId, conversation: {values:[]},
                errors: null, messages: null
			};
	}

	componentDidUpdate(prevProps) {
		if (this.props.skillId !== prevProps.skillId) {
			this.setState({skillId: this.props.skillId});
			this.reload(this.props.skillId);
		}
	}

	componentDidMount() {
		this.reload(this.props.skillId);
	}

	reload(skillId) {

		//Load Conversations
		SkillActions.skillConversationLoad(this.state.accountId, skillId, 0,
			(results) => this.setState({conversation: results}),
			(errors) => this.setState({errors: errors})
		);

	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	handlePostChange(event) {

		var post = this.state.post;
		post[event.target.name] = event.target.value;
		this.setState({post: post});

	}

	handlePostMentionsChange(event) {

		var post = this.state.post;
		post.body = event.target.value;
		this.setState({post: post});

	}

	handlePostSubmit(event) {

		var errorMessages = [];
		if (!this.state.post.subject) {
			errorMessages.push({message: "Subject is required to submit a post."});
		}

		if (!this.state.post.body) {
			errorMessages.push({message: "A message is needed to submit a post."});
		}

		if (errorMessages.length > 0) {
			this.setState({errors: errorMessages});
		} else {

			//Load Conversations
			var post = this.state.post;
			if (this.state.replyToId) post['replyToId'] = this.state.replyToId;
			SkillActions.skillConversationPost(this.state.accountId, this.state.skillId, post,
				(results) => {
					this.setState({conversation: results, post: {}});
					this.closePostDialog();
				},
				(errors) => this.setState({errors: errors})
			);

		}

	}

	handleMentionsQuery(q, callback) {

		if (q && q.length > 2) {

			AccountActions.userSearch(this.state.accountId, q,
				(results) => {
					var users = [];
					results.map((user) => users.push(
						{
							id: user.id,
							email: user.email,
							display: user.fullname,
							photoThumbnailUrl: (user.photoThumbnailUrl ? user.photoThumbnailUrl : "/media/user@4x.png")
						})
					);
					callback(users);
				},
				(errors) => this.setState({errors: errors})
			);

		} else {
			callback([]);
		}
		//var data = [{id: "ben@laughlin.com.au", display: "Ben Laughlin"}, {id: "evie@laughlin.com.au", display: "Evie Laughlin"}];
		//callback(data);
	}


	closePostDialog() {
		this.setState({showPost: false});
	}

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


	render() {

		let conversations = this.state.conversation.values.map((post, i) => {

			/*var mentionPattern = /(\@\[.*\]\(.*\))/g
			var displayPattern = /(\@\[)(.*)(\])/i
			var postSplit = post.body.split(mentionPattern);
			var postBody = postSplit.map((part, i) => {
				if (part.match(mentionPattern)) {
					var displayName = part.match(displayPattern);
					return <a key={part + "_" + i}>{displayName ? "@" + displayName[2] : part}</a>;
				} else {
					return part;
				}
			});*/

			return (

				<div key={post.id} className="skill-conversation-post">

					<div className="header">
						<div className="thumbnail">
							<Link to={Routes.userProfile(post.user.id)}><img className="profile-thumbnail" src={post.user.photoThumbnailUrl ? post.user.photoThumbnailUrl : "/media/user@4x.png"} alt="User"/></Link>
						</div>
						<div className="details">
							<div className="name">{post.user.fullname}</div>
							<div className="timestamp">{post.updatedText}</div>
						</div>
					</div>
					<div className="subject">{post.subject}</div>
					<div className="body" >{Utils.markupMentions(post.body)}</div>
					<div className="actions">
						<Glyphicon name="reply" title="Reply" onClick={() => this.setState({showPost: true, replyToId: post.id})} />
					</div>
				</div>

			);
        });

		let postDialogue = (
			<Modal isOpen={this.state.showPost} >

				<ModalHeader>
					Post Message
				</ModalHeader>

				<ModalBody>

					<FormFields.Text name="subject" title="Subject" placeholder="Subject" value={this.state.post.subject} mandatory={true} onChange={(e) => this.handlePostChange(e)} />

					{
						//<FormFields.TextArea name="bodyx" title="Message" rows={5} placeholder="Enter your message" value={this.state.post.body} mandatory={true} onChange={(e) => this.handlePostChange(e)} />
					}

					<FormFields.Mentions name="body" title="Message" value={this.state.post.body}
						onChange={(e) => this.handlePostMentionsChange(e)}
						data={(search, callback) => this.handleMentionsQuery(search, callback)}
						height={'150px'}
					/>

				</ModalBody>

				<ModalFooter>

					<button className="" onClick={(e) => this.closePostDialog()}>Cancel</button><span> </span>
					<button className="btn btn-default" onClick={(e) => this.handlePostSubmit(e)}>Post Message</button>

				</ModalFooter>

			</Modal>
		)

		if (this.state.conversation.values.length > 0) {

            return (
                <div className="skill-conversations">
					<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages} />
                    <h4>Conversation:</h4>
					<div className="controls">
						<Glyphicon name="chat-message" title="Post Message" onClick={() => this.setState({showPost: true, replyToId: null})}/>
					</div>
					{postDialogue}
                    <div className="grid">
                        {conversations}
                    </div>
                </div>
            )
        }

        return (
			<div className="skill-conversations">
				<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages} />
				<h4>Conversation:</h4>
				<div className="controls">
					<Glyphicon name="chat-message" title="Post Message" onClick={() => this.setState({showPost: true})} />
				</div>
				{postDialogue}
				<div className="grid">
					<div className="skill-conversation-post">
						<div className="body">Start a conversation about this skill</div>
					</div>
				</div>
			</div>
		);

	}
}