
class Logger {

	debug(message?: any, ...optionals: any[]) {
		if (this.isDevelopment) console.log("DEBUG: " + message, ...optionals);
	}

	info(message?: any, ...optionals: any[]) {
		console.log("INFO: " + message, ...optionals);
	}

	warn(message?: any, ...optionals: any[]) {
		console.log("WARN: " + message, ...optionals);
	}

	error(message?: any, ...optionals: any[]) {
		console.log("ERROR: " + message, ...optionals);
	}

	get isDevelopment() {
		return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
	}


}

const logger = new Logger();
export default logger;