import * as Dto from "actions/Dto";
import * as ProjectActions from "actions/ProjectActions";
import * as FormFields from "components/forms/FormFields";
import { IconButton } from "components/general/IconButton";
import Spinner from "components/general/Spinner";
import React, { useEffect, useState } from 'react';
import * as Icons from 'react-bootstrap-icons';
import SessionStore from "store/SessionStore";
import { UserAsyncTypeahead } from "../users/UserAsyncTypeahead";


interface AssignmentSubscribersFormProps {
	assignment: Dto.AssignmentDto
	onError: (errors: Dto.ErrorDto[]) => void
	onUpdated: (assignment: Dto.AssignmentDto) => void
	onCancel: () => void
}

export const AssignmentSubscribersForm: React.FunctionComponent<AssignmentSubscribersFormProps> = (props) => {

	const [ loading, setLoading ] = useState(false);
	const [ subscribers, setSubscribers ] = useState<Dto.SubscriberDto[]>();
	const [ addSubscriber, setAddSubscriber ] = useState<Dto.UserDto>();

	useEffect(() => {

		if (SessionStore.accountId) {

			setLoading(true);
			ProjectActions.assignmentSubscribers(SessionStore.accountId, props.assignment.id)
				.then(subscribers => {
					setSubscribers(subscribers.values);
					setLoading(false);
				})
				.catch(errors => {
					props.onError(errors);
					setLoading(false);
				});
		}

	}, []);

	const handleAddSubscriber = (user: Dto.UserDto) => {
		setAddSubscriber(user);
	}

	const handleAddSubscriberSave = () => {

		if (SessionStore.accountId && addSubscriber) {
			setLoading(true);
			ProjectActions.assignmentSubscribersAdd(SessionStore.accountId, props.assignment.id, {userId: addSubscriber.id})
				.then(subscribers => {
					setSubscribers(subscribers.values);
					setLoading(false);
					setAddSubscriber(undefined);
				})
				.catch(errors => {
					props.onError(errors);
					setLoading(false);
				});
		}

	}

	const handleRestoreSubscriber = (subscriber: Dto.SubscriberDto) => {
		
		if (SessionStore.accountId) {
			//setLoading(true);
			ProjectActions.assignmentSubscribersAdd(SessionStore.accountId, props.assignment.id, {userId: subscriber.user.id})
				.then(subscribers => {
					setSubscribers(subscribers.values);
					setLoading(false);
					setAddSubscriber(undefined);
				})
				.catch(errors => {
					props.onError(errors);
					setLoading(false);
				});
		}

	}

	const handleDeleteSubscriber = (subscriber: Dto.SubscriberDto) => {
		
		if (window.confirm("Delete subscriber?") && SessionStore.accountId) {
			//setLoading(true);
			ProjectActions.assignmentSubscribersDelete(SessionStore.accountId, props.assignment.id, subscriber.id)
				.then(subscribers => {
					setSubscribers(subscribers.values);
					setLoading(false);
					setAddSubscriber(undefined);
				})
				.catch(errors => {
					props.onError(errors);
					setLoading(false);
				});
		}

	}

	const renderSubscribers = () => {

		if (!subscribers || subscribers.length === 0) return <li className="form-list-item">No subscribers</li>;

		
		return (
			subscribers.map(subscriber => {
				
				if (subscriber.status === Dto.SubscriberStatus.DELETED) {
					return <li key={subscriber.id} className="form-list-item deleted"><span className="label">{subscriber.user.fullname}<span className="minor-text">{subscriber.user.email}</span></span><IconButton className="button" onClick={() => handleRestoreSubscriber(subscriber)} icon={<Icons.Toggle2Off />} /></li>
				}
				return <li key={subscriber.id} className="form-list-item"><span className="label">{subscriber.user.fullname}<span className="minor-text">{subscriber.user.email}</span></span><IconButton className="button" onClick={() => handleDeleteSubscriber(subscriber)} icon={<Icons.Toggle2On />} /></li>

			})
		)
	}



	return (
		<FormFields.Model title="Subscribers" isOpen={!!props.assignment}
			onHide={props.onCancel} onCancel={props.onCancel} onConfirm={props.onUpdated} showConfirm={false} cancelLabel="Close">
			
			
				<div>Subscriber will recieve an email notification for this Assignment when any timesheets are approved.</div>
				<div className={"form-list form-group"}>
					<Spinner loading={loading}>
					<ul>
						{renderSubscribers()}
					</ul>
					</Spinner>
				</div>

				<hr/>

				<label>Add subscriber:</label>
				{!addSubscriber ?
					<div className="input-group add-list-item">
						
						<UserAsyncTypeahead onError={props.onError} onSelected={handleAddSubscriber}/>
						
					</div>
					:
					<div className={"form-list form-group"}>
						<ul>
							<li className="form-list-item"><span className="label">{addSubscriber.fullname}<span className="minor-text">{addSubscriber.email}</span></span></li>
						</ul>
						<button className="btn btn-default" onClick={() => handleAddSubscriberSave()}>Add</button>
					</div>
				}

			

		</FormFields.Model>
	)

}


