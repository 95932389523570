import * as FormFields from "components/forms/FormFields";
import { IconButton } from 'components/general/IconButton';
import React from 'react';
import * as Icons from 'react-bootstrap-icons';
export default class EducationStep extends React.Component {


	handleChange(event, index) {
		var profile = this.props.profile;
		if (!profile.educations[index]) profile.educations[index] = {};
		profile.educations[index][event.target.name] = event.target.value;
		this.props.onChange(profile);
	}

	handleAdd(event) {
		var profile = {...this.props.profile};
		profile.educations.push({});
		this.props.onChange(profile);
	}

	handleDelete(educationIndex) {
		var profile = {...this.props.profile};
		profile.educations.splice(educationIndex, 1);
		this.props.onChange(profile);
	}


	render() {

		if (this.props.step !== this.props.index) return null;

		return (

			<React.Fragment>

				<h4>Education and Qualifications</h4>

				{this.props.profile.educations.map((education, index) => {
					return (
						<div key={index} className="experience">
							<div className="row">
								<div className="col-5">
									<FormFields.Text name="title" title="Title" placeholder="Degree, certificate or qualification" value={education.title} mandatory={false} onChange={(e) => this.handleChange(e, index)} />
								</div>
								<div className="col-4">
									<FormFields.Text name="organisation" title="Education provider" placeholder="University, College, School" value={education.organisation} onChange={(e) => this.handleChange(e, index)} />
								</div>
								<div className="col">
									<FormFields.DateYear name="endDate" title="Year" value={education.endDate} mandatory={false} onChange={(e) => this.handleChange(e, index)} />
								</div>
								<div className="col">
									<div className="form-group">
										<label>&nbsp;</label>
										<div>
										<IconButton icon={<Icons.Trash />} onClick={() => this.handleDelete(index)} title="Delete"/>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				})}
				
				<IconButton icon={<Icons.PlusLg />} onClick={() => this.handleAdd()} title="Add more"/>


			</React.Fragment>

		);
	}

}