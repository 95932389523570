import React from 'react';
import { Link } from "react-router-dom"
import * as FormFields from "components/forms/FormFields"
import ErrorMessages from "components/general/ErrorMessages"
import * as AccountActions from "actions/AccountActions"
import LoginPage from "../LoginPage"
import Routes from "actions/Routes"


export default class PasswordReset extends React.Component {

	constructor(props) {
		super(props);
		this.state = {password: null, passwordCheck: null, success: false, errors: null, messages: null};
	}

	componentWillMount() {



	}

	handleChange(event) {
		var state = this.state;
		state[event.target.name] = event.target.value;
		this.setState(state);
	}

	handleSubmit(e) {

		this.setState({errors:null, messages: null, success: false});
		if (this.state.password == null || this.state.password.length < 6) {
			this.setState({errors: [{code:"error", message:"Password length must be 6 characters or more"}]});
			return;
		}

		if (this.state.password !== this.state.passwordCheck) {
			this.setState({errors: [{code:"error", message:"Passwords do not match"}]});
			return;
		}

		this.setState({errors: null});
		AccountActions.passwordReset(this.props.match.params.token, this.state.password,
			() => this.props.history.push({pathname: "/login", state: {messages: ["Password has been set, you can now login"], success: true}}),
			(errors) => {this.setState({errors: errors})}
		);
	}

	render() {

		if (this.state.success) return <LoginPage errors={this.state.errors} messages={this.state.messages}/>


		return(

			<section className="join">

				<nav>
					<Link className="logo" to={Routes.splash()} ></Link>
				</nav>

				<div className="container">
					<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages}/>

						<div className="row justify-content-md-center">
							<div className="col-6 join-panel">
								<h1>Password Reset</h1>

								<div className="well">

									<FormFields.Password name="password" title="Password" placeholder="" value={this.state.password} onChange={(e) => this.handleChange(e)}/>
									<FormFields.Password name="passwordCheck" title="Password Check" placeholder="" value={this.state.passwordCheck} onChange={(e) => this.handleChange(e)}/>

									<button className="btn btn-default" onClick={(e) => this.handleSubmit(e)}>Save</button>
									<Link to={Routes.login()} className="btn">Cancel</Link>

									<hr/>
									<p>You can set a new password for your account above. Please note your password reset link is valid for today only. To get a new link you will need to go to the login page and request via the forgotten password function on the login page <Link to="/login">here</Link>.</p>

								</div>
							</div>
						</div>

				</div>

			</section>
		);
	}

}