import React from "react";

import Routes from "actions/Routes"
import TalentAvailability from "./TalentAvailability"
import AbstractDataPage from "./../AbstractDataPage"
import * as TalentActions from "actions/TalentActions"
import * as SkillActions from "actions/SkillActions"
import * as Utils from "components/general/Utils";
import { TopMenuItem } from "components/layouts/navigation/TopMenuItem";
import SessionStore from "store/SessionStore"

export default class TalentAvailabilityBySkillPage extends AbstractDataPage {

	showFilter = false;

	constructor(props) {

		super(props);
		this.state = this.initialiseState(props);
		this.loadSkillType(props.match.params.skillTypeId);

	}

	initialiseState(props) {
		return { 
			...this._defaultState, skillTypeId: props.match.params.skillTypeId
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.skillTypeId !== prevProps.match.params.skillTypeId) {
			this.setState({skillTypeId: this.props.match.params.skillTypeId});
			this.loadSkillType(this.props.match.params.skillTypeId);
			this.loadData(0);
		}
	}

	pagePath() {
		return Routes.talentsAvailabilityBySkillLoad(this.state.skillTypeId);
	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	loadSkillType(skillTypeId) {
		//Load Skill
		SkillActions.skillTypeLoad(this.state.accountId, skillTypeId,
			(result) => this.setState({skillTypeId: skillTypeId, skillType: result}),
			(errors) => {this.setState({errors: errors})}
		);
	}

	loadData(page) {

		if (page < 0) page = 0;

		TalentActions.talentsAvailabilityBySkillLoad(this.state.accountId, this.props.match.params.skillTypeId, page,
			(data) => this.handleLoadSuccessCallback(data),
			(errors) => this.handleErrorCallback(errors)
		);

	}

	loadMore(page) {

		TalentActions.talentsAvailabilityBySkillLoad(this.state.accountId, this.props.match.params.skillTypeId, this.state.data.index + 1,
			(data) => this.handleLoadMoreSuccessCallback(data),
			(errors) => this.handleErrorCallback(errors)
		);

	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	title() {
		return "Availability";
	}

	renderData() {
		if (this.state == null || this.state.data.values.length <= 0) return (<div>None</div>);

		var nextDividerDate = 0;

		//get divider dates
		var nextWeek = new Date();
		nextWeek.setDate(nextWeek.getDate() + (1 + 7 - nextWeek.getDay()) % 7);

		var fortnight = new Date();
		fortnight.setDate(fortnight.getDate() + (1 + 7 - fortnight.getDay()) % 7 + 7);

		var fourWeeks = new Date();
		fourWeeks.setDate(fourWeeks.getDate() + (1 + 7 - fourWeeks.getDay()) % 7 + 21);

		var eightWeeks = new Date();
		eightWeeks.setDate(eightWeeks.getDate() + (1 + 7 - eightWeeks.getDay()) % 7 + 49);

		var addDividers = true;

		return this.state.data.values.map((skillAvailability, index) => {

			var endDate = 0;
			if (skillAvailability.availability) {
				endDate = Utils.parseDateTime(skillAvailability.availability);
			}

			//build for divider
			var divider = null;
			if (index === 0) {

				divider = <div key="now" className="data-divider">Now</div>;
				nextDividerDate = nextWeek;

			} else if (endDate && endDate >= nextDividerDate) {

				if (addDividers) {
					if (endDate > eightWeeks) {
						divider = <div key="much-later" className="data-divider">Much Later</div>;
						addDividers = false;
					} else if (endDate > fourWeeks) {
						divider = <div key="later" className="data-divider">Later</div>;
						nextDividerDate = eightWeeks;
					} else if (endDate > fortnight) {
						divider = <div key="fortnight" className="data-divider">Fortnight</div>;
						nextDividerDate = fourWeeks;
					} else if (endDate > nextWeek) {
						divider = <div key="next-week" className="data-divider">Next Week</div>;
						nextDividerDate = fortnight;
					}
				}

			}

			return (
				<React.Fragment key={"availability" + index}>
					{divider}
					{this.renderDataItem(skillAvailability)}
				</React.Fragment>
			);

		});

	}

	renderDataItem(skillAvailability) {
		return <TalentAvailability skill={skillAvailability.skill} assignment={skillAvailability.assignment}
					profile={skillAvailability.skill.profile} user={skillAvailability.skill.profile.user} availability={skillAvailability.availability}
					key={skillAvailability.skill.profile.user.id} accountId={this.state.accountId} />
	}

	renderAddDataItem() {

		return null;

	}

	renderActionsDropdown() {

		return null;

	}

	contextMenuItems() {
		if(!this.state.skillType) return null;
		return (
			<React.Fragment>
				<TopMenuItem label={"/ " + this.state.skillType.title} to={Routes.skillsEdit(this.state.skillType.id)} hidden={!this.state.skillType}/>
				<TopMenuItem label="Talent" to={Routes.skillsTalent(this.state.skillType.id)} />
				{ SessionStore.getAccount().projectsEnabled ? <TopMenuItem label="Availability" to={Routes.skillsTalentAvailability(this.state.skillType.id)} hidden={!this.state.skillType.title} active={true} /> : null }
			</React.Fragment>
		);
	}

}