import * as Utils from "components/general/Utils";
import React, { useState } from 'react';
import * as Icons from 'react-bootstrap-icons';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import DatePicker from 'react-datepicker';
import { Mention, MentionsInput } from 'react-mentions';
import { Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';

import './Forms.css';

import { IconButton } from 'components/general/IconButton';
import { useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";

interface InputProps {
	title?: string
	className?: string
	placeholder?: string
	mandatory?: boolean
	name?: string
	value?: string | number | null
	prefix?: any
	suffix?: any
	helpText?: any
	inline?: boolean
	readOnly?: boolean
	disabled?: boolean
	onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
}

export function Text(props: InputProps) {
	return (
		<div className="form-group">
			{props.title != null && props.title.length > 0 ? <label>{props.title}{props.mandatory ? "*" : ""}</label> : ""}

			<TextInner prefix={props.prefix} suffix={props.suffix}>

				<input
					{...props}
					type="text"
					className="form-control"
					value={props.value !== null ? props.value : ""}
				/>

			</TextInner>
			{props.helpText ? <small>{props.helpText}</small> : null}

		</div>
	);
}

interface NumberInputProps extends InputProps {
	format?: string
	step?: number
	min?: number
	max?: number
}
export function Number(props: NumberInputProps) {

	const handleBlur = (e) => {

		let format = (props.format ? props.format : "0");

		e.target.value = Utils.formatNumber(e.target.value, format);
		if (props.onChange) props.onChange(e);
	}

	return (

		<div className="form-group">
			{props.title != null && props.title.length > 0 ? <label>{props.title}{props.mandatory ? "*" : ""}</label> : ""}

			<TextInner prefix={props.prefix} suffix={props.suffix}>

				<input {...props} 
					type="number" step={props.step ? props.step : "1"} min={props.min ? props.min : "0"} max={props.max ? props.max : undefined}
					className="form-control"
					onBlur={(e) => {handleBlur(e)} }
					onWheel={(e) => e.currentTarget.blur() }
					value={props.value !== null ? props.value : ""} />

			</TextInner>
			{props.helpText ? <small>{props.helpText}</small> : null}

		</div>
	);
}

export function Money(props: NumberInputProps) {
	return (
		<Number prefix="$" {...props} step={0.01} min={0} format={props.format ? props.format : "0.00"} />
	);
	/*return (
		<div className="form-group">
			{props.title != null && props.title.length > 0 ? <label>{props.title}{props.mandatory ? "*" : ""}</label> : ""}
			<div className="input-group">
				<div className="input-group-prepend">
					<span className="input-group-text"> $ </span>
				</div>
				<input name={props.name} type="text" className="form-control"
					placeholder={props.placeholder} value={props.value !== null ? props.value : ""}
					onChange={props.onChange} onBlur={props.onBlur} readOnly={props.readOnly}
					onKeyPress={props.onKeyPress}
				/>
			</div>
			{props.helpText ? <small>{props.helpText}</small> : null}
		</div>
	);*/
}
export const TextInner: React.FunctionComponent<InputProps> = (props) => {

	if (props.prefix || props.suffix) {
		return (
			<div className="input-group">

				{props.prefix ?
					<div className="input-group-prepend">
						<span className="input-group-text"> {props.prefix} </span>
					</div>
					: null
				}

				{props.children}

				{props.suffix ?
					<div className="input-group-append">
						<span className="input-group-text"> {props.suffix} </span>
					</div>
					: null
				}

			</div>
		);
	}

	return <>{props.children}</>;

}

export function Password(props: InputProps) {
	return (
		<div className="form-group">
			<label>{props.title}{props.mandatory ? "*" : ""}</label>

			<input 
				name={props.name} type="password" className="form-control" placeholder={props.placeholder} 
				value={props.value !== null ? props.value : ""} onChange={props.onChange} readOnly={props.readOnly}  
			/>

			{props.helpText ? <small>{props.helpText}</small> : null}
		</div>
	);
}

interface CheckboxInputProps {
	title?: string
	className?: string
	placeholder?: string
	mandatory?: boolean
	name?: string
	value?: boolean
	prefix?: any
	suffix?: any
	helpText?: any
	inline?: boolean
	readOnly?: boolean
	disabled?: boolean
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
}
export function Checkbox(props: CheckboxInputProps) {
	if (props.inline !== null && props.inline === true) {
		return (
			<div className={"form-group inline" + (props.className ? " " + props.className : "")}>
				<input name={props.name} type="checkbox" className="form-control inline" placeholder={props.placeholder} checked={props.value} value={props.value ? "true" : "false"} onChange={props.onChange} readOnly={props.readOnly !== null ? props.readOnly : false} disabled={props.disabled !== null ? props.disabled : false} /> <label>{props.title}</label>
			</div>
		);
	} else {
		return (
			<div className={"form-group " + (props.className ? " " + props.className : "")}>
				<label>{props.title}</label>
				<input name={props.name} type="checkbox" className="form-control" placeholder={props.placeholder} checked={props.value} value={props.value ? "true" : "false"} onChange={props.onChange} readOnly={props.readOnly !== null ? props.readOnly : false} disabled={props.disabled !== null ? props.disabled : false}/>
			</div>
		);
	}
}

interface TextAreaInputProps extends InputProps {
	rows: number
	supportTab?: boolean
}
export function TextArea(props: TextAreaInputProps) {

	const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

	const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {

		if (props.supportTab && e.key == 'Tab') {

			e.preventDefault();

			const value = textAreaRef.current!.value;
			const selectionStart = textAreaRef.current!.selectionStart;
			const selectionEnd = textAreaRef.current!.selectionEnd;

			if (selectionEnd === selectionStart) {
				textAreaRef.current!.value = value.substring(0, selectionStart) + "\t" + value.substring(selectionEnd);
				const lengthOfTab = "\t".length;
				textAreaRef.current!.selectionStart = selectionEnd + lengthOfTab - (selectionEnd - selectionStart);
				textAreaRef.current!.selectionEnd = selectionEnd + lengthOfTab - (selectionEnd - selectionStart);
			}

		}
	}

	return (
		<div className={"form-group " + (props.className ? " " + props.className : "")}>
			<label>{props.title}{props.mandatory ? "*" : ""}</label>
			<textarea ref={textAreaRef} name={props.name} className="form-control" onKeyDown={handleKeyDown} placeholder={props.placeholder} value={props.value !== null ? props.value : ""} onChange={props.onChange}  rows={props.rows} />
			{props.helpText ? <small>{props.helpText}</small> : null}
		</div>
	);
}

function renderOptions(options, valueSelector, labelSelector) {
	return options.map((option, i) => {
		return <option key={i} value={valueSelector ? valueSelector(option) : option}>{labelSelector ? labelSelector(option) : option}</option>
	});
}

/*
 * props.title
 * props.value
 * props.name
 * props.OnChange(event)  -> get value from event.target.value
 * props.options = array of options data
 * props.valueSelector = function to return value on options element
 * props.labelSelector = function to return label from options element
 *
 */
export function Select(props) {
	return (
		<div className="form-group">
			{props.title != null && props.title.length > 0 ? <label>{props.title}</label> : ""}
			<select className="form-control" value={props.value !== null ? props.value : null} onChange={props.onChange} name={props.name}>
				{renderOptions(props.options, props.valueSelector, props.labelSelector)}
			</select>
			{props.helpText ? <small>{props.helpText}</small> : null}
		</div>
	);
}

/*
 * props.title
 * props.value
 * props.name
 * props.OnChange(event)  -> get value from event.target.value
 * props.options = array of options data
 * props.valueSelector = function to return value on options element
 * props.labelSelector = function to return label from options element
 *
 */
export function Radio(props) {
	return (
		<div className="form-group">
			{props.title != null && props.title.length > 0 ? <label>{props.title}</label> : ""}
			<div className="radio-group">
			{props.options.map(option => {
					let value = props.valueSelector ? props.valueSelector(option) : option;
					let inputID = "radio-" + props.name + "-" + value;
					return (
						<div className={"radio-item" + (props.value === value ? " selected" : "")}>
							<label htmlFor={inputID}>{props.labelSelector ? props.labelSelector(option) : option}</label>
							<input 
								id={inputID}
								key={inputID} 
								type="radio" name={props.name} 
								value={value} 
								checked={props.value === (value)} 
								onChange={(e) => props.onChange(e)} 
							/>
						</div>
					)
				}
			)}
			</div>
			{props.helpText ? <small>{props.helpText}</small> : null}
		</div>
	);
}


export function DateInput(props) {

	return (
		<div className="form-group">
			<label>{props.title}{props.mandatory ? "*" : ""}</label>
			<input name={props.name} type="text" className="form-control" placeholder="01-01-2017" value={props.value !== null ? props.value : ""} onChange={props.onChange}  />
			{props.helpText ? <small>{props.helpText}</small> : null}
		</div>
	);

}
interface DateYearProps {
	name: string
	title: string
	mandatory?: boolean
	value?: number|string
	fromYear?: number
	toYear?: number
	onChange: (target: React.ChangeEvent<HTMLSelectElement>) => void
}

export function DateYear(props: DateYearProps) {

	var fromYear = props.fromYear ? props.fromYear : (new Date()).getFullYear() - 100;
	var toYear = props.toYear ? props.toYear : (new Date()).getFullYear();
	var years = Array.from(new Array(toYear - fromYear + 1), (val,index) => index + fromYear).reverse();

	return (
		<div className="form-group">
			<label>{props.title}{props.mandatory ? "*" : ""}</label>
			<select name={props.name} className="form-control" value={props.value !== null ? props.value : ""} onChange={props.onChange}>
				<option value="">(none)</option>
				{years.map((year) => <option key={year} value={year}>{year}</option>)}
			</select>
		</div>
	);

}


interface SimpleDate {
	day: string|null
	month: string|null
	year: string|null
}
interface SimpleEventTarget {
	name: string,
	value: string
}
interface DateMonthYearProps {
	name: string
	title: string
	mandatory?: boolean
	allowCurrent?: boolean
	value?: Date
	fromYear?: number
	toYear?: number
	onChange: ({target: SimpleEventTarget}) => void
}

export const DateMonthYear: React.FunctionComponent<DateMonthYearProps> = (props) => {

	/*constructor(props) {
		super();
		this.state = {
				title: props.title, date: this.splitDate(props.value),
				name: props.name, allowCurrent: props.allowCurrent,
				fromYear: props.fromYear ? props.fromYear : (new Date()).getFullYear() - 100,
				toYear: props.toYear ? props.toYear : (new Date()).getFullYear(),
				mandatory: props.mandatory, onChange: props.onChange};
	}*/
//splitDate(props.value)
	let [date, setDate] = useState<SimpleDate>({day: null, month: null, year: null});

	useEffect(() => {
		if (props.value) setDate(splitDate(props.value));
	}, [props.value]);


	const splitDate = (value: string|Date) : SimpleDate => {
		
		if (value instanceof Date) {
			return {year: value.getFullYear().toString(), month: (value.getMonth() < 9 ? '0' : '') + (value.getMonth() + 1), day: (value.getDate() <= 9 ? '0' : '') + value.getDate()};
		}

		if (!value || value.length === 0) return {day: null, month: null, year: null}
		let split = value.split("-");
		let date = {day: split[0], month: split[1], year: split[2]};
		
		return date;
	}

	const combineDate = (date: SimpleDate) :string|null => {
		if (!date.month || !date.year) return null;
		return date.day + "-" + date.month + "-" + date.year;
	}

	const onChangeMonth = (event: React.ChangeEvent<HTMLSelectElement>) => {
		var date_: SimpleDate = { ...date };
		date_.month = event.target.value;
		if (!date_.month) {
			date.year = "";
		} else {
			if (!date_.year) date_.year = (new Date()).getFullYear().toString();
			if (!date_.day) date_.day = "01";
		}
		setDate(date_);
		props.onChange({target: {name: props.name, value: combineDate(date_) }});
	}

	const onChangeYear = (event: React.ChangeEvent<HTMLSelectElement>) => {
		var date_ = {...date};
		date_.year = event.target.value;
		if (!date_.year) {
			date_.month = "";
		} else {
			
			if (!date_.month) date_.month = "01";
			if (!date_.day) date_.day = "01";
			
		}
		setDate(date_);
		props.onChange({target: {name: props.name, value: combineDate(date_) }});
	}

	let fromYear = (props.fromYear && props.fromYear > 0 ? props.fromYear : new Date().getFullYear() - 50);
	let toYear = (props.toYear && props.toYear > 0 && props.toYear >= fromYear ? props.toYear : new Date().getFullYear());
	var years = Array.from(new Array(toYear - fromYear + 1), (val,index) => index + fromYear).reverse();

	return (
		<div className="form-group form-month-year">
			<label>{props.title}{props.mandatory ? "*" : ""}</label>
			<div className="form-row">

				<div className={"col date-selectors month" + (date.month && date.year ? " year" : "")}>
					
					<select name={props.name} className="form-control month" value={date.year ? date.year + "" : ""} onChange={(e) => onChangeYear(e)} >
						{props.allowCurrent ? <option value="">(Current)</option> : null}
						{!props.allowCurrent ? <option value={0}>(None)</option> : null}
						{years.map((year) => <option key={year} value={year}>{year}</option>)}
					</select>

					{ date.month && date.year ?

						<select name={props.name} className="form-control year" value={date.month + ""} onChange={(e) => onChangeMonth(e)}>
							{props.allowCurrent ? <option value="">(Current)</option> : null}
							<option value="01">Jan</option>
							<option value="02">Feb</option>
							<option value="03">Mar</option>
							<option value="04">Apr</option>
							<option value="05">May</option>
							<option value="06">Jun</option>
							<option value="07">Jul</option>
							<option value="08">Aug</option>
							<option value="09">Sep</option>
							<option value="10">Oct</option>
							<option value="11">Nov</option>
							<option value="12">Dec</option>
						</select>

					: null }

					

				</div>
			</div>
		</div>
	);

}


export function DatePick(props) {

	return (
		<div className="form-group">
			<label>{props.title}{props.mandatory ? "*" : ""}</label>
			<div className="input-group">
				<DatePicker className="form-control" todayButton="Today" selected={props.value} onChange={date => props.onChange(date)} placeholderText={props.placeholder} dateFormat="dd-MMM-yyy" />
			</div>
		</div>
	);

}


export function SearchBox(props) {
	return (
		<div className="form-inline search-form">
			<div className="input-group">
				<input name={props.name} type="text" value={props.value} placeholder={props.placeholder} className="form-control" onChange={props.onChange} onKeyPress={(e) => { if (e.key === 'Enter') props.onSubmit(e); }} />
				<div className="input-group-append">
					<button className="input-group-text" onClick={props.onSubmit}>
						<Icons.Search />
					</button>
				</div>
			</div>
			{props.helpText ? <div className="pill search-criteria">{props.helpText}</div> : null }
		</div>
	);
}

export function Typeahead(props) {

	return (
		<div className="form-group">

			{props.title != null && props.title.length > 0 ? <label>{props.title}{props.mandatory ? "*" : ""}</label> : ""}

			<AsyncTypeahead {...props} />

			{props.helpText ? <small>{props.helpText}</small> : null}
		</div>
	)
	
}

export function Slider(props) {
	return (
		<div className="form-group">
			<input type="range" className="slider" name={props.name} data-highlight="true" min={props.min ? props.min : 0} max={props.max ? props.max : 0} value={props.value !== null ? props.value : 100} step={props.step ? props.step : 10} onChange={props.onChange} />
		</div>
	);
}

export function Mentions(props) {
	return (
		<div className="form-group">
			<label>{props.title}</label>
			<MentionsInput placeholder={props.placeholder ? props.placeholder : "Enter your message"}
				value={props.value}
				onChange={props.onChange} singleLine={false}
				className="mentions" style={{ input:{ height: props.height} }}
				>
				<Mention
					trigger="@"
					className="mention"
					data={props.data}
					style={{background: 'var(--feature-colour-light-2)'}}
					renderSuggestion={(entry, search, highlightedDisplay, index, focused) => (
						<div className="mention-item">
							<img alt="mention" className="profile-thumbnail mini" src={(entry as any).photoThumbnailUrl} />{entry.display}
						</div>
					  )}
				/>
			</MentionsInput>
		</div>
	);
}

/*
 * props.id = ID root of elements
 * props.starValues = array value of stars
 * props.name = names array of stars to tooltips
 * props.onChange = callback for change event
 *
 */
export function FiveStars(props) {
	var id = (Math.random() * 100000) + "-" + (Math.random() * 100000) + "-" + (Math.random() * 100000);
	if (props.id != null) {
		id = props.id;
	}

	return (
		<div className="form-group">
			<div className="five-stars">
				<button id={"fivestars-5-" + id} className="star" onClick={() => props.onChange({target:{name: props.name, value: props.starValues[4]}})} >{props.value >= props.starValues[4] ? "★" : "☆"}</button>
				<UncontrolledTooltip target={"fivestars-5-" + id} delay={{show:0, hide: 20}}>{props.tooltips != null ? props.tooltips[4] : "5 star"}</UncontrolledTooltip>

				<button id={"fivestars-4-" + id} className="star" onClick={() => props.onChange({target:{name: props.name, value: props.starValues[3]}})} >{props.value >= props.starValues[3] ? "★" : "☆"}</button>
				<UncontrolledTooltip target={"fivestars-4-" + id} delay={{show:0, hide: 20}}>{props.tooltips != null ? props.tooltips[3] : "4 star"}</UncontrolledTooltip>

				<button id={"fivestars-3-" + id} className="star" onClick={() => props.onChange({target:{name: props.name, value: props.starValues[2]}})} >{props.value >= props.starValues[2] ? "★" : "☆"}</button>
				<UncontrolledTooltip target={"fivestars-3-" + id} delay={{show:0, hide: 20}}>{props.tooltips != null ? props.tooltips[2] : "3 star"}</UncontrolledTooltip>

				<button id={"fivestars-2-" + id} className="star" onClick={() => props.onChange({target:{name: props.name, value: props.starValues[1]}})} >{props.value >= props.starValues[1] ? "★" : "☆"}</button>
				<UncontrolledTooltip target={"fivestars-2-" + id} delay={{show:0, hide: 20}}>{props.tooltips != null ? props.tooltips[1] : "2 star"}</UncontrolledTooltip>

				<button id={"fivestars-1-" + id} className="star" onClick={() => props.onChange({target:{name: props.name, value: props.starValues[0]}})} >{props.value >= props.starValues[0] ? "★" : "☆"}</button>
				<UncontrolledTooltip target={"fivestars-1-" + id} delay={{show:0, hide: 20}}>{props.tooltips != null ? props.tooltips[0] : "1 star"}</UncontrolledTooltip>

				<button onClick={() => props.onChange({target:{name: props.name, value: 0}})} >&nbsp;</button>
			</div>
		</div>
	);
}

export function FiveStarsDisplayOnly(props) {
	switch (true) {
		case props.value < 20:
			return (<span>☆☆☆☆☆</span>);
		case props.value < 40:
			return (<span>★☆☆☆☆</span>);
		case props.value < 60:
			return (<span>★★☆☆☆</span>);
		case props.value < 80:
			return (<span>★★★☆☆</span>);
		case props.value < 100:
			return (<span>★★★★☆</span>);
		case props.value >= 100:
			return (<span>★★★★★</span>);
		default:
			return null;
	}

}

interface ModelProps {
	className?: string
	commitClass?: string
	confirmLabel?: string
	cancelLabel?: string
	confirmDisabled?: boolean
	isOpen: boolean
	title: string
	noFooter?: boolean
	showConfirm?: boolean
	extraButtons?: JSX.Element
	onCancel: (e?) => void
	onConfirm: (e?) => void
	onOpened?: (e?) => void
	onHide?: (e?) => void
}

export const Model: React.FunctionComponent<ModelProps> = (props) => {	//note miss spelling of modal, is needs to be differnt to import, at least thats the easiest

	return (
		<Modal isOpen={props.isOpen} className={"modal-right" + props.className ? " " + props.className : " modal-lg"} onOpened={props.onOpened} onClosed={props.onHide}>

			<ModalHeader>
				{props.title}
			</ModalHeader>

			<ModalBody>

				{props.children}

			</ModalBody>

			{props.noFooter ?
				null
				:
				<ModalFooter>
					<div className="model-extra-buttons">
						<button className="btn" onClick={props.onCancel}>{props.cancelLabel ? props.cancelLabel : "Cancel"}</button>
					</div>
					{props.extraButtons}
					{props.showConfirm === false ? null : <button className={props.commitClass ? props.commitClass : "btn btn-default"} onClick={props.onConfirm} disabled={props.confirmDisabled}>{props.confirmLabel ? props.confirmLabel : "Save"}</button> }
				</ModalFooter>
			}

		</Modal>
	);
}

export function Pagination(props) {
	return(
		<nav className="pagination-nav">
			<ul className="pagination">
				{props.page > 0 ? <li className="page-item"><button className="page-link" onClick={props.onPreviousClicked}>Previous</button></li> : <li className="page-item disabled"><button className="page-link" >Previous</button></li> }
				<li className="page-item"><button className="page-link">Page {props.page + 1} of {props.total}</button></li>
				{props.page + 1 < props.total ? <li className="page-item"><button className="page-link" onClick={props.onNextClicked}>Next</button></li> : <li className="page-item disabled"><button className="page-link" >Next</button></li> }
			</ul>
		</nav>
	);
}

export function WizardSteps(props) {

	return (
		<div className="wizard-steps">
			{props.stepIcons.map((iconName, index) => wizardStep(index, iconName, props.step, props.onClick))}
		</div>
	)
}

function wizardStep(index, icon, step, onClick) {
	return (
		<div key={"wizard-step" + index} className={"step step-" + index + (index === step ? " current" : "") + (index < step ? " complete" : "")}>
			<div>
				<IconButton icon={icon}
					className={"big " + (index === step ? "blue" : "") + (index > step ? "shade" : "")}
					onClick={() => onClick(index)}
				/>
			</div>
		</div>
	)
}

//https://medium.com/@650egor/simple-drag-and-drop-file-upload-in-react-2cb409d88929

interface DragAndDropProps {
	handleDrop: (files: FileList) => void
	className?: string
}
export class DragAndDrop extends React.Component<DragAndDropProps, {drag: boolean}> {

	constructor(props) {
		super(props);
		this.state = { drag: false }
	}

	dropRef = React.createRef<HTMLDivElement>();
	dragCounter = 0;

	handleDrag = (e: DragEvent) => {
		e.preventDefault();
		e.stopPropagation();
	}

	handleDragIn = (e: DragEvent) => {
		e.preventDefault();
		e.stopPropagation();
		this.dragCounter++;
		if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			this.setState({drag: true});
		}
	}

	handleDragOut = (e: DragEvent) => {
		e.preventDefault();
		e.stopPropagation();
		this.dragCounter--;
		if (this.dragCounter === 0) {
			this.setState({drag: false});
		}
	}

	handleDrop = (e: DragEvent) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({drag: false});
		if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			this.props.handleDrop(e.dataTransfer.files);
			e.dataTransfer.clearData();
			this.dragCounter = 0;
		}
	}

	componentDidMount() {
		let div = this.dropRef.current;
		if (div) {
			div.addEventListener('dragenter', this.handleDragIn);
			div.addEventListener('dragleave', this.handleDragOut);
			div.addEventListener('dragover', this.handleDrag);
			div.addEventListener('drop', this.handleDrop);
		}
	}

	componentWillUnmount() {
		let div = this.dropRef.current;
		if (div) {
			div.removeEventListener('dragenter', this.handleDragIn);
			div.removeEventListener('dragleave', this.handleDragOut);
			div.removeEventListener('dragover', this.handleDrag);
			div.removeEventListener('drop', this.handleDrop);
		}
	}

	render() {

		return (

			<div className={"drop-zone" + (this.props.className ? " " + this.props.className : "") + (this.state.drag ? " dragging" : "")}
				ref={this.dropRef} >
				{this.props.children}
			</div>

		)
	}
}

interface ListProps {

	className?: string
	values: any[]
	title?: string
	helpText?: string
	onAdd: (value: any) => void
	onDelete: (index: number) => void
	valueLabel?: (value: any) => string | JSX.Element
}


export const List: React.FunctionComponent<ListProps> = (props) => {	//note miss spelling of modal, is needs to be differnt to import, at least thats the easiest

	const [ add, setAdd ] = useState();

	const handleAddChange = (event) => {
		setAdd(event.target.value);
	}

	const handleAdd = () => {
		props.onAdd(add);
		//this.setState({add: null});
	}

	return (
		<div className={"form-list form-group" + (props.className ? " " + props.className : "")}>

			{ props.title ? <label>{props.title}</label> : null }


			<ul>
				{props.values ?
					props.values.map((value, index) => <li className="form-list-item"><span className="label">{props.valueLabel ? props.valueLabel(value) : value}</span><IconButton className="delete-button" onClick={() => props.onDelete(index)} icon={<Icons.Trash />} /></li>)
					: <li className="placeholder">(none)</li>
				}
			</ul>

			<hr/>

			<div className="input-group add-list-item">
				<input name="add" type="text" value={add} placeholder="Add value" className="form-control" onChange={(e) => handleAddChange(e)} onKeyPress={(e) => { if (e.key === 'Enter') handleAdd(); }} />
				<div className="input-group-append">
					<button className="input-group-text" onClick={() => handleAdd()}>
						<Icons.Plus />
					</button>
				</div>
			</div>


			{props.helpText ? <small>{props.helpText}</small> : null}
		</div>
	)
}


export function InfoBox(props) {
	return (
		<div className={"info-box" + (props.className ? " " + props.className : "")}>
			{props.children}
		</div>
	)
}


interface SmileySelectorProps {
	onClick: (value: any) => void
	ratings: any[]
	title: string
	helpText?: string
	value: any
}
export function SmileySelector(props: SmileySelectorProps) {

	let index = props.ratings.indexOf(props.value);

	const handleClick = (nextIndex) => {
		props.onClick(props.ratings[nextIndex]);
	}

	return (
		<div className="form-group">
			<label>{props.title}</label>
			<small>{props.helpText}</small>
			<div className={"smiley-selector"}>
				<div className={"smiley happy" + (index === 0 ? " selected" : "")} onClick={() => handleClick(0)} onKeyDown={() => handleClick(0)} role="button" tabIndex={0} aria-label="happy" ><Icons.EmojiSmile /></div>
				<div className={"smiley frown" + (index === 1 ? " selected" : "")} onClick={() => handleClick(1)} onKeyDown={() => handleClick(1)} role="button" tabIndex={0} aria-label="frown" ><Icons.EmojiNeutral /></div>
				<div className={"smiley sad" + (index === 2 ? " selected" : "")} onClick={() => handleClick(2)} onKeyDown={() => handleClick(2)} role="button" tabIndex={0} aria-label="sad" ><Icons.EmojiFrown /></div>
			</div>
		</div>
	)
}