import React from 'react';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { RouteComponentProps } from 'react-router-dom';

import * as AccountActions from "actions/AccountActions";
import * as Dto from "actions/Dto";
import Routes from "actions/Routes";
import * as TalentActions from "actions/TalentActions";
import * as FormFields from "components/forms/FormFields";
import * as Utils from "components/general/Utils";

import { IconButton } from 'components/general/IconButton';
import * as Icons from 'react-bootstrap-icons';

import SessionStore from "store/SessionStore";

import { UserStatus } from 'components/general/Constants';
import SectionBody from "components/layouts/SectionBody";
import { TalentContextMenu } from './TalentContextMenu';
import UserPhotoUpload from './UserPhotoUpload';
import UserResumeUpload from './UserResumeUpload';
import { Availability } from './application/Availability';

import ReactGA from 'react-ga';

export enum EditType {
    DETAILS = "details",
	ORGANISATION = "organisation",
	AVAILABILITY = "availability",
	CONTACT = "contact",
	PASSWORD = "password",
	ROLES = "roles",
	PREFERENCES = "preferences"

}

interface UserEditPageState {
	user: Dto.UserDetailedDto|null
	edit: Dto.UserDetailedDto|null
	preferences: Dto.UserPreferencesDto|null
	editPreferences: Dto.UserPreferencesForm|null
	password: string|null,
	passwordCheck: string|null,
	changePassword: boolean
	userId: number
	editType: EditType|null
	uploading: boolean
	errors: Dto.ErrorDto[]
	messages: Dto.MessageDto[]
	isSearchLoading: boolean
	search: any[]
	manager: Dto.UserSimpleDto|null
}

export default class UserEditPage extends React.Component<RouteComponentProps<{}>, UserEditPageState> {
	
	path: string;

	constructor(props) {
		super(props);
		this.state = {
			user: null, edit: null, 
			preferences: null, editPreferences: null,
			changePassword: false, password: null, passwordCheck: null,
			uploading: false, userId: props.match.params.userId, editType: null,
			errors: [], messages: [], isSearchLoading: false, search: [], manager: null
		};
		this.path = Routes.userEdit(props.match.params.userId);
	}

	componentWillMount() {

		window.scrollTo(0, 0);	//scroll to top
		ReactGA.pageview(this.path);	//analytics

		this.reload();

	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *		H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	reload() {
		this.reloadUser();
		this.reloadPreferennces();
	}

	reloadUser() {
		if (SessionStore.accountId) {
			TalentActions.talentLoad(SessionStore.accountId, this.state.userId,
				(user) => {
					this.setState({user: user});
					
				},
				(errors) => {this.setState({errors: errors})}
			);
		}
	}

	reloadPreferennces() {
		if (SessionStore.accountId) {
			TalentActions.talentPreferences(SessionStore.accountId, this.state.userId,
				(preferences) => {
					this.setState({preferences: preferences});
					
				},
				(errors) => {this.setState({errors: errors})}
			);
		}
	}

	handleChange(event) {
		this.setState({errors: [], messages: []});
		let user = this.state.edit as any;
		user[event.target.name] = event.target.value;
		this.setState({edit: user});
	}

	handleChangePassword(event) {
		this.setState({errors: [], messages: [], password: event.target.value});
	}
	handleChangePasswordCheck(event) {
		this.setState({errors: [], messages: [], passwordCheck: event.target.value});
	}
	

	handleChangeContact(event) {
		this.setState({errors: [], messages: []});
		let user = this.state.edit as any;
		user.contact[event.target.name] = event.target.value;
		this.setState({edit: user});
	}

	handleSubmit(event) {
		TalentActions.talentUpdate(SessionStore.getAccountId(), this.state.edit,
			(user) => this.setState({user: user, edit: null, editType: null, messages: [{message: "User details saved"}]}),
			(errors) => this.setState({errors: errors})
		);
	}

	handleContactSubmit(event) {
		TalentActions.talentContactUpdate(SessionStore.getAccountId(), this.state.edit,
			(user) => this.setState({user: user, edit: null, editType: null, messages: [{message: "User details saved"}]}),
			(errors) => this.setState({errors: errors})
		);
	}

	handleOrganisationSubmit(event) {
		if (this.state.edit) {
			TalentActions.talentOrganisationUpdate(SessionStore.getAccountId(), this.state.edit,
				(user) => this.setState({user: user, edit: null, editType: null, messages: [{message: "User details saved"}]}),
				(errors) => this.setState({errors: errors})
			);
		}
	}


	handlePreferencesSubmit() {
		if (this.state.editPreferences) {
			TalentActions.talentPreferencesUpdate(SessionStore.getAccountId(), this.state.userId, this.state.editPreferences,
				(preferences) => this.setState({preferences: preferences, editPreferences: null, editType: null, messages: [{message: "User preferences saved"}]}),
				(errors) => this.setState({errors: errors})
			);
		}
	}

	handlePasswordSubmit(event) {
		this.setState({errors: [], messages: []});
		if (this.state.password == null || this.state.password.length < 6) {
			this.setState({errors: [{code:"error", message:"Password length must be 6 characters or more"}]});
			return;
		}

		if (this.state.password !== this.state.passwordCheck) {
			this.setState({errors: [{code:"error", message:"Passwords do not match"}]});
			return;
		}

		let accountId = SessionStore.getAccountId();
		if (accountId) {
			AccountActions.passwordUpdate(accountId, this.state.userId, this.state.password,
				() => this.setState({editType: null, edit: null, messages: [{message: "Password changed"}]}),
				(errors) => this.setState({errors: errors})
			);
		}
	}

	handleStatusUpdate(status) {
		let accountId = SessionStore.getAccountId();
		if (this.state.user && accountId) {
			AccountActions.userStatusUpdate(accountId, this.state.user.id, status,
				(user) => this.setState({user: user}),
				(errors) => this.setState({errors: errors})
			);
		}

	}

	handleStatusDeleted() {
		let accountId = SessionStore.getAccountId();
		if (this.state.user && accountId && window.confirm("Are you sure you want to permenently delete this User?")) {
			AccountActions.userStatusUpdate(accountId, this.state.user.id, UserStatus.DELETED,
				(user) => this.setState({user: user}),
				(errors) => this.setState({errors: errors})
			);
		}

	}

	handlePreferenceChange(event: React.ChangeEvent<HTMLInputElement>) {
		
		if (!this.state.editPreferences) return;

		var preferences = this.state.editPreferences as any;
		preferences[event.target.name] = event.target.checked;
		this.setState({editPreferences: preferences});

	}

	handleRoleChange(event, type: Dto.UserRoleType) {

		if (!this.state.edit) return;

		var user = this.state.edit;
		if (event.target.checked) {

			const hasRole = !!user.roles.find(role => role.type === type);

			if (!hasRole) {
				user.roles.push({type: type});
			}
		} else {
			const index = user.roles.findIndex(role => role.type === type);
			if (index >= 0) user.roles.splice(index, 1);	//remove element

		}
		this.setState({edit: user})
	}


	handleRoleSubmit(event) {

		let accountId = SessionStore.getAccountId();

		if (!this.state.edit || ! accountId) return;
		
		const roles = this.state.edit.roles.map(userRole => userRole.type);

		AccountActions.userRoleUpdate(accountId, this.state.userId, roles,
			(user) => this.setState({user: user, editType: null, edit: null, messages: [{message: "Roles saved"}]}),
			(errors) => this.setState({errors: errors})
		);
	}

	handleAvailabilityOffsetDateChange(offsetDate: Date) {

		if (!this.state.edit) return;

		this.setState({errors: [], messages: []});
		var user = this.state.edit;
		user.availability.offsetDate = offsetDate;
		user.availability.offsetDays = undefined;
		this.setState({edit: user});

	}

	handleAvailabilityOffsetDaysChange(offsetDays: number) {

		if (!this.state.edit) return;

		this.setState({errors: [], messages: []});
		var user = this.state.edit;
		user.availability.offsetDate = undefined;
		user.availability.offsetDays = offsetDays;
		this.setState({edit: user});
		
	}

	handleAvailabilitySubmit(event) {

		let accountId = SessionStore.getAccountId();
		if (!this.state.edit || !accountId) return;
		let form: Dto.UserAvailabilityForm = {offsetDate: this.state.edit.availability.offsetDate, offsetDays:  this.state.edit.availability.offsetDays };
		AccountActions.userAvailabilityUpdate(accountId, this.state.userId, form,
			(user) => this.setState({user: user, editType: null, edit: null, messages: [{message: "Availability updated"}]}),
			(errors) => this.setState({errors: errors})
		);
	}

	handleTalentSearchChange(value: Dto.UserSimpleDto[]) {

		if (!value) return;
		
		if (this.state.edit) {

			let user = this.state.edit;
			user.manager = value[0] as any;
			this.setState({manager: value[0], edit: user});

		}

	}

	resendInvite() {
		let accountId = SessionStore.getAccountId();
		if (accountId) {
			AccountActions.userInvite(accountId, this.state.userId,
				() => this.setState({editType: null, edit: null, messages: [{message: "Invite sent"}]}),
				(errors) => this.setState({errors: errors})
			);
		}
	}

	edit(type) {
		const cloneUser = JSON.parse(JSON.stringify(this.state.user));	//Hack to get a deep clone, else only top level is cloned
		this.setState({editType: type, edit: cloneUser, editPreferences: this.state.preferences ? { ...this.state.preferences } : null });
	}

	unedit() {
		this.setState({editType: null, edit: null});
	}

	getClassFromStatus(status) {
		if (status === UserStatus.APPLICANT) return 'btn-secondary';
		if (status === UserStatus.INACTIVE) return 'btn-danger';
		if (status === UserStatus.ACTIVE) return 'btn-success';
		if (status === UserStatus.CANDIDATE) return 'btn-warning';
		if (status === UserStatus.DELETED) return 'btn-dark';
		return 'btn-default';
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	renderTitle() {

		if (!this.state.user) return;

		if (this.state.user.firstname == null && this.state.user.surname == null) return (<h1>Edit User</h1>);
		return (<h1>{this.state.user.firstname != null ? this.state.user.firstname : ""}{this.state.user.surname != null ? " " + this.state.user.surname : ""}</h1>);
	}

	renderRoles() {
		return (
			<div>
				
				{this.renderRole(Dto.UserRoleType.ADMIN, "Admin Role")}
				{this.renderRole(Dto.UserRoleType.MANAGER, "Manager Role")}

			</div>
		);
	}

	renderRole(type: Dto.UserRoleType, title: string) {

		if (!this.state.edit) return;

		const hasRole = !!this.state.edit.roles.find(role => role.type === type);

		if (SessionStore.isRoleSeniority(type)) {
			return <FormFields.Checkbox name={type} title={title} value={hasRole} inline={true} onChange={(e) => this.handleRoleChange(e, type)}/>
		} else {
			return <FormFields.Checkbox name={type} title={title} value={hasRole} inline={true} disabled={true} className="disabled" />
		}

	}

	renderStatus() {

		if (!this.state.user) return;

		if (this.state.user.id !== SessionStore.getUserId()) {
			return (
				<div className={"float-right btn-group pill " + this.state.user.status}>

					<button title="User status" className={"btn btn-secondary btn-sm dropdown-toggle " + this.getClassFromStatus(this.state.user.status)} type="button" data-toggle="dropdown" >
						{Utils.titleCase(this.state.user.status)}

					</button>
					<div className="dropdown-menu">
						{this.state.user.status !== UserStatus.APPLICANT ? <button className="dropdown-item" onClick={(e) => this.handleStatusUpdate(UserStatus.APPLICANT)}>Applicant</button> : null}
						{this.state.user.status !== UserStatus.CANDIDATE ? <button className="dropdown-item" onClick={(e) => this.handleStatusUpdate(UserStatus.CANDIDATE)}>Candidate</button> : null}
						{this.state.user.status !== UserStatus.ACTIVE ? <button className="dropdown-item" onClick={(e) => this.handleStatusUpdate(UserStatus.ACTIVE)}>Active</button> : null}
						{this.state.user.status !== UserStatus.INACTIVE ? <button className="dropdown-item" onClick={(e) => this.handleStatusUpdate(UserStatus.INACTIVE)}>Disable</button> : null}
					</div>
				</div>
			);
		} else {

			return (
				<div className={"float-right btn-group pill " + this.state.user.status}>
					<button title="User status" className={"btn btn-secondary btn-sm " + this.getClassFromStatus(this.state.user.status)} >
						{Utils.titleCase(this.state.user.status)}
					</button>
				</div>
			);
		}

	}


	renderDefaultRole() {

		if (!this.state.user) return;

		if (!this.state.user.roles || this.state.user.roles.length === 0) {
			if (this.state.user.userRole) return <span className="emum-type" key="USER">USER</span>;
			return <span className="emum-type" key="GUEST">GUEST</span>;
		}
		return null;
	}

	renderDetailsModal() {

		if (!this.state.edit) return null;

		return (
			<FormFields.Model title="Edit Details" isOpen={this.state.editType != null && this.state.editType === EditType.DETAILS}
				onHide={(e) => this.unedit()} onCancel={(e) => this.unedit()} onConfirm={(e) => this.handleSubmit(e)} >

				<FormFields.Text name="firstname" title="First Name" placeholder="First Name" value={this.state.edit.firstname} onChange={(e) => this.handleChange(e)} />
				<FormFields.Text name="surname" title="Surame" placeholder="Surname" value={this.state.edit.surname} onChange={(e) => this.handleChange(e)} />
				<FormFields.Text name="email" title="Email" placeholder="your@email.com" value={this.state.edit.email} onChange={(e) => this.handleChange(e)} />

			</FormFields.Model>
		)
	}


	renderManagerTypeAhead() {

		let accountId = SessionStore.getAccountId()

		return (
			<AsyncTypeahead isLoading={this.state.isSearchLoading} minLength={3}
				inputProps={{name: "title", maxLength: 60}}
				placeholder="Select Talent" autoFocus={true} allowNew={false}
				caseSensitive={false} filterBy={["fullname"]}
				onSearch={query => {
					if (accountId) {
						this.setState({isSearchLoading: true});
						AccountActions.userSearch(accountId, query,
							(data) => {
								this.setState({search: data, isSearchLoading: false});
							},
							(errors) => this.setState({errors: errors})
						);
					}
				}}
				onChange={(value) => this.handleTalentSearchChange(value as Dto.UserDto[])}
				labelKey="fullname" multiple={false}
				options={this.state.search}
				renderMenu={(results, menuProps) => (
					<Menu {...menuProps}>
					{results.map((result, index) => (
						<MenuItem option={result} position={index}>
						<img alt="mention" className="profile-thumbnail mini" src={((result as Dto.UserDto).photoThumbnailUrl ? (result as Dto.UserDto).photoThumbnailUrl : "/media/user@4x.png")} /> {(result as Dto.UserDto).fullname}
						</MenuItem>
					))}
					</Menu>
				)}

			/>
		);
	}

	renderOrganisationModal() {

		if (!this.state.edit) return null;

		return (
			<FormFields.Model title="Edit Details" isOpen={this.state.editType != null && this.state.editType === EditType.ORGANISATION}
				onHide={(e) => this.unedit()} onCancel={(e) => this.unedit()} onConfirm={(e) => this.handleOrganisationSubmit(e)} >

				<div className="form-group">

					<label>Manager</label>

					{this.state.edit.manager ?


						<div className="typeahead-selected">
							<img alt="mention" className="profile-thumbnail mini" src={(this.state.edit.manager.photoThumbnailUrl ? this.state.edit.manager.photoThumbnailUrl : "/media/user@4x.png")} />
							<span className="full-name">{this.state.edit.manager.fullname}</span>
						</div>

						:

						this.renderManagerTypeAhead()
						
					}
				</div>

				<FormFields.Text name="department" title="Department" placeholder="Department" value={this.state.edit.department} onChange={(e) => this.handleChange(e)} />

				<FormFields.Text name="office" title="Office" placeholder="Office" value={this.state.edit.office} onChange={(e) => this.handleChange(e)} />

			</FormFields.Model>
		)
	}

	renderAvailabilityModal() {

		if (!this.state.edit || !this.state.user) return null;

		return (
			<FormFields.Model title="Edit Availability" isOpen={this.state.editType != null && this.state.editType === EditType.AVAILABILITY}
				onHide={(e) => this.unedit()} onCancel={(e) => this.unedit()} onConfirm={(e) => this.handleAvailabilitySubmit(e)} >

				<Availability

					onOffsetDateChanged={(availability) => this.handleAvailabilityOffsetDateChange(availability)} 
					onOffsetDaysChanged={(availability) => this.handleAvailabilityOffsetDaysChange(availability)}
					availability={this.state.user.availability}

					
				/>

			</FormFields.Model>
		)
	}

	renderContactDetails() {

		if (this.state.user) {
			return (
				<React.Fragment>

					<hr/>
					<div className="settings-tile">

						<h4>Contact</h4>
						<div>Personal Email: <span className="setting-value">{this.state.user.contact.email ? this.state.user.contact.email : <span className="placeholder">(none)</span>}</span></div>
						<div>Phone: <span className="setting-value">{this.state.user.contact.phone ? this.state.user.contact.phone : <span className="placeholder">(none)</span>}</span></div>
						<div>Home Address: <span className="setting-value">{this.state.user.contact.address? this.state.user.contact.address : <span className="placeholder">(none)</span>}</span></div>

						<div className="controls">
							<IconButton icon={<Icons.Pencil />} onClick={() => this.edit("contact")} />
						</div>

					</div>

					{ this.state.edit ?
						<FormFields.Model title="Personal Contact Details" isOpen={this.state.editType != null && this.state.editType === EditType.CONTACT}
							onHide={(e) => this.unedit()} onCancel={(e) => this.unedit()} onConfirm={(e) => this.handleContactSubmit(e)} >

							<FormFields.Text name="email" title="Personal Email" placeholder="your-home@email.com" value={this.state.edit.contact.email} onChange={(e) => this.handleChangeContact(e)} />
							<FormFields.Text name="phone" title="Phone" placeholder="0400 123 123" value={this.state.edit.contact.phone} onChange={(e) => this.handleChangeContact(e)} />

							<hr/>

							<FormFields.Text name="street" title="Home Address" placeholder="123 Some Street" value={this.state.edit.contact.street} onChange={(e) => this.handleChangeContact(e)} />
							<FormFields.Text name="city" title="City" placeholder="City" value={this.state.edit.contact.city} onChange={(e) => this.handleChangeContact(e)} />
							<div className="row">
								<div className="col">
									<FormFields.Text name="postCode" title="Post Code" placeholder="Postcode / ZIP" value={this.state.edit.contact.postCode} onChange={(e) => this.handleChangeContact(e)} />
								</div>
								<div className="col">
									<FormFields.Text name="state" title="State" placeholder="State" value={this.state.edit.contact.state} onChange={(e) => this.handleChangeContact(e)} />
								</div>
								<div className="col">
									<FormFields.Text name="country" title="Country" placeholder="Country" value={this.state.edit.contact.country} onChange={(e) => this.handleChangeContact(e)} />
								</div>
							</div>

						</FormFields.Model>
						: null
					}
				</React.Fragment>
			)
		}

		return null;

	}

	renderPreferences() {

		if (!SessionStore.isTimesheetsEnabled)  return false;

		return (
			<>
				<div className="settings-tile">

					<h4>Preferences</h4>
					<div>
						<div>Carry Timesheets Forward: <span className="setting-value">{this.state.preferences?.timesheetCopyPrevious ? "Yes" : "No"}</span></div>
					</div>

					<div>
						<div>Timesheet required: <span className="setting-value">{this.state.preferences?.timesheetRequired ? "Yes" : "No"}</span></div>
					</div>

					<div className="controls">
						<IconButton icon={<Icons.Pencil />} onClick={() => this.edit(EditType.PREFERENCES)} />
					</div>


				</div>
								

				{ this.state.edit ?
					<FormFields.Model title="Edit Roles" isOpen={this.state.editType != null && this.state.editType === EditType.PREFERENCES}
						onHide={(e) => this.unedit()} onCancel={(e) => this.unedit()} onConfirm={(e) => this.handlePreferencesSubmit()} >

						<FormFields.Checkbox name="timesheetCopyPrevious" title="Carry Timesheets Forward" placeholder="City" value={this.state.editPreferences?.timesheetCopyPrevious} onChange={(e) => this.handlePreferenceChange(e)} />

					</FormFields.Model>
					: null
				}
			</>
		)
	}

	render() {

		if (!this.state.user || !this.state.user.id) {
			return <section className="user-edit"></section>;
		}

		if (!SessionStore.isWriteAccess(this.state.user)) {
			return <section className="user-edit">No access</section>;
		}

		let topMenuItems = <TalentContextMenu user={this.state.user} path={this.props.location.pathname} />;

		return(

			<SectionBody className="user-edit" topMenuItems={topMenuItems}
				onNotificationClose={() => this.setState({errors: [], messages: []})}
				errors={this.state.errors} messages={this.state.messages} >

				<div className="row">

					<div className="col-md-3">

						<div className="status-bar">
							<IconButton icon={<Icons.PersonBadge />} title="User Profile" to={Routes.userProfile(this.state.user.id)} />
							{SessionStore.isManagerRole() && this.state.user.status === UserStatus.INACTIVE ? <IconButton icon={<Icons.Trash />} title="Delete" className="delete-link" onClick={() => this.handleStatusDeleted()} /> : null}
							{this.renderStatus()}
						</div>

						<hr/>

						{this.renderTitle()}

						<UserPhotoUpload user={this.state.user}
							onUploadSuccess={(user) => this.setState({user: user, messages: [{message: "Photo uploaded"}]})}
							onUploadFail={(errors) => this.setState({errors: errors})}
							onDeleteSuccess={(user) => this.setState({user: user, messages: [{message: "Photo deleted"}]})}
							onDeleteFail={(errors) => this.setState({errors: errors})}
						/>

					</div>
					<div className="col">

						<div className="settings">

							<div className="settings-tile">

								<h4>Details</h4>
								<div>Name: <span className="setting-value">{this.state.user.firstname} {this.state.user.surname}</span></div>
								<div>Email: <span className="setting-value">{this.state.user.email}</span></div>

								<div className="controls">
									<IconButton icon={<Icons.Pencil />} onClick={() => this.edit(EditType.DETAILS)} />
								</div>

								{this.renderDetailsModal()}

							</div>

							<div className="settings-tile">

								<h4>Organisation</h4>
								<div>Department: <span className="setting-value">{this.state.user.department ? this.state.user.department : <span className="placeholder">(none)</span>}</span></div>
								<div>Manager: <span className="setting-value">{this.state.user.manager ? this.state.user.manager.fullname : <span className="placeholder">(none)</span>}</span></div>
								<div>Office: <span className="setting-value">{this.state.user.office ? this.state.user.office : <span className="placeholder">(none)</span>}</span></div>
								<div className="controls">
									<IconButton icon={<Icons.Pencil />} onClick={() => this.edit(EditType.ORGANISATION)} />
								</div>

								{this.renderOrganisationModal()}

							</div>

							{this.state.user.status === UserStatus.CANDIDATE || this.state.user.status === UserStatus.APPLICANT ?
								<div className="settings-tile">

									<h4>Availability</h4>
									<div>
										{Utils.dateLabel(new Date(this.state.user.availability.date))}
										<small>{Utils.formatDateWithMonth(this.state.user.availability.date)}</small>
									</div>

									<div className="controls">
										<IconButton icon={<Icons.Pencil />} onClick={() => this.edit(EditType.AVAILABILITY)} />
									</div>

									{this.renderAvailabilityModal()}

								</div>
								: null
							}

							{this.renderContactDetails()}
							
							<UserResumeUpload user={this.state.user} onUploadSuccess={(message) => this.setState({messages: message})} onUploadFail={(errors) => this.setState({errors: errors})} />

							<hr/>

							{this.state.user.inviteStatus === 'PENDING' || this.state.user.inviteStatus === 'NONE' ?
								<div className="settings-tile">

									<h4>Invite Status</h4>
									<div className="emum-type">{this.state.user.inviteStatus}</div>

									<div className="controls">
										<button onClick={() => this.resendInvite()}>Resend Invite</button>
									</div>

								</div>
								: null
							}

							<div className="settings-tile">
								<h4>Password</h4>

								{this.state.user.authenticationType === 'AD' ?

									<div><span className="setting-value"><span className="placeholder">Password managed by Microsoft 365</span></span></div>

									:

									<React.Fragment>
										<div>********</div>

										<div className="controls">
											<IconButton icon={<Icons.Pencil />} onClick={() => this.edit(EditType.PASSWORD)} />
										</div>
									</React.Fragment>

								}

							</div>

							{ this.state.edit ?
								<FormFields.Model title="Change Password" isOpen={this.state.editType != null && this.state.editType === EditType.PASSWORD}
									onHide={(e) => this.unedit()} onCancel={(e) => this.unedit()} onConfirm={(e) => this.handlePasswordSubmit(e)} >

									<FormFields.Password name="password" title="Password" placeholder="********" value={this.state.password} onChange={(e) => this.handleChangePassword(e)} />
									<FormFields.Password name="passwordCheck" title="Password Check" placeholder="********" value={this.state.passwordCheck} onChange={(e) => this.handleChangePasswordCheck(e)} />

								</FormFields.Model>
								: null
							}

							<div className="settings-tile">

								<h4>Roles</h4>
								<div>
									{this.state.user.roles.map((role, index) => <span key={role.type}><span className="emum-type">{role.type}</span>{this.state.user && index < this.state.user.roles.length - 1 ? ", " : null}</span>)}
									{
										this.renderDefaultRole()
									}
								</div>

								<div className="controls">
									{ this.state.user.status === UserStatus.ACTIVE ?
										<IconButton icon={<Icons.Pencil />} onClick={() => this.edit(EditType.ROLES)} />
										: null
									}
								</div>

							</div>
							

							{ this.state.edit ?
								<FormFields.Model title="Edit Roles" isOpen={this.state.editType != null && this.state.editType === EditType.ROLES}
									onHide={(e) => this.unedit()} onCancel={(e) => this.unedit()} onConfirm={(e) => this.handleRoleSubmit(e)} >

									<div className="model-group">
										{
											this.renderRoles()
										}
									</div>

								</FormFields.Model>
								: null
							}


							{this.renderPreferences()}


						</div>


					</div>

				</div>

			</SectionBody>

		);
	}
}


/*class PhotoEdit extends React.Component {

	constructor(props) {}


	render() {

	}

}
*/