import React from "react";
import { Link } from "react-router-dom"
import Routes from "actions/Routes"

import SessionStore from "store/SessionStore"
import * as TalentActions from "actions/TalentActions"
import TalentSkill from "./TalentSkill"


const MAX_EXPERTS = 3;

export default class SkillExperts extends React.Component {

	constructor(props) {

		super();
		this.state = { accountId: SessionStore.getAccountId(),
				skillId: props.skillId, talent: {values:[]},
				errors: null, messages: null
			};
	}

	componentDidUpdate(prevProps) {
		if (this.props.skillId !== prevProps.skillId) {
			this.setState({skillId: this.props.skillId});
			this.reload(this.props.skillId);
		}
	}

	componentDidMount() {
		this.reload(this.props.skillId);
	}

	reload(skillId) {

		//Load Talent
		TalentActions.talentsBySkillLoad(this.state.accountId, skillId, null, 0,
			(results) => {
				if (results.pages > 0) {
					this.setState({talent: results})
				} else {
					//re-run query with broader filter
					TalentActions.talentsBySkillLoad(this.state.accountId, skillId, "ACTIVE,STANDBY", 0,
						(results) => this.setState({talent: results}),
						(errors) => {this.setState({errors: errors})}
					);
				}
			},
			(errors) => this.setState({errors: errors})
		);

	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */




    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


    render() {

        let talent = this.state.talent.values.map((skill, i) => {
            if (i < MAX_EXPERTS) {
                return (

					<TalentSkill profile={skill.profile} user={skill.profile.user} skill={skill} key={skill.profile.user.id} accountId={this.state.accountId} />

                );
            } else {
                return null;
            }
        });

		if (this.state.talent.values.length > 0) {

            return (
                <div className="talent-by-skill top-experts">
                    <h5>Our experts:</h5>
                    <div className="grid">
                        {talent}
                    </div>
                    <div className="more-link minor-text"><Link to={Routes.skillsTalent(this.state.skillId)}>See more experts...</Link></div>
                </div>
            )
        }

        return (<div><Link to={Routes.skillsTalent(this.state.skillId)}>See stand-by talent...</Link></div>);

	}

}