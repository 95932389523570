import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Routes from "actions/Routes";
import * as TalentActions from "actions/TalentActions";
import * as FormFields from "components/forms/FormFields";

import * as Icons from 'react-bootstrap-icons';
import ReactGA from 'react-ga';
import SessionStore from "store/SessionStore";

import * as Dto from "actions/Dto";
import SectionBody from 'components/layouts/SectionBody';

import { AvailabilityStep } from "./application/AvailabilityStep";
import EducationStep from "./application/EducationStep";
import ExperienceStep from "./application/ExperienceStep";
import SummaryStep from "./application/SummaryStep";
import TalentDetailsStep from "./application/TalentDetailsStep";
import UploadStep from "./application/UploadStep";

const WizardStep = {
	USER: 0,
	UPLOAD: 1,
	AVAILABILITY: 2,
	SUMMARY: 3,
	EDUCATION: 4,
	EXPERIENCE: 5
}

interface UserOnboardingPageState {
	user: Dto.UserDetailsForm
	profile: Dto.ProfileDetailedForm
	availability: Dto.UserAvailabilityForm
	availabilityLabel: string
	resumeFilename: string|null
	upload: any
	wizardStep: number
	wizardStepStarted: any[]
	wizardFinished: boolean
	password: string|null
	passwordCheck: string|null
	autoFillEmail: boolean
	errors: Dto.ErrorDto[]|null
	messages: Dto.MessageDto[]|null
	scrollIntervalId?: any
}


export default class UserOnboardingPage extends React.Component<RouteComponentProps<{}>, UserOnboardingPageState> {


	constructor(props) {

		super(props);
		this.state = {
			user: {} as Dto.UserDetailsForm,
			profile: { summary: "", headline: "", educations: [], experiences: [], features: [], skills: [] },
			resumeFilename: null,
			availability: {} as Dto.UserAvailabilityForm,
			availabilityLabel: "Now",
			upload: {},
			wizardStep: 0,
			wizardStepStarted: [],
			wizardFinished: false,
			password: null, passwordCheck: null,
			autoFillEmail: true,
			errors: null, messages: null
		};

	}

	componentWillMount() {

		window.scrollTo(0, 0);	//scroll to top
		ReactGA.pageview(Routes.userAdd());

	}

	/*handleChange(event) {

		var user = this.state.user;
		var autoFillEmail = this.state.autoFillEmail;
		user[event.target.name] = event.target.value;

		if ("email" === event.target.name) autoFillEmail = false;

		if (autoFillEmail) {
			//if the email is not set, has no @ or appears to be calculated, re-calculate
			user.email = this.buildEmail(user.firstname, user.surname, SessionStore.getAccount().domain);
		}

		this.setState({user: user, autoFillEmail: autoFillEmail});

	}*/

	scrollStep() {
		if (window.pageYOffset <= 0) {	//stop scrolling at 100
			window.scroll(0, 0);
			clearInterval(this.state.scrollIntervalId);
		} else {
			window.scroll(0, window.pageYOffset - 10);
		}
	}

	scrollToTop() {
		if (window.pageYOffset > 300) window.scroll(0, window.pageYOffset - 200);
		let scrollIntervalId = setInterval(this.scrollStep.bind(this), 10);
		this.setState({ scrollIntervalId: scrollIntervalId });
	}

	handleUserChange(user) {
		this.stepStarted(WizardStep.USER);
		this.setState({user: user});
	}

	handleProfileChange(profile) {
		this.stepStarted(this.state.wizardStep);
		this.setState({profile: profile});
	}

	handleUploadChange(file) {
		this.stepStarted(WizardStep.UPLOAD);
		this.setState({resumeFilename: file});

	}

	// handleAvailabilityChange(availability) {
	// 	this.stepStarted(WizardStep.AVAILABILITY);
	// 	this.setState({availability: Utils.formatDate(availability)});
	// }

	handleAvailabilityDateChange(date: Date) {

		this.stepStarted(WizardStep.AVAILABILITY);

		let availability = this.state.availability;
		if (availability) {
			availability.offsetDate = date; //Utils.formatDate(availability);
			availability.offsetDays = undefined;
			this.setState({availability: availability});
		}

	}

	handleAvailabilityDaysChange(offsetDays: number) {

		this.stepStarted(WizardStep.AVAILABILITY);

		let availability = this.state.availability;
		if (availability) {
			availability.offsetDays = offsetDays;
			availability.offsetDate = undefined;
			this.setState({availability: availability});
		}

	}

	handleNext() {

		var step = this.state.wizardStep;

		if (step >= WizardStep.EXPERIENCE) {
			this.handleSubmit();
		} else {

			if (this.validateStep(step)) {
				this.setState({errors: null, wizardStep: step + 1});
			}

			this.scrollToTop();	//scroll to top

		}

	}

	handleBack() {
		var step = this.state.wizardStep - 1;
		if (step < 0) step = 0;
		this.setState({wizardStep: step});
		this.scrollToTop();	//scroll to top
	}

	handleSubmit() {

		if (!this.validate()) {
			this.scrollToTop();	//scroll to top
			return;
		}

		let requestData : Dto.UserOnboardingForm = {
			user: this.state.user,
			profile: this.state.profile,
			availability: this.state.availability,
			resumeFilename: this.state.resumeFilename + ""
		}

		if (SessionStore.accountId) {
			TalentActions.talentOnboard(SessionStore.accountId,
				requestData,
				(data) => {
					this.setState({user: {...data, invite: false} as Dto.UserDetailsForm, wizardFinished: true});
					this.props.history.push("/talent/" + data.id + "/profile")
				},
				(errors) => {this.setState({errors: errors})}
			);
		}

		this.scrollToTop();	//scroll to top

	}

	stepStarted(step) {
		var stepStarted = this.state.wizardStepStarted;
		stepStarted[step] = true;
		this.setState({wizardStepStarted: stepStarted});
	}

	validate() {

		var errors = [];
		return this.validateStep(3, errors) && this.validateStep(0, errors);

	}

	validateStep(step, errors?) {

		if (!errors) errors = [];
		let errorCount = errors.length;

		if (step === WizardStep.USER) {
			//contact
			if (!this.state.user.firstname) errors.push({message: "A firstname must be specified"});
			if (!this.state.user.firstname) errors.push({message: "A surname must be specified"});
			if (!this.state.user.email) errors.push({message: "Email has not been provided"});
			//if (!this.state.user.password) errors.push({message: "Password has not been provided"});
			//if (this.state.user.password && this.state.user.password.length < 6) errors.push({message:"Password length must be 6 characters or more"});
		}

		if (step === WizardStep.SUMMARY) {
			//summary
			//if (!this.state.profile.headline) errors.push({message: "A headline about your experience and skills required."});
			//if (!this.state.profile.summary) errors.push({message: "A short elevator pitch summary of your experience is required."});
		}

		if (errors.length > errorCount) this.setState({errors: errors, wizardStep: step});
		return errors.length === errorCount;

	}

	nextButtonLabel() {
		if (this.state.wizardStep >= WizardStep.EXPERIENCE) return "Submit";
		if (this.state.wizardStep === WizardStep.UPLOAD || this.state.wizardStep === WizardStep.SUMMARY || this.state.wizardStep === WizardStep.EDUCATION) {
			if (this.state.wizardStepStarted[this.state.wizardStep]) {
				return "Next";
			} else {
				return "Skip";
			}
		}
		return "Next";
	}


	renderTitle() {
		if (this.state.user.firstname == null && this.state.user.surname == null) return (<h1>Talent Profile</h1>);
		return (<h1>{this.state.user.firstname != null ? this.state.user.firstname : ""}{this.state.user.surname != null ? " " + this.state.user.surname : ""}</h1>);
	}

	render() {
		return(

			<SectionBody className="onboarding-section"
				onNotificationClose={() => this.setState({errors: null, messages: null})}
				errors={this.state.errors} messages={this.state.messages} >

				<div className="onboarding-panel">

					<div className="onboard">

						<h1>Talent Onboarding</h1>
						<p>To add new talent to your team, simply complete the wizard below.</p>
						<div className="onboard-inner">

							<hr/>

							<FormFields.WizardSteps
								stepIcons={[<Icons.PersonBadge />, <Icons.Paperclip />, <Icons.Calendar3 />, <Icons.Newspaper />, <Icons.Easel />, <Icons.Briefcase />]}
								step={this.state.wizardStep}
								onClick={(index) => this.setState({wizardStep: index})} />

							<div className="wizard-body">

								<TalentDetailsStep index={WizardStep.USER} onChange={(user) => this.handleUserChange(user)} user={this.state.user} step={this.state.wizardStep} />

								<UploadStep index={WizardStep.UPLOAD} accountId={SessionStore.getAccountId()} onChange={(file) => this.handleUploadChange(file)} step={this.state.wizardStep}
									helpText="Upload the original resume of the new team member, this is often a useful reference of their talents and skills. The resume is not visible for standard user roles. PDF and Word documents are supported."/>

								<AvailabilityStep index={WizardStep.AVAILABILITY} 
									onOffsetDateChanged={(offsetDate) => this.handleAvailabilityDateChange(offsetDate)}
									onOffsetDaysChanged={(offsetDays) => this.handleAvailabilityDaysChange(offsetDays)}
									availability={this.state.availability} 
									step={this.state.wizardStep}
									helpText="What date will the new team member be available to start working with us."
									/>

								<SummaryStep index={WizardStep.SUMMARY} onChange={(profile) => this.handleProfileChange(profile)} profile={this.state.profile} step={this.state.wizardStep}/>

								<EducationStep index={WizardStep.EDUCATION} onChange={(profile) => this.handleProfileChange(profile)} profile={this.state.profile} step={this.state.wizardStep}/>

								<ExperienceStep index={WizardStep.EXPERIENCE} onChange={(profile) => this.handleProfileChange(profile)} profile={this.state.profile} step={this.state.wizardStep}/>

							</div>

							<div className="wizard-footer">
								{this.state.wizardStep > 0 ? <button className="btn" onClick={() => this.handleBack()}>Back</button> : null }
								<button className="btn btn-default" onClick={() => this.handleNext()}>{this.nextButtonLabel()}</button>
							</div>

						</div>


					</div>
				</div>
			</SectionBody>

		);
	}
}
