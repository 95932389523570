import React from 'react';

import Spinner from "components/general/Spinner";
import * as AccountActions from "actions/AccountActions";
import Routes from "actions/Routes"

import '../layouts/Layout.css';
import logger from 'actions/Logger';

export default class OAuthVerifyPage extends React.Component {

	constructor(props) {
		super(props);
		console.log(props);
	}
	
	//http://localhost:3000/oauth2/ad/verify?code=0.AAAA-FdFLfsa30eBqUDkEuaE1rny3rC7DwtDnjCiicnF45RBAOE.AQABAAIAAABeStGSRwwnTq2vHplZ9KL4IYEbbOGdZ0SpIlbi0uobBUo6YS4KDKZElmewEKZP2zqiXzTzLPxNzSaf9z68086Cjo-XFP_evNaHZVeowfS-Kqp5QIaCr7Oz-OBqexLxusdDGt6qCih98JP_Z26CysVx9bm2JvwG_KNlM9PViCu2CTn-A7oHKpUK7gXf3koCgfxOTLp2MYXj2mxfs3-i4BocbURaqfdf4SaQU-qmJinryOQsMxSoFCa-EBnTBY5NRK67ZEkfKiKkcSmtcutQuP_MVKzQTa4u2D8bdOpSM-XmVtmq3J6HkgJnPDUH-7k4R0yxm-oFQpYWJbmlTVUH01XkocsRBBiIvEaYgbsIiX9qKZp5xUtJkRsDoYZqfGaPeiFi3vJL03E4TyFpAC-uCj-WX-WFA2y3DEBnI729fAb0RR2AyhX80kn8qRqZ8ilPsxbcq1wIZxiiohBUfjw1R3rh-3kpKw8qcPG90RqdbD59Yq8a7fSGlAHOc_Zs1QdEJ2mMWUziWR4or5HxnNydqshMpD6hqfk6gUL_r0wa7p30zyAA&state=8138A58452282%40excopartners.com&session_state=9cf08b9d-2adb-4f75-9f7f-c19de8fa95d7#
	componentWillMount() {

		let query = new URLSearchParams(this.props.location.search);
		let authCode = query.get("code");
		let state = query.get("state");
		if (authCode && state) {
			logger.debug("AUTH VERIFY", authCode);
			AccountActions.oauth2Verify(this.props.match.params.provider, authCode, state,
				(auth) => this.props.history.push(Routes.home()),
				(error) => this.props.history.push(Routes.login() + "?error=" + error.message)
			);
		} else {
			logger.debug("NO AUTH VERIFY", authCode);
			this.props.history.push(Routes.login() + "?error=OAuth authentication failed");
		}

	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	render() {
		return (
			<div className="spinner-container">
				<Spinner />
			</div>
		);
	}

}