import React, { useState } from 'react';
import * as FormFields from "components/forms/FormFields";
import * as Utils from "components/general/Utils";
import * as Dto from "actions/Dto";
import { useEffect } from 'react';


interface AvailabilityProps {
	availability: Dto.Availability|Dto.UserAvailabilityForm,
	step?: number,
	index?: number
	onOffsetDateChanged: (offsetDate: Date) => void
	onOffsetDaysChanged: (offsetDays: number) => void
}

//export default class Availability extends React.Component<AvailabilityProps, AvailabilityState> {
export const Availability: React.FunctionComponent<AvailabilityProps> = (props) => {

	const dates: Date[] = Utils.dateSpans();
	const dateLabels = Utils.dateSpanLabels();

	const [slider, setSlider] = useState(0);
	const [type, setType] = useState(Dto.AvailabilityType.DATE);

	useEffect(() => {

		const sliderFromDate = (date: Date|null): number => {

			if (!date) return 0;
			let index = dates.findIndex((nextDate) => {
				return date <= nextDate;
			});
			return (index >= 0 ? index : dates.length - 1);
		}

		//Check if Dto.Availability
		if ((props.availability as Dto.Availability).date !== undefined) {
			let date = (props.availability as Dto.Availability).date;
			setSlider(sliderFromDate(date));
			setType( (props.availability as Dto.Availability).type );
		}

	}, [props.availability, dates]);

	

	const offsetDate = () => {
		return (props.availability.offsetDate ? props.availability.offsetDate : Utils.formatDate(new Date()));
	}

	const availabilityFormatted = () => {
		let offsetDate_ = offsetDate();
		return (offsetDate_ ? Utils.formatDateWithMonth(Utils.parseDateTime(offsetDate_)) : Utils.formatDateWithMonth(new Date()))
	}

	const availabilityLabel = () => {
		return dateLabels[slider];
	}



	const handleChange = (event) => {

		var slider = event.target.value;

		if (type === Dto.AvailabilityType.DATE) {
			let value = dates[slider];
			props.onOffsetDateChanged(value);
		} else {
			let value = dates[slider];
			props.onOffsetDaysChanged(Utils.daysBetween(new Date(), value));
		}

		setSlider(slider);

	}

	const handleTypeChange = (event) => {

		var type = event.target.value;

		if (type === Dto.AvailabilityType.DATE) {
			let value = dates[slider];
			props.onOffsetDateChanged(value);
		} else {
			let value = dates[slider];
			props.onOffsetDaysChanged(Utils.daysBetween(new Date(), value));
		}

		setType(type);
		
	}

	if (props.step && props.index && props.step!== props.index) return null;

	return (

		<>

			<FormFields.Radio 
				name="availabilityType"
				options={
					[
						{value: Dto.AvailabilityType.DATE, label:"Available on Date"}, 
						{value: Dto.AvailabilityType.NOTICE, label:"Available with Notice"}
				]}
				title="Availability"
				valueSelector={option => option.value} labelSelector={option => option.label}
				value={type} 
				onChange={e => handleTypeChange(e) }
			/>

			<FormFields.Slider name="availability" title="Available from" min={0} max={dates.length - 1} step={1} value={slider} onChange={(e) => handleChange(e)}/>

			<div className="availability">
				<h5>{availabilityLabel()}</h5>
				{type ===  Dto.AvailabilityType.DATE ? <small>{availabilityFormatted()}</small> : null }
			</div>

		</>

	);

}