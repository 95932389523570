
class Routes {

	splash() {
		return "/splash";
	}

	login(email?: string) {
		if (email) return "/login/" + email;
		return "/login";
	}
	
	oauth2Verify(provider) {
		return "/oauth2/" + provider + "/verify";
	}

	loginSuccess() {
		return "/login/success";
	}

	settings() {
		return "/settings";
	}

	settingsForTimesheets() {
		return "/settings/timesheets";
	}

	settingsForTemplates() {
		return "/settings/templates";
	}

	home() {
		return "/home";
	}

	join(token) {
		return "/join/" + (token ? token : "");
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

	A C C O U N T

	* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
	accountCreate() {
		return "/account/create";
	}

	accountJoin() {
		return "/account/join"
	}

	accountJoined() {
		return "/account/joined"
	}

	accountJobApplicationEmbeddable(token) {
		return "/jobs/" + token + "/apply/embedded";
	}

	accountJobApplication(token) {
		return "/jobs/" + token + "/apply";
	}

	masquerade(accountId) {
		return "/account/" + accountId + "/masquerade"
	}

	accountStylesheet(accountId) {
		return "/api/accounts/" + accountId + "/style.css"
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

	S K I L L S

	* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
	skills() {
		return "/skills";
	}


	skillsEdit(skillTypeId) {
		return "/skills/" + skillTypeId + "/edit";
	}

	skillsTalent(skillTypeId) {
		return "/skills/" + skillTypeId + "/talent";
	}

	skillsTalentAvailability(skillTypeId) {
		return "/skills/" + skillTypeId + "/talent/availability";
	}

	community() {
		return "/community";
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

		U S E R S  /  T A L E N T

	* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
	talent() {
		return "/talent";
	}

	userProfile(userId) {
		return "/talent/" + userId + "/profile";
	}

	userEdit(userId) {
		return "/talent/" + userId + "/edit";
	}

	userAdd() {
		return "/talent/add";
	}

	userOnboard() {
		return "/talent/onboard";
	}

	myProfile() {
		return "/profile";
	}

	talentSearch() {
		return "/talent/search";
	}

	talentSearchAdvanced() {
		return "/talent/search/advanced";
	}


	publishProfile(accountId: number, userId: number, templateId: number) {
		return `/api/accounts/${accountId}/profiles/publish?userId=${userId}&templateId=${templateId}`;
	}


	profileHistoryRestore(accountId: number, profileId: number, publishedProfileId: number) {
		return `/api/accounts/${accountId}/profiles/${profileId}/history/${publishedProfileId}/restore`;
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

	P R O J E C T S

	* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
	projects() {
		return "/projects";
	}

	project(projectId) {
		return "/projects/" + projectId + "/edit";
	}

	projectAdd() {
		return "/projects/add";
	}

	userAssignments(userId) {
		return "/talent/" + userId + "/assignments";
	}

	projectTimesheetsReport(accountId, projectId) {
		return "/api/accounts/" + accountId + "/projects/" + projectId + "/reports?name=summary";
	}

	assignmentsReport(accountId, params) {
		return "/api/accounts/" + accountId + "/assignments/reports?" + params;
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

	T I M E S H E E T S

	* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	timesheetsForUser(userId: number|string, date?: string|Date) {
		return "/talent/" + userId + "/timesheets" + (date ? "/" + date : "");
	}

	timesheets() {
		return "/timesheets";
	}

	timesheetReports(accountId, params) {
		return "/api/accounts/" + accountId + "/timesheets/reports?" + params;
	}

	timesheetSubscriptions(token: string) {
		return "/timesheets/subscriptions/" + token;
	}


}

const routes = new Routes();
export default routes;