import React from 'react';

import SessionStore from "store/SessionStore"
import ErrorMessages from "components/general/ErrorMessages"
import * as FormFields from "components/forms/FormFields"
import * as AccountActions from "actions/AccountActions"
import * as Icons from 'react-bootstrap-icons';
import { IconButton } from "components/general/IconButton"

import './Settings.css';

export default class Features extends React.Component {

	constructor(props) {
		super();
		this.state = {account: SessionStore.getAccount(), edit: null, editType: null, features: SessionStore.getFeatures()}
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	unedit() {
		this.setState({edit: null, editType: null});
	}

	edit(editType) {
		this.setState({edit: [...SessionStore.getFeatures()], editType: editType})
	}

	handleSubmit(event) {
		AccountActions.accountFeaturesUpdate(SessionStore.getAccountId(), this.state.edit,
			(settings) => this.setState({edit: null, editType: null, messages: ["Feature list updated"], features: settings.features}),
			(errors) => this.setState({errors: errors})
		);
	}

	handleDeleteFeature(index) {
		if (index >= 0) {
			var features = this.state.edit;
			features.splice(index, 1);
			this.setState({edit: features});
		}
	}

	handleAddFeature(value) {
		var features = this.state.edit;
		features.push(value);
		this.setState({edit: features});
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


	render() {

		return (

			<div className="company-settings">

				<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages} />

				<div className="settings-tile">

					<h4>Features</h4>
					<div>
						{this.state.features.map((feature, index) => <span key={index} className="setting-value">{index > 0 ? "; " : ""}{feature}</span>)}
					</div>

					<div className="controls">
						<IconButton onClick={() => this.edit("features")} icon={<Icons.Pencil />} />
					</div>

				</div>


				<FormFields.Model title="Edit Features" isOpen={this.state.editType === "features"}
					onHide={(e) => this.unedit()} onCancel={(e) => this.unedit()} onConfirm={(e) => this.handleSubmit(e)} >

					<FormFields.List title="" values={this.state.edit}
						onAdd={(value) => this.handleAddFeature(value)} onDelete={(index) => this.handleDeleteFeature(index)}
					/>

				</FormFields.Model>

			</div>
		);
	}
}
