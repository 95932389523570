import React from 'react';
import { Link } from "react-router-dom"

import Routes from "actions/Routes"

import SessionStore from "store/SessionStore"
import * as Utils from "components/general/Utils"
import Glyphicon from "components/general/Glyphicon"



export default class ProfileSearchResult extends React.Component {

	constructor(props) {
		super(props);
		this.state = {profile: props.profile, summary: props.summary, skills: props.skills, experience: props.experience};
	}

	render() {

		var props = this.state;

		return(
			<tr key={props.profile.id}>
				<td className="thumbnail">
					<img className="profile-thumbnail" src={props.profile.user.photoThumbnailUrl ? props.profile.user.photoThumbnailUrl : "/media/user@4x.png"} alt="User"/>
				</td>

				<td className="details">
					<div className="recent-talent tile" key={"user-" + props.profile.user.id}>

						<div className="row profile-thumbnail-tile">
							<div className="col">
								<h5 title={props.profile.user.firstname + " " + props.profile.user.surname}><Link to={Routes.userProfile(props.profile.user.id)}>{props.profile.user.firstname} {props.profile.user.surname}</Link></h5>
								<div className="headline" title={props.profile.headline}>{props.profile.user.department ? props.profile.user.department + " / " : null}{props.profile.headline}</div>
								<small className="summary" title={props.summary}>{Utils.elipsis(props.summary, 200)}</small>
								<small className="summary" title={props.experience}>{Utils.elipsis(props.experience, 200)}</small>
								<small className="summary" title={props.skills}>Skills: {Utils.elipsis(props.skills, 200)}</small>
							</div>
						</div>
					</div>
				</td>
				<td className="actions">
					{props.profile.user.status === "ACTIVE" ? null : <div className={"user-status enum-type " + props.profile.user.status}>{Utils.titleCase(props.profile.user.status)}</div>}

					<div>
						<Link to={Routes.userProfile(props.profile.user.id)}><Glyphicon name="id-badge" /></Link>
						{SessionStore.isWriteAccess(props.profile.user) ? <Link to={Routes.userEdit(props.profile.user.id)}><Glyphicon name="user" /></Link> : null }
					</div>
				</td>
			</tr>
		);
	}
	//<a onClick={() => Utils.copyToClipboard(this.refs["user-tile-" + props.profile.user.id])}>Copy</a>
}