import React from 'react';
import Routes from "actions/Routes"
import SessionStore from "store/SessionStore"
import { TopMenuItem } from '../../layouts/navigation/TopMenuItem';
import * as Dto from "actions/Dto";

interface TalentContextMenuProps {
	user: Dto.UserDto|null
	path: string
}
	
export const TalentContextMenu: React.FunctionComponent<TalentContextMenuProps> = (props) => {
	
	const isActive = (currentPath, match) : boolean => {

		if (!currentPath) return false;
		return currentPath.match(match);

	}

	if(!props.user) return null;
	return (
		<React.Fragment>
			<TopMenuItem label={"/ " + props.user.firstname + " " + props.user.surname} to={Routes.userProfile(props.user.id)} active={false}/>
			<TopMenuItem label="Profile" to={Routes.userProfile(props.user.id)} active={isActive(props.path, Routes.userProfile("([0-9]*)"))}/>
			<TopMenuItem label="Details" to={Routes.userEdit(props.user.id)} hidden={!SessionStore.isWriteAccess(props.user)} active={isActive(props.path, Routes.userEdit("([0-9]*)"))}/>
			{ SessionStore.isProjectsEnabled() && SessionStore.isUserRole() ?
				<TopMenuItem label="Assignments" to={Routes.userAssignments(props.user.id)} active={isActive(props.path, Routes.userAssignments("([0-9]*)"))} />
				: null
			}
			{ SessionStore.isTimesheetsEnabled() ?
				<TopMenuItem label="Timesheet" to={Routes.timesheetsForUser(props.user.id)} active={isActive(props.path, Routes.timesheetsForUser("([0-9]*)"))} />
				: null
			}
		</React.Fragment>
	);
	

}