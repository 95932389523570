import React from 'react';
import { Link, RouteComponentProps } from "react-router-dom";
import Remarkable from 'remarkable';

import * as AccountActions from "actions/AccountActions";
import * as Dto from "actions/Dto";
import * as ProfileActions from "actions/ProfileActions";
import Routes from "actions/Routes";
import * as FormFields from "components/forms/FormFields";
import ErrorMessages from "components/general/ErrorMessages";
import { IconButton } from 'components/general/IconButton';
import Spinner from "components/general/Spinner";
import * as Utils from "components/general/Utils";
import SectionBody from "components/layouts/SectionBody";
import UserAvatar from "components/pages/users/UserAvatar";
import * as Icons from 'react-bootstrap-icons';
import ReactGA from 'react-ga';
import SessionStore from "../../../store/SessionStore";
import { TalentContextMenu } from '../users/TalentContextMenu';
import { Educations } from "./Educations";
import { Experiences } from "./Experiences";
import Features from "./Features";
import Skills from "./Skills";

import routes from "actions/Routes";
import './Profile.css';


enum EditTypes {
    SUMMARY = "summary",
    EMAIL = "email",
    HISTORY = "history",
	SHARE = "share",
	ADD_EXPERIENCE = "add-experience"
}

interface ProfilePageState {
	profile: null|Dto.ProfileDto,
	editType: null|EditTypes,
	editId?: null|number,
	email: any
	accountId: number|null,
	profileDownloadLink: null|string,
	templateId: null|number,
	errors: Dto.ErrorDto[]|null,
	messages: string[]|null,
	history: any|null
	wizard: boolean
	extractingResume: boolean
}

export default class ProfilePage extends React.Component<RouteComponentProps<{userId?: string, email?: string}>, ProfilePageState> {

	private path: string = "";

	constructor(props) {
		super(props);
		this.state = {
			profile: null, editType: null, editId: null, email: {},
			accountId: SessionStore.getAccountId(), profileDownloadLink: null,
			templateId: null, errors:null, messages: null, history: null, wizard: false,
			extractingResume: false
		};

		//editType: summary, email, history, share, add-experience, edit-experience

		this.path = Routes.userProfile(props.match.params.userId);
	}

	componentDidMount() {

		window.scrollTo(0, 0);	//scroll to top
		ReactGA.pageview(this.path);	//analytics

		//get values from store
		var userId = this.props.match.params.userId;
		var email = this.props.match.params.email;

		if (!userId && !email) userId = SessionStore.getUserId() + "";

		if (userId) {
			this.loadProfileById(userId);
		} else {
			this.loadProfileByEmail(email);
		}		

		if (this.state.templateId == null && SessionStore.getTemplates().length > 0) {
			this.setState({templateId: SessionStore.getTemplates()[0].id});
		}

	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	loadProfileById(userId) {


		if (!this.state.accountId) return null;

		ProfileActions.profileLoad(this.state.accountId, userId,
			(data) => {
				this.setState({profile: data, editType: (data.empty ? EditTypes.SUMMARY : null), wizard: data.empty});
				document.title = data.user.firstname + " " + data.user.surname;
			},
			(errors) => {this.setState({errors: errors})}
		);

	}

	loadProfileByEmail(email) {
		ProfileActions.profileByEmail(this.state.accountId, email,
			(data) => {
				this.setState({profile: data, editType: (data.empty ? EditTypes.SUMMARY : null), wizard: data.empty});
				this.path = Routes.userProfile(data.user.id);	//update path since loaded with email
				document.title = data.user.firstname + " " + data.user.surname;
			},
			(errors) => {this.setState({errors: errors})}
		);
	}

	loadProfileDownloadLink(templateId: number) {

		if (!this.state.accountId || !this.state.profile) return null;

		//Get profile download link
		ProfileActions.profileDownloadLink(this.state.accountId, this.state.profile.user.id, templateId,
			(link) => this.setState({profileDownloadLink: link.publicUrl, templateId: templateId}),
			(errors) => this.setState({errors: errors, profileDownloadLink: null})
		);
	}

	edit(type: EditTypes, id?: number) {
		this.setState({editType: type, editId: id});
	}

	unedit() {
		this.setState({editType: null, editId: null});
	}

	handleChangeProfile(event) {

		var profile = this.state.profile;

		if (profile) {
			var value = event.target.value;
			if (!value) value = "";
			value = value.replace(/•/g, "-");	//replace bullets with markdown
			value = value.replace(/ & /g, " and ");	//replace & with and
			value = value.replace(//g, "-");	//replace bullets with markdown
			value = value.replace(/-\t/g, "- ");	//replace bullets-tab with bullet-space

			profile[event.target.name] = value;
			this.setState({profile: profile});
		}
	}

	handleChangeEmail(event) {

		var email = this.state.email;

		if (email) {
			var value = event.target.value;
			email = value;
			this.setState({email: email});
		}

	}

	handleSubmit() {
		this.setState({errors: null});
		ProfileActions.profileUpdate(this.state.accountId, this.state.profile,
			(profile) => this.setState({profile: profile, editId: null, editType: (this.state.wizard ? EditTypes.ADD_EXPERIENCE : null)}),
			(errors) => {this.setState({errors: errors})}
		);
	}

	handleProfileUpdated(profile) {
		this.setState({profile: profile});
	}

	handleChangeTemplate(event) {

		this.setState({templateId: event.target.value, profileDownloadLink: null});
		this.loadProfileDownloadLink(event.target.value);

	}

	handleStateUpdate(event, state) {

		if (!this.state.accountId || !this.state.profile) return null;

		ProfileActions.profileStateUpdate(this.state.accountId, this.state.profile, state,
			(profile) => this.setState({profile: profile}),
			(errors) => {this.setState({errors: errors})}
		);
	}

	handleShowShare() {
		this.setState({editType: EditTypes.SHARE});
		if (this.state.templateId) this.loadProfileDownloadLink(this.state.templateId);
	}

	handleShowHistory() {

		if (!this.state.accountId || !this.state.profile) return null;

		this.setState({editType: EditTypes.HISTORY});
		ProfileActions.profileHistory(this.state.accountId, this.state.profile,
			(data) => this.setState({history: data.values}),
			(errors) => {this.setState({errors: errors})}
		);
	}

	handleShowEmail() {
		let account = SessionStore.getAccount();

		if (account && this.state.profile) {
			var email = {toAddress: "", subject: ""};
			email.subject = account.name + " - " + this.state.profile.user.fullname + " Profile";
			this.setState({editType: EditTypes.EMAIL, email: email});
		}
	}

	handleSendEmail() {
		ProfileActions.profileSend(this.state.accountId, this.state.profile,
			{ toAddress: this.state.email.toAddress, subject:  this.state.email.subject, templateId: this.state.templateId },
			(data) => this.setState({messages: ["Profile sent"], editType: null}),
			(errors) => {this.setState({errors: errors})}
		);
	}

	handleCopyDownloadLink() {
		Utils.copyTextToClipboard(this.state.profileDownloadLink);
		this.setState({messages: ['Link copied to clipboard']})
	}

	handleExtractResume() {
		if (!this.state.accountId || !this.state.profile) return null;
		this.setState({extractingResume: true})
		ProfileActions.profileExtractFromResume(this.state.accountId, this.state.profile.user.id, 
			(profile) => {
				this.setState({profile: profile, extractingResume: false})
			},
			(errors) => {this.setState({errors: errors, extractingResume: false})}
		);
	}

	getPdfUrl() {

		if (this.state.templateId && this.state.profile) {

			let template = SessionStore.getTemplateById(this.state.templateId as number);
			//console.log("getPdfUrl", this.state.templateId, this.state.profile, template);
			if (template) {
				return template.pdfUrl + "?userId=" + this.state.profile.user.id + "&templateId=" + template.id;
			}
			return "none";
		}
	}

	getPublishUrl() {

		if (!this.state.templateId || !this.state.profile) return null;

		var template = SessionStore.getTemplateById(this.state.templateId);
		if (template && SessionStore.accountId) {
			return Routes.publishProfile(SessionStore.accountId, this.state.profile.user.id, template.id);
			//return template.publishUrl + "?userId=" + this.props.match.params.userId + "&templateId=" + template.id;
		}
		return "none";
	}

	getClassFromStatus(status) {
		if (status === 'DRAFT') return 'btn-danger';
		if (status === 'REVIEW') return 'btn-warning';
		return 'btn-default';
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	renderUserDetails() {
		if(this.state.profile != null) {
			return (
				<div className="card-body">
					<div><span>{this.state.profile.user.firstname}</span> <span>{this.state.profile.user.surname}</span></div>
					<div><span>{this.state.profile.user.email}</span></div>
				</div>
			);
		}
		return (<div className="card-body"></div>)
	}

	renderSummary() {
		if (this.state.profile === null) return null;
		var markdown = new Remarkable();

		return (
			<div>
				<h3>{this.state.profile.headline != null ? this.state.profile.headline : "Summary"}</h3>
				<div dangerouslySetInnerHTML={{ __html: markdown.render(this.state.profile.summary)}} />
				{this.renderEdit()}
				<div className="controls"><IconButton icon={<Icons.Pencil />} className="edit-link" title="edit" onClick={() => this.edit(EditTypes.SUMMARY)} /></div>
			</div>
		);

	}

	renderEdit() {

		if (!this.state.profile) return null;

		return (

			<FormFields.Model title="Edit Summary" isOpen={this.state.editType != null && this.state.editType === EditTypes.SUMMARY}
				onHide={() => this.unedit()} className="modal-lg" onConfirm={() => this.handleSubmit()} onCancel={() => this.unedit()}>

				{ this.state.wizard ? <p className="card-body bg-light">Begin by completing your profile starting with a Headline and Summary of yourself. You profile will include detail of your experience, skills and education which you can start to add next.</p> : null }

				<FormFields.Text name="headline" title="Headline" placeholder="Headline about your experience and skills" value={this.state.profile.headline} onChange={(e) => this.handleChangeProfile(e)} />
				<FormFields.TextArea name="summary" title="Summary" placeholder="A short elevator pitch summary about yourself. This should be a paragraph or two about your experience, skills and most importantly the achievements that you are the most proud of and which will highlight your value." value={this.state.profile.summary} onChange={(e) => this.handleChangeProfile(e)} rows={8} helpText={<small>Use markdown to add formatting. See <a href="https://en.wikipedia.org/wiki/Markdown" target="_blank" rel="noopener noreferrer">here</a> for help on markdown.</small>} />


			</FormFields.Model>
		);
	}

	renderHistory() {

		var history = <tr><td colSpan={3}>Loading...</td></tr>;

		if (this.state.history) {
			if (this.state.history.length <= 0) {
				history = <tr><td colSpan={4}>No history. Use the Publish version function to build a history of published profiles.</td></tr>;
			} else {
				history = this.state.history.map((history) =>
					<tr>
						<td><a href={history.url} target="_blank" rel="noopener noreferrer">{history.headline}</a></td>
						<td>{history.contentType}</td>
						<td>{history.template?.title}</td>
						<td>{history.updated}</td>
						<td><a href={routes.profileHistoryRestore(SessionStore.accountId || 0, history.profile.id, history.id)} target="_blank" rel="noopener noreferrer" onClick={() => confirm("Restore this version?")}><Icons.Recycle /></a></td>
					</tr>
				)
			}

		}

		return (

			<FormFields.Model title="Published History" isOpen={this.state.editType != null && this.state.editType === EditTypes.HISTORY}
				onHide={() => this.unedit()} onCancel={() => this.unedit()} onConfirm={() => this.unedit()} showConfirm={false}>

				<p>This is a list of all of the published versions of this profile. Use the <b>Publish a Version</b> function to save a version of this profile.</p>
					<table className="table table-striped">
						<tr>
							<th>Headline</th>
							<th>Type</th>
							<th>Template</th>
							<th>Date</th>
							<th>Restore</th>
						</tr>
						{history}
					</table>

			</FormFields.Model>

		);
	}

	renderPDFPanel() {
		return (

			<div className="profile-tile">

				{this.renderHistory()}

			</div>
		);

	}

	renderProfileState() {

		if (!this.state.profile) return null;

		return (
			<div className={"float-right btn-group pill " + this.state.profile.state}>
				{
				/*<button className={"btn btn-secondary btn-sm dropdown-toggle " + this.getClassFromStatus(this.state.profile.state)} type="button" data-toggle="dropdown" >
					{Utils.titleCase(this.state.profile.state)}
				</button>

				<div className="dropdown-menu">
					<button className="dropdown-item" target="_blank" onClick={(e) => this.handleStateUpdate(e, 'DRAFT')}>Mark as Draft</button>
					<button className="dropdown-item" target="_blank" onClick={(e) => this.handleStateUpdate(e, 'REVIEW')}>Mark as In Review</button>
					<button className="dropdown-item" target="_blank" onClick={(e) => this.handleStateUpdate(e, 'ACTIVE')}>Mark as Complete</button>
				</div>
				*/
				}
			</div>
		);

	}

	renderDownloadLink() {
		if (this.state.profileDownloadLink) {
			return (
				<div className="input-group mb-2">
        			<div className="input-group-prepend">
          				<div className="input-group-text"><button className="btn" onClick={() => this.handleCopyDownloadLink()}>Link</button></div>
        			</div>
        			<input type="text" className="form-control" value={this.state.profileDownloadLink} readOnly />
      			</div>
			);

		} else {
			return (
				<div className="input-group mb-2">
        			<div className="input-group-prepend">
          				<div className="input-group-text"><button className="btn">Link</button></div>
        			</div>
        			<input type="text" className="form-control" value="" placeholder="loading..." readOnly/>
      			</div>
			);
		}
	}

	render() {

		if (this.state.profile == null) {
			return (
				<div>
					<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages} type="inline"/>
					{!this.state.errors ? <Spinner /> : null}
				</div>
			);
		}

		let topMenuItems = <TalentContextMenu user={this.state.profile.user} path={this.props.location.pathname} />;

		return(

			<SectionBody className="profiles" topMenuItems={topMenuItems}
				onNotificationClose={() => this.setState({errors: null, messages: null})}
				errors={this.state.errors} messages={this.state.messages} >

				{/*	Share model box */}
				<FormFields.Model title="Share" isOpen={this.state.editType != null && this.state.editType === EditTypes.SHARE}
					onCancel={() => this.unedit()} onConfirm={() => this.unedit()} showConfirm={false}>

					<div className="row">
						<div className="col-sm">
							<FormFields.Select title="Template" value={this.state.templateId} options={SessionStore.getTemplates()} labelSelector={(option) => option.title} valueSelector={(option) => option.id} onChange={(e) => this.handleChangeTemplate(e)} />

						</div>
						<div className="col">

						</div>
					</div>
					<div className="row">
						<div className="col">{this.renderDownloadLink()}</div>
					</div>
					<hr />

					<IconButton className="big" title="Download" target="_blank" href={this.getPdfUrl()} icon={<Icons.CloudDownload />} label="Download" />
					<IconButton className="big" title="Copy Link" target="_blank" onClick={() => this.handleCopyDownloadLink()} icon={<Icons.Link />}  label="Copy Link" />
					<IconButton className="big" title="Save Version" target="_blank" href={this.getPublishUrl()} icon={<Icons.Save />}  label="Save a Version" />
					<IconButton className="big" title="Show History" onClick={() => this.handleShowHistory()} icon={<Icons.Clock />} label="History" />
					<IconButton className="big" title="Email" onClick={() => this.handleShowEmail()} icon={<Icons.Envelope />} label="Email" />

				</FormFields.Model>

				{/*	Email model box */}
				<FormFields.Model title="Send" isOpen={this.state.editType != null && this.state.editType === EditTypes.EMAIL}
					onCancel={() => this.unedit()} onConfirm={() => this.handleSendEmail()} confirmLabel="Send">

					<FormFields.Text name="toAddress" title="To" placeholder="reciever@email.com.au" value={this.state.email.toAddress} onChange={(e) => this.handleChangeEmail(e)} />
					<FormFields.Text name="subject" title="Subject" placeholder="Subject" value={this.state.email.subject} onChange={(e) => this.handleChangeEmail(e)} />

				</FormFields.Model>



				<div className="row">
					<div className="col-md-3">

						{ SessionStore.isWriteAccess(this.state.profile.user) ?
							<div>
								<IconButton icon={<Icons.PersonCircle />} title="User Details" to={Routes.userEdit(this.state.profile.user.id)} />
								<IconButton icon={<Icons.CloudDownload />} title="Share" onClick={() => this.handleShowShare()} />
								<IconButton icon={<Icons.Clock />} title="Show History" onClick={() => this.handleShowHistory()} />
								{this.state.profile.user.resumeFilename && this.state.accountId?
									<IconButton icon={<Icons.FileRichtext />} title="Resume" target="_blank" href={AccountActions.resumeUrl(this.state.accountId, this.state.profile.user.id, this.state.profile.user.resumeFilename)} />
									: null
								}
								{this.renderProfileState()}
							</div>
							:
							<div>
								<IconButton icon={<Icons.Share />} title="Share" onClick={() => this.setState({editType: EditTypes.SHARE})} />
							</div>
						}
						<hr/>

						{this.state.profile != null ? <h1 title={this.state.profile.user.firstname + " " + this.state.profile.user.surname}><span className="firstname-title">{this.state.profile.user.firstname}</span> <span className="surname-title">{this.state.profile.user.surname}</span></h1> : <h1>Talent Profile</h1>}

						<div className="text-center">
							<Link to={"/talent/" + this.state.profile.user.id + "/edit"}>
								<UserAvatar user={this.state.profile.user} />
							</Link>
						</div>

						<hr/>

						<div className="profile-tile">

							{this.renderHistory()}

						</div>

						{this.state.profile != null && this.state.accountId ? <Educations accountId={this.state.accountId} profile={this.state.profile} onUpdated={(profile) => this.handleProfileUpdated(profile)} /> : null}

						<hr/>
						{this.state.profile != null ? <Skills profile={this.state.profile} accountId={this.state.accountId} skills={this.state.profile.skills} onUpdated={(profile) => this.handleProfileUpdated(profile)}/> : null }

						<hr/>
						{this.state.profile != null ? <Features profile={this.state.profile} accountId={this.state.accountId} features={this.state.profile.features} onUpdated={(profile) => this.handleProfileUpdated(profile)} /> : null }

					</div>
					<div className="col">

						{this.state.profile && !this.state.profile.empty ?
							null
							: <div className="info-box warning"><IconButton icon={<Icons.ExclamationCircle />} /> Begin by completing your profile starting with a Headline and Summary of yourself. You profile will include detail of your experience, skills and education which you can start to add next.</div>
						}

						<div className="profile-tile">

							{
								this.renderSummary()
							}

						</div>

						<hr/>

						{this.state.accountId && SessionStore.isManagerRole() && this.state.profile.user.resumeFilename ?
							<div className="info-box">
								<IconButton icon={<Icons.FileRichtext />}  title="Resume" href={AccountActions.resumeUrl(this.state.accountId, this.state.profile.user.id, this.state.profile.user.resumeFilename)} target="_blank" />
								<div>View the candidates original Resume <a href={AccountActions.resumeUrl(this.state.accountId, this.state.profile.user.id, this.state.profile.user.resumeFilename)} target="_blank" rel="noopener noreferrer">here</a>.</div>
								{SessionStore.isRole(Dto.UserRoleType.ADMIN) ? <div className=""><a onClick={() => this.handleExtractResume()}  target="_blank" >{this.state.extractingResume ? "Extracting" : "Extract Resume"}</a></div> : null }
							</div>
							: null
						}

						{this.state.profile.experiences && this.state.profile.experiences.length > 0 ?
							null
							: <React.Fragment>
								<div className="info-box warning"><IconButton icon={<Icons.ExclamationCircle />} /> Expand your profile by adding examples of your experiences.</div>
							</React.Fragment>
						}

						{this.state.accountId ?
							<div key={this.state.profile.id}>
								{this.state.profile != null ? <Experiences accountId={this.state.accountId} profile={this.state.profile} onUpdated={(profile) => this.handleProfileUpdated(profile)} /> : null }
							</div>
							: null
						}

					</div>
				</div>


			</SectionBody>
		);
	}
}
