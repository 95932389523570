import React from 'react';
import Routes from "actions/Routes"
import * as FormFields from "components/forms/FormFields"
import * as Const from "components/general/Constants"
import ErrorMessages from "components/general/ErrorMessages"
import * as AccountActions from "actions/AccountActions"
import Spinner from "components/general/Spinner"

import ContactDetailsStep from "./ContactDetailsStep"
import UploadStep from "./UploadStep"
import { AvailabilityStep } from "./AvailabilityStep"
import SummaryStep from "./SummaryStep"
//import EducationStep from "./EducationStep"
//import ExperienceStep from "./ExperienceStep"
import * as Utils from "components/general/Utils"
import Glyphicon from '../../../general/Glyphicon';

const WizardStep = {
	USER: 0,
	UPLOAD: 1,
	AVAILABILITY: 2,
	SUMMARY: 3,
	EDUCATION: 4,
	EXPERIENCE: 5
}

export default class ApplicationPage extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			jobToken: props.match.params.token,
			user: { contact:{} },
			profile: { educations: [{}], experiences: [{}] },
			availability: Utils.formatDate(new Date()),
			availabilityLabel: "Now",
			upload: {},
			noJob: false,
			wizardStep: 0,
			wizardFinished: false,
			password: null, passwordCheck: null,
			errors: null, messages: null};

		this.reload();
	}

	componentWillMount() {

	}

	reload() {
		AccountActions.job(this.props.match.params.token,
			(job) => this.setState({job: job, noJob: false }),
			(errors) => {this.setState({noJob: true, errors: errors})}
		);
	}

	scrollStep() {
		if (window.pageYOffset <= 100) {	//stop scrolling at 100
			window.scroll(0, 100);
			clearInterval(this.state.scrollIntervalId);
		} else {
			window.scroll(0, window.pageYOffset - 10);
		}
	}

	scrollToTop() {
		//if (window.pageYOffset > 300) window.scroll(0, window.pageYOffset - 200);
		let scrollIntervalId = setInterval(this.scrollStep.bind(this), 10);
		this.setState({ scrollIntervalId: scrollIntervalId });
	}

	handleUserChange(user) {
		this.setState({user: user});
	}

	handleProfileChange(profile) {
		this.setState({profile: profile});
	}

	handleUploadChange(file) {

		this.setState({resumeFilename: file});

	}

	handleChange(event) {
		var state = this.state;
		state[event.target.name] = event.target.value;
		this.setState(state);
	}

	handleAvailabilityChange(availability) {
		this.setState({availability: Utils.formatDate(availability)});
	}

	handleNext() {

		var step = this.state.wizardStep;

		if (this.validateStep(step)) {
			this.setState({errors: null, wizardStep: step + 1});
		}

		this.scrollToTop();	//scroll to top

	}

	handleBack() {
		var step = this.state.wizardStep - 1;
		if (step < 0) step = 0;
		this.setState({wizardStep: step});
		this.scrollToTop();	//scroll to top
	}

	handleSubmit() {

		if (!this.validate()) {
			this.scrollToTop();	//scroll to top
			return;
		}

		let requestData = {
			user: this.state.user,
			profile: this.state.profile,
			password: "no-password",
			availability: this.state.availability,
			resumeFilename: this.state.resumeFilename
		}

		AccountActions.jobApply(this.props.match.params.token,
			requestData,
			(data) => this.setState({user: data, wizardFinished: true}),
			(errors) => {this.setState({errors: errors})}
		);

		this.scrollToTop();	//scroll to top

	}

	validate() {

		var errors = [];
		return this.validateStep(3, errors) & this.validateStep(0, errors);

	}

	validateStep(step, errors) {

		if (!errors) errors = [];
		let errorCount = errors.length;

		if (step === WizardStep.USER) {
			//contact
			if (!this.state.user.firstname) errors.push({message: "A firstname must be specified"});
			if (!this.state.user.firstname) errors.push({message: "A surname must be specified"});
			if (!this.state.user.email) errors.push({message: "Email has not been provided"});
			//if (!this.state.user.password) errors.push({message: "Password has not been provided"});
			//if (this.state.user.password && this.state.user.password.length < 6) errors.push({message:"Password length must be 6 characters or more"});
		}

		if (step === WizardStep.SUMMARY) {
			//summary
			if (!this.state.profile.headline) errors.push({message: "A headline about your experience and skills required."});
			if (!this.state.profile.summary) errors.push({message: "A short elevator pitch summary of your experience is required."});
		}

		if (errors.length > errorCount) this.setState({errors: errors, wizardStep: step});
		return errors.length === errorCount;

	}



	render() {

		if (!this.state.job) {

			if (this.state.noJob) {

				return(

					<section className="apply">

						<div className="jobs-inner">

							<h1>Job not Found</h1>

						</div>

					</section>

				);

			} else {
				return (
					<div className="spinner-container">
						<Spinner />
					</div>
				);
			}

		}

		if (this.state.wizardFinished) {
			return (
				<section className="apply finished">

					<div className="jobs-inner">

						<h1>Congratulations</h1>

						<div className="wizard-body">

							<p>Your application to {this.state.job.account.name} has been sucessfully submitted.</p>

							{this.state.user.inviteStatus === Const.UserInviteStatus.ACCEPTED ?
								<React.Fragment>
									<p>We have created a profile to capture your capabilities and experience from your application. You can view, change and add to your profile to keep us up to date. Simply click the link below to start.</p>
									<p><a href={Routes.userProfile(this.state.user.id)} className="btn btn-default">View your Profile</a></p>
								</React.Fragment>
								:
								<React.Fragment>
									<p>{this.state.job.account.name} will be in touch shortly to discuss the next steps in your application.</p>
								</React.Fragment>
							}

						</div>



					</div>

				</section>
			);
		}

		return(

			<section className="apply">

				<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages} />

				<div className="jobs-inner">

					<h1>Join {this.state.job.account.name}</h1>
					<div>{this.state.job.title}</div>
					<div>{this.state.job.description}</div>
					<hr/>
					{this.state.job.instructions ?
						<div className="info-box plain"><Glyphicon name="circle-empty-info" className="large blue" /> {this.state.job.instructions}</div>
						: <div className="info-box plain"><Glyphicon name="circle-empty-info" className="large blue" /> Please complete your profile to submit and finalise your application with {this.state.job.account.name}</div>
					}

					<FormFields.WizardSteps
						stepIcons={["id-badge", "paperclip", "calendar", "newspaper"]}
						step={this.state.wizardStep}
						onClick={(index) => this.setState({wizardStep: index})} />

					<div className="wizard-body">

						<ContactDetailsStep index={0} onChange={(user) => this.handleUserChange(user)} user={this.state.user} step={this.state.wizardStep} hidePassword={true} hideAddress={true} />

						<UploadStep index={1} onChange={(file) => this.handleUploadChange(file)} token={this.props.match.params.token} step={this.state.wizardStep}/>

						<AvailabilityStep index={2} onChange={(availability) => this.handleAvailabilityChange(availability)} availability={this.state.availability} availabilityLabel={this.state.availabilityLabel} step={this.state.wizardStep}/>

						<SummaryStep index={3} onChange={(profile) => this.handleProfileChange(profile)} profile={this.state.profile} step={this.state.wizardStep}/>

						{
							//<EducationStep index={4} onChange={(profile) => this.handleProfileChange(profile)} profile={this.state.profile} step={this.state.wizardStep}/>

							//<ExperienceStep index={5} onChange={(profile) => this.handleProfileChange(profile)} profile={this.state.profile} step={this.state.wizardStep}/>

						}

					</div>

					<div className="wizard-footer">
						<button className="btn" onClick={() => this.handleBack()}>Back</button>
						{this.state.wizardStep < 3 ?
							<button className="btn btn-default" onClick={() => this.handleNext()}>Next</button>
							:
							<button className="btn btn-default" onClick={() => this.handleSubmit()}>Submit</button>
						}
					</div>

				</div>

			</section>

		);
	}

}