import React from 'react';

import Routes from "actions/Routes";
import SessionStore from "../../../store/SessionStore"
import Company from "./Company"
import Features from "./Features"
import Authentication from "./Authentication"
import SectionBody from "components/layouts/SectionBody"
import { SettingsContextMenu } from "./SettingsContextMenu";

import ReactGA from 'react-ga';

import './Settings.css';

export default class SettingsPage extends React.Component {

	constructor(props) {
		super();
		this.state = {templates: SessionStore.getTemplates(), account: SessionStore.getAccount(), edit: null, template: {}}
	}

	componentDidMount() {

		if (!SessionStore.isAdminRole()) {
			this.props.history.push("/");
		}

		ReactGA.pageview(Routes.settings());	//analytics

	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */



	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


	render() {

		if (!SessionStore.isAdminRole()) {
			return <section className="settings">No access</section>
		}

		let topMenuItems = <SettingsContextMenu path={this.props.location.pathname} />;

		return (

			<SectionBody className="settings"
				onNotificationClose={() => this.setState({errors: null, messages: null})}
				errors={this.state.errors} messages={this.state.messages} 
				topMenuItems={topMenuItems}
				>

				<div className="row justify-content-md-center">

					<div className="col-7">

						<h1>Settings</h1>

						<Company />

						<Authentication />

						<Features />

					</div>

				</div>


			</SectionBody>
		);
	}
}
