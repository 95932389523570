import React from 'react';
import { Link, RouteComponentProps } from "react-router-dom"
import Remarkable from "remarkable"

import Routes from "actions/Routes"
import SessionStore from "store/SessionStore"
import * as ProjectActions from "actions/ProjectActions"
import * as TalentActions from "actions/TalentActions"
import Glyphicon from "components/general/Glyphicon"
import * as Utils from "components/general/Utils"
import AbstractDataPage, { DataPageState } from '../AbstractDataPage'
import { TalentContextMenu } from './TalentContextMenu'
import UserAvatar from "components/pages/users/UserAvatar"
import { AssignmentBriefEditForm } from './AssignmentBriefEditForm'
import { AssignmentDto, UserDto } from "actions/Dto";

interface AssignmentsPageState extends DataPageState<AssignmentDto> {
	user?: UserDto
	editAssignmentId: number | null
}

export default class AssignmentsPage extends AbstractDataPage<AssignmentDto, AssignmentsPageState> {

	constructor(props) {

		super(props);
		this.loadUser(props.match.params.userId);

	}

	initialiseState(props: RouteComponentProps<{ id?: string | undefined}>): AssignmentsPageState {
		return { 
			...this._defaultState,
			editAssignmentId: null,
			user: undefined
		}
	}

	pagePath() {
		return Routes.userAssignments(this.props.match.params["userId"]);
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	loadUser(userId) {

		if (!SessionStore.accountId) return;

		//Load User
		TalentActions.talentLoad(SessionStore.accountId, userId,
			(user) => this.setState({user: user}) ,
			(errors) => {this.setState({errors: errors})}
		);
	}

	loadData(page: number) {

		if (page < 0) page = 0;

		ProjectActions.assignmentsByUser(this.state.accountId, this.props.match.params["userId"], page, this.state.quickFilter,
			(data) => this.handleLoadSuccessCallback(data),
			(errors) => this.handleErrorCallback(errors)
		);

	}

	loadMore(page: number) {

		ProjectActions.assignmentsByUser(this.state.accountId, this.props.match.params["userId"], this.state.data ? this.state.data.index + 1 : 0, this.state.quickFilter,
			(data) => this.handleLoadMoreSuccessCallback(data),
			(errors) => this.handleErrorCallback(errors)
		);

	}


	handleAssignmentSaved(assignment: AssignmentDto) {

		this.handleSaveSuccess(assignment);
		this.unedit();

	}

	unedit() {
		this.setState({errors: null, messages: null, edit: null, editAssignmentId: null});
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	title() {
		return "Assignments";
	}

	renderDate(assignment) {
		
		let startDate = new Date(assignment.startDate);
		let endDate = new Date(assignment.endDate);
		return (
			<div className="project-date">
				<div><span className="date">{Utils.formatDateWithMonth(startDate)} to {Utils.formatDateWithMonth(endDate)}</span></div>
			</div>
		)
	}

	renderDataItem(assignment) {

		let project = assignment.project;

		let description = ( assignment.brief.content ? assignment.brief.content : null );
		let markdown = new Remarkable();

		return (
			<div key={project.id} className={"project my-assignment status-" + project.status.toLowerCase() + (project.selected ? " selected" : "")}>

				<div className="header">
					<div className="title">
						<h5 className="name" title={project.title}>
							<Link to={Routes.project(project.id)}>{project.title}</Link>
						</h5>
						<div className="headline" title={project.organisation}>{project.organisation}</div>
						{this.renderDate(assignment)}
					</div>
					<div className="info">
						
						<div className="assignment-terms">
							<div className="title">{assignment.title}</div>
							<div className={"badge badge-pill status " + assignment.state}>{assignment.state}</div>
						</div>
						<UserAvatar user={assignment.user} />
					</div>
				</div>
				{description ?
					<div className="brief">
						<div className="headline" >
							<h6>Assignment Brief:</h6>
							<div className="terms">
								<div className={"badge badge-pill status " + assignment.brief.state}>{assignment.brief.state}</div>
							</div>
						</div>
						<div className="brief-content" dangerouslySetInnerHTML={{ __html: markdown.render(description)}} />
					</div>
					: <div></div>
				}

				<div className="footer">
					<div>
					
					</div>
				</div>

				<div className="tools">
					<Glyphicon onClick={() => this.setState({editAssignmentId: assignment.id})} title="Edit Project" name="pencil" />
					<Link className="btn btn-icon glyphicon-button" to={Routes.project(project.id)} title="Edit Project"><Glyphicon name="briefcase-alt" /></Link>
				</div>

				<AssignmentBriefEditForm 
					title="Assignment Brief"
					assignment={assignment} 
					isOpen={this.state.editAssignmentId === assignment.id} 
					onCancel={() => this.unedit()}
					onError={(errors) => this.setState({errors: errors})}
					onUpdated={(assignment) => this.handleAssignmentSaved(assignment)}
				/>



			</div>
		);
	}
	

	renderActionsDropdown() {

		if (SessionStore.isManagerRole()) {
			return (
				<div className="dropdown">
					<button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
						<Glyphicon name="cogwheel" className="invert" />
					</button>
					<div className="dropdown-menu dropdown-menu-right">
						<Link className="dropdown-item disabled" to={"#"}>Add Project</Link>
						<button className="dropdown-item" onClick={()=> this.clearFilter() }>Clear filter</button>
						</div>
				</div>
			);
		} else {
			return null;
		}

	}

	contextMenuItems() {
		if (!this.state.user) return <></>;
		return <TalentContextMenu user={this.state.user} path={this.props.location.pathname} />;
	}

}