import * as Dto from "actions/Dto";
import * as FormFields from "components/forms/FormFields";
import ErrorMessages from "components/general/ErrorMessages";
import { IconButton } from 'components/general/IconButton';
import React, { useState } from 'react';
import * as Icons from 'react-bootstrap-icons';

import * as ProfileActions from "actions/ProfileActions";



interface EducationsProps {

	accountId: number
	profile: Dto.ProfileDto
	onUpdated: (profile: Dto.ProfileDto) => void

}

export const Educations: React.FunctionComponent<EducationsProps> = (props) => {
	
	const [ editId, setEditId ] = useState<number>();
	const [ add, setAdd ] = useState<Dto.EducationForm>();
	const [ edit, setEdit ] = useState<Dto.EducationForm>();
	const [ showAdd, setShowAdd ] = useState(false);
	const [ errors, setErrors ] = useState<Dto.ErrorDto[]>();

	const handleSubmit = () => {

		if (!editId || !edit) return;

		setErrors(undefined);
		ProfileActions.educationUpdate(props.accountId, props.profile.id, editId, edit,
			(profile) => {
				handleUnedit();
				props.onUpdated(profile);
			},
			setErrors
		);
	}


	const handleAddSubmit = (event) => {
		setErrors(undefined);
		ProfileActions.educationAdd(props.accountId, props.profile.id, add,
			(profile) => {
				handleUnadd();
				props.onUpdated(profile)
			},
			setErrors
		);
	}

	const handleEditChange = (event) => {

		if (edit == null) return;

		var _edit = { ...edit };
		_edit[event.target.name] = event.target.value;
		setEdit(_edit);

	}

	const handleAddChange = (event) => {

		if (add == null) return;

		var _add = { ...add };
		_add[event.target.name] = event.target.value;
		setAdd(_add);
	}


	const handleEdit = (education: Dto.EducationDto) => {
		setEditId(education.id);

		const form = { 
			...education, 
			startDate: education.startDate ? new Date(education.startDate).getFullYear() : undefined,
			endDate: education.endDate ? new Date(education.endDate).getFullYear() : undefined,
		} as Dto.EducationForm;

		//console.log("FORM", form);

		setEdit(form)
	}

	const handleUnedit = () => {
		setEditId(undefined);
	}

	const handleAdd = () => {
		setAdd({} as Dto.EducationForm);
		setShowAdd(true);
	}

	const handleUnadd = () => {
		setAdd(undefined);
		setShowAdd(false);
	}

	const handleDelete = (education) => {
		if (window.confirm('Delete Education?')) {
			ProfileActions.educationDelete(props.accountId, props.profile.id, education.id, 
				(profile) => props.onUpdated(profile),
				() => alert("Error deleting education")
			);
		}
	}

	const renderEducation = () => {

		return props.profile.educations.map((education) => {

			return (
				<li className="education" key={"education" + education.id} >
					<h3>{education.title}</h3>
					<div>{education.organisation}{education.endDate && education.organisation ? ", " : ""}{education.endDate}</div>
					<div className="controls">
						<IconButton icon={<Icons.Trash />} className="small delete-link" onClick={() => handleDelete(education)}/>
						<IconButton icon={<Icons.Pencil />} className="small edit-link" onClick={() => handleEdit(education)}/>
					</div>
					{editId === education.id ? renderEdit() : null}
				</li>
			);

		});

	}

	const renderEdit = () => {

		if (!edit) return;

		return (
			<FormFields.Model title="Edit Education" isOpen={true} onHide={(e) => handleUnedit()}
				onCancel={(e) => handleUnedit()} onConfirm={(e) => handleSubmit()}>

				<FormFields.Text name="organisation" title="Organisation" placeholder="Name of education provider" value={edit.organisation} onChange={(e) => handleEditChange(e)} />

				<FormFields.Text name="title" title="Title" placeholder="Title of the degree, certificate or qualification" value={edit.title} mandatory={true} onChange={(e) => handleEditChange(e)} />

				<div className="row">
					<div className="col">
						<FormFields.DateYear name="startDate" title="From Year" value={ edit.startDate } onChange={(e) => handleEditChange(e)} />
					</div>
					<div className="col">
						<FormFields.DateYear name="endDate" title="To Year" value={ edit.endDate } mandatory={false} onChange={(e) => handleEditChange(e)} />
					</div>
				</div>

			</FormFields.Model>

		);

	}

	const renderAdd = () => {

		if (!add) return;

		return (

			<FormFields.Model isOpen={showAdd} onHide={(e) => handleUnadd()} title="Add Education"
				onCancel={(e) => handleUnadd()} onConfirm={(e) => handleAddSubmit(e)}>

				<FormFields.Text name="organisation" title="Organisation" placeholder="Organisation" value={ add.organisation} onChange={(e) => handleAddChange(e)} />

				<FormFields.Text name="title" title="Title" placeholder="Title" mandatory={true} value={ add.title} onChange={(e) => handleAddChange(e)} />

				<div className="row">
					<div className="col">
						<FormFields.DateYear name="startDate" title="Start Date" value={ add.startDate } onChange={(e) => handleAddChange(e)} />
					</div>
					<div className="col">
						<FormFields.DateYear name="endDate" title="End Date" value={ add.endDate } onChange={(e) => handleAddChange(e)} />
					</div>
				</div>

			</FormFields.Model>

		);
	}
	
	return (
		<div className="profile-tile">

			<ErrorMessages errors={errors} />

			<h2>Education</h2>
			<div className="controls"><IconButton icon={<Icons.PlusLg />} onClick={() => handleAdd()} /></div>

			<ul className="educations">{renderEducation()}</ul>

			{renderAdd()}

		</div>
	);
	

}