import React from 'react';
import { Link } from "react-router-dom";

interface TopMenuItemProps {
	hidden?: boolean
	href?: string
	to?: string
	className?: string
	label?: string
	active?: boolean
	icon?: any
}

export const TopMenuItem: React.FunctionComponent<TopMenuItemProps> = (props) => {


	if (props.hidden) {
		return null;
	}

	let body = (
		<>
			{props.icon ?
				<div className="icon">{props.icon}</div>
				: null
			}
			{props.label}
		</>
	)

	if (props.href) {

		return (
			<li className={props.active ? props.className + " active" : props.className}>
				<a href={props.href}>
					{body}
				</a>
				{props.children}
			</li>
		);

	}

	if (props.to) {
		return (
			<li className={props.active ? props.className + " active" : props.className}>
				<Link to={props.to}>
				{body}
				</Link>
				{props.children}
			</li>
		);
	}

	return (
		<li className={props.active ? props.className + " active" : props.className}>
			
			{body}
			
			{props.children}
		</li>
	);

}

export const TopMenuItemDivider: React.FunctionComponent<{}> = (props) => {

	
	return (
		<li>/</li>
	);

	
}