import React from 'react';
import { Link } from "react-router-dom"

import Routes from "actions/Routes"
import * as FormFields from "../forms/FormFields"
import * as AccountActions from "actions/AccountActions"
import ErrorMessages from "../general/ErrorMessages"
import ReactGA from 'react-ga';

import '../layouts/Layout.css';

export default class AccountCreatePage extends React.Component {

	constructor(props) {
		super(props);

		this.state = {errors: props.errors, messages: props.messages, joinForm: {}};
	}

	componentWillMount() {

		window.scrollTo(0, 0);	//scroll to top
		ReactGA.pageview(Routes.accountCreate());	//analytics

	}

	handleChange(event) {

		var joinForm = this.state.joinForm;
		joinForm[event.target.name] = event.target.value;
		this.setState({joinForm: joinForm});

	}

	handleSubmit(event) {
		AccountActions.accountCreate(this.state.joinForm,
			() => this.props.history.push(Routes.accountJoined()),
			(errors) => this.setState({errors: errors})
		);
	}

	render() {
		return (
			<section className="join">

				<nav>
					<Link className="logo" to={Routes.splash()} ></Link>
				</nav>

				<div className="container">
					<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages}/>

					<div className="row justify-content-md-center">
						<div className="col-md-6 join-panel">
							<h1>Sign-up</h1>
							<p>Complete this form to create a new account for your company. If your company already has a TalentedTeam account, you can join that account <a href={Routes.accountJoin}>here.</a></p>
							<hr/>
							<div className="well">
								<h5>Company details:</h5>
								<FormFields.Text title="Company name" name="name" placeholder="Company name" value={this.state.joinForm.title} onChange={(e) => this.handleChange(e)} />

								<FormFields.Text title="Domain name" name="domain" placeholder="company-domin.com" value={this.state.joinForm.domain} onChange={(e) => this.handleChange(e)} />

								<hr/>
								<h5>Your own details:</h5>
								<FormFields.Text title="Your first name" name="firstname" placeholder="Firstname" value={this.state.joinForm.firstname} onChange={(e) => this.handleChange(e)} />
								<FormFields.Text title="Your surname" name="surname" placeholder="Surname" value={this.state.joinForm.surname} onChange={(e) => this.handleChange(e)} />

								<FormFields.Text title="Your email" name="email" placeholder="your-email@company-domain.com" value={this.state.joinForm.email} onChange={(e) => this.handleChange(e)} />

								<FormFields.Password title="Password" name="password" placeholder="" value={this.state.joinForm.password} onChange={(e) => this.handleChange(e)} />

								<FormFields.Password title="Password Check" name="passwordCheck" placeholder="" value={this.state.joinForm.passwordCheck} onChange={(e) => this.handleChange(e)} />

								<button className="btn btn-default" onClick={(e) => this.handleSubmit(e)}>Save</button>

							</div>
						</div>
					</div>
				</div>

				<footer>
					<small>TalentedTeam™ is a Trade Mark of RoarEnergy Group Pty. Ltd.</small>
					<small>RoarEnergy is proudly an Australian Company ACN: 147 462 315, ABN: 98 147 462 315</small>
				</footer>

			</section>
		);
	}

}