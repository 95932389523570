import React from 'react';
import Glyphicon from 'components/general/Glyphicon';

import './ErrorMessages.css';

export default class ErrorMessages extends React.Component {

	constructor(props) {
		super();
		this.state = {errors: props.errors, messages: props.messages, type: props.type}
	}

	componentWillReceiveProps(nextProps, nextState){
		this.setState({errors: nextProps.errors, messages: nextProps.messages});
	}

	renderErrors() {
		if (this.state.errors == null || this.state.errors.length === 0) return null;
		if (!Array.isArray(this.state.errors)) return <li>{(this.state.errors.message ? this.state.errors.message : "Error")}</li>
		return this.state.errors.map((error, i) => {
			return <li key={i}>{error.message}</li>
		});
	}

	renderMessages() {
		if (this.state.messages == null || this.state.messages.length === 0) return null;
		return this.state.messages.map((message, i) => {
			return <li key={i}>{message}</li>
		});
	}

	renderMessagesPanel() {
		if (this.state.messages == null || this.state.messages.length === 0) {
			return null;
		} else {
			return(
				<div id="messages" className={"messages messages-type-" + this.state.type + " messages-" + this.state.messages.length} >
					<ul>
						{this.renderMessages()}
					</ul>
					<Glyphicon name="times" className="small messages-close invert" onClick={() => this.props.component.setState({messages: null})} />
				</div>
			);
		}
	}

	renderErrorsPanel() {

		if (this.state.errors == null || this.state.errors.length === 0) {
			return null;
		} else {
			return(
				<div id="errors" className={"errors errors-type-" + this.state.type + " errors-" + this.state.errors.length}>
					<ul className={!Array.isArray(this.props.errors) || this.props.errors.length <= 1 ? "single" : "multiple"}>
						{this.renderErrors()}
					</ul>
					<Glyphicon name="times" className="small error-close invert" onClick={() => this.props.component.setState({errors: null})} />
				</div>
			);
		}
	}


	render() {

		return (
			<React.Fragment>
				{this.renderMessagesPanel()}
				{this.renderErrorsPanel()}
			</React.Fragment>
		);

	}

}