import React from 'react';
import { Link } from "react-router-dom"

import Routes from "actions/Routes"
import SessionStore from "store/SessionStore"
import * as ProfileActions from "actions/ProfileActions"
import Talent from "./Talent"
import * as Icons from 'react-bootstrap-icons';
import { IconButton } from 'components/general/IconButton';
import AbstractDataPage from '../AbstractDataPage';
import * as FormFields from "components/forms/FormFields"
import { UserStatus } from 'components/general/Constants';
import * as Utils from "components/general/Utils"

const EditTypes = {
	TAGS: "tags",
	FILTER: "filter",
	STATUS: "status-filter",
	ADD: "add-talent"
}

export default class TalentsPage extends AbstractDataPage {

	constructor(props) {
		super(props);
		this.state = this.initialiseState(props);
	}

	initialiseState(props) {
		return { 
			...this._defaultState, statusFilter: this.defaultStatusFilter()
		}
	}

	pagePath() {
		return Routes.talent();
	}

	defaultStatusFilter() {
		return [UserStatus.CANDIDATE, UserStatus.ACTIVE];
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	handleChangeStatusFilter(event, status) {
		var statusFilter = this.state.statusFilter;
		if (event.target.checked) {
			if (statusFilter.indexOf(status) === -1) {
				statusFilter.push(status);
			}
		} else {
			statusFilter = statusFilter.filter(item => item !== status)
		}
		this.setState({statusFilter: statusFilter});
	}

	handleStatusFilterSubmit() {
		this.setState({filterType: "advanced"});
		var filter = {name: this.state.quickFilter, status: this.state.statusFilter}
		ProfileActions.profilesByFilter(this.state.accountId, 0, JSON.stringify(filter), "advanced",
			(data) => { this.setState({editType: null, filterLabel: this.advancedFilterLabel()}); this.handleLoadSuccessCallback(data); },
			(errors) => {this.setState({errors: errors})}
		);
	}

	loadMore(page) {
		ProfileActions.profiles(this.state.accountId, this.state.data.index + 1, this.state.quickFilter,
			(data) => { this.handleLoadMoreSuccessCallback(data)},
			(errors) => {this.handleErrorCallback(errors)}
		);
	}

	loadData(page) {
		if (page < 0) page = 0;
		//Load talent. On error, just redirect to own profile page
		ProfileActions.profiles(this.state.accountId, page, this.state.quickFilter,
			(data) => { this.setState({filterLabel: this.state.quickFilter}); this.handleLoadSuccessCallback(data)},
			(errors) => {this.handleErrorCallback(errors)}
		);
	}

	edit(type) {
		this.setState({editType: type});
	}

	unedit() {
		this.setState({editType: null});
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	title() {
		return "Talent";
	}

	advancedFilterLabel() {
		return (this.state.quickFilter ? "name = " + this.state.quickFilter : "") + (this.state.statusFilter ? (this.state.quickFilter ? ", " : "") + "status = " + this.state.statusFilter.join().toLowerCase() : "");
	}

	renderDataItem(profile) {
		return <Talent profile={profile} user={profile.user} key={profile.id} accountId={this.state.accountId} />
	}

	renderStatusFilterCheckbox(status) {
		return <FormFields.Checkbox
			name={status} title={Utils.titleCase(status)}
			inline={true} value={this.state.statusFilter.indexOf(status) >= 0 }
			onChange={(e) => this.handleChangeStatusFilter(e, status)}
		/>
	}

	renderStatusFilter() {
		return (
			<FormFields.Model title="Advanced Filter" isOpen={this.state.editType === EditTypes.FILTER} className="modal-normal" confirmLabel="Filter"
				onCancel={(e) => this.unedit()} onConfirm={(e) => this.handleStatusFilterSubmit(e)} >

				<div>
					<FormFields.Text title="Text Filter:" placeholder="Filter talent by name" name="quickFilter" value={this.state.quickFilter} onChange={(e) => this.handleChangeFilter(e)} />
					<div>Filter talent by status:</div>
					<div className="model-group">
						{this.renderStatusFilterCheckbox(UserStatus.APPLICANT)}
						{this.renderStatusFilterCheckbox(UserStatus.CANDIDATE)}
						{this.renderStatusFilterCheckbox(UserStatus.ACTIVE)}
						{this.renderStatusFilterCheckbox(UserStatus.INACTIVE)}
					</div>
				</div>

			</FormFields.Model>
		);
	}

	renderActionsDropdown() {

		return (
			<React.Fragment>
				<div className="dropdown">
					<button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
						<IconButton icon={<Icons.GearFill />} className="invert" />
					</button>
					<div className="dropdown-menu dropdown-menu-right">
					<Link className="dropdown-item" to={Routes.userOnboard()}>Add Talent</Link>
						<button className="dropdown-item" onClick={() => this.edit(EditTypes.FILTER)}>Advanced Filter</button>
						{
							//<button className="dropdown-item" onClick={() => this.setState({editType: EditTypes.FILTER})}>Advanced filter</button>
						}
					</div>
				</div>
				{this.renderStatusFilter()}

			</React.Fragment>
		)
	}

	renderAddDataItem() {

		if (SessionStore.isManagerRole()) {
			return (
				<div className="talent-skill add-new">
					<Link className="add-label" to={Routes.userOnboard()}><IconButton icon={<Icons.PlusLg />} className="grey" label="Add Talent"/></Link>
				</div>
			)
		}

		return null;

	}

}