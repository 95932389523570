import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Routes from "actions/Routes"
import SessionStore from "store/SessionStore"
import * as FormFields from "components/forms/FormFields"
import SectionBody from 'components/layouts/SectionBody';
import * as Dto from 'actions/Dto';

import ReactGA from 'react-ga';
import Spinner from 'components/general/Spinner';

export interface DataPageState<DTO> {
	accountId: number|null
	data: Dto.PagedListDto<DTO> | null
	errors: Dto.ErrorDto[] | null
	messages: Dto.MessageDto[] | null
	edit: DTO | null
	showEdit: boolean
	showAdd: boolean
	loading: boolean
	advancedFilter?: any
	quickFilter?: string
	filterLabel?: string
}

interface DataPageProps extends RouteComponentProps {
	id?: string | undefined
}

//export default class AbstractDataPage extends React.Component {
export default abstract class AbstractDataPage<DTO extends { id?: number }, State extends DataPageState<DTO>> extends React.Component<DataPageProps, State> {

	showFilter = true;
	protected _defaultState = { 
			data: {index: 0, total: 0, size: 0, pages: 0, values: []},
			accountId: SessionStore.accountId, quickFilter: "", filterLabel: "",
			errors: null, messages: null, loading: false, editType: null,
			edit: null, showEdit: false, showAdd: false
	}

	constructor(props: RouteComponentProps<{ id: string | undefined }>) {
		super(props);
		this.state = this.initialiseState(props);
		/*this.state = { data: {index: 0, total: 0, size: 0, pages: 0, values: []},
			accountId: SessionStore.accountId, quickFilter: "", filterLabel: "",
			errors: null, messages: null, loading: false};*/
	}

	abstract initialiseState(props: RouteComponentProps<{ id?: string | undefined }>): State;

	componentDidMount() {

		window.scrollTo(0, 0);	//scroll to top
		ReactGA.pageview(Routes.projects());	//analytics
		document.title = this.title();

		this.loadData(this.state.data ? this.state.data.index : 0);

		

	}

	pagePath() {
		//return Routes.projects();
		throw new Error("Must override pagePath");
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	entityDidSave =  () => {
		//do nothing, used as override	
	};

	handleLoadData = (successCallback, errorCallback) => {
		throw new Error("Must override handleLoadData");
	}

	handleLoadSuccessCallback(data) {
		this.setState({data: data, loading: false});
	}

	handleLoadMoreSuccessCallback(data) {

		if (!this.state.data) return;

		data.values = this.state.data.values.concat(data.values);
		this.setState({data: data});
	}

	handleErrorCallback(errors) {
		this.setState({errors: errors, loading: false});
	}
	
	handleChangeFilter(event) {
		this.setState({quickFilter: event.target.value});
	}

	handleFilterSubmit() {
		this.loadData(0);
	}

	loadNextPage() {
		this.loadData(this.state.data ? this.state.data.index + 1 : 0);
	}

	loadPreviousPage() {
		this.loadData(this.state.data && this.state.data.index > 0 ? this.state.data.index - 1 : 0);
	}

	clearFilter() {
		this.setState({filterLabel: "", quickFilter: "", advancedFilter: {}});
		this.loadData(this.state.data ? this.state.data.index : 0);
	}

	loadMore(page?: number) {
		throw new Error("Must override loadMore");
	}

	loadData(page: number) {
		throw new Error("Must override loadData");
	}

	handleSaveSuccess = (entity) => {

		//update in-place entity

		if (!this.state.data) return;
		let data = {...this.state.data};

		this.handleSaveSuccessForDto(entity, data);


	}

	handleSaveSuccessForDto(entity, data) {

		//find matching index
		const index = data.values.findIndex(next => next.id === entity.id);

		//update or push
		if (index >= 0) {
			data.values[index] = entity;
		} else {
			data.values.push(entity);
		}

		//set state
		this.setState({data: data, edit: null, showEdit: false, showAdd: false, loading: false, messages: [{message: "Saved"}]}, this.entityDidSave);

	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


	renderFilterHelper() : React.ReactElement | null {
		if (this.state.filterLabel) {
			return (
				<div className="pill search-criteria">Filter: {this.state.filterLabel}</div>
			);
		}
		return null;
	}

	renderFilterControls() :JSX.Element {

		if (!this.showFilter) return <small>x{this.state.filterLabel}</small>;

		return (
			<FormFields.SearchBox name="filter" title="Filter" placeholder={"Filter " + this.title()} value={this.state.quickFilter}
					onChange={(e) => this.handleChangeFilter(e)} onSubmit={(e) => this.handleFilterSubmit()} helpText={this.state.filterLabel ? " Filter: " + this.state.filterLabel : null}/>
		)
	}

	renderDataItem(item) : React.ReactElement | null {
		throw new Error("Must override renderDataItem");
	}

	renderNoRecordsFound() : React.ReactElement | null {
		return (<div className="data-item no-data"><div className="no-data-label">No records found</div></div>);
	}

	renderData() : JSX.Element | null {
		if (this.state == null || !this.state.data || this.state.data.values.length <= 0) return this.renderNoRecordsFound();
		return (
			<>
				{this.state.data.values.map((item) => this.renderDataItem(item))}
			</>
		);
	}

	renderActionsDropdown() : JSX.Element | null  {
		throw new Error("Must override enderActionsDropdown");
	}

	title(): string {
		throw new Error("Must override renderTitle");
	}

	contextMenuItems() : JSX.Element | null {
		return null;
	}

	renderAddDataItem() : JSX.Element | null  {
		return null;
	}

	render() : JSX.Element | null  {

		//pagination
		let pagination;
		if (this.state.data && this.state.data.pages > this.state.data.index + 1) {
			pagination = (
				<nav className="pagination-nav">
					<ul className="pagination">
						<li className="page-item"><button className="page-link" onClick={(e) => this.loadMore()}>Load more...</button></li>
					</ul>
					Showing {this.state.data.values.length} of {this.state.data.total}
				</nav>
			);
		}

		return(

			<SectionBody className={this.title().toLowerCase() + "-section"} topMenuItems={this.contextMenuItems()}
				onNotificationClose={() => this.setState({errors: null, messages: null})}
				errors={this.state.errors} messages={this.state.messages} >

				<div className="row">

					<div className="col">

						<h1>{this.title()}</h1>

						<div className={this.title().toLowerCase() + " data-items"}>

							<div className="controls-container">

								{this.renderFilterControls()}
								<div className="controls">
									{this.renderActionsDropdown()}
								</div>

							</div>

							<Spinner loading={this.state.loading} >
								
								<div className="data-grid grid">

									
										{this.renderAddDataItem()}
										{this.renderData()}
									
								</div>

								{pagination}

							</Spinner>
						</div>

					</div>

				</div>

			</SectionBody>
		);
	}
}