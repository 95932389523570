import React from 'react';

import Routes from "actions/Routes"
import * as FormFields from "../forms/FormFields"
import * as AccountActions from "actions/AccountActions"
import ErrorMessages from "../general/ErrorMessages"
import ReactGA from 'react-ga';
import { Link } from "react-router-dom"

import '../layouts/Layout.css';

export default class LoginPage extends React.Component {

	constructor(props) {
		super(props);

		this.state = {email: (props.match.params.email ? props.match.params.email : null),
			password: null, forgotten: false, passwordShow: false,
			errors: props.errors, messages: props.messages, auth: {type: 'UNKNOWN'}};
	}

	componentWillMount() {

		if (this.props.location != null && this.props.state != null) {
			this.setState({messages: [this.props.location.state.messages]});
		}

		ReactGA.pageview(Routes.login());	//analytics

	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	handleChange(event) {
		var state = this.state;
		var submitLoginNext = false;
		state[event.target.name] = event.target.value;
		this.setState(state);
		if (event.target.name === 'email') {
			if (state.email.endsWith('.com') || state.email.endsWith('.au') || state.email.endsWith('.co')) {
				submitLoginNext = true;
			}
		}
		if (submitLoginNext) {
			this.handleCheckAuth(event);
		}
	}

	//window.location = "/"
	handleSubmitLogin(e) {

		this.setState({errors: null});

		AccountActions.login(this.state.email, this.state.password,
			() => this.props.history.push("/"),
			(errors) => {this.setState({errors: errors})}
		);
	}

	//window.location = "/"
	handleCheckAuth(e) {

		var email = this.state.email;

		if (this.state.auth.type === 'AD') {
			window.location = this.state.auth.loginURL;
		} else {
			AccountActions.authenticationInfo(email, null,
				(auth) => {
					this.setState({auth: auth, passwordShow: false});
				},
				(errors) => {this.setState({errors: errors})}
			);
		}
	}

	//window.location = "/"
	handleLoginNext(e) {

		var email = this.state.email;

		if (this.state.auth.type === 'AD') {
			window.location = this.state.auth.loginURL + "&email=" + this.state.email;
		} else {
			AccountActions.authenticationInfo(email, null,
				(auth) => {
					if (auth.type && auth.type === "AD") {
						window.location = auth.loginURL + "&email=" + this.state.email;
					}
					this.setState({auth: auth, passwordShow: true});
				},
				(errors) => {this.setState({errors: errors})}
			);
		}

	}

	handleSubmitForgotten(e) {
		this.setState({errors: null});
		AccountActions.forgotten(this.state.email,
			() => this.setState({messages: ["An email has been sent with password reset instructions."]}),
			(errors) => this.setState({errors: errors})
		);
	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	renderLogin() {

		return(
			<section className="join">

				<nav>
					<Link className="logo" to={Routes.splash()} ></Link>
				</nav>

				<div className="container">
					<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages}/>

					<div className="row justify-content-md-center">
						<div className="col-md-6 join-panel">
							<h1>Login</h1>
							<hr/>
							<div className="well">
								<FormFields.Text name="email" title="Email" placeholder="email@address.com" value={this.state.email} onChange={(e) => this.handleChange(e)} />

								{this.state.passwordShow ?
									<FormFields.Password name="password" title="Password" placeholder="" value={this.state.password} onChange={(e) => this.handleChange(e)} readOnly={this.state.auth.type !== 'BASIC'} />
								:
									null
								}

								{//this.state.auth.type !== 'BASIC' ?
									!this.state.passwordShow ?
									<button className="btn btn-default" onClick={(e) => this.handleLoginNext(e)}>Next</button>
								:
									<button className="btn btn-default" onClick={(e) => this.handleSubmitLogin(e)}>Login</button>
								}

								<button className="btn" onClick={() => this.setState({forgotten: true})}>Forgotten Password</button>

							</div>
						</div>
					</div>
				</div>


			</section>
		);
	}

	renderForgotten() {
		return(
			<section className="join">

				<nav>
					<Link className="logo" to={Routes.splash()} ></Link>
				</nav>

				<div className="container">
					<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages}/>

					<div className="row justify-content-md-center">
						<div className="col-md-6 join-panel">
							<h1>Forgotten Password</h1>

							<div className="well">
								<p>Enter your email and click submit and we will send you and email with instructions to reset your password.</p>
								<hr/>
								<FormFields.Text name="email" title="Email" placeholder="email@address.com" value={this.state.email} onChange={(e) => this.handleChange(e)}/>

								<button className="btn btn-default" onClick={(e) => this.handleSubmitForgotten(e)}>Submit</button>

								<button className="btn" onClick={() => this.setState({forgotten: false})}>Cancel</button>

							</div>
						</div>
					</div>
				</div>

			</section>
		);
	}

	render() {
		if (this.state.forgotten) return this.renderForgotten();
		return this.renderLogin();
	}

}