import React from 'react';
import { Link } from "react-router-dom"

import './Glyphicon.css';

export default class Glyphicon extends React.Component {


	render() {

		var img = <img className={"glyphicon" + (this.props.className ? " " + this.props.className : "")} src={"/media/icons/glyphicons/" + ICON_LOOKUP[this.props.name]} alt={this.props.name + " icon"} />;

		if (this.props.to) {
			return (
				<Link className="btn btn-icon glyphicon-button" target={this.props.target} onClick={this.props.onClick} title={this.props.title} to={this.props.to}>
					{img}
					{this.props.label ? <label>{this.props.label}</label> : null}
				</Link>
			);
		} else if (this.props.href) {
			return (
				<a className="btn btn-icon glyphicon-button" target={this.props.target} onClick={this.props.onClick} title={this.props.title} href={this.props.href}>
					{img}
					{this.props.label ? <label>{this.props.label}</label> : null}
				</a>
			);
		} else if (this.props.onClick) {
			return (
				<button className="btn btn-icon glyphicon-button" onClick={this.props.onClick} title={this.props.title} >
					{img}
					{this.props.label ? <label> {this.props.label}</label> : null}
				</button>
			);
		} else {
			if (!this.props.label) return img;
			return (
				<span className="glyphicon-labelled">
					{img}
					{this.props.label}
				</span>
			);
		}

	}
}

let ICON_LOOKUP = {
		"glass": "glyphicons-basic-1-glass.svg",
		"magic-wand": "glyphicons-basic-10-magic-wand.svg",
		"vector-path-edit": "glyphicons-basic-100-vector-path-edit.svg",
		"text": "glyphicons-basic-101-text.svg",
		"italic": "glyphicons-basic-102-italic.svg",
		"bold": "glyphicons-basic-103-bold.svg",
		"underline": "glyphicons-basic-104-underline.svg",
		"strikethrough": "glyphicons-basic-105-strikethrough.svg",
		"text-height": "glyphicons-basic-106-text-height.svg",
		"text-width": "glyphicons-basic-107-text-width.svg",
		"text-resize": "glyphicons-basic-108-text-resize.svg",
		"indent-left": "glyphicons-basic-109-indent-left.svg",
		"envelope": "glyphicons-basic-11-envelope.svg",
		"indent-right": "glyphicons-basic-110-indent-right.svg",
		"paragraph-left": "glyphicons-basic-111-paragraph-left.svg",
		"paragraph-center": "glyphicons-basic-112-paragraph-center.svg",
		"paragraph-right": "glyphicons-basic-113-paragraph-right.svg",
		"paragraph-justify": "glyphicons-basic-114-paragraph-justify.svg",
		"list": "glyphicons-basic-115-list.svg",
		"text-smaller": "glyphicons-basic-116-text-smaller.svg",
		"text-bigger": "glyphicons-basic-117-text-bigger.svg",
		"terminal-isolated": "glyphicons-basic-118-terminal-isolated.svg",
		"code": "glyphicons-basic-119-code.svg",
		"camera": "glyphicons-basic-12-camera.svg",
		"table": "glyphicons-basic-120-table.svg",
		"envelope-full": "glyphicons-basic-121-envelope-full.svg",
		"envelope-empty": "glyphicons-basic-122-envelope-empty.svg",
		"envelope-down": "glyphicons-basic-123-envelope-down.svg",
		"envelope-up": "glyphicons-basic-124-envelope-up.svg",
		"envelope-plus": "glyphicons-basic-125-envelope-plus.svg",
		"envelope-minus": "glyphicons-basic-126-envelope-minus.svg",
		"envelope-no": "glyphicons-basic-127-envelope-no.svg",
		"envelope-flag": "glyphicons-basic-128-envelope-flag.svg",
		"envelope-lock": "glyphicons-basic-129-envelope-lock.svg",
		"heart": "glyphicons-basic-13-heart.svg",
		"envelope-star": "glyphicons-basic-130-envelope-star.svg",
		"inbox": "glyphicons-basic-131-inbox.svg",
		"inbox-plus": "glyphicons-basic-132-inbox-plus.svg",
		"inbox-minus": "glyphicons-basic-133-inbox-minus.svg",
		"inbox-lock": "glyphicons-basic-134-inbox-lock.svg",
		"inbox-down": "glyphicons-basic-135-inbox-down.svg",
		"inbox-up": "glyphicons-basic-136-inbox-up.svg",
		"cogwheel": "glyphicons-basic-137-cogwheel.svg",
		"cogwheels": "glyphicons-basic-138-cogwheels.svg",
		"curves": "glyphicons-basic-139-curves.svg",
		"beach-umbrella": "glyphicons-basic-14-beach-umbrella.svg",
		"adjust": "glyphicons-basic-140-adjust.svg",
		"database": "glyphicons-basic-141-database.svg",
		"database-plus": "glyphicons-basic-142-database-plus.svg",
		"database-minus": "glyphicons-basic-143-database-minus.svg",
		"database-search": "glyphicons-basic-144-database-search.svg",
		"folder-open": "glyphicons-basic-145-folder-open.svg",
		"folder-plus": "glyphicons-basic-146-folder-plus.svg",
		"folder-minus": "glyphicons-basic-147-folder-minus.svg",
		"folder-refresh": "glyphicons-basic-148-folder-refresh.svg",
		"folder-check": "glyphicons-basic-149-folder-check.svg",
		"train": "glyphicons-basic-15-train.svg",
		"folder-star": "glyphicons-basic-150-folder-star.svg",
		"square-edit": "glyphicons-basic-151-square-edit.svg",
		"square-new-window": "glyphicons-basic-152-square-new-window.svg",
		"square-checkbox": "glyphicons-basic-153-square-checkbox.svg",
		"square-empty": "glyphicons-basic-154-square-empty.svg",
		"square-selected": "glyphicons-basic-155-square-selected.svg",
		"square-indeterminate": "glyphicons-basic-156-square-indeterminate.svg",
		"thumbnails": "glyphicons-basic-157-thumbnails.svg",
		"thumbnails-small": "glyphicons-basic-158-thumbnails-small.svg",
		"thumbnails-list": "glyphicons-basic-159-thumbnails-list.svg",
		"print": "glyphicons-basic-16-print.svg",
		"playlist": "glyphicons-basic-160-playlist.svg",
		"computer-all-in-one": "glyphicons-basic-161-computer-all-in-one.svg",
		"computer-pc-tower": "glyphicons-basic-162-computer-pc-tower.svg",
		"computer": "glyphicons-basic-163-computer.svg",
		"notebook": "glyphicons-basic-164-notebook.svg",
		"tablet": "glyphicons-basic-165-tablet.svg",
		"mobile-phone": "glyphicons-basic-166-mobile-phone.svg",
		"mobile-phone-off": "glyphicons-basic-167-mobile-phone-off.svg",
		"mobile-phone-vibrations": "glyphicons-basic-168-mobile-phone-vibrations.svg",
		"mobile-phone-shaking": "glyphicons-basic-169-mobile-phone-shaking.svg",
		"bin": "glyphicons-basic-17-bin.svg",
		"earphones": "glyphicons-basic-170-earphones.svg",
		"skip-to-previous": "glyphicons-basic-171-skip-to-previous.svg",
		"skip-to-start": "glyphicons-basic-172-skip-to-start.svg",
		"rewind": "glyphicons-basic-173-rewind.svg",
		"pause": "glyphicons-basic-174-pause.svg",
		"play": "glyphicons-basic-175-play.svg",
		"stop": "glyphicons-basic-176-stop.svg",
		"forward": "glyphicons-basic-177-forward.svg",
		"skip-to-end": "glyphicons-basic-178-skip-to-end.svg",
		"skip-to-next": "glyphicons-basic-179-skip-to-next.svg",
		"music": "glyphicons-basic-18-music.svg",
		"eject": "glyphicons-basic-180-eject.svg",
		"video": "glyphicons-basic-181-video.svg",
		"video-off": "glyphicons-basic-182-video-off.svg",
		"volume-bluetooth": "glyphicons-basic-183-volume-bluetooth.svg",
		"volume-off": "glyphicons-basic-184-volume-off.svg",
		"volume-down": "glyphicons-basic-185-volume-down.svg",
		"volume-up": "glyphicons-basic-186-volume-up.svg",
		"volume-max": "glyphicons-basic-187-volume-max.svg",
		"cd": "glyphicons-basic-188-cd.svg",
		"mc": "glyphicons-basic-189-mc.svg",
		"music-alt": "glyphicons-basic-19-music-alt.svg",
		"Ip": "glyphicons-basic-190-Ip.svg",
		"circle-empty-plus": "glyphicons-basic-191-circle-empty-plus.svg",
		"circle-empty-minus": "glyphicons-basic-192-circle-empty-minus.svg",
		"circle-empty-remove": "glyphicons-basic-193-circle-empty-remove.svg",
		"circle-empty-check": "glyphicons-basic-194-circle-empty-check.svg",
		"circle-empty-question": "glyphicons-basic-195-circle-empty-question.svg",
		"circle-empty-info": "glyphicons-basic-196-circle-empty-info.svg",
		"circle-empty-alert": "glyphicons-basic-197-circle-empty-alert.svg",
		"open": "glyphicons-basic-198-open.svg",
		"save": "glyphicons-basic-199-save.svg",
		"leaf": "glyphicons-basic-2-leaf.svg",
		"heart-empty": "glyphicons-basic-20-heart-empty.svg",
		"save-as": "glyphicons-basic-200-save-as.svg",
		"cart": "glyphicons-basic-201-cart.svg",
		"cart-in": "glyphicons-basic-202-cart-in.svg",
		"cart-out": "glyphicons-basic-203-cart-out.svg",
		"cart-checkout": "glyphicons-basic-204-cart-checkout.svg",
		"cart-check": "glyphicons-basic-205-cart-check.svg",
		"circle-empty-play": "glyphicons-basic-206-circle-empty-play.svg",
		"circle-empty-down": "glyphicons-basic-207-circle-empty-down.svg",
		"circle-empty-up": "glyphicons-basic-208-circle-empty-up.svg",
		"circle-empty-left": "glyphicons-basic-209-circle-empty-left.svg",
		"home": "glyphicons-basic-21-home.svg",
		"circle-empty-right": "glyphicons-basic-210-circle-empty-right.svg",
		"arrow-down": "glyphicons-basic-211-arrow-down.svg",
		"arrow-up": "glyphicons-basic-212-arrow-up.svg",
		"arrow-left": "glyphicons-basic-213-arrow-left.svg",
		"arrow-right": "glyphicons-basic-214-arrow-right.svg",
		"fullscreen-off": "glyphicons-basic-215-fullscreen-off.svg",
		"fullscreen": "glyphicons-basic-216-fullscreen.svg",
		"lock": "glyphicons-basic-217-lock.svg",
		"lock-open": "glyphicons-basic-218-lock-open.svg",
		"coins": "glyphicons-basic-219-coins.svg",
		"snowflake": "glyphicons-basic-22-snowflake.svg",
		"banknotes": "glyphicons-basic-220-banknotes.svg",
		"chevron-down": "glyphicons-basic-221-chevron-down.svg",
		"chevron-up": "glyphicons-basic-222-chevron-up.svg",
		"chevron-left": "glyphicons-basic-223-chevron-left.svg",
		"chevron-right": "glyphicons-basic-224-chevron-right.svg",
		"bitcoin": "glyphicons-basic-225-bitcoin.svg",
		"euro": "glyphicons-basic-226-euro.svg",
		"dollar": "glyphicons-basic-227-dollar.svg",
		"pound": "glyphicons-basic-228-pound.svg",
		"yen": "glyphicons-basic-229-yen.svg",
		"fire": "glyphicons-basic-23-fire.svg",
		"ruble": "glyphicons-basic-230-ruble.svg",
		"moon": "glyphicons-basic-231-moon.svg",
		"sun": "glyphicons-basic-232-sun.svg",
		"lasso": "glyphicons-basic-233-lasso.svg",
		"calculator": "glyphicons-basic-234-calculator.svg",
		"chat-conversation": "glyphicons-basic-235-chat-conversation.svg",
		"chat-conversation-off": "glyphicons-basic-236-chat-conversation-off.svg",
		"chat-conversation-lock": "glyphicons-basic-237-chat-conversation-lock.svg",
		"chat-message": "glyphicons-basic-238-chat-message.svg",
		"chat-message-lock": "glyphicons-basic-239-chat-message-lock.svg",
		"magnet": "glyphicons-basic-24-magnet.svg",
		"chat-message-check": "glyphicons-basic-240-chat-message-check.svg",
		"brush": "glyphicons-basic-241-brush.svg",
		"marker": "glyphicons-basic-242-marker.svg",
		"pen": "glyphicons-basic-243-pen.svg",
		"spray": "glyphicons-basic-244-spray.svg",
		"fill": "glyphicons-basic-245-fill.svg",
		"clean": "glyphicons-basic-246-clean.svg",
		"drop-off": "glyphicons-basic-247-drop-off.svg",
		"palette": "glyphicons-basic-248-palette.svg",
		"diamond": "glyphicons-basic-249-diamond.svg",
		"user-parents": "glyphicons-basic-25-user-parents.svg",
		"eraser": "glyphicons-basic-250-eraser.svg",
		"diving-free": "glyphicons-basic-251-diving-free.svg",
		"diving-scuba": "glyphicons-basic-252-diving-scuba.svg",
		"boat": "glyphicons-basic-253-boat.svg",
		"swimming-pool": "glyphicons-basic-254-swimming-pool.svg",
		"buoy": "glyphicons-basic-255-buoy.svg",
		"oxygen-bottles": "glyphicons-basic-256-oxygen-bottles.svg",
		"fins": "glyphicons-basic-257-fins.svg",
		"anchor": "glyphicons-basic-258-anchor.svg",
		"fishes": "glyphicons-basic-259-fishes.svg",
		"binoculars": "glyphicons-basic-26-binoculars.svg",
		"fishes-bait": "glyphicons-basic-260-fishes-bait.svg",
		"direction": "glyphicons-basic-261-direction.svg",
		"direction-empty": "glyphicons-basic-262-direction-empty.svg",
		"direction-full": "glyphicons-basic-263-direction-full.svg",
		"flag-waving": "glyphicons-basic-264-flag-waving.svg",
		"flag": "glyphicons-basic-265-flag.svg",
		"flag-triangle": "glyphicons-basic-266-flag-triangle.svg",
		"comments": "glyphicons-basic-267-comments.svg",
		"comments-off": "glyphicons-basic-268-comments-off.svg",
		"comments-lock": "glyphicons-basic-269-comments-lock.svg",
		"road": "glyphicons-basic-27-road.svg",
		"cake": "glyphicons-basic-270-cake.svg",
		"set-wine": "glyphicons-basic-271-set-wine.svg",
		"glass-wine": "glyphicons-basic-272-glass-wine.svg",
		"coffee-mug": "glyphicons-basic-273-coffee-mug.svg",
		"drink": "glyphicons-basic-274-drink.svg",
		"beer": "glyphicons-basic-275-beer.svg",
		"beer-alt": "glyphicons-basic-276-beer-alt.svg",
		"fast-food": "glyphicons-basic-277-fast-food.svg",
		"cutlery": "glyphicons-basic-278-cutlery.svg",
		"blender": "glyphicons-basic-279-blender.svg",
		"search": "glyphicons-basic-28-search.svg",
		"pizza": "glyphicons-basic-280-pizza.svg",
		"coffee-to-go": "glyphicons-basic-281-coffee-to-go.svg",
		"tea-pot": "glyphicons-basic-282-tea-pot.svg",
		"tea-mug": "glyphicons-basic-283-tea-mug.svg",
		"coffee-beans": "glyphicons-basic-284-coffee-beans.svg",
		"tea-leaves": "glyphicons-basic-285-tea-leaves.svg",
		"french-press": "glyphicons-basic-286-french-press.svg",
		"pot": "glyphicons-basic-287-pot.svg",
		"kettle": "glyphicons-basic-288-kettle.svg",
		"grater": "glyphicons-basic-289-grater.svg",
		"cars": "glyphicons-basic-29-cars.svg",
		"cooks-hat": "glyphicons-basic-290-cooks-hat.svg",
		"square-empty-plus": "glyphicons-basic-291-square-empty-plus.svg",
		"square-empty-minus": "glyphicons-basic-292-square-empty-minus.svg",
		"square-empty-remove": "glyphicons-basic-293-square-empty-remove.svg",
		"square-empty-check": "glyphicons-basic-294-square-empty-check.svg",
		"square-empty-question": "glyphicons-basic-295-square-empty-question.svg",
		"square-empty-info": "glyphicons-basic-296-square-empty-info.svg",
		"square-empty-alert": "glyphicons-basic-297-square-empty-alert.svg",
		"circle-empty": "glyphicons-basic-298-circle-empty.svg",
		"circle-selected": "glyphicons-basic-299-circle-selected.svg",
		"dog": "glyphicons-basic-3-dog.svg",
		"clipboard": "glyphicons-basic-30-clipboard.svg",
		"asian-food": "glyphicons-basic-300-asian-food.svg",
		"square-upload": "glyphicons-basic-301-square-upload.svg",
		"square-download": "glyphicons-basic-302-square-download.svg",
		"verify-empty": "glyphicons-basic-303-verify-empty.svg",
		"verify-check": "glyphicons-basic-304-verify-check.svg",
		"no-symbol": "glyphicons-basic-305-no-symbol.svg",
		"square-empty-play": "glyphicons-basic-306-square-empty-play.svg",
		"square-empty-download": "glyphicons-basic-307-square-empty-download.svg",
		"square-empty-upload": "glyphicons-basic-308-square-empty-upload.svg",
		"square-empty-left": "glyphicons-basic-309-square-empty-left.svg",
		"pencil": "glyphicons-basic-31-pencil.svg",
		"square-empty-right": "glyphicons-basic-310-square-empty-right.svg",
		"spade": "glyphicons-basic-311-spade.svg",
		"saw": "glyphicons-basic-312-saw.svg",
		"claw-hammer": "glyphicons-basic-313-claw-hammer.svg",
		"hammer": "glyphicons-basic-314-hammer.svg",
		"screwdriver": "glyphicons-basic-315-screwdriver.svg",
		"wrench": "glyphicons-basic-316-wrench.svg",
		"ax": "glyphicons-basic-317-ax.svg",
		"blacksmith": "glyphicons-basic-318-blacksmith.svg",
		"saw-blade": "glyphicons-basic-319-saw-blade.svg",
		"bus": "glyphicons-basic-32-bus.svg",
		"certificate": "glyphicons-basic-320-certificate.svg",
		"users": "glyphicons-basic-321-users.svg",
		"shield-check": "glyphicons-basic-322-shield-check.svg",
		"shield-half": "glyphicons-basic-323-shield-half.svg",
		"shield-empty-star": "glyphicons-basic-324-shield-empty-star.svg",
		"shield-quarter": "glyphicons-basic-325-shield-quarter.svg",
		"shield-star": "glyphicons-basic-326-shield-star.svg",
		"contactless-lock": "glyphicons-basic-327-contactless-lock.svg",
		"contactless": "glyphicons-basic-328-contactless.svg",
		"credit-card": "glyphicons-basic-329-credit-card.svg",
		"access-point": "glyphicons-basic-33-access-point.svg",
		"credit-card-off": "glyphicons-basic-330-credit-card-off.svg",
		"quad-bike": "glyphicons-basic-331-quad-bike.svg",
		"motorbike": "glyphicons-basic-332-motorbike.svg",
		"squares": "glyphicons-basic-333-squares.svg",
		"brightness-up": "glyphicons-basic-334-brightness-up.svg",
		"brightness-down": "glyphicons-basic-335-brightness-down.svg",
		"folder": "glyphicons-basic-336-folder.svg",
		"folder-cogwheel": "glyphicons-basic-337-folder-cogwheel.svg",
		"folder-alert": "glyphicons-basic-338-folder-alert.svg",
		"folder-lock": "glyphicons-basic-339-folder-lock.svg",
		"luggage": "glyphicons-basic-34-luggage.svg",
		"folder-no": "glyphicons-basic-340-folder-no.svg",
		"mic": "glyphicons-basic-341-mic.svg",
		"mic-off": "glyphicons-basic-342-mic-off.svg",
		"repeat": "glyphicons-basic-343-repeat.svg",
		"repeat-once": "glyphicons-basic-344-repeat-once.svg",
		"electric-cord": "glyphicons-basic-345-electric-cord.svg",
		"self-winding-cord": "glyphicons-basic-346-self-winding-cord.svg",
		"id-badge": "glyphicons-basic-347-id-badge.svg",
		"id-badge-vertical": "glyphicons-basic-348-id-badge-vertical.svg",
		"id-binder": "glyphicons-basic-349-id-binder.svg",
		"user-incognito": "glyphicons-basic-35-user-incognito.svg",
		"lock-off": "glyphicons-basic-350-lock-off.svg",
		"link": "glyphicons-basic-351-link.svg",
		"link-remove": "glyphicons-basic-352-link-remove.svg",
		"bank": "glyphicons-basic-353-bank.svg",
		"scissors-cutting": "glyphicons-basic-354-scissors-cutting.svg",
		"mouse": "glyphicons-basic-355-mouse.svg",
		"mouse-wireless": "glyphicons-basic-356-mouse-wireless.svg",
		"keyboard": "glyphicons-basic-357-keyboard.svg",
		"keyboard-wireless": "glyphicons-basic-358-keyboard-wireless.svg",
		"graphic-tablet": "glyphicons-basic-359-graphic-tablet.svg",
		"user-female": "glyphicons-basic-36-user-female.svg",
		"graphic-tablet-wireless": "glyphicons-basic-360-graphic-tablet-wireless.svg",
		"t-shirt": "glyphicons-basic-361-t-shirt.svg",
		"t-shirt-v": "glyphicons-basic-362-t-shirt-v.svg",
		"t-shirt-sleeves": "glyphicons-basic-363-t-shirt-sleeves.svg",
		"shirt": "glyphicons-basic-364-shirt.svg",
		"tie": "glyphicons-basic-365-tie.svg",
		"trousers": "glyphicons-basic-366-trousers.svg",
		"dress": "glyphicons-basic-367-dress.svg",
		"underwear": "glyphicons-basic-368-underwear.svg",
		"fabric": "glyphicons-basic-369-fabric.svg",
		"file": "glyphicons-basic-37-file.svg",
		"leather": "glyphicons-basic-370-leather.svg",
		"plus": "glyphicons-basic-371-plus.svg",
		"minus": "glyphicons-basic-372-minus.svg",
		"times": "glyphicons-basic-373-times.svg",
		"division": "glyphicons-basic-374-division.svg",
		"equals": "glyphicons-basic-375-equals.svg",
		"hanger": "glyphicons-basic-376-hanger.svg",
		"flower": "glyphicons-basic-377-flower.svg",
		"plant": "glyphicons-basic-378-plant.svg",
		"tree-conifer": "glyphicons-basic-379-tree-conifer.svg",
		"picture": "glyphicons-basic-38-picture.svg",
		"tree-deciduous": "glyphicons-basic-380-tree-deciduous.svg",
		"bowling": "glyphicons-basic-381-bowling.svg",
		"rugby": "glyphicons-basic-382-rugby.svg",
		"table-tennis": "glyphicons-basic-383-table-tennis.svg",
		"basketball": "glyphicons-basic-384-basketball.svg",
		"volleyball": "glyphicons-basic-385-volleyball.svg",
		"football": "glyphicons-basic-386-football.svg",
		"baseball": "glyphicons-basic-387-baseball.svg",
		"hockey": "glyphicons-basic-388-hockey.svg",
		"golf": "glyphicons-basic-389-golf.svg",
		"airplane": "glyphicons-basic-39-airplane.svg",
		"golf-green": "glyphicons-basic-390-golf-green.svg",
		"winners": "glyphicons-basic-391-winners.svg",
		"winners-stars": "glyphicons-basic-392-winners-stars.svg",
		"medal": "glyphicons-basic-393-medal.svg",
		"dumbbell": "glyphicons-basic-394-dumbbell.svg",
		"filter": "glyphicons-basic-395-filter.svg",
		"filter-plus": "glyphicons-basic-396-filter-plus.svg",
		"filter-minus": "glyphicons-basic-397-filter-minus.svg",
		"filter-cogwheel": "glyphicons-basic-398-filter-cogwheel.svg",
		"import": "glyphicons-basic-399-import.svg",
		"user": "glyphicons-basic-4-user.svg",
		"notes": "glyphicons-basic-40-notes.svg",
		"export": "glyphicons-basic-400-export.svg",
		"hard-drive": "glyphicons-basic-401-hard-drive.svg",
		"hard-drive-cogwheel": "glyphicons-basic-402-hard-drive-cogwheel.svg",
		"hard-drive-lock": "glyphicons-basic-403-hard-drive-lock.svg",
		"hard-drive-eject": "glyphicons-basic-404-hard-drive-eject.svg",
		"turtle": "glyphicons-basic-405-turtle.svg",
		"rabbit": "glyphicons-basic-406-rabbit.svg",
		"cat": "glyphicons-basic-407-cat.svg",
		"sailboat": "glyphicons-basic-408-sailboat.svg",
		"webcam": "glyphicons-basic-409-webcam.svg",
		"stats": "glyphicons-basic-41-stats.svg",
		"projector": "glyphicons-basic-410-projector.svg",
		"shopping-bag": "glyphicons-basic-411-shopping-bag.svg",
		"dice": "glyphicons-basic-412-dice.svg",
		"game-controller": "glyphicons-basic-413-game-controller.svg",
		"game-controller-wireless": "glyphicons-basic-414-game-controller-wireless.svg",
		"push-pin": "glyphicons-basic-415-push-pin.svg",
		"id-badge-lanyard": "glyphicons-basic-416-id-badge-lanyard.svg",
		"globe": "glyphicons-basic-417-globe.svg",
		"world-west": "glyphicons-basic-418-world-west.svg",
		"worl-east": "glyphicons-basic-419-worl-east.svg",
		"stats-bars": "glyphicons-basic-42-stats-bars.svg",
		"vases": "glyphicons-basic-420-vases.svg",
		"book-play": "glyphicons-basic-421-book-play.svg",
		"book-library": "glyphicons-basic-422-book-library.svg",
		"briefcase-alt": "glyphicons-basic-423-briefcase-alt.svg",
		"blackboard": "glyphicons-basic-424-blackboard.svg",
		"crown": "glyphicons-basic-425-crown.svg",
		"club": "glyphicons-basic-426-club.svg",
		"square-triangle-down": "glyphicons-basic-427-square-triangle-down.svg",
		"square-triangle-up": "glyphicons-basic-428-square-triangle-up.svg",
		"square-triangle-left": "glyphicons-basic-429-square-triangle-left.svg",
		"stats-circle": "glyphicons-basic-43-stats-circle.svg",
		"square-triangle-right": "glyphicons-basic-430-square-triangle-right.svg",
		"log-in": "glyphicons-basic-431-log-in.svg",
		"log-out": "glyphicons-basic-432-log-out.svg",
		"reply": "glyphicons-basic-433-reply.svg",
		"reply-all": "glyphicons-basic-434-reply-all.svg",
		"forward-email": "glyphicons-basic-435-forward-email.svg",
		"archive": "glyphicons-basic-436-archive.svg",
		"playlist-music": "glyphicons-basic-437-playlist-music.svg",
		"playlist-video": "glyphicons-basic-438-playlist-video.svg",
		"video-play-empty": "glyphicons-basic-439-video-play-empty.svg",
		"user-group": "glyphicons-basic-44-user-group.svg",
		"video-play": "glyphicons-basic-440-video-play.svg",
		"bell": "glyphicons-basic-441-bell.svg",
		"bell-off": "glyphicons-basic-442-bell-off.svg",
		"bell-ringing": "glyphicons-basic-443-bell-ringing.svg",
		"sampler": "glyphicons-basic-444-sampler.svg",
		"wallet": "glyphicons-basic-445-wallet.svg",
		"wallet-plus": "glyphicons-basic-446-wallet-plus.svg",
		"wallet-minus": "glyphicons-basic-447-wallet-minus.svg",
		"wallet-lock": "glyphicons-basic-448-wallet-lock.svg",
		"wallet-cogwheel": "glyphicons-basic-449-wallet-cogwheel.svg",
		"key": "glyphicons-basic-45-key.svg",
		"wallet-check": "glyphicons-basic-450-wallet-check.svg",
		"hand-like": "glyphicons-basic-451-hand-like.svg",
		"hand-dislike": "glyphicons-basic-452-hand-dislike.svg",
		"hand-pointing-right": "glyphicons-basic-453-hand-pointing-right.svg",
		"hand-pointing-left": "glyphicons-basic-454-hand-pointing-left.svg",
		"hand-pointing-up": "glyphicons-basic-455-hand-pointing-up.svg",
		"hand-pointing-down": "glyphicons-basic-456-hand-pointing-down.svg",
		"hand-open": "glyphicons-basic-457-hand-open.svg",
		"hand-open-alt": "glyphicons-basic-458-hand-open-alt.svg",
		"hand-ok": "glyphicons-basic-459-hand-ok.svg",
		"calendar": "glyphicons-basic-46-calendar.svg",
		"briefcase": "glyphicons-basic-460-briefcase.svg",
		"send": "glyphicons-basic-461-send.svg",
		"footprints": "glyphicons-basic-462-footprints.svg",
		"construction-cone": "glyphicons-basic-463-construction-cone.svg",
		"phone-old": "glyphicons-basic-464-phone-old.svg",
		"call": "glyphicons-basic-465-call.svg",
		"fax": "glyphicons-basic-466-fax.svg",
		"shredder": "glyphicons-basic-467-shredder.svg",
		"multifunction-printer": "glyphicons-basic-468-multifunction-printer.svg",
		"scanner": "glyphicons-basic-469-scanner.svg",
		"router": "glyphicons-basic-47-router.svg",
		"security-camera": "glyphicons-basic-470-security-camera.svg",
		"sort": "glyphicons-basic-471-sort.svg",
		"sort-alphabetically": "glyphicons-basic-472-sort-alphabetically.svg",
		"sort-alphabetically-alt": "glyphicons-basic-473-sort-alphabetically-alt.svg",
		"sort-numerically": "glyphicons-basic-474-sort-numerically.svg",
		"sort-numerically-alt": "glyphicons-basic-475-sort-numerically-alt.svg",
		"sort-attributes": "glyphicons-basic-476-sort-attributes.svg",
		"sort-attributes-alt": "glyphicons-basic-477-sort-attributes-alt.svg",
		"switch": "glyphicons-basic-478-switch.svg",
		"user-scam": "glyphicons-basic-479-user-scam.svg",
		"video-camera": "glyphicons-basic-48-video-camera.svg",
		"user-scam-alt": "glyphicons-basic-480-user-scam-alt.svg",
		"shop-window": "glyphicons-basic-481-shop-window.svg",
		"shop-door": "glyphicons-basic-482-shop-door.svg",
		"kiosk": "glyphicons-basic-483-kiosk.svg",
		"kiosk-wheels": "glyphicons-basic-484-kiosk-wheels.svg",
		"kiosk-immobile": "glyphicons-basic-485-kiosk-immobile.svg",
		"kiosk-food": "glyphicons-basic-486-kiosk-food.svg",
		"exchange": "glyphicons-basic-487-exchange.svg",
		"bullets": "glyphicons-basic-488-bullets.svg",
		"gun": "glyphicons-basic-489-gun.svg",
		"star": "glyphicons-basic-49-star.svg",
		"gun-off": "glyphicons-basic-490-gun-off.svg",
		"charging-station": "glyphicons-basic-491-charging-station.svg",
		"gas-station": "glyphicons-basic-492-gas-station.svg",
		"rotate": "glyphicons-basic-493-rotate.svg",
		"rotate-horizontal": "glyphicons-basic-494-rotate-horizontal.svg",
		"rotate-vertical": "glyphicons-basic-495-rotate-vertical.svg",
		"podcast": "glyphicons-basic-496-podcast.svg",
		"more": "glyphicons-basic-497-more.svg",
		"more-vertical": "glyphicons-basic-498-more-vertical.svg",
		"skull-crossbones": "glyphicons-basic-499-skull-crossbones.svg",
		"settings": "glyphicons-basic-5-settings.svg",
		"star-empty": "glyphicons-basic-50-star-empty.svg",
		"skull": "glyphicons-basic-500-skull.svg",
		"server": "glyphicons-basic-501-server.svg",
		"server-plus": "glyphicons-basic-502-server-plus.svg",
		"server-minus": "glyphicons-basic-503-server-minus.svg",
		"server-no": "glyphicons-basic-504-server-no.svg",
		"server-check": "glyphicons-basic-505-server-check.svg",
		"server-lock": "glyphicons-basic-506-server-lock.svg",
		"server-refresh": "glyphicons-basic-507-server-refresh.svg",
		"server-flag": "glyphicons-basic-508-server-flag.svg",
		"server-cogwheel": "glyphicons-basic-509-server-cogwheel.svg",
		"pulse": "glyphicons-basic-51-pulse.svg",
		"light-beacon": "glyphicons-basic-510-light-beacon.svg",
		"bed": "glyphicons-basic-511-bed.svg",
		"bed-single": "glyphicons-basic-512-bed-single.svg",
		"cot": "glyphicons-basic-513-cot.svg",
		"personal-hygiene": "glyphicons-basic-514-personal-hygiene.svg",
		"hairdresser": "glyphicons-basic-515-hairdresser.svg",
		"bed-sleeping": "glyphicons-basic-516-bed-sleeping.svg",
		"food-service": "glyphicons-basic-517-food-service.svg",
		"lamp-table": "glyphicons-basic-518-lamp-table.svg",
		"lamp": "glyphicons-basic-519-lamp.svg",
		"eye": "glyphicons-basic-52-eye.svg",
		"hourglass": "glyphicons-basic-520-hourglass.svg",
		"monitor": "glyphicons-basic-521-monitor.svg",
		"monitors": "glyphicons-basic-522-monitors.svg",
		"monitor-rotate": "glyphicons-basic-523-monitor-rotate.svg",
		"monitor-vertical": "glyphicons-basic-524-monitor-vertical.svg",
		"phone-to-horizontal": "glyphicons-basic-525-phone-to-horizontal.svg",
		"phone-rotate": "glyphicons-basic-526-phone-rotate.svg",
		"phone-horizontal": "glyphicons-basic-527-phone-horizontal.svg",
		"shower": "glyphicons-basic-528-shower.svg",
		"shower-alt": "glyphicons-basic-529-shower-alt.svg",
		"eye-off": "glyphicons-basic-53-eye-off.svg",
		"bath": "glyphicons-basic-530-bath.svg",
		"user-parents-alt": "glyphicons-basic-531-user-parents-alt.svg",
		"user-family": "glyphicons-basic-532-user-family.svg",
		"user-child": "glyphicons-basic-533-user-child.svg",
		"user-boy": "glyphicons-basic-534-user-boy.svg",
		"user-girl": "glyphicons-basic-535-user-girl.svg",
		"speakers": "glyphicons-basic-536-speakers.svg",
		"progress": "glyphicons-basic-537-progress.svg",
		"tasks": "glyphicons-basic-538-tasks.svg",
		"barcode": "glyphicons-basic-539-barcode.svg",
		"alarm": "glyphicons-basic-54-alarm.svg",
		"qr-code": "glyphicons-basic-540-qr-code.svg",
		"credit-empty": "glyphicons-basic-541-credit-empty.svg",
		"credit": "glyphicons-basic-542-credit.svg",
		"star-half": "glyphicons-basic-543-star-half.svg",
		"cloud": "glyphicons-basic-544-cloud.svg",
		"cloud-upload": "glyphicons-basic-545-cloud-upload.svg",
		"cloud-download": "glyphicons-basic-546-cloud-download.svg",
		"cloud-refresh": "glyphicons-basic-547-cloud-refresh.svg",
		"cloud-alert": "glyphicons-basic-548-cloud-alert.svg",
		"cloud-pause": "glyphicons-basic-549-cloud-pause.svg",
		"clock": "glyphicons-basic-55-clock.svg",
		"cloud-check": "glyphicons-basic-550-cloud-check.svg",
		"bag": "glyphicons-basic-551-bag.svg",
		"hand-donate": "glyphicons-basic-552-hand-donate.svg",
		"hand-heart": "glyphicons-basic-553-hand-heart.svg",
		"handshake": "glyphicons-basic-554-handshake.svg",
		"hand-praying": "glyphicons-basic-555-hand-praying.svg",
		"flash": "glyphicons-basic-556-flash.svg",
		"flash-automatic": "glyphicons-basic-557-flash-automatic.svg",
		"flash-no": "glyphicons-basic-558-flash-no.svg",
		"target": "glyphicons-basic-559-target.svg",
		"stopwatch": "glyphicons-basic-56-stopwatch.svg",
		"marriage": "glyphicons-basic-560-marriage.svg",
		"shoes": "glyphicons-basic-561-shoes.svg",
		"shoes-off": "glyphicons-basic-562-shoes-off.svg",
		"card-hearts": "glyphicons-basic-563-card-hearts.svg",
		"card-spades": "glyphicons-basic-564-card-spades.svg",
		"card-diamonds": "glyphicons-basic-565-card-diamonds.svg",
		"card-clubs": "glyphicons-basic-566-card-clubs.svg",
		"bring-forward": "glyphicons-basic-567-bring-forward.svg",
		"bring-to-front": "glyphicons-basic-568-bring-to-front.svg",
		"send-backward": "glyphicons-basic-569-send-backward.svg",
		"scissors-horizontal": "glyphicons-basic-57-scissors-horizontal.svg",
		"send-to-back": "glyphicons-basic-570-send-to-back.svg",
		"washing-machine": "glyphicons-basic-571-washing-machine.svg",
		"dryer": "glyphicons-basic-572-dryer.svg",
		"dishwasher": "glyphicons-basic-573-dishwasher.svg",
		"oven": "glyphicons-basic-574-oven.svg",
		"hob": "glyphicons-basic-575-hob.svg",
		"microwave": "glyphicons-basic-576-microwave.svg",
		"cluster": "glyphicons-basic-577-cluster.svg",
		"share": "glyphicons-basic-578-share.svg",
		"translate-alt": "glyphicons-basic-579-translate-alt.svg",
		"history": "glyphicons-basic-58-history.svg",
		"translate": "glyphicons-basic-580-translate.svg",
		"directions": "glyphicons-basic-581-directions.svg",
		"directions-arrow": "glyphicons-basic-582-directions-arrow.svg",
		"finish-line": "glyphicons-basic-583-finish-line.svg",
		"radio": "glyphicons-basic-584-radio.svg",
		"start": "glyphicons-basic-585-start.svg",
		"bomb": "glyphicons-basic-586-bomb.svg",
		"bug": "glyphicons-basic-587-bug.svg",
		"book-open-text": "glyphicons-basic-588-book-open-text.svg",
		"book-open": "glyphicons-basic-589-book-open.svg",
		"truck": "glyphicons-basic-59-truck.svg",
		"newspaper": "glyphicons-basic-590-newspaper.svg",
		"map-marker": "glyphicons-basic-591-map-marker.svg",
		"map": "glyphicons-basic-592-map.svg",
		"route": "glyphicons-basic-593-route.svg",
		"nearby": "glyphicons-basic-594-nearby.svg",
		"nearby-alt": "glyphicons-basic-595-nearby-alt.svg",
		"piggy-bank": "glyphicons-basic-596-piggy-bank.svg",
		"piggy-bank-coins": "glyphicons-basic-597-piggy-bank-coins.svg",
		"sound-surround": "glyphicons-basic-598-sound-surround.svg",
		"menu-close": "glyphicons-basic-599-menu-close.svg",
		"car": "glyphicons-basic-6-car.svg",
		"timer": "glyphicons-basic-60-timer.svg",
		"menu": "glyphicons-basic-600-menu.svg",
		"user-no": "glyphicons-basic-601-user-no.svg",
		"user-lock": "glyphicons-basic-602-user-lock.svg",
		"user-flag": "glyphicons-basic-603-user-flag.svg",
		"user-star": "glyphicons-basic-604-user-star.svg",
		"user-alert": "glyphicons-basic-605-user-alert.svg",
		"user-chat": "glyphicons-basic-606-user-chat.svg",
		"user-check": "glyphicons-basic-607-user-check.svg",
		"square-hospital": "glyphicons-basic-608-square-hospital.svg",
		"circle-red-cross": "glyphicons-basic-609-circle-red-cross.svg",
		"compass": "glyphicons-basic-61-compass.svg",
		"star-of-life": "glyphicons-basic-610-star-of-life.svg",
		"copy-duplicate": "glyphicons-basic-611-copy-duplicate.svg",
		"scissors": "glyphicons-basic-612-scissors.svg",
		"paste": "glyphicons-basic-613-paste.svg",
		"copy": "glyphicons-basic-614-copy.svg",
		"balance-scales": "glyphicons-basic-615-balance-scales.svg",
		"court-gavel": "glyphicons-basic-616-court-gavel.svg",
		"court-gavel-alt": "glyphicons-basic-617-court-gavel-alt.svg",
		"circle-hospital": "glyphicons-basic-618-circle-hospital.svg",
		"square-red-cross": "glyphicons-basic-619-square-red-cross.svg",
		"presentation": "glyphicons-basic-62-presentation.svg",
		"star-of-life-empty": "glyphicons-basic-620-star-of-life-empty.svg",
		"scale-personal": "glyphicons-basic-621-scale-personal.svg",
		"scale-kitchen": "glyphicons-basic-622-scale-kitchen.svg",
		"fireworks": "glyphicons-basic-623-fireworks.svg",
		"fireworks-alt": "glyphicons-basic-624-fireworks-alt.svg",
		"chemistry": "glyphicons-basic-625-chemistry.svg",
		"chemistry-alt": "glyphicons-basic-626-chemistry-alt.svg",
		"syringe-empty": "glyphicons-basic-627-syringe-empty.svg",
		"syringe": "glyphicons-basic-628-syringe.svg",
		"heartbeat": "glyphicons-basic-629-heartbeat.svg",
		"paperclip": "glyphicons-basic-63-paperclip.svg",
		"heart-broken": "glyphicons-basic-630-heart-broken.svg",
		"circle-plus": "glyphicons-basic-631-circle-plus.svg",
		"circle-minus": "glyphicons-basic-632-circle-minus.svg",
		"circle-remove": "glyphicons-basic-633-circle-remove.svg",
		"circle-check": "glyphicons-basic-634-circle-check.svg",
		"circle-question": "glyphicons-basic-635-circle-question.svg",
		"circle-info": "glyphicons-basic-636-circle-info.svg",
		"circle-alert": "glyphicons-basic-637-circle-alert.svg",
		"triangle-alert": "glyphicons-basic-638-triangle-alert.svg",
		"octagon-remove-empty": "glyphicons-basic-639-octagon-remove-empty.svg",
		"power": "glyphicons-basic-64-power.svg",
		"octagon-remove": "glyphicons-basic-640-octagon-remove.svg",
		"triangle-radioactive": "glyphicons-basic-641-triangle-radioactive.svg",
		"radioactive": "glyphicons-basic-642-radioactive.svg",
		"compound": "glyphicons-basic-643-compound.svg",
		"dna": "glyphicons-basic-644-dna.svg",
		"biohazard": "glyphicons-basic-645-biohazard.svg",
		"circle-play": "glyphicons-basic-646-circle-play.svg",
		"circle-down": "glyphicons-basic-647-circle-down.svg",
		"circle-up": "glyphicons-basic-648-circle-up.svg",
		"circle-left": "glyphicons-basic-649-circle-left.svg",
		"lightbulb": "glyphicons-basic-65-lightbulb.svg",
		"circle-right": "glyphicons-basic-650-circle-right.svg",
		"king": "glyphicons-basic-651-king.svg",
		"queen": "glyphicons-basic-652-queen.svg",
		"rook": "glyphicons-basic-653-rook.svg",
		"bishop": "glyphicons-basic-654-bishop.svg",
		"knight": "glyphicons-basic-655-knight.svg",
		"pawn": "glyphicons-basic-656-pawn.svg",
		"door": "glyphicons-basic-657-door.svg",
		"bicycle": "glyphicons-basic-658-bicycle.svg",
		"id": "glyphicons-basic-659-id.svg",
		"tag": "glyphicons-basic-66-tag.svg",
		"payment": "glyphicons-basic-660-payment.svg",
		"legal-section-sign": "glyphicons-basic-661-legal-section-sign.svg",
		"voicemail": "glyphicons-basic-662-voicemail.svg",
		"equalizer-dots": "glyphicons-basic-663-equalizer-dots.svg",
		"equalizer-bars": "glyphicons-basic-664-equalizer-bars.svg",
		"taxi": "glyphicons-basic-665-taxi.svg",
		"transport-mixed": "glyphicons-basic-666-transport-mixed.svg",
		"metro": "glyphicons-basic-667-metro.svg",
		"car-rental": "glyphicons-basic-668-car-rental.svg",
		"tram": "glyphicons-basic-669-tram.svg",
		"tags": "glyphicons-basic-67-tags.svg",
		"dashboard": "glyphicons-basic-670-dashboard.svg",
		"mountains": "glyphicons-basic-671-mountains.svg",
		"forest": "glyphicons-basic-672-forest.svg",
		"cableway": "glyphicons-basic-673-cableway.svg",
		"canister": "glyphicons-basic-674-canister.svg",
		"motor-oil": "glyphicons-basic-675-motor-oil.svg",
		"car-battery": "glyphicons-basic-676-car-battery.svg",
		"car-wheel": "glyphicons-basic-677-car-wheel.svg",
		"car-wheel-defect": "glyphicons-basic-678-car-wheel-defect.svg",
		"car-wheel-wrench": "glyphicons-basic-679-car-wheel-wrench.svg",
		"education": "glyphicons-basic-68-education.svg",
		"car-wheel-wrench-alt": "glyphicons-basic-680-car-wheel-wrench-alt.svg",
		"suitcase-doctor": "glyphicons-basic-681-suitcase-doctor.svg",
		"tent": "glyphicons-basic-682-tent.svg",
		"key-rounded": "glyphicons-basic-683-key-rounded.svg",
		"flashlight": "glyphicons-basic-684-flashlight.svg",
		"backpack": "glyphicons-basic-685-backpack.svg",
		"watch": "glyphicons-basic-686-watch.svg",
		"watch-squared": "glyphicons-basic-687-watch-squared.svg",
		"smart-bracelet": "glyphicons-basic-688-smart-bracelet.svg",
		"lighter": "glyphicons-basic-689-lighter.svg",
		"ruler": "glyphicons-basic-69-ruler.svg",
		"multifunction-knife": "glyphicons-basic-690-multifunction-knife.svg",
		"text-underline": "glyphicons-basic-691-text-underline.svg",
		"font": "glyphicons-basic-692-font.svg",
		"superscript": "glyphicons-basic-693-superscript.svg",
		"subscript": "glyphicons-basic-694-subscript.svg",
		"text-background": "glyphicons-basic-695-text-background.svg",
		"header": "glyphicons-basic-696-header.svg",
		"directions-sign": "glyphicons-basic-697-directions-sign.svg",
		"barbed-wire": "glyphicons-basic-698-barbed-wire.svg",
		"tractor": "glyphicons-basic-699-tractor.svg",
		"user-plus": "glyphicons-basic-7-user-plus.svg",
		"gift": "glyphicons-basic-70-gift.svg",
		"fence": "glyphicons-basic-700-fence.svg",
		"ice-cream": "glyphicons-basic-701-ice-cream.svg",
		"ice-cream-off": "glyphicons-basic-702-ice-cream-off.svg",
		"remote-control": "glyphicons-basic-703-remote-control.svg",
		"pills": "glyphicons-basic-704-pills.svg",
		"medicine": "glyphicons-basic-705-medicine.svg",
		"park": "glyphicons-basic-706-park.svg",
		"dog-tags": "glyphicons-basic-707-dog-tags.svg",
		"luggage-alt": "glyphicons-basic-708-luggage-alt.svg",
		"lawn-mover": "glyphicons-basic-709-lawn-mover.svg",
		"umbrella": "glyphicons-basic-71-umbrella.svg",
		"brick-wall": "glyphicons-basic-710-brick-wall.svg",
		"voting-urn": "glyphicons-basic-711-voting-urn.svg",
		"government": "glyphicons-basic-712-government.svg",
		"announcement": "glyphicons-basic-713-announcement.svg",
		"dustbin": "glyphicons-basic-714-dustbin.svg",
		"feather": "glyphicons-basic-715-feather.svg",
		"recycling": "glyphicons-basic-716-recycling.svg",
		"home-fire": "glyphicons-basic-717-home-fire.svg",
		"home-flood": "glyphicons-basic-718-home-flood.svg",
		"water": "glyphicons-basic-719-water.svg",
		"book": "glyphicons-basic-72-book.svg",
		"home-for-sale": "glyphicons-basic-720-home-for-sale.svg",
		"campfire": "glyphicons-basic-721-campfire.svg",
		"fireplace": "glyphicons-basic-722-fireplace.svg",
		"basket": "glyphicons-basic-723-basket.svg",
		"basket-in": "glyphicons-basic-724-basket-in.svg",
		"basket-out": "glyphicons-basic-725-basket-out.svg",
		"basket-checkout": "glyphicons-basic-726-basket-checkout.svg",
		"basket-check": "glyphicons-basic-727-basket-check.svg",
		"firefighters": "glyphicons-basic-728-firefighters.svg",
		"police": "glyphicons-basic-729-police.svg",
		"bookmark": "glyphicons-basic-73-bookmark.svg",
		"ambulance": "glyphicons-basic-730-ambulance.svg",
		"ear": "glyphicons-basic-731-ear.svg",
		"ear-off": "glyphicons-basic-732-ear-off.svg",
		"theater-icon": "glyphicons-basic-733-theater-icon.svg",
		"kettlebell": "glyphicons-basic-734-kettlebell.svg",
		"ticket": "glyphicons-basic-735-ticket.svg",
		"ticket-parking": "glyphicons-basic-736-ticket-parking.svg",
		"parking-meter": "glyphicons-basic-737-parking-meter.svg",
		"parking": "glyphicons-basic-738-parking.svg",
		"check": "glyphicons-basic-739-check.svg",
		"wifi": "glyphicons-basic-74-wifi.svg",
		"hash": "glyphicons-basic-740-hash.svg",
		"align-top": "glyphicons-basic-741-align-top.svg",
		"align-bottom": "glyphicons-basic-742-align-bottom.svg",
		"align-horizontal": "glyphicons-basic-743-align-horizontal.svg",
		"align-left": "glyphicons-basic-744-align-left.svg",
		"align-vertical": "glyphicons-basic-745-align-vertical.svg",
		"align-right": "glyphicons-basic-746-align-right.svg",
		"reflect-y": "glyphicons-basic-747-reflect-y.svg",
		"reflect-x": "glyphicons-basic-748-reflect-x.svg",
		"resize-horizontal": "glyphicons-basic-749-resize-horizontal.svg",
		"cup": "glyphicons-basic-75-cup.svg",
		"resize-vertical": "glyphicons-basic-750-resize-vertical.svg",
		"call-redirect": "glyphicons-basic-751-call-redirect.svg",
		"call-ip": "glyphicons-basic-752-call-ip.svg",
		"call-record": "glyphicons-basic-753-call-record.svg",
		"call-music": "glyphicons-basic-754-call-music.svg",
		"call-data": "glyphicons-basic-755-call-data.svg",
		"call-pause": "glyphicons-basic-756-call-pause.svg",
		"call-incoming": "glyphicons-basic-757-call-incoming.svg",
		"call-outgoing": "glyphicons-basic-758-call-outgoing.svg",
		"call-video": "glyphicons-basic-759-call-video.svg",
		"stroller": "glyphicons-basic-76-stroller.svg",
		"call-lock": "glyphicons-basic-760-call-lock.svg",
		"sync": "glyphicons-basic-761-sync.svg",
		"sync-alert": "glyphicons-basic-762-sync-alert.svg",
		"sync-check": "glyphicons-basic-763-sync-check.svg",
		"modal-window": "glyphicons-basic-764-modal-window.svg",
		"gas": "glyphicons-basic-765-gas.svg",
		"waste-pipe": "glyphicons-basic-766-waste-pipe.svg",
		"waste-pipe-end": "glyphicons-basic-767-waste-pipe-end.svg",
		"toilet": "glyphicons-basic-768-toilet.svg",
		"drinking-water": "glyphicons-basic-769-drinking-water.svg",
		"headphones": "glyphicons-basic-77-headphones.svg",
		"drinking-water-off": "glyphicons-basic-770-drinking-water-off.svg",
		"apple": "glyphicons-basic-771-apple.svg",
		"neighborhood": "glyphicons-basic-772-neighborhood.svg",
		"temperature": "glyphicons-basic-773-temperature.svg",
		"temperature-alert": "glyphicons-basic-774-temperature-alert.svg",
		"temperature-set": "glyphicons-basic-775-temperature-set.svg",
		"temperature-low": "glyphicons-basic-776-temperature-low.svg",
		"temperature-high": "glyphicons-basic-777-temperature-high.svg",
		"tornado": "glyphicons-basic-778-tornado.svg",
		"ice-lolly": "glyphicons-basic-779-ice-lolly.svg",
		"headset": "glyphicons-basic-78-headset.svg",
		"ice-lolly-eaten": "glyphicons-basic-780-ice-lolly-eaten.svg",
		"milk": "glyphicons-basic-781-milk.svg",
		"egg": "glyphicons-basic-782-egg.svg",
		"wheat": "glyphicons-basic-783-wheat.svg",
		"corn": "glyphicons-basic-784-corn.svg",
		"soya": "glyphicons-basic-785-soya.svg",
		"nuts": "glyphicons-basic-786-nuts.svg",
		"carrot": "glyphicons-basic-787-carrot.svg",
		"meat": "glyphicons-basic-788-meat.svg",
		"seafood": "glyphicons-basic-789-seafood.svg",
		"triangle-empty-alert": "glyphicons-basic-79-triangle-empty-alert.svg",
		"fish": "glyphicons-basic-790-fish.svg",
		"square-email": "glyphicons-basic-791-square-email.svg",
		"email": "glyphicons-basic-792-email.svg",
		"paired": "glyphicons-basic-793-paired.svg",
		"paired-off": "glyphicons-basic-794-paired-off.svg",
		"set": "glyphicons-basic-795-set.svg",
		"set-down": "glyphicons-basic-796-set-down.svg",
		"chevron-last-down": "glyphicons-basic-797-chevron-last-down.svg",
		"chevron-last-up": "glyphicons-basic-798-chevron-last-up.svg",
		"chevron-last-left": "glyphicons-basic-799-chevron-last-left.svg",
		"user-minus": "glyphicons-basic-8-user-minus.svg",
		"bars": "glyphicons-basic-80-bars.svg",
		"chevron-last-right": "glyphicons-basic-800-chevron-last-right.svg",
		"file-plus": "glyphicons-basic-801-file-plus.svg",
		"file-minus": "glyphicons-basic-802-file-minus.svg",
		"file-refresh": "glyphicons-basic-803-file-refresh.svg",
		"file-lock": "glyphicons-basic-804-file-lock.svg",
		"file-cloud": "glyphicons-basic-805-file-cloud.svg",
		"user-rounded": "glyphicons-basic-806-user-rounded.svg",
		"user-squared": "glyphicons-basic-807-user-squared.svg",
		"drop-down": "glyphicons-basic-808-drop-down.svg",
		"drop-plus": "glyphicons-basic-809-drop-plus.svg",
		"retweet": "glyphicons-basic-81-retweet.svg",
		"block-move": "glyphicons-basic-810-block-move.svg",
		"fingerprint-scan": "glyphicons-basic-811-fingerprint-scan.svg",
		"fingerprint": "glyphicons-basic-812-fingerprint.svg",
		"fingerprint-lock": "glyphicons-basic-813-fingerprint-lock.svg",
		"fingerprint-check": "glyphicons-basic-814-fingerprint-check.svg",
		"fingerprint-remove": "glyphicons-basic-815-fingerprint-remove.svg",
		"satellite": "glyphicons-basic-816-satellite.svg",
		"satellite-dish": "glyphicons-basic-817-satellite-dish.svg",
		"satellite-dish-alt": "glyphicons-basic-818-satellite-dish-alt.svg",
		"robot": "glyphicons-basic-819-robot.svg",
		"refresh": "glyphicons-basic-82-refresh.svg",
		"radar": "glyphicons-basic-820-radar.svg",
		"battery-charging": "glyphicons-basic-821-battery-charging.svg",
		"battery-charged": "glyphicons-basic-822-battery-charged.svg",
		"battery-three-quarters": "glyphicons-basic-823-battery-three-quarters.svg",
		"battery-half": "glyphicons-basic-824-battery-half.svg",
		"battery-quarter": "glyphicons-basic-825-battery-quarter.svg",
		"battery-low": "glyphicons-basic-826-battery-low.svg",
		"arrow-thin-down": "glyphicons-basic-827-arrow-thin-down.svg",
		"arrow-thin-up": "glyphicons-basic-828-arrow-thin-up.svg",
		"arrow-thin-left": "glyphicons-basic-829-arrow-thin-left.svg",
		"roundabout": "glyphicons-basic-83-roundabout.svg",
		"arrow-thin-right": "glyphicons-basic-830-arrow-thin-right.svg",
		"male": "glyphicons-basic-831-male.svg",
		"female": "glyphicons-basic-832-female.svg",
		"virgin-female": "glyphicons-basic-833-virgin-female.svg",
		"intersex": "glyphicons-basic-834-intersex.svg",
		"transgender": "glyphicons-basic-835-transgender.svg",
		"lesbian": "glyphicons-basic-836-lesbian.svg",
		"gay": "glyphicons-basic-837-gay.svg",
		"hetero": "glyphicons-basic-838-hetero.svg",
		"other": "glyphicons-basic-839-other.svg",
		"random": "glyphicons-basic-84-random.svg",
		"unknown": "glyphicons-basic-840-unknown.svg",
		"square-plus": "glyphicons-basic-841-square-plus.svg",
		"square-minus": "glyphicons-basic-842-square-minus.svg",
		"square-remove": "glyphicons-basic-843-square-remove.svg",
		"square-check": "glyphicons-basic-844-square-check.svg",
		"square-question": "glyphicons-basic-845-square-question.svg",
		"square-info": "glyphicons-basic-846-square-info.svg",
		"square-alert": "glyphicons-basic-847-square-alert.svg",
		"subtitles": "glyphicons-basic-848-subtitles.svg",
		"computer-network": "glyphicons-basic-849-computer-network.svg",
		"heating": "glyphicons-basic-85-heating.svg",
		"computer-network-alt": "glyphicons-basic-850-computer-network-alt.svg",
		"subtitles-off": "glyphicons-basic-851-subtitles-off.svg",
		"subtitles-add": "glyphicons-basic-852-subtitles-add.svg",
		"subtitles-minus": "glyphicons-basic-853-subtitles-minus.svg",
		"audio-description-d": "glyphicons-basic-854-audio-description-d.svg",
		"audio-description": "glyphicons-basic-855-audio-description.svg",
		"square-play": "glyphicons-basic-856-square-play.svg",
		"square-down": "glyphicons-basic-857-square-down.svg",
		"square-up": "glyphicons-basic-858-square-up.svg",
		"square-left": "glyphicons-basic-859-square-left.svg",
		"reload": "glyphicons-basic-86-reload.svg",
		"square-right": "glyphicons-basic-860-square-right.svg",
		"vr-inside": "glyphicons-basic-861-vr-inside.svg",
		"vr": "glyphicons-basic-862-vr.svg",
		"user-vr": "glyphicons-basic-863-user-vr.svg",
		"user-vr-plus": "glyphicons-basic-864-user-vr-plus.svg",
		"user-vr-minus": "glyphicons-basic-865-user-vr-minus.svg",
		"view-full": "glyphicons-basic-866-view-full.svg",
		"view-half": "glyphicons-basic-867-view-half.svg",
		"puzzle": "glyphicons-basic-868-puzzle.svg",
		"door-open": "glyphicons-basic-869-door-open.svg",
		"tv": "glyphicons-basic-87-tv.svg",
		"door-close": "glyphicons-basic-870-door-close.svg",
		"package": "glyphicons-basic-871-package.svg",
		"one-day-delivery": "glyphicons-basic-872-one-day-delivery.svg",
		"forklift": "glyphicons-basic-873-forklift.svg",
		"transport-trolley": "glyphicons-basic-874-transport-trolley.svg",
		"simple-trolley": "glyphicons-basic-875-simple-trolley.svg",
		"palette-package": "glyphicons-basic-876-palette-package.svg",
		"warehouse": "glyphicons-basic-877-warehouse.svg",
		"this-side-up": "glyphicons-basic-878-this-side-up.svg",
		"terminal-empty": "glyphicons-basic-879-terminal-empty.svg",
		"book-log": "glyphicons-basic-88-book-log.svg",
		"terminal": "glyphicons-basic-880-terminal.svg",
		"candle": "glyphicons-basic-881-candle.svg",
		"balloon": "glyphicons-basic-882-balloon.svg",
		"balloons": "glyphicons-basic-883-balloons.svg",
		"missile": "glyphicons-basic-884-missile.svg",
		"step-back": "glyphicons-basic-885-step-back.svg",
		"step-forward": "glyphicons-basic-886-step-forward.svg",
		"undo": "glyphicons-basic-887-undo.svg",
		"redo": "glyphicons-basic-888-redo.svg",
		"three-dimensional": "glyphicons-basic-889-three-dimensional.svg",
		"book-address": "glyphicons-basic-89-book-address.svg",
		"three-dimensional-full": "glyphicons-basic-890-three-dimensional-full.svg",
		"factory": "glyphicons-basic-891-factory.svg",
		"factory-alt": "glyphicons-basic-892-factory-alt.svg",
		"user-worker": "glyphicons-basic-893-user-worker.svg",
		"barrel": "glyphicons-basic-894-barrel.svg",
		"dice-one": "glyphicons-basic-895-dice-one.svg",
		"dice-two": "glyphicons-basic-896-dice-two.svg",
		"dice-three": "glyphicons-basic-897-dice-three.svg",
		"dice-four": "glyphicons-basic-898-dice-four.svg",
		"dice-five": "glyphicons-basic-899-dice-five.svg",
		"film": "glyphicons-basic-9-film.svg",
		"building": "glyphicons-basic-90-building.svg",
		"dice-six": "glyphicons-basic-900-dice-six.svg",
		"eyedropper": "glyphicons-basic-91-eyedropper.svg",
		"contrast": "glyphicons-basic-92-contrast.svg",
		"drop": "glyphicons-basic-93-drop.svg",
		"crop": "glyphicons-basic-94-crop.svg",
		"vector-path": "glyphicons-basic-95-vector-path.svg",
		"vector-path-circle": "glyphicons-basic-96-vector-path-circle.svg",
		"vector-path-pentagon": "glyphicons-basic-97-vector-path-pentagon.svg",
		"vector-path-line": "glyphicons-basic-98-vector-path-line.svg",
		"vector-path-curve": "glyphicons-basic-99-vector-path-curve.svg",
		"square-share": "glyphicons-basic-b001-square-share.svg",
		"file-text": "glyphicons-filetypes-1-file-text.svg",
		"file-rich-text": "glyphicons-filetypes-2-file-rich-text.svg"
	}