/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.21.588 on 2024-06-25 12:24:24.

export interface AccountDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    name: string;
    domain: string;
    secondaryDomains: string[];
    skillTypeCreateMinimumRole: UserRoleType;
    projectsEnabled: boolean;
    timesheetsEnabled: boolean;
    authType: string;
    token: string;
}

export interface AssignmentBriefDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    content: string;
    state: AssignmentBriefState;
    author: AssignmentBriefAuthor;
}

export interface AssignmentDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    project: ProjectDto;
    user: UserSimpleDto;
    title: string;
    location: string;
    description: string;
    externalId: string;
    durationDays: number;
    remainingDays: number;
    state: AssignmentState;
    startDate: Date;
    endDate: Date;
    chargeRate: number;
    effort: number;
    forecastSpend: number;
    timeSummary: TimeEntrySummaryDto;
    brief: AssignmentBriefDto;
    projectManagerRole: boolean;
    subscriberCount: number;
    billable: boolean;
}

export interface AssignmentSimpleDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    project: ProjectDto;
    userId: number;
    title: string;
    location: string;
    description: string;
    externalId: string;
    durationDays: number;
    remainingDays: number;
    state: AssignmentState;
    startDate: Date;
    endDate: Date;
}

export interface AttachmentDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    title: string;
    category: string;
    imageUrl: string;
    thumbnailUrl: string;
    width: number;
    height: number;
}

export interface AuthResultDto {
    authenticated: boolean;
    message: string;
    accessToken: string;
    settings: SettingsDto;
}

export interface AuthenticationProviderDetailedDto extends AuthenticationProviderDto {
    clientSecret: string;
    automaticUserProvisioning: boolean;
    description: string;
}

export interface AuthenticationProviderDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    type: AuthenticationType;
    tenant: string;
    clientId: string;
    baseUrl: string;
    loginURL: string;
}

export interface AvailabilityDto extends Availability {
    dateOverride: Date;
}

export interface ContactDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    phone: string;
    email: string;
    street: string;
    city: string;
    state: string;
    postCode: string;
    country: string;
    address: string;
}

export interface EducationDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    title: string;
    organisation: string;
    description: string;
    website: string;
    priority: number;
    hidden: boolean;
    startDate: Date;
    endDate: Date;
}

export interface ErrorDto {
    code: string;
    message: string;
    detail?: any;
}

export interface ExperienceDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    title: string;
    organisation: string;
    location: string;
    description: string;
    summary: string;
    website: string;
    priority: number;
    hidden: boolean;
    breakAfter: boolean;
    featureImageUrl: string;
    attachments: AttachmentDto[];
    startDate: Date;
    endDate: Date;
}

export interface FeatureDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    name: string;
    value: string;
    link: string;
}

export interface FileUploadDto extends FileUpload {
}

export interface HolidayDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    leaveType: LeaveTypeDto;
    title: string;
    description: string;
    office: string;
    date: Date;
}

export interface JobDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    account: AccountDto;
    title: string;
    description: string;
    instructions: string;
    state: JobState;
    token: string;
}

export interface LeaveTypeDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    code: string;
    title: string;
    description: string;
}

export interface MessageDto {
    code?: string;
    message: string;
    detail?: string;
}

export interface PagedListDto<C> {
    index: number;
    size: number;
    pages: number;
    total: number;
    values: C[];
}

export interface PagedSpecialListDto<DAO, DTO> {
    page: number;
    size: number;
    count: number;
    total: number;
    values: DTO[];
}

export interface ProfileDto extends ProfileSimpleDto {
    skills: SkillDto[];
    experiences: ExperienceDto[];
    educations: EducationDto[];
    features: FeatureDto[];
    empty: boolean;
}

export interface ProfileLinkDto {
    userId: number;
    templateId: number;
    publicUrl: string;
}

export interface ProfileSimpleDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    user: UserDto;
    headline: string;
    summary: string;
    state: ProfileState;
}

export interface ProjectDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    accountId: number;
    title: string;
    organisation: string;
    location: string;
    code: string;
    description: string;
    externalId: string;
    status: ProjectStatus;
    startDate: Date;
    endDate: Date;
    owner: UserSimpleDto;
    budget: number;
}

export interface PublishedProfileDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    profile: ProfileSimpleDto;
    headline: string;
    filename: string;
    url: string;
    profileVersion: number;
    contentType: string;
    template: TemplateDto;
}

export interface SearchResultsDto {
    profiles: ResultItem[];
    hits: number;
    page: number;
    pages: number;
    query: string;
    cursor: string;
}

export interface SettingsDto {
    account: AccountDto;
    user: UserDto;
    templates: TemplateDto[];
    tags: TagDto[];
    features: string[];
    departments: string[];
    offices: string[];
    settings: { [P in SettingName]?: any };
}

export interface SkillAddDto {
    id: number;
    title: string;
    strength: number;
}

export interface SkillAvailabilityDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    skill: SkillSimpleDto;
    assignment: AssignmentDto;
    availability: Date;
}

export interface SkillChatDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    type: SkillTypeDto;
    user: UserDto;
    subject: string;
    body: string;
    replyToId: number;
    state: SkillChatState;
    updatedText: string;
    deleted: boolean;
}

export interface SkillDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    strength: number;
    type: SkillTypeDto;
}

export interface SkillSimpleDto {
    id: number;
    profile: ProfileSimpleDto;
    strength: number;
    type: SkillTypeDto;
}

export interface SkillTypeDetailedDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    title: string;
    category: string;
    status: SkillTypeStatus;
    description: string;
    talentCount: number;
    deleted: boolean;
}

export interface SkillTypeDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    title: string;
    category: string;
    status: SkillTypeStatus;
    alternatives: SkillAddDto[];
    deleted: boolean;
}

export interface SkillsTalentDto extends PagedListDto<SkillSimpleDto> {
    values: SkillSimpleDto[];
    skillType: SkillType;
}

export interface SubscriberDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    assignment: AssignmentDto;
    user: UserSimpleDto;
    title: string;
    status: SubscriberStatus;
}

export interface SubscriberReviewDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    timesheetId: number;
    subscriberId: number;
    status: SubscriberReviewStatus;
    notes: string;
    created: Date;
    quantity: number;
}

export interface TagDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    label: string;
    colour: string;
    accountId: number;
}

export interface TemplateDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    title: string;
    description: string;
    pdfUrl: string;
    publishUrl: string;
    contentType: string;
    mode: TemplateMode;
    state: TemplateState;
}

export interface TimeEntrySummaryDto {
    assignment: AssignmentDto;
    billableQuantity: number;
    nonBillableQuantity: number;
    billableAmount: number;
}

export interface TimesheetAssignmentDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    project: TimesheetProjectDto;
    userId: number;
    title: string;
    chargeRate: number;
    startDate: Date;
    endDate: Date;
    effort: number;
    forecastSpend: number;
    billable: boolean;
    briefState: AssignmentBriefState;
}

export interface TimesheetDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    user: UserSimpleDto;
    notes: string;
    date: Date;
    endDate: Date;
    status: TimesheetStatus;
    content: string;
    entries: TimesheetEntryDto[];
    totalQuantity: number;
    totalBillableQuantity: number;
    totalNonBillableQuantity: number;
    totalLeaveQuantity: number;
    message: string;
    statusReport: string;
    statusRating: TimesheetRating;
    dateLabel: string;
}

export interface TimesheetEntryDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    assignment?: TimesheetAssignmentDto;
    leaveType?: LeaveTypeDto;
    holiday?: HolidayDto;
    notes?: string;
    quantity: number;
    billable: boolean;
    date: Date;
    dayOfWeek: number;
    dayOfWeekLabel?: string;
    entryType: EntryType;
}

export interface TimesheetLogDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    timesheetId: number;
    totalQuantity: number;
    totalBillableQuantity: number;
    totalNonBillableQuantity: number;
    totalLeaveQuantity: number;
    status: TimesheetStatus;
    notes: string;
    subscriberReview: SubscriberReviewStatus;
    subscriberId: number;
    updatedBy: UserSimpleDto;
}

export interface TimesheetProjectDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    title: string;
    organisation: string;
    startDate: Date;
    endDate: Date;
    budget: number;
    status: ProjectStatus;
}

export interface TimesheetSettingsDto {
    user: UserDto;
    minimumQuantity: number;
    assignments: AssignmentDto[];
    leaveTypes: LeaveTypeDto[];
}

export interface TimesheetSubscriptionDto {
    timesheet: TimesheetDto;
    subscriber: SubscriberDto;
    reviews: TimesheetLogDto[];
    accountId: number;
    nextToken: string;
    prevToken: string;
}

export interface UserAssignmentsDto extends UserSimpleDto {
    assignments: AssignmentSimpleDto[];
}

export interface UserDetailedDto extends UserDto {
    contact: ContactDto;
    authenticationType: AuthenticationType;
    inviteStatus: UserInviteStatus;
    manager: UserSimpleDto;
}

export interface UserDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    firstname: string;
    surname: string;
    fullname: string;
    email: string;
    photoUrl: string;
    photoThumbnailUrl: string;
    status: UserStatus;
    department: string;
    office: string;
    userRole: boolean;
    resumeFilename: string;
    availability: AvailabilityDto;
    roles: UserRoleDto[];
}

export interface UserPreferencesDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    timesheetCopyPrevious: boolean;
    timesheetAutoApprove: boolean;
    timesheetRequired: boolean;
}

export interface UserRoleDto {
    type: UserRoleType;
    projectId?: number;
}

export interface UserSimpleDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    firstname: string;
    surname: string;
    fullname: string;
    email: string;
    photoThumbnailUrl: string;
    department: string;
}

export interface WebhookDto {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    name: string;
    clientId: string;
    callback: string;
    active: boolean;
    event: WebhookEvent;
    failureCount: number;
}

export interface WebhookRequestDto {
    clientId: string;
    name: string;
    active: boolean;
    callback: string;
    events: WebhookEvent[];
}

export interface AbstractForm<DAO> {
    uuid?: string;
}

export interface AccountCreateForm extends AccountForm {
    firstname: string;
    surname: string;
    email: string;
    password: string;
}

export interface AccountForm extends AbstractForm<Account> {
    domain: string;
    name: string;
    skillTypeCreateMinimumRole: UserRoleType;
}

export interface AccountJoinForm {
    firstname: string;
    surname: string;
    email: string;
}

export interface AdvancedSearchForm {
    query: string;
    fields: string[];
    values: string[];
    operators: string[];
}

export interface AssignmentBriefForm {
    content: string;
    author: AssignmentBriefAuthor;
    state: AssignmentBriefState;
}

export interface AssignmentForm extends AbstractForm<Assignment> {
    userId: number;
    title: string;
    location: string;
    description: string;
    externalId: string;
    startDate: Date;
    endDate: Date;
    chargeRate: number;
    effort: number;
    brief: AssignmentBriefForm;
    projectManagerRole: boolean;
}

export interface AssignmentUpsertForm {
    projectId: number;
    projectExternalId: string;
    projectTitle: string;
    projectDescription: string;
    projectStatus: ProjectStatus;
    projectOrganisation: string;
    projectLocation: string;
    assignmentId: number;
    assignmentExternalId: string;
    userId: number;
    assignmentTitle: string;
    assignmentDescription: string;
    assignmentStatus: AssignmentStatus;
    assignmentStartDate: Date;
    assignmentEndDate: Date;
    assignmentEndDateAsLocalDate: Date;
    assignmentStartDateAsLocalDate: Date;
}

export interface AttachmentForm extends AbstractForm<Attachment> {
    title: string;
    category: string;
}

export interface AuthenticationProviderForm {
    type: AuthenticationType;
    email: string;
    domain: string;
    tenant: string;
    clientId: string;
    clientSecret: string;
}

export interface EducationForm {
    organisation: string;
    title: string;
    startDate?: string;
    endDate?: string;
}

export interface EmailProfileForm {
    templateId: number;
    toAddress: string;
}

export interface ExperienceForm extends AbstractForm<Experience> {
    organisation: string;
    title: string;
    description: string;
    location: string;
    summary: string;
    startDate: string;
    endDate?: string;
    breakAfter: boolean;
    featureImageUrl: string;
}

export interface FeatureForm extends AbstractForm<Feature> {
    name: string;
    value: string;
    link: string;
}

export interface ForgottenForm {
    email: string;
}

export interface HolidayForm {
    leaveTypeId: number;
    title: string;
    description: string;
    office: string;
    date: string;
}

export interface JobOnboardingForm extends UserOnboardingForm {
    password: string;
}

export interface LeaveTypeForm {
    code: string;
    title: string;
    description: string;
}

export interface ListForm {
    list: string[];
}

export interface LoginForm {
    email: string;
    password: string;
}

export interface OAuth2VerifyForm {
    code: string;
    state: string;
}

export interface PasswordChangeForm {
    password: string;
}

export interface ProfileDetailedForm {
    summary: string;
    headline: string;
    educations: EducationForm[];
    experiences: ExperienceForm[];
    skills: SkillForm[];
    features: FeatureForm[];
}

export interface ProfileForm extends AbstractForm<Profile> {
    summary: string;
    headline: string;
    fullname: string;
}

export interface ProfileStateForm {
    state: ProfileState;
}

export interface ProjectForm extends AbstractForm<Project> {
    organisation: string;
    title: string;
    description: string;
    location: string;
    summary: string;
    code: string;
    externalId: string;
    status: ProjectStatus;
    budget: number;
}

export interface SearchForm {
    q: string;
}

export interface SkillCategoryForm {
    skillTypeIds: string;
    category: string;
}

export interface SkillChatForm {
    subject: string;
    body: string;
    replyToId: number;
}

export interface SkillForm {
    strength: number;
    title: string;
    type: SkillTypeDto;
}

export interface SkillMergeForm {
    skillTypeIds: string;
}

export interface SkillTypeStatusForm {
    status: SkillTypeStatus;
}

export interface SkillsForm {
    skills: SkillAddDto[];
    validated: boolean;
}

export interface SkillsStrengthForm {
    strength: number;
}

export interface SkillsTypeForm {
    title: string;
    description: string;
    category: string;
}

export interface SubscriberCreateForm {
    userId: number;
}

export interface SubscriberReviewForm {
    status: SubscriberReviewStatus;
    notes: string;
}

export interface TalentForm {
    firstname: string;
    surname: string;
    email: string;
    department: string;
    invite: boolean;
}

export interface TemplateForm {
    title: string;
    description: string;
    content: string;
    inheritFromId: number;
    priority: number;
    contentType: TemplateContentType;
    state: TemplateState;
    mode: TemplateMode;
}

export interface TemplateStateForm {
    state: TemplateState;
}

export interface TimesheetEntryForm {
    id?: number;
    assignmentId?: number;
    leaveTypeId?: number;
    notes?: string;
    quantity: number;
    billable: boolean;
    date: Date;
}

export interface TimesheetForm {
    date: Date;
    notes: string;
    status: TimesheetStatus;
    entries: TimesheetEntryForm[];
    statusReport: string;
    statusRating: TimesheetRating;
}

export interface UserAvailabilityForm {
    offsetDate?: Date;
    offsetDays?: number;
}

export interface UserContactForm {
    email: string;
    phone: string;
    street: string;
    city: string;
    state: string;
    postCode: string;
    country: string;
}

export interface UserDetailsForm extends UserForm {
    contact: UserContactForm;
    invite: boolean;
}

export interface UserForm extends AbstractForm<User> {
    firstname: string;
    surname: string;
    email: string;
    department: string;
}

export interface UserOnboardingForm {
    user: UserDetailsForm;
    profile: ProfileDetailedForm;
    availability: UserAvailabilityForm;
    resumeFilename: string;
}

export interface UserOrganisationForm {
    managerId?: number;
    department?: string;
    office?: string;
}

export interface UserPreferencesForm {
    timesheetCopyPrevious: boolean;
}

export interface UserRolesForm {
    roles: UserRoleType[];
}

export interface UserStatusForm {
    status: UserStatus;
}

export interface Availability extends Serializable {
    offsetDate?: Date;
    offsetDays?: number;
    type: AvailabilityType;
    date: Date;
    now: boolean;
}

export interface FileUpload {
    container: string;
    filename: string;
    label: string;
    type: string;
    url: string;
    size: number;
}

export interface ResultItem {
    profile: ProfileSimpleDto;
    summary: string;
    experience: string;
    skills: string;
}

export interface SkillType {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    account: Account;
    title: string;
    content: SkillTypeContent;
    category: string;
    status: SkillTypeStatus;
    mergedTo: SkillType;
    deleted: boolean;
    approved: boolean;
    longId: string;
    repository: SkillTypeRepository;
}

export interface Serializable {
}

export interface Account {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    name: string;
    domain: string;
    active: boolean;
    secondaryDomains: string;
    skillTypeCreateMinimumRole: UserRoleType;
    projectsEnabled: boolean;
    timesheetsEnabled: boolean;
    features: string;
    defaultSkillTypeStatus: SkillTypeStatus;
    featuresArray: string[];
    secondaryDomainsArray: string[];
    longId: string;
    repository: AccountRepository;
}

export interface SkillTypeContent {
    description: string;
}

export interface SkillTypeRepository extends CrudRepository<SkillType, number> {
}

export interface Assignment extends Comparable<Assignment> {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    project: Project;
    user: User;
    title: string;
    location: string;
    description: string;
    summary: string;
    startDate: Date;
    endDate: Date;
    priority: number;
    externalId: string;
    chargeRate: number;
    effort: number;
    status: AssignmentStatus;
    brief: AssignmentBrief;
    subscribers: Subscriber[];
    state: AssignmentState;
    durationDays: number;
    remainingDays: number;
    billable: boolean;
    longId: string;
    repository: AssignmentRepository;
}

export interface Attachment {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    title: string;
    category: string;
    width: number;
    height: number;
    filename: string;
    imageUrl: string;
    thumbnailUrl: string;
    longId: string;
    repository: AttachmentRepository;
}

export interface Experience extends Comparable<Experience> {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    title: string;
    organisation: string;
    location: string;
    description: string;
    summary: string;
    website: string;
    startDate: Date;
    endDate: Date;
    priority: number;
    hidden: boolean;
    breakAfter: boolean;
    attachments: Attachment[];
    longId: string;
    repository: ExperienceRepository;
}

export interface Feature {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    profile: Profile | number;
    name: string;
    value: string;
    link: string;
    longId: string;
    repository: FeatureRepository;
}

export interface Profile {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    user: User;
    headline: string;
    summary: string;
    email: string;
    website: string;
    educations: Education[];
    experiences: Experience[];
    skills: Skill[];
    features: Feature[];
    updatedBy: User;
    state: ProfileState;
    searchIndexStatus: SearchIndexStatus;
    fullname: string;
    longId: string;
    repository: ProfileRepository;
}

export interface Project {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    account: Account;
    title: string;
    organisation: string;
    location: string;
    description: string;
    code: string;
    priority: number;
    hidden: boolean;
    status: ProjectStatus;
    externalId: string;
    owner: User;
    budget: number;
    startDate: Date;
    endDate: Date;
    assignments: Assignment[];
    state: ProjectState;
    longId: string;
    repository: ProjectRepository;
}

export interface User {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    firstname: string;
    surname: string;
    fullname: string;
    department: string;
    office: string;
    email: string;
    roles: UserRole[];
    status: UserStatus;
    inviteStatus: UserInviteStatus;
    photoFilename: string;
    resumeFilename: string;
    contact: Contact;
    availability: Availability;
    resumeFilePath: string;
    photoMediumUrl: string;
    photoThumbnailUrl: string;
    userRole: boolean;
    photoUrl: string;
    longId: string;
    password: string;
    repository: UserRepository;
}

export interface AccountRepository extends CrudRepository<Account, number> {
}

export interface AssignmentBrief extends AbstractEmbeddable {
    content: string;
    state: AssignmentBriefState;
    author: AssignmentBriefAuthor;
    updated: Date;
    blank: boolean;
    draft: boolean;
    approved: boolean;
    authorSelf: boolean;
}

export interface Subscriber {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    assignment: Assignment;
    user: User;
    title: string;
    status: SubscriberStatus;
    longId: string;
    repository: AssignmentRepository;
}

export interface AssignmentRepository extends CrudRepository<Assignment, number> {
}

export interface AttachmentRepository extends CrudRepository<Attachment, number> {
}

export interface ExperienceRepository extends CrudRepository<Experience, number> {
}

export interface FeatureRepository extends CrudRepository<Feature, number> {
}

export interface Education extends Comparable<Education> {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    title: string;
    organisation: string;
    description: string;
    website: string;
    startDate: Date;
    endDate: Date;
    priority: number;
    longId: string;
    repository: EducationRepository;
}

export interface Skill {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    type: SkillType;
    mergedFrom: SkillType;
    strength: number;
    longId: string;
    repository: SkillRepository;
}

export interface ProfileRepository extends CrudRepository<Profile, number> {
}

export interface ProjectRepository extends CrudRepository<Project, number> {
}

export interface UserRole extends GrantedAuthority {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    type: UserRoleType;
    typeOrdinal: number;
    longId: string;
    repository: UserRoleRepository;
}

export interface Contact {
    id: number;
    uuid: string;
    updated: Date;
    version: number;
    phone: string;
    email: string;
    address: Address;
    longId: string;
    repository: ContactRepository;
}

export interface UserRepository extends CrudRepository<User, number> {
}

export interface CrudRepository<T, ID> extends Repository<T, ID> {
}

export interface AbstractEmbeddable {
}

export interface Comparable<T> {
}

export interface EducationRepository extends CrudRepository<Education, number> {
}

export interface SkillRepository extends CrudRepository<Skill, number> {
}

export interface UserRoleRepository extends CrudRepository<UserRole, number> {
}

export interface GrantedAuthority extends Serializable {
    authority: string;
}

export interface Address extends Serializable {
    street: string;
    city: string;
    state: string;
    postCode: string;
    country: string;
}

export interface ContactRepository extends CrudRepository<Contact, number> {
}

export interface Repository<T, ID> {
}

export enum ReportTypeEnum {
    TIMESHEET = "TIMESHEET",
    PROJECT = "PROJECT",
    LEAVE = "LEAVE",
    ASSIGNMENT = "ASSIGNMENT",
}

export enum UserRoleType {
    GUEST = "GUEST",
    USER = "USER",
    PROJECT_MANAGER = "PROJECT_MANAGER",
    MANAGER = "MANAGER",
    ADMIN = "ADMIN",
    ROOT = "ROOT",
}

export enum AssignmentBriefState {
    NONE = "NONE",
    DRAFT = "DRAFT",
    SUBMITTED = "SUBMITTED",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
}

export enum AssignmentBriefAuthor {
    SELF = "SELF",
    MANAGER = "MANAGER",
    NONE = "NONE",
}

export enum AssignmentState {
    PENDING = "PENDING",
    ACTIVE = "ACTIVE",
    FINISHED = "FINISHED",
    CANCELLED = "CANCELLED",
}

export enum AuthenticationType {
    BASIC = "BASIC",
    AD = "AD",
}

export enum AvailabilityType {
    DATE = "DATE",
    NOTICE = "NOTICE",
}

export enum JobState {
    OPEN = "OPEN",
    CLOSED = "CLOSED",
}

export enum ProfileState {
    DRAFT = "DRAFT",
    REVIEW = "REVIEW",
    ACTIVE = "ACTIVE",
    DELETED = "DELETED",
}

export enum ProjectStatus {
    PROPOSED = "PROPOSED",
    APPROVED = "APPROVED",
    CANCELLED = "CANCELLED",
    DELETED = "DELETED",
}

export enum SettingName {
    LOGO = "LOGO",
    STYLESHEET = "STYLESHEET",
    SEND_ASSIGNMENT_BRIEF = "SEND_ASSIGNMENT_BRIEF",
    ASSIGNMENT_BRIEF_STATE_DEFAULT = "ASSIGNMENT_BRIEF_STATE_DEFAULT",
    ASSIGNMENT_BRIEF_AUTHOR_DEFAULT = "ASSIGNMENT_BRIEF_AUTHOR_DEFAULT",
}

export enum SkillChatState {
    ACTIVE = "ACTIVE",
    DELETED = "DELETED",
}

export enum SkillTypeStatus {
    APPROVED = "APPROVED",
    PENDING = "PENDING",
    DELETED = "DELETED",
    MERGED = "MERGED",
}

export enum SubscriberStatus {
    ACTIVE = "ACTIVE",
    CANCELLED = "CANCELLED",
    DELETED = "DELETED",
}

export enum SubscriberReviewStatus {
    NONE = "NONE",
    SENT = "SENT",
    REVIEWED = "REVIEWED",
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED",
}

export enum TemplateMode {
    LEGACY = "LEGACY",
    HANDLEBAR = "HANDLEBAR",
}

export enum TemplateState {
    DRAFT = "DRAFT",
    ACTIVE = "ACTIVE",
    DELETED = "DELETED",
}

export enum TimesheetStatus {
    DRAFT = "DRAFT",
    SUBMITTED = "SUBMITTED",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
}

export enum TimesheetRating {
    GREEN = "GREEN",
    AMBER = "AMBER",
    RED = "RED",
}

export enum EntryType {
    ASSIGNMENT = "ASSIGNMENT",
    LEAVE = "LEAVE",
    INVALID = "INVALID",
}

export enum UserStatus {
    APPLICANT = "APPLICANT",
    CANDIDATE = "CANDIDATE",
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    DELETED = "DELETED",
}

export enum UserInviteStatus {
    NONE = "NONE",
    PENDING = "PENDING",
    ACCEPTED = "ACCEPTED",
}

export enum WebhookEvent {
    USER_CREATED = "USER_CREATED",
    USER_UPDATED = "USER_UPDATED",
    PROFILE_UPDATED = "PROFILE_UPDATED",
    PROFILE_CREATED = "PROFILE_CREATED",
    PROJECT_CREATED = "PROJECT_CREATED",
    PROJECT_UPDATED = "PROJECT_UPDATED",
    ASSIGNMENT_CREATED = "ASSIGNMENT_CREATED",
    ASSIGNMENT_UPDATED = "ASSIGNMENT_UPDATED",
    TIMESHEET_CREATED = "TIMESHEET_CREATED",
    TIMESHEET_UPDATED = "TIMESHEET_UPDATED",
}

export enum AssignmentStatus {
    ACTIVE = "ACTIVE",
    CANCELLED = "CANCELLED",
    DELETED = "DELETED",
}

export enum TemplateContentType {
    PROFILE_PDF = "PROFILE_PDF",
    PROFILE_HTML = "PROFILE_HTML",
    PROFILE_EMAIL = "PROFILE_EMAIL",
    INVITE_EMAIL = "INVITE_EMAIL",
}

export enum SearchIndexStatus {
    PENDING = "PENDING",
    OK = "OK",
    FAILED = "FAILED",
}

export enum ProjectState {
    PENDING = "PENDING",
    ACTIVE = "ACTIVE",
    FINISHED = "FINISHED",
    CANCELLED = "CANCELLED",
}
