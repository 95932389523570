import * as FormFields from "components/forms/FormFields";
import { IconButton } from 'components/general/IconButton';
import React from 'react';
import * as Icons from 'react-bootstrap-icons';


export default class EducationStep extends React.Component {


	handleChange(event, index) {
		var profile = this.props.profile;
		if (!profile.experiences[index]) profile.educations[index] = {};
		profile.experiences[index][event.target.name] = event.target.value;
		this.props.onChange(profile);
	}

	handleAdd(event) {
		var profile = {...this.props.profile};
		profile.experiences.push({});
		this.props.onChange(profile);
	}

	handleDelete(index) {
		var profile = {...this.props.profile};
		profile.experiences.splice(index, 1);
		this.props.onChange(profile);
	}

	render() {

		if (this.props.step !== this.props.index) return null;

		return (

			<React.Fragment>

				<h4>Work Experiences</h4>

				{this.props.profile.experiences.map((experience, index) => {
					return (

						<React.Fragment key={index} >

							<div className="experience">

								<div className="row">
									<div className="col">
										<FormFields.Text name="title" title="Title" placeholder="Job title or role" value={experience.title} mandatory={true} onChange={(e) => this.handleChange(e, index)} />
									</div>
									<div className="col">
										<FormFields.Text name="organisation" title="Organisation" placeholder="Working for" value={experience.organisation} onChange={(e) => this.handleChange(e, index)} />
									</div>
								</div>
								<div className="row">
									<div className="col">
										<FormFields.DateMonthYear name="startDate" title="Start Date" value={experience.startDate} mandatory={true} onChange={(e) => this.handleChange(e, index)} />
									</div>
									<div className="col">
										<FormFields.DateMonthYear name="endDate" title="End Date" value={experience.endDate} mandatory={false} allowCurrent={true} onChange={(e) => this.handleChange(e, index)} />
									</div>
								</div>

								<FormFields.TextArea rows={6} name="description" title="Description" placeholder="A description of your experience" value={experience.description} onChange={(e) => this.handleChange(e, index)} />

								<IconButton icon={<Icons.Trash />} onClick={() => this.handleDelete(index)} title="Delete"/>
							</div>

						</React.Fragment>

					);
				})}

				<IconButton icon={<Icons.PlusLg />} onClick={() => this.handleAdd()} title="Add more"/>

			</React.Fragment>

		);
	}

}