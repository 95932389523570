import React from 'react';
import Glyphicon from 'components/general/Glyphicon';

export default class Notifications extends React.Component {

	constructor(props) {
		super();
	}

	renderErrors() {
		if (this.props.errors == null || this.props.errors.length === 0) return null;
		if (!Array.isArray(this.props.errors)) return <li>{(this.props.errors.message ? this.props.errors.message : "Error")}</li>
		return this.props.errors.map((error, i) => {
			return <li key={i}>{error.message}</li>
		});
	}

	renderMessages() {
		if (this.props.messages == null || !Array.isArray(this.props.messages)) return null;

		return this.props.messages.map((message, i) => {
			if (typeof message === "string") {
				return <li key={i}>{message}</li>
			}
			return <li key={i}>{message.message}</li>
		});
	}

	renderMessagesPanel() {
		if (this.props.messages == null || this.props.messages.length === 0) {
			return null;
		} else {
			return(
				<div id="messages" className={"messages messages-type-" + this.props.type } >
					<ul className={!Array.isArray(this.props.messages) || this.props.messages.length <= 1 ? "single" : "multiple"}>
						{this.renderMessages()}
					</ul>
					<Glyphicon name="times" className="small messages-close" onClick={() => this.props.onNotificationClose()} />
				</div>
			);
		}
	}

	renderErrorsPanel() {

		if (this.props.errors == null || this.props.errors.length === 0) {
			return null;
		} else {
			return(
				<div id="errors" className={"errors errors-type-" + this.props.type }>
					<ul className={!Array.isArray(this.props.errors) || this.props.errors.length <= 1 ? "single" : "multiple"}>
						{this.renderErrors()}
					</ul>
					<Glyphicon name="times" className="small error-close" onClick={() => this.props.onNotificationClose()} />
				</div>
			);
		}
	}

	render() {

		return (
			<div>
				{this.renderMessagesPanel()}
				{this.renderErrorsPanel()}
			</div>
		);

	}

}