import React from 'react';

import './Spinner.css';

interface SpinnerProps {
	loading?: boolean,
	failed?: boolean,
	failureMessage?: string
}

export const Spinner: React.FunctionComponent<SpinnerProps> = (props) => {

	if (props.loading === undefined || props.loading) {
		return (
			<div className="sk-circle">
				<div className="sk-circle1 sk-child"></div>
				<div className="sk-circle2 sk-child"></div>
				<div className="sk-circle3 sk-child"></div>
				<div className="sk-circle4 sk-child"></div>
				<div className="sk-circle5 sk-child"></div>
				<div className="sk-circle6 sk-child"></div>
				<div className="sk-circle7 sk-child"></div>
				<div className="sk-circle8 sk-child"></div>
				<div className="sk-circle9 sk-child"></div>
				<div className="sk-circle10 sk-child"></div>
				<div className="sk-circle11 sk-child"></div>
				<div className="sk-circle12 sk-child"></div>
			</div>
		);
	} else {
		if (props.failed !== undefined && props.failed === true) {
			return <div>{props.failureMessage ? props.failureMessage : null}</div>
		} else {
			return <>{props.children}</>;
		}

	}
}

export default Spinner;