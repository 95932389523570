import React from 'react';
import { Link } from "react-router-dom"

import SessionStore from "store/SessionStore"
import ErrorMessages from "components/general/ErrorMessages"
import * as FormFields from "components/forms/FormFields"
import * as AccountActions from "actions/AccountActions"
import * as ProfileActions from "actions/ProfileActions"
import Glyphicon from "components/general/Glyphicon"

import './Settings.css';

export const CONTENT_TYPES = {
	PROFILE_PDF: "Profile PDF template",
	PROFILE_HTML: "Profile HTML template",
	PROFILE_EMAIL: "Profile Email template",
	INVITE_EMAIL: "Invite email template"
}

export default class Templates extends React.Component {

	constructor(props) {
		super();
		this.state = {templates: SessionStore.getTemplates(), account: SessionStore.getAccount(), edit: null, template: {}}
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	unedit() {
		this.setState({edit: null});
	}

	handleTemplateChange(event) {
		this.setState({errors:null, messages: null});
		var template = this.state.template;
		template[event.target.name] = event.target.value;
		this.setState({template: template});
	}

	handleSubmitCreateTemplate(event) {

		if (!this.state.template.inheritFromId && this.state.templates.length != null && this.state.templates.length > 0) {
			var template = this.state.template;
			template.inheritFromId = this.state.templates[0].id;
			this.setState({template: template});
		}

		ProfileActions.templateDuplicate(SessionStore.getAccountId(), this.state.template,
			() => {
				this.setState({edit: null, messages: ["Account details saved"]});
				AccountActions.settingsLoad(
					() => this.setState({templates: SessionStore.getTemplates()}),
					() => null
				);
			},
			(errors) => this.setState({errors: errors})
		);
	}


	handleTemplateDelete(template) {

		if(window.confirm("Delete template?")) {
			ProfileActions.templateDelete(SessionStore.getAccountId(), template.id,
				() => {
					this.setState({edit: null, messages: ["Template deleted"]});
					AccountActions.settingsLoad(
						() => this.setState({templates: SessionStore.getTemplates()}),
						() => null
					);
				},
				(errors) => this.setState({errors: errors})
			);
		}
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	render() {
		var templates = this.state.templates.map((template) => {
				return (
					<div className="template tile" key={template.id}>
						<Link to={"/settings/templates/" + template.id}><h4>{template.title} {template.state === "DELETED" ? "(deleted)" : ""}</h4></Link>
						<div className="content-type">{CONTENT_TYPES[template.contentType]}</div>
						<small>{template.description}</small>
						<small>Format: {template.mode}</small>
						<div className="tools">
							<Link to={"/settings/templates/" + template.id} className="btn btn-icon glyphicon-button"><Glyphicon name="pencil" /></Link>
							<Glyphicon name="bin" onClick={() => this.handleTemplateDelete(template)} />
						</div>
					</div>
				);
		});

		return (
			<div className="template-settings">

				<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages} />

				<div className="tiles">

					{templates}

					<div className="tile add-new" key="new">
						<button onClick={() => this.setState({edit: "create-template"})} className="add-label"><Glyphicon name="plus" className="grey" label="New Template"/></button>
					</div>

					<FormFields.Model title="Create Template" isOpen={this.state.edit === "create-template"}
						onHide={(e) => this.unedit()} onCancel={(e) => this.unedit()} onConfirm={(e) => this.handleSubmitCreateTemplate(e)} >

						<FormFields.Text name="title" title="Title" placeholder="Title" value={this.state.template.title} onChange={(e) => this.handleTemplateChange(e)} />
						<FormFields.TextArea name="description" title="Description" placeholder="This is the description of the template" value={this.state.template.description} onChange={(e) => this.handleTemplateChange(e)} />

						<FormFields.Select name="inheritFromId" title="Copy From" options={this.state.templates} value={this.state.template.inheritFromId} onChange={(e) => this.handleTemplateChange(e)} valueSelector={(template) => template.id} labelSelector={(template) => template.title} />

					</FormFields.Model>

				</div>
			</div>
		);
	}

}