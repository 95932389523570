import React from 'react';
import Notifications from "./Notifications"
import { TopMenu } from 'components/layouts/navigation/TopMenu'

export default class SectionBody extends React.Component {


	render() {

		return (
			<section className={this.props.className}>

				<TopMenu>
					{this.props.topMenuItems}
				</TopMenu>

				<Notifications
					onNotificationClose={() => this.props.onNotificationClose()}
					errors={this.props.errors} messages={this.props.messages}/>

				{this.props.children}

			</section>
		);

	}

}