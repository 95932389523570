import * as AccountActions from "actions/AccountActions";
import * as FormFields from "components/forms/FormFields";
import ErrorMessages from "components/general/ErrorMessages";
import React from 'react';
import { Link } from "react-router-dom";
import LoginPage from "../LoginPage";


export default class JoinPage extends React.Component {

	constructor(props) {

		super(props);
		this.state = {password: null, passwordCheck: null, success: false, errors: null, messages: null};
	}

	componentWillMount() {

	}

	handleChange(event) {
		var state = this.state;
		state[event.target.name] = event.target.value;
		this.setState(state);
	}

	handleSubmit(e) {

		this.setState({errors:null, messages: null, success: false});
		if (this.state.password == null || this.state.password.length < 6) {
			this.setState({errors: [{code:"error", message:"Password length must be 6 characters or more"}]});
			return;
		}

		if (this.state.password !== this.state.passwordCheck) {
			this.setState({errors: [{code:"error", message:"Passwords do not match"}]});
			return;
		}

		this.setState({errors: null});
		AccountActions.join(this.props.match.params.token, this.state.password,
			() => this.props.history.push({pathname: "/profile", state: {messages: ["Password has been set, you can now login"], success: true}}),
			(errors) => {this.setState({errors: errors})}
		);
	}

	render() {

		if (this.state.success) return <LoginPage errors={this.state.errors} messages={this.state.messages}/>


		return(

			<section className="join">

				<nav>
					<Link className="logo" to="/splash" ></Link>
				</nav>

				<div className="container">
					<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages}/>

					<div className="row justify-content-md-center">
						<div className="col-md-6 join-panel">
							<h1>Join</h1>
							<p>Welcome to TalentedTeam! Create a password to continue through to start building your TalentedTeam profile.</p>
							<hr/>
							<div className="well">

								<FormFields.Password name="password" title="Password" placeholder="" value={this.state.password} onChange={(e) => this.handleChange(e)}/>
								<FormFields.Password name="passwordCheck" title="Password Check" placeholder="" value={this.state.passwordCheck} onChange={(e) => this.handleChange(e)}/>
								<p className="minor-text">Your password needs to be at least 6 characters and should contain a range of combinations of numbers, characters, upper and lower case and the odd special character.</p>

								<hr/>

								<button className="btn btn-default" onClick={(e) => this.handleSubmit(e)}>Save</button>


							</div>
						</div>
					</div>

				</div>

				<footer>
					<small>TalentedTeam™ is a Trade Mark of RoarEnergy Group Pty. Ltd.</small>
					<small>RoarEnergy is proudly an Australian Company ACN: 147 462 315, ABN: 98 147 462 315</small>
				</footer>

			</section>

		);
	}

}