import React from 'react';
import Remarkable from 'remarkable'
import * as FormFields from "components/forms/FormFields"
import * as ProfileActions from "actions/ProfileActions"
import { IconButton } from 'components/general/IconButton'
import * as Icons from 'react-bootstrap-icons';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SessionStore from "store/SessionStore";


//https://github.com/ericgio/react-bootstrap-typeahead


export default class Features extends React.Component {

	constructor(props) {
		super();
		this.state = {features: props.features, edit: null, add: null}
	}

	componentWillReceiveProps(nextProps, nextState){
		this.setState({features: nextProps.features});
	}

	handleEditChange(event) {

		if (this.state.edit == null) return;

		var value = (event.target.value ? event.target.value : "");
		value = value.replace(/•/g, "-");	//replace bullets with markdown
		value = value.replace(//g, "-");	//replace bullets with markdown
		value = value.replace(/-\t/g, "- ");	//replace bullets-tab with bullet-space

		var edit = this.state.edit;
		edit[event.target.name] = value;


		this.setState({edit: edit});


	}

	handleAddChange(event) {

		if (this.state.add == null) return;

		var value = (event.target.value ? event.target.value : "");
		value = value.replace(/•/g, "-");	//replace bullets with markdown
		value = value.replace(//g, "-");	//replace bullets with markdown
		value = value.replace(/-\t/g, "- ");	//replace bullets-tab with bullet-space

		var add = this.state.add;
		add[event.target.name] = value;

		this.setState({add: add});
	}

	handleAddSubmit(event) {

		ProfileActions.featureAdd(this.props.accountId, this.props.profile.id, this.state.add,
			(profile) => {
				this.setState({edit: null, add: null, errors: null});
				this.props.onUpdated(profile);
			},
			(errors) => this.setState({errors: errors})
		);
	}

	handleEditSubmit(value) {

		ProfileActions.featureUpdate(this.props.accountId, this.props.profile.id, this.state.edit,
			(profile) => {
				this.setState({edit: null, add: null, errors: null});
				this.props.onUpdated(profile);
			},
			(errors) => this.setState({errors: errors})
		);

	}

	edit(feature) {
		const cloneFeature = {...feature}
		cloneFeature.name = SessionStore.getFeatures()[0];	//set first
		this.setState({edit: cloneFeature});
	}

	unedit() {
		this.setState({edit: null});
	}

	add() {
		var add = {name: SessionStore.getFeatures()[0]};//set first
		this.setState({add: add});
	}

	unadd() {
		this.setState({add: null});
	}


	delete(feature) {
		if (window.confirm('Delete feature?')) {
			ProfileActions.featureDelete(this.props.accountId, this.props.profile.id, feature.id,
				(profile) => this.props.onUpdated(profile),
				(errors) => this.setState({errors: errors})
			);
		}
	}

	renderFeatures() {

		var md = new Remarkable();

		return this.state.features.map((feature) => {
			return (
				<li className={"feature"} key={"feature-" + feature.id}>
					<h3 className="name">{feature.name}</h3>
					<div className="value" dangerouslySetInnerHTML={{ __html: md.render(feature.value)}} />
					{ feature.link ? <a className="minor-text" href={feature.link} target="_blank" rel="noopener noreferrer">{feature.link}</a> : null }
					<div className="controls">
						<IconButton title="Edit" className="edit-link" onClick={(e) => this.edit(feature)} icon={<Icons.Pencil />}  />
						<IconButton title="Delete" className="delete-link" onClick={(e) => this.delete(feature)} icon={<Icons.Trash />}  />
					</div>
				</li>
			);
		});
	}

	renderEdit() {

		if (!this.state.edit) return null;

		return (
				<Modal isOpen={this.state.edit !== null} >
					<ModalHeader>
						Edit a Feature
					</ModalHeader>
					<ModalBody>

						<FormFields.Select name="name" title="Name"  value={this.state.edit.name}
							options={SessionStore.getFeatures()} onChange={(e) => this.handleEditChange(e)}
						/>
						
						<FormFields.TextArea name="value" title="Value" placeholder="Value" value={this.state.edit.value} rows={6} onChange={(e) => this.handleEditChange(e)} />
						<FormFields.Text name="link" title="Link" placeholder="http://linktopublication.com" value={this.state.edit.link} onChange={(e) => this.handleEditChange(e)} />

					</ModalBody>
					<ModalFooter>
						<button className="btn" onClick={(e) => this.unedit()}>Cancel</button>
						<button className="btn btn-default" onClick={(e) => this.handleEditSubmit(e)}>Save</button>
					</ModalFooter>
				</Modal>
			);
	}

	renderAdd() {

		if (!this.state.add) return null;

		return (
				<Modal isOpen={this.state.add !== null} >
					<ModalHeader>
						Add a Feature
					</ModalHeader>
					<ModalBody>

						<FormFields.Select name="name" title="Name"  value={this.state.add.name}
							options={SessionStore.getFeatures()} onChange={(e) => this.handleAddChange(e)}
						/>

						<FormFields.TextArea name="value" title="Value" placeholder="Value" value={this.state.add.value} rows={6} onChange={(e) => this.handleAddChange(e)} />
						<FormFields.Text name="link" title="Link" placeholder="http://linktopublication.com" value={this.state.add.link} onChange={(e) => this.handleAddChange(e)} />

					</ModalBody>
					<ModalFooter>
						<button className="btn" onClick={(e) => this.unadd()}>Cancel</button>
						<button className="btn btn-default" onClick={(e) => this.handleAddSubmit(e)}>Add</button>
					</ModalFooter>
				</Modal>
			);
	}

	render() {
		return (
			<div className="profile-tile">
				<h2>Features</h2>
				<div className="controls"><IconButton onClick={(e) => this.add()}  icon={<Icons.PlusLg />} /></div>

				<ul className="features">
					{this.renderFeatures()}
				</ul>

				{this.renderAdd()}
				{this.renderEdit()}

			</div>
		);
	}

}