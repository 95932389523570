import React from 'react';
import * as FormFields from "components/forms/FormFields"
import * as Utils from "components/general/Utils"
import * as Dto from "actions/Dto"

export default class ReportsModal extends React.Component {

	constructor(props){

		super(props);

		let reports = [
			{index: 0, name: "Timesheet", value: Dto.ReportTypeEnum.TIMESHEET},
			{index: 1, name: "Leave", value: Dto.ReportTypeEnum.LEAVE},
			{index: 2, name: "Availability", value: Dto.ReportTypeEnum.ASSIGNMENT},
			{index: 3, name: "Project", value: Dto.ReportTypeEnum.PROJECT},
		];

		let monday = Utils.getMonday(new Date());
		this.state = {
			reports: reports,
			selectedReport: 0,
			fromDate: Utils.datePlusDays(monday, -28),
			toDate: monday
		}

	}

	getSelelectedReport() {
		return this.state.reports[this.state.selectedReport];
	}


	render() {

		return (
			<FormFields.Model title="Share" isOpen={this.props.show}
				onCancel={(e) => this.props.onCancel()} onConfirm={(e) => this.props.onDownload(this.getSelelectedReport().value, this.state.fromDate, this.state.toDate)} confirmLabel="Download" >

				<FormFields.Select title="Report Type" value={this.state.selectedReport}
					options={this.state.reports} labelSelector={(option) => option.name}
					valueSelector={(option) => option.index}
					onChange={(e) => this.setState({selectedReport: e.target.value})} />

				<FormFields.DatePick title="From Date" name="fromDate" value={this.state.fromDate} onChange={(date) => this.setState({"fromDate": date})} />
				<FormFields.DatePick title="To Date" name="toDate" value={this.state.toDate} onChange={(date) => this.setState({"toDate": date})} />

			</FormFields.Model>
		);
	}

}

