import React from 'react';
import { Link } from "react-router-dom"

import Routes from "actions/Routes"
import SessionStore from "store/SessionStore"
import * as ProfileActions from "actions/ProfileActions"
import * as SkillActions from "actions/SkillActions"
import * as FormFields from "components/forms/FormFields"
import Glyphicon from "components/general/Glyphicon"
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import * as Utils from "components/general/Utils"
import SectionBody from 'components/layouts/SectionBody'

import ReactGA from 'react-ga';

import "./Skills.css"


export default class SkillsPage extends React.Component {

	constructor(props) {
		super();
		this.state = { skills: {page: 0, total: 0, size: 0, count: 0, values: []}, assignCategory: null, isSearchLoading: false,
				accountId: SessionStore.getAccountId(), recent: [], showAssignCategory: false, showMerge: false, showAdvancedFilter: false,
				quickFilter: "", advancedFilter: {}, filterLabel: ""
			};
	}

	componentWillReceiveProps(nextProps, nextState){
		if (nextProps.match.params.category) {
			this.setState({category: nextProps.match.params.category});
			this.loadSkills(this.state.skills.page);
		}
	}

	componentDidMount() {

		window.scrollTo(0, 0);	//scroll to top
		ReactGA.pageview(Routes.skills());	//analytics

		this.loadSkills(this.state.skills.page);

	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	getSelectedSkills() {
		return this.state.skills.values.filter(skill => skill.selected);
	}

	handleChangeFilter(event) {
		this.setState({quickFilter: event.target.value});
	}

	handleFilterSubmit() {
		ProfileActions.skills(this.state.accountId, 0, this.state.quickFilter,
			(skills) => this.setState({skills: skills, filterLabel: this.state.quickFilter}),
			(errors) => {this.setState({errors: errors})}
		);
	}

	handleChangeAdvancedFilter(event) {
		var filter = this.state.advancedFilter;
		filter[event.target.name] = event.target.value;
		this.setState({advancedFilter: filter});
	}

	/*
	 * Loads a filter in {name: value, name: value} format
	 */
	handleAdvanceFilterSubmit() {
		ProfileActions.skillsAdvanceFilter(this.state.accountId, 0, this.state.advancedFilter,
			(skills) => this.setState({skills: skills, showAdvancedFilter: false, quickFilter: "", filterLabel: this.state.advancedFilter.status}),
			(errors) => {this.setState({errors: errors})}
		);
	}

	loadSkills(page) {

		if (!page || page < 0) page = 0;

		if (Object.keys(this.state.advancedFilter).length > 0) {

			ProfileActions.skillsAdvanceFilter(this.state.accountId, 0, this.state.advancedFilter,
				(skills) => this.setState({skills: skills, showAdvancedFilter: false, quickFilter: "", filterLabel: this.state.advancedFilter.status}),
				(errors) => {this.setState({errors: errors})}
			);

		} else {
			ProfileActions.skills(this.state.accountId, page, this.state.quickFilter,
				(skills) => this.setState({skills: skills}),
				(errors) => {this.setState({errors: errors})}
			);
		}

	}

	loadSkillsNextPage() {
		this.loadSkills(this.state.skills.page + 1);
	}

	loadSkillsPreviousPage() {
		this.loadSkills(this.state.skills.page - 1);
	}


	loadMore(page) {

		if (Object.keys(this.state.advancedFilter).length > 0) {

			ProfileActions.skillsAdvanceFilter(this.state.accountId, this.state.skills.page + 1, this.state.advancedFilter,
				(results) => {
					results.values = this.state.skills.values.concat(results.values);
					this.setState({skills: results, showAdvancedFilter: false, quickFilter: "", filterLabel: this.state.advancedFilter.status});
				},
				(errors) => this.setState({errors: errors})
			);

		} else {
			ProfileActions.skills(this.state.accountId, this.state.skills.page + 1, this.state.quickFilter,
				(results) => {
					results.values = this.state.skills.values.concat(results.values);
					this.setState({skills: results});
				},
				(errors) => this.setState({errors: errors})
			);
		}

	}

	handleSelectSkill(skill) {
		var skills = this.state.skills;
		var index = skills.values.indexOf(skill);
		skill.selected = true;
		skills.values[index] = skill;
		this.setState({skills: skills});
	}

	handleUnselectSkill(skill) {
		var skills = this.state.skills;
		var index = skills.values.indexOf(skill);
		skill.selected = false;
		skills.values[index] = skill;
		this.setState({});
	}

	replaceSkills(skills) {
		var currentSkills = this.state.skills;
		skills.forEach((skill) => {
			var index = currentSkills.values.findIndex(next => parseInt(next.id, 10) === parseInt(skill.id, 10));
			if (index >= 0) currentSkills.values[index] = skill;
		});
		return currentSkills;
	}

	handleSetCategory() {
		var selectedSkillIds = this.getSelectedSkills().reduce((accumulator, skill) => accumulator + (accumulator.length > 0 ? "," : "") + skill.id, "");

		SkillActions.skillTypeAssignCategory(this.state.accountId, this.state.assignCategory, selectedSkillIds,
			(skills) => this.setState({skills: this.replaceSkills(skills), assignCategory: null, showAssignCategory: false}),
			(errors) => this.setState({errors: errors})
		);
	}

	handleSkillMergeChange(value) {
		this.setState({merge: value[0]});
	}

	handleMergeSubmit() {
		var selectedSkillIds = this.getSelectedSkills().reduce((accumulator, skill) => accumulator + (accumulator.length > 0 ? "," : "") + skill.id, "");

		this.setState({errors: null, messages: null});
		SkillActions.skillTypesMerge(this.state.accountId, selectedSkillIds, this.state.merge,
			(skills) => this.setState({showEdit: false, showMerge: false, merge: null, skills: this.replaceSkills(skills)}),
			(errors) => this.setState({errors: errors})
		);
	}

	handleDeleteSkill(skill) {
		if (window.confirm('Delete skill?')) {
			SkillActions.skillTypeDelete(SessionStore.getAccountId(), skill.id,
				(skill) => this.setState({skills: this.replaceSkills([skill])}),
				(errors) => {this.setState({errors: errors})}
			);
		}
	}

	handleDeleteSkills() {
		if (window.confirm('Delete skills?')) {
			var selectedSkillIds = this.getSelectedSkills().reduce((accumulator, skill) => accumulator + (accumulator.length > 0 ? "," : "") + skill.id, "");

			SkillActions.skillTypeMultipleDelete(this.state.accountId, selectedSkillIds,
				(skills) => this.setState({skills: this.replaceSkills(skills), assignCategory: null, showAssignCategory: false}),
				(errors) => this.setState({errors: errors})
			);
		}
	}

	handleStatusToggle(skill) {
		SkillActions.skillTypeStatus(SessionStore.getAccountId(), skill.id, (skill.status === 'PENDING' ? 'APPROVED' : 'PENDING'),
				(skill) => this.setState({skills: this.replaceSkills([skill])}),
				(errors) => this.setState({errors: errors})
			);
	}

	clearFilter() {
		this.setState({filterLabel: "", quickFilter: "", advancedFilter: {}});
		this.loadSkills(this.state.skills.page);
	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


	renderFilterHelper() {
		if (this.state.filterLabel) {
			return <div className="minor-text filter-helper pill">Filter: {this.state.filterLabel}</div>
		}
		return null;
	}

	renderSkills() {

		if (this.state == null || this.state.skills.values.length <= 0) return (<div className="data-item no-data"><div className="no-data-label">No records</div></div>);

		return this.state.skills.values.map((skill) => {
			return (
				<div key={skill.id} className={"skill-type status-" + skill.status.toLowerCase() + (skill.selected ? " selected" : "")}>


					<div className="details">
						<h5 className="name" title={skill.title}><Link to={Routes.skillsEdit(skill.id)}>
							{Utils.splitTwoLines(skill.title, " ")}
						</Link></h5>
						<div className="headline" title={skill.category}>{skill.category ? skill.category : <span className="placeholder">Category Undefined</span>}</div>

						<small className="summary" title={skill.description}>{skill.description}</small>
						{skill.status === 'PENDING' && SessionStore.isManagerRole() ? <small className="error">Pending Approval</small> : null}
					</div>

					<div className="tools">
						<Link className="btn btn-icon glyphicon-button" to={Routes.skillsTalent(skill.id)} title="View talent"><Glyphicon name="users" /> {skill.talentCount}</Link>
						{SessionStore.isManagerRole() ? <Link to={Routes.skillsEdit(skill.id)} className="btn btn-icon glyphicon-button"><Glyphicon name="pencil" title="Edit Skill" className="edit-link" /></Link> : null }
						{SessionStore.isManagerRole() ? <Glyphicon name="bin" title="Delete Skill" className="edit-link" onClick={() => this.handleDeleteSkill(skill)} /> : null }
						{SessionStore.isManagerRole() && skill.status === 'PENDING' ? <Glyphicon name="check" title="Approve Skill" className="edit-link" onClick={() => this.handleStatusToggle(skill)} /> : null }

						<div className={"controls" + (skill.selected ? " selected" : "")}>
							{skill.selected ? <Glyphicon name="square-empty-check" onClick={() => this.handleUnselectSkill(skill)} /> : <Glyphicon name="square-empty"  onClick={() => this.handleSelectSkill(skill)} />}
						</div>

					</div>

				</div>
			);
		});
	}

	renderActionsDropdown() {

		var isSkillsSelected = this.getSelectedSkills().length > 0;

		if (SessionStore.isManagerRole()) {
			return (
				<div className="dropdown">
					<button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
						<Glyphicon name="cogwheel" className="invert" />
					</button>
					<div className="dropdown-menu dropdown-menu-right">
						<Link className="dropdown-item disabled" to={"#"}>Add Skill</Link>
						<button className={isSkillsSelected ? "dropdown-item" : "dropdown-item disabled"} onClick={()=> this.handleDeleteSkills() }>Delete Skills</button>
						<button className="dropdown-item disabled">Approve Skills</button>
						<button className="dropdown-item" onClick={()=> this.setState({showAdvancedFilter: true}) }>Advanced filter</button>
						<button className="dropdown-item" onClick={()=> this.clearFilter() }>Clear filter</button>
						<div className="dropdown-divider"></div>
				   	 	<button className={isSkillsSelected ? "dropdown-item" : "dropdown-item disabled"} onClick={()=> this.setState({showAssignCategory: isSkillsSelected}) }>Assign Category...</button>
				    	<button className={isSkillsSelected ? "dropdown-item" : "dropdown-item disabled"} onClick={()=> this.setState({showMerge: isSkillsSelected, merge: null}) }>Merge skills into...</button>
				    </div>
				</div>
			);
		} else {
			return null;
		}

	}

	render() {

		//pagination
		let pagination = null;
		if (this.state.skills.pages > this.state.skills.index + 1) {
			pagination = (
					<nav className="pagination-nav">
						<ul className="pagination">
							<li className="page-item"><button className="page-link" onClick={(e) => this.loadMore()}>Load more...</button></li>
						</ul>
						Showing {this.state.skills.values.length} of {this.state.skills.total}
					</nav>
			);
		}

		return(

			<SectionBody className="skills"
				onNotificationClose={() => this.setState({errors: null, messages: null})}
				errors={this.state.errors} messages={this.state.messages} >

				<FormFields.Model title="Assign Category" isOpen={this.state.showAssignCategory} className="modal-normal" confirmLabel="Assign"
					onCancel={(e) => this.setState({showAssignCategory: false})} onConfirm={(e) => this.handleSetCategory(e)}
					commitClass={this.getSelectedSkills().length <= 0 || this.state.assignCategory == null || this.state.assignCategory === 0 ? "btn btn-default disabled" : "btn btn-default"}>

					<div>
						<FormFields.Text name="category" title="Category" placeholder="Category" value={this.state.assignCategory} onChange={(e) => this.setState({assignCategory: e.target.value})} />
						{this.getSelectedSkills().length > 0 ? <span>Assign the {this.getSelectedSkills().length} selected skills to the category entered above.</span> : <span className="error">No skills selected</span>}
					</div>

				</FormFields.Model>

				<FormFields.Model title="Merge Skill into..." isOpen={this.state.showMerge} className="modal-normal"
					onCancel={(e) => this.setState({showMerge: false})} onConfirm={(e) => this.handleMergeSubmit(e)} confirmLabel="Merge" >

					{this.state.merge == null || !this.state.merge.id ?
						<div>
							<label>Find Skill: </label>
							<AsyncTypeahead minLength={3} inputProps={{name: "title"}} isLoading={this.state.isSearchLoading}
								title="Skill" placeholder="Search Skills" allowNew={false}
								caseSensitive={false} filterBy={['title', 'category']}
								onSearch={query => {
									this.setState({isSearchLoading: true});
									ProfileActions.skillsSearch(this.state.accountId, query,
										(data) => {
											this.setState({search: data, isSearchLoading: false});
										}
									);
								}}
								onChange={(value) => this.handleSkillMergeChange(value)}
								labelKey="title"
							  	options={this.state.search}

							/>
						</div>
						:
						<div>
							<h5>{this.state.merge.title}</h5>
							<div>{Utils.elipsis(this.state.merge.description, 200)}</div>
						</div>
					}

				</FormFields.Model>

				<FormFields.Model title="Advanced Filter" isOpen={this.state.showAdvancedFilter} className="modal-normal" confirmLabel="Filter"
					onCancel={(e) => this.setState({showAdvancedFilter: false})} onConfirm={(e) => this.handleAdvanceFilterSubmit(e)} >

					<div>
						<FormFields.Select name="status" title="Status" placeholder="Status" value={this.state.advancedFilter.status}
							valueSelector={(option) => option.value} labelSelector={(option) => option.label}
							onChange={(e) => this.handleChangeAdvancedFilter(e)}
							options={[{label:'All Status', value:''}, {label:'Pending Only', value:'PENDING'}, {label:'Approved Only', value:'APPROVED'}]} />
					</div>

				</FormFields.Model>

				<div className="row">

					<div className="col">

						<h1>Skills</h1>

						<div className="skills">

							<div className="controls-container">

								<FormFields.SearchBox name="filter" title="Filter" placeholder="Filter Results" value={this.state.quickFilter}
										onChange={(e) => this.handleChangeFilter(e)} onSubmit={(e) => this.handleFilterSubmit()} helpText={this.state.filterLabel ? " Filter: " + this.state.filterLabel : null}/>

								<div className="controls">
									{this.renderActionsDropdown()}
								</div>

							</div>

							<div className="data-grid grid">

								{this.renderSkills()}

							</div>

							{pagination}

						</div>

					</div>

				</div>

			</SectionBody>
		);
	}
}