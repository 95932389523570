import React from "react";
import Remarkable from "remarkable"

import Routes from "actions/Routes"
import SectionBody from 'components/layouts/SectionBody'
import SessionStore from "store/SessionStore"
import * as SkillActions from "actions/SkillActions"
import * as ProfileActions from "actions/ProfileActions"
import * as FormFields from "components/forms/FormFields"
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import { TopMenuItem } from "components/layouts/navigation/TopMenuItem";

import SkillExperts from "./SkillExperts"
import SkillChat from "./SkillChat"

import * as Utils from "components/general/Utils"
import Glyphicon from "components/general/Glyphicon"

import ReactGA from 'react-ga';


export default class SkillPage extends React.Component {

	constructor(props) {

		super();
		this.state = { skill: {}, accountId: SessionStore.getAccountId(), merge: null,
                skillId: props.match.params.skillId, showEdit: false, showMerge: false,
                errors: null, messages: null
			};
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.skillId !== prevProps.match.params.skillId) {
			this.setState({skillId: this.props.match.params.skillId});
			this.reload(this.props.match.params.skillId);
		}
	}

	componentDidMount() {
		this.reload(this.props.match.params.skillId);
	}

	reload(skillId) {

        this.path = Routes.skillsEdit(skillId);

		window.scrollTo(0, 0);			//scroll to top
		ReactGA.pageview(this.path);	//analytics

        //Load Skill
		SkillActions.skillTypeLoad(SessionStore.getAccountId(), skillId,
			(skillType) => this.setState({skillId: skillId, skill: skillType}),
			(errors) => {this.setState({errors: errors})}
		);

	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	 handleChange(event) {
		this.setState({errors:null, messages: null});
		var skill = this.state.skill;
		skill[event.target.name] = event.target.value;
		this.setState({skill: skill});
	}

	handleSubmit(event) {
		this.setState({errors: null, messages: null});
		SkillActions.skillTypeUpdate(this.state.accountId, this.state.skill,
			(skillType) => this.setState({showEdit: false, showMerge: false, skill: skillType}),
			(errors) => this.setState({errors: errors})
		);
	}

	unedit() {
		this.setState({showEdit: false, showMerge: false, merge: null});
	}

	statusToggle() {
		
		SkillActions.skillTypeStatus(SessionStore.getAccountId(), this.state.skillId, (this.state.skill.status === 'PENDING' ? 'APPROVED' : 'PENDING'),
				(skillType) => this.setState({skill: skillType}),
				(errors) => {this.setState({errors: errors})}
			);
	}

	delete() {
		if(window.confirm('Delete Skill?')) {
			SkillActions.skillTypeDelete(SessionStore.getAccountId(), this.state.skillId,
					(skillType) => this.setState({skill: skillType}),
					(errors) => {this.setState({errors: errors})}
				);
		}
	}

	handleSkillMergeChange(value) {
		this.setState({merge: value[0]});
	}

	handleMergeSubmit(event) {
		this.setState({errors: null, messages: null});
		SkillActions.skillTypeMerge(this.state.accountId, this.state.skill, this.state.merge,
			(data) => this.setState({showEdit: false, showMerge: false, merge: null, skill: data[0]}),
			(errors) => this.setState({errors: errors})
		);
	}

	handleMergeWith(merge) {
		this.setState({errors: null, messages: null});

		if (window.confirm("Merge '" + merge.title + "' into '" + this.state.skill.title + "'?")) {
			SkillActions.skillTypeMerge(this.state.accountId, merge, this.state.skill,
				(data) => {
					this.setState({showEdit: false, showMerge: false, merge: null, messages: ["Skill merged"]});

					//reload
					this.reload(this.state.skill.id);
				},
				(errors) => this.setState({errors: errors})
			);
		}
	}

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	renderEdit() {

		return (

			<FormFields.Model title="Edit Skill" isOpen={this.state.showEdit}
				onHide={(e) => this.unedit()} onCancel={(e) => this.unedit()} onConfirm={(e) => this.handleSubmit(e)} >

				<FormFields.Text name="title" title="Title" placeholder="Skill Title" value={this.state.skill.title} onChange={(e) => this.handleChange(e)} readOnly={!SessionStore.isManagerRole()} />
				<FormFields.Text name="category" title="Category" placeholder="Category" value={this.state.skill.category} onChange={(e) => this.handleChange(e)} readOnly={!SessionStore.isManagerRole()} />
				<FormFields.TextArea name="description" title="Description" placeholder="Enter a description to define the details and dimensions to which this skill applies" value={this.state.skill.description} onChange={(e) => this.handleChange(e)} rows={8}/>

			</FormFields.Model>


		);
	}

	renderMerge() {

		return (

			<FormFields.Model title="Merge with:" isOpen={this.state.showMerge} onHide={(e) => this.unedit()}
					onCancel={(e) => this.unedit()} onConfirm={(e) => this.handleMergeSubmit(e)} >

				<div className="form-group">

					{this.state.merge == null || !this.state.merge.id ?

						<div>
							<label>Find Skill: </label>
							<AsyncTypeahead minLength={3} inputProps={{name: "title"}} isLoading={this.state.isSearchLoading}
								title="Skill" placeholder="Search Skills" allowNew={false}
								caseSensitive={false} filterBy={['title', 'category']}
								onSearch={query => {
									this.setState({isSearchLoading: true});
									ProfileActions.skillsSearch(this.state.accountId, query,
										(data) => {
											this.setState({search: data, isSearchLoading: false});
										}
									);
								}}
								onChange={(value) => this.handleSkillMergeChange(value)}
								labelKey="title"
								options={this.state.search}

							/>
						</div>
						:
						<div>
							<h5>{this.state.merge.title}</h5>
							<div>{Utils.elipsis(this.state.merge.description, 200)}</div>
						</div>
					}
				</div>

			</FormFields.Model>
		);
	}

	renderStatus() {

		if (SessionStore.isManagerRole()) {
			return (
				<div className={"float-right btn-group pill " + this.state.skill.status}>
					<button className={"btn btn-secondary btn-sm dropdown-toggle " + this.getClassFromStatus(this.state.skill.status)} type="button" data-toggle="dropdown" >
						{Utils.titleCase(this.state.skill.status)}
					</button>
					<div className="dropdown-menu">
						<button className="dropdown-item" target="_blank" onClick={(e) => this.statusToggle(e, 'PENDING')}>Mark as Pending</button>
						<button className="dropdown-item" target="_blank" onClick={(e) => this.statusToggle(e, 'APPROVED')}>Mark as Approved</button>
						<div className="dropdown-divider"></div>
						<button className="dropdown-item" target="_blank" onClick={(e) => this.delete()}>Delete skill</button>
						<button className="dropdown-item" target="_blank" onClick={(e) => this.setState({showMerge: true})}>Merge with another skill</button>
					</div>
				</div>
			);
		} else {
			return (
				<div className={"float-right btn-group pill " + this.state.skill.status}>
					<button className={"btn btn-secondary btn-sm " + this.getClassFromStatus(this.state.skill.status)} type="button" data-toggle="dropdown" >
						{Utils.titleCase(this.state.skill.status)}
					</button>

				</div>
			);
		}

	}

	getClassFromStatus(status) {
		if (status === 'DELETED') return 'btn-danger';
		if (status === 'PENDING') return 'btn-danger';
		if (status === 'MERGED') return 'btn-warning';
		return 'btn-default';
	}

	render() {

		if (this.state.skill == null) return null;
		var markdown = new Remarkable();

		let topMenuItems = (
			<React.Fragment>
				<TopMenuItem label={"/ " + this.state.skill.title} to={Routes.skillsEdit(this.state.skill.id)} active={true} hidden={!this.state.skill.title} />
				<TopMenuItem label="Talent" to={Routes.skillsTalent(this.state.skill.id)} hidden={!this.state.skill.title} />
				{SessionStore.getAccount().projectsEnabled ? <TopMenuItem label="Availability" to={Routes.skillsTalentAvailability(this.state.skill.id)} hidden={!this.state.skill.title} /> : null }
			</React.Fragment>
		);

		return(

			<SectionBody className="skill-edit" topMenuItems={topMenuItems}
				onNotificationClose={() => this.setState({errors: null, messages: null})}
				errors={this.state.errors} messages={this.state.messages} >

				{this.renderEdit()}
				{this.renderMerge()}

				<div className="row">

					<div className="col">

						<div className="skill-header">
							<h1>{this.state.skill.title}</h1>
							{this.state.skill.category ? <h4>{this.state.skill.category}</h4> : <h4 className="placeholder">No category</h4>}
						</div>

						<SkillExperts skillId={this.state.skillId} />

						<div className="skill-description">
							{this.state.skill.description ?
								<p dangerouslySetInnerHTML={{ __html: markdown.render(this.state.skill.description)}} /> :
								<p>{Utils.loremIpsum()}</p>
							}
						</div>

					</div>
					<div className="col-md-3">
						{SessionStore.isManagerRole() ?
							<div>
								<Glyphicon title="Edit" name="pencil" onClick={(e) => this.setState({showEdit: true})} />

								{this.renderStatus()}
							</div>
							:
							<div>
								<Glyphicon title="Edit" name="pencil" onClick={(e) => this.setState({showEdit: true})} />
								<Glyphicon name="circle-info" />
								{this.renderStatus()}
							</div>
						}

						<hr/>

						{this.state.skill.status === 'PENDING' ? <div className="error">Pending Approval</div> : null}
						{this.state.skill.status === 'MERGED' ? <div className="error">Merged</div> : null}
						{this.state.skill.status === 'MERGED' ? <div className="placeholder">This skill has been merged with another and is no longer valid</div> : null}

						<SkillChat skillId={this.state.skillId} />

					</div>
				</div>

			</SectionBody>
		);
	}

}