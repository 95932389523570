import React from 'react';
import * as FormFields from "components/forms/FormFields"
import * as Utils from "components/general/Utils"
export default class ProjectEditForm extends React.Component {

	constructor(props) {
		super();
		this.state = { data: (props.data ? props.data : this.emptyProject()), errors: null};
	}

	emptyProject() {
		return {
			id: null, title: null, description: null, organisation: null,
			location: null, externalId: null, startDate: this.getTodaysDate(), endDate: null
		}
	}

	componentWillUpdate(nextProps, nextState) {
		if (nextProps.data && nextProps.data.id !== this.state.data.id) {
			this.setState({ data: {...nextProps.data} });
		}
	}

	handleChange(event) {
		var project = this.state.data;
		project[event.target.name] = event.target.value;
		this.setState({data: project});

	}

	handleDateChange(name, date) {
		var project = this.state.data;
		project[name] = Utils.formatDateISO(date);
		this.setState({data: project});

	}

	handleDelete() {
		this.props.onDelete();
	}

	getTodaysDate() {
		return Utils.formatDateISO(new Date());
	}

	render() {

		var statusOptions = [
			{label:'Proposed', value:'Proposed'},
			{label:'Approved', value:'APPROVED'},
			{label:'Cancelled', value:'CANCELLED'}
		];

		return(

			<FormFields.Model title={this.props.title} isOpen={this.props.isOpen}
				className="modal-lg" onConfirm={(e) => this.props.onConfirm(this.state.data)}
				onCancel={(e) => this.props.onCancel()}
				extraButtons={<button className="btn" onClick={(e) => this.handleDelete()}>Delete</button>}
				onOpened={() => this.setState({data: (this.props.data ? {...this.props.data} : this.emptyProject())})}
				>

				<FormFields.Text title="Title" name="title" value={this.state.data.title} onChange={(e) => this.handleChange(e)} mandatory={true} placeholder="The title of the project"/>
				<FormFields.Text title="Organisation" name="organisation" value={this.state.data.organisation} onChange={(e) => this.handleChange(e)} mandatory={true} placeholder="Client or customer name" />

				<FormFields.Money name="budget" title="Budget" value={this.state.data.budget} onChange={(e) => this.handleChange(e)} placeholder="0.00" />


				<FormFields.TextArea title="Description" name="description" value={this.state.data.description} onChange={(e) => this.handleChange(e)} rows={5} placeholder="Describe the orgainisation, the objectives of the project, the approach and the measures of success."/>

				<FormFields.Select name="status" title="Status"  value={this.state.data.status}
					options={statusOptions} onChange={(e) => this.handleChange(e)}
					valueSelector={(option) => option.value} labelSelector={(option) => option.label} />

				<FormFields.Text name="code" title="Project Id" value={this.state.data.code} onChange={(e) => this.handleChange(e)} placeholder="Project Identifier" helpText="Customer Project Identifier or reference number"/>

				<FormFields.Text name="externalId" title="Reference Id" value={this.state.data.externalId} onChange={(e) => this.handleChange(e)} placeholder="External system ID" helpText="Optional external system reference ID. Used for syncronising data."/>

				<FormFields.Text name="location" title="Location" value={this.state.data.location} onChange={(e) => this.handleChange(e)} placeholder="Project location"/>

			</FormFields.Model>

		);
	}
}
