import * as Http from "./Http";
import * as Dto from "./Dto";
import * as Utils from "components/general/Utils";


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

T I M E S H E E T S

* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function settings(accountId: number, userId: number, success: Http.SuccessResponse<Dto.TimesheetSettingsDto>, failure: Http.FailureResponse) {
	Http.get<Dto.TimesheetSettingsDto>( "/api/accounts/" + accountId + "/users/" + userId + "/timesheets/settings").then(success).catch(failure);
}

export function holidays(accountId: number, success: Http.SuccessResponse<Dto.PagedListDto<Dto.HolidayDto>>, failure: Http.FailureResponse) {
	Http.get<Dto.PagedListDto<Dto.HolidayDto>>( "/api/accounts/" + accountId + "/timesheets/holidays").then(success).catch(failure);
}

export function holidayCreate(accountId: number, form: Dto.HolidayForm, success: Http.SuccessResponse<Dto.PagedListDto<Dto.HolidayDto>>, failure: Http.FailureResponse) {
	Http.post<Dto.HolidayForm, Dto.PagedListDto<Dto.HolidayDto>>( "/api/accounts/" + accountId + "/timesheets/holidays/create", form).then(success).catch(failure);
}


export function holidayDelete(accountId: number, holidayId: number, success: Http.SuccessResponse<Dto.PagedListDto<Dto.HolidayDto>>, failure: Http.FailureResponse) {
	Http.deleteElement<Dto.PagedListDto<Dto.HolidayDto>>( "/api/accounts/" + accountId + "/timesheets/holidays/" + holidayId).then(success).catch(failure);
}

export function leaveTypes(accountId: number, success: Http.SuccessResponse<Dto.PagedListDto<Dto.LeaveTypeDto>>, failure: Http.FailureResponse) {
	Http.get<Dto.PagedListDto<Dto.LeaveTypeDto>>( "/api/accounts/" + accountId + "/timesheets/leave-types").then(success).catch(failure);
}

export function leaveTypesSave(accountId: number, leaveTypeId: number, form: Dto.LeaveTypeForm, success: Http.SuccessResponse<Dto.PagedListDto<Dto.LeaveTypeDto>>, failure: Http.FailureResponse) {
	Http.post<Dto.LeaveTypeForm, Dto.PagedListDto<Dto.LeaveTypeDto>>( "/api/accounts/" + accountId + "/timesheets/leave-types/" + leaveTypeId, form).then(success).catch(failure);
}

export function leaveTypeCreate(accountId: number, form: Dto.LeaveTypeForm, success: Http.SuccessResponse<Dto.PagedListDto<Dto.LeaveTypeDto>>, failure: Http.FailureResponse) {
	Http.post<Dto.LeaveTypeForm, Dto.PagedListDto<Dto.LeaveTypeDto>>( "/api/accounts/" + accountId + "/timesheets/leave-types/create", form).then(success).catch(failure);
}

export function leaveTypeDelete(accountId: number, leaveTypeId: number, success: Http.SuccessResponse<Dto.PagedListDto<Dto.LeaveTypeDto>>, failure: Http.FailureResponse) {
	Http.deleteElement<Dto.PagedListDto<Dto.LeaveTypeDto>>( "/api/accounts/" + accountId + "/timesheets/leave-types/" + leaveTypeId).then(success).catch(failure);
}

export function timesheets(accountId: number, page, success, failure) {
	Http.get( "/api/accounts/" + accountId + "/timesheets/page/" + page).then(success).catch(failure);
}

export function timesheetsCreateDrafts(accountId: number, success, failure) {
	Http.post( "/api/accounts/" + accountId + "/timesheets/create", {}).then(success).catch(failure);
}

export function timesheetsUntilWithFilter(accountId: number, page: number, toDate: string, filters: {name: string, value: string}[], success: Http.SuccessResponse<Dto.TimesheetDto>, failure: Http.FailureResponse) {
	if (!toDate) return timesheets(accountId, page, success, failure);

	let filterString = "";
	filters.forEach(filter => filterString = filterString + "&" + filter.name + "=" + encodeURIComponent(filter.value) );

	Http.get<Dto.TimesheetDto>( "/api/accounts/" + accountId + "/timesheets/page/" + page + "?to=" + toDate + filterString).then(success).catch(failure);
}

export function timesheetsUntil(accountId: number, page: number, toDate: string, success: Http.SuccessResponse<Dto.TimesheetDto>, failure: Http.FailureResponse) {
	timesheetsUntilWithFilter(accountId, page, toDate, [], success, failure);
}

export function timesheetsForUser(accountId: number, userId: number, page: number, success: Http.SuccessResponse<Dto.TimesheetDto>, failure: Http.FailureResponse) {
	Http.get<Dto.TimesheetDto>( "/api/accounts/" + accountId + "/users/" + userId + "/timesheets/page/" + page).then(success).catch(failure);
}

export function timesheetCurrent(accountId: number,  userId: number, success: Http.SuccessResponse<Dto.TimesheetDto>, failure: Http.FailureResponse) {
	Http.get<Dto.TimesheetDto>( "/api/accounts/" + accountId + "/users/" + userId + "/timesheets/today").then(success).catch(failure);
}


export function timesheetForDate(accountId: number, userId: number, date: Date, success: Http.SuccessResponse<Dto.TimesheetDto>, failure: Http.FailureResponse) {
	Http.get<Dto.TimesheetDto>( "/api/accounts/" + accountId + "/users/" + userId + "/timesheets/" + Utils.formatDateISO(date)).then(success).catch(failure);
}

export function timesheetSave(accountId: number, userId, timesheet: Dto.TimesheetForm, success: Http.SuccessResponse<Dto.TimesheetDto>, failure: Http.FailureResponse) {
	Http.post<Dto.TimesheetForm, Dto.TimesheetDto>( "/api/accounts/" + accountId + "/users/" + userId + "/timesheets", timesheet).then(success).catch(failure);
	//Http.postJsonNative("/api/accounts/" + accountId + "/users/" + userId + "/timesheets", timesheet, success, failure);
}

export function timesheetApprove(accountId: number, timesheetId: number, success, failure) {
	Http.post( "/api/accounts/" + accountId + "/timesheets/" + timesheetId + "/approve", "APPROVE").then(success).catch(failure);
	//Http.postJsonNative("/api/accounts/" + accountId + "/users/" + userId + "/timesheets", timesheet, success, failure);
}

export function timesheetUnapprove(accountId: number, timesheetId: number, success, failure) {
	Http.post( "/api/accounts/" + accountId + "/timesheets/" + timesheetId + "/draft", "DRAFT").then(success).catch(failure);
	//Http.postJsonNative("/api/accounts/" + accountId + "/users/" + userId + "/timesheets", timesheet, success, failure);
}

export function timesheetReject(accountId: number, timesheetId: number, success, failure) {
	Http.post( "/api/accounts/" + accountId + "/timesheets/" + timesheetId + "/reject", "REJECT").then(success).catch(failure);
}

export function timesheetLogs(accountId: number, timesheetId: number, success: Http.SuccessResponse<Dto.PagedListDto<Dto.TimesheetLogDto>>, failure: Http.FailureResponse) {
	Http.get<Dto.PagedListDto<Dto.TimesheetLogDto>>( "/api/accounts/" + accountId + "/timesheets/" + timesheetId + "/logs").then(success).catch(failure);
}

export function timesheetNotifySubscribers(accountId: number, timesheetId: number, success: Http.SuccessResponse<Dto.PagedListDto<Dto.TimesheetLogDto>>, failure: Http.FailureResponse) {
	Http.post<{}, Dto.PagedListDto<Dto.TimesheetLogDto>>( "/api/accounts/" + accountId + "/timesheets/" + timesheetId + "/notify", {}).then(success).catch(failure);
}

export function timesheetSubscribers(accountId: number, timesheetId: number, success: Http.SuccessResponse<Dto.PagedListDto<Dto.SubscriberDto>>, failure: Http.FailureResponse) {
	Http.get<Dto.PagedListDto<Dto.SubscriberDto>>( "/api/accounts/" + accountId + "/timesheets/" + timesheetId + "/subscribers").then(success).catch(failure);
}

export function timesheetBySubsciberToken(token: string) {
	return Http.get<Dto.TimesheetSubscriptionDto>( "/api/timesheets/subscription/" + token);
}

export function timesheetAcceptBySubsciberToken(token: string, form: Dto.SubscriberReviewForm) {
	return Http.post<Dto.SubscriberReviewForm, Dto.TimesheetSubscriptionDto> ( "/api/timesheets/subscription/" + token, form);
}