import React from 'react';

import Routes from "actions/Routes"
import * as AccountActions from "actions/AccountActions"
import SessionStore from '../../store/SessionStore';
import SectionBody from 'components/layouts/SectionBody'

import '../layouts/Layout.css';
import { Link } from 'react-router-dom';


export default class LoginPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {errors: null, messages: null};
		this.reload();
	}

	reload() {

		if(!SessionStore.isRootRole()) {
			this.props.history.push(Routes.home());
		} else {

			AccountActions.settingsForAccount(this.props.match.params.accountId,
				(data) => this.setState({settings: data}),
				(errors) => this.setState({errors: errors})
			);

		}

	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	render() {
		return <SectionBody className="masquerade" onNotificationClose={() => this.setState({errors: null, messages: null})}
			errors={this.state.errors} messages={this.state.messages} >

			Masquerade
			<Link to={Routes.home}>Home</Link>

		</SectionBody>
	}

}