import React from 'react';
import Routes from "actions/Routes"
import { Link } from "react-router-dom"
import * as FormFields from "components/forms/FormFields"
import * as Utils from "components/general/Utils"
import Glyphicon from "components/general/Glyphicon"
import SessionStore from "store/SessionStore"

export default class TalentSkill extends React.Component {

	constructor(props) {
		super(props);
		this.state = {profile: props.profile, user: props.user, skill: props.skill};
	}

	unedit() {
		this.setState({editType: null});
	}

	renderTags(tags) {

		if (!SessionStore.isManagerRole()) return null;

		if (!tags) return null;

		let output = tags.map((tag) => {
				return <button key={tag.id} className="user-tag" style={{backgroundColor: tag.colour}}>{output}{tag.label}</button>;
			}
		);

		return <div className="user-tags">{output}</div>;

	}

	render() {

		var props = this.state;

		return(

			<div className="talent-skill">

				<div className="thumbnail">
					<Link to={Routes.userProfile(props.user.id)}><img className="profile-thumbnail" src={props.user.photoThumbnailUrl ? props.user.photoThumbnailUrl : "/media/user@4x.png"} alt="User"/></Link>
					<div className="strength"><FormFields.FiveStarsDisplayOnly value={props.skill.strength} /></div>
					<div className="">

						{props.user.status === 'ACTIVE' ?
							<div className="user-status enum-type">&nbsp;</div> :
							<div className={"user-status enum-type " + props.user.status}>{Utils.titleCase(props.user.status)}</div>
						}

					</div>
				</div>

				<div className="details">
					<div className="recent-talent tile" key={"user-" + props.user.id}>

						<h5 className="name" title={props.user.firstname + " " + props.user.surname}>
							<Link to={Routes.userProfile(props.user.id)}>
								<div className="firstname">{props.user.firstname}</div> <div className="surname">{props.user.surname}</div>
							</Link>
						</h5>
						<div className="headline" title={props.profile.headline}>{props.profile.headline}</div>
						<small className="summary" >{props.profile.summary}</small>
					</div>
				</div>

				<div className="extras">
					<div className="available-when">&nbsp;</div>
					<div className="tags">
						{this.renderTags(props.profile.user.tags)}
					</div>
				</div>

				{SessionStore.isManagerRole() ?
					<div className="tools">
						<Link to={Routes.userProfile(props.profile.user.id)} className="btn btn-icon glyphicon-button"><Glyphicon name="id-badge" /></Link>
						<Link to={Routes.userEdit(props.profile.user.id)} className="btn btn-icon glyphicon-button"><Glyphicon name="user" /></Link>
					</div>
					: null
				}

			</div>

		);
	}

}