import React from 'react';
import { Link } from "react-router-dom"

export default function Footer(props) {
	return (
		<footer>
			<div className="footer-links container">

				<div className="grid">

					<nav>
						<Link to="/splash" className="logo"></Link>
						<small>TalentedTeam™ is a Trade Mark of RoarEnergy Group Pty. Ltd. and has been developed in partnership with Exco Partners Pty. Ltd.</small>
						<small><a href="https://roarenergy.com">RoarEnergy</a> ACN: 147 462 315, ABN: 98 147 462 315</small>
						<small><a href="https://excopartners.com">Exco Partners</a> ACN: 634 654 701, ABN: 20 634 654 701</small>
					</nav>

					<nav>
						<h5>Privacy and Terms</h5>
						<ul>
							<li><a target="_blank" rel="noopener noreferrer" href="https://site.talentedteam.com/privacy/">Privacy</a></li>
							<li><a target="_blank" rel="noopener noreferrer" href="https://site.talentedteam.com/terms/">Terms</a></li>
							<li><a target="_blank" rel="noopener noreferrer" href="https://site.talentedteam.com/about/">About</a></li>
						</ul>
					</nav>
					<nav>
						<h5>Help</h5>
						<ul>
							<li><a target="_blank" rel="noopener noreferrer" href="https://site.talentedteam.com/documentation/">Documentation</a></li>
							<li><a target="_blank" rel="noopener noreferrer" href="https://site.talentedteam.com/documentation/">Talent</a></li>
							<li><a target="_blank" rel="noopener noreferrer" href="https://site.talentedteam.com/documentation/profiles/">Profiles</a></li>
							<li><a target="_blank" rel="noopener noreferrer" href="https://site.talentedteam.com/documentation/templates/">Templates</a></li>
							<li><a target="_blank" rel="noopener noreferrer" href="https://site.talentedteam.com/documentation/skills/">Skills</a></li>
						</ul>
					</nav>

				</div>
			</div>

		</footer>
	);
}