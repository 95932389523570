import React from 'react';
import { Link } from "react-router-dom"

import Routes from "actions/Routes"
import SessionStore from "store/SessionStore"
import ReactGA from 'react-ga';
import Footer from '../layouts/Footer';
import Glyphicon from "components/general/Glyphicon"

import '../layouts/Layout.css';

export default class SplashPage extends React.Component {

	constructor(props) {
		super(props);

		this.state = {};
	}

	componentWillMount() {

		ReactGA.pageview(Routes.splash());	//analytics

	}

	render() {

		return (
			<div>
				<section className="splash">

					<div id="carouselIndicators" className="carousel slide" data-ride="carousel">
						<ol className="carousel-indicators">
							<li data-target="#carouselIndicators" data-slide-to="0" className="active"></li>
							<li data-target="#carouselIndicators" data-slide-to="1"></li>
							<li data-target="#carouselIndicators" data-slide-to="2"></li>
						</ol>
						<div className="carousel-inner">
							<div className="carousel-item active">
								<img className="d-block w-100" src="/media/team-around-table.jpg" alt="First slide" />
								<div className="carousel-caption">
									<h3>Do you truly understand the talent in your team?</h3>
									<p>As your organisation grows, keeping track of the abilities in your team can be difficult. For individuals, being able to promote new skills is also important to help grow and build your teams experience and careers. Talented Team lets you search, rank and categorise your team's skills and ability giving quick and effective access to your organisation's talents.</p>
									<Link className="btn btn-default" to={Routes.accountCreate()}>Create Account</Link>

								</div>
							</div>
							<div className="carousel-item">
								<img className="d-block w-100" src="/media/team-around-table.jpg" alt="Second slide" />
								<div className="carousel-caption">
									<h3>Professional and consulting services profile management</h3>
									<p>Managing and communicating the professional profile of your staff in consulting and professional services environments doesn't need to be hard anymore. Simple, self-service, consistent template based creation for staff profiles and CV. Perfect for professional tender and proposal responses.</p>
									<Link className="btn btn-default" to={Routes.accountCreate()}>Create Account</Link>

								</div>
							</div>
							<div className="carousel-item">
								<img className="d-block w-100" src="/media/team-sholder-to-sholder.jpg" alt="Second slide" />
								<div className="carousel-caption">
									<h3>Looking for a smarter way to profile your organisation's skills?</h3>
									<p>People are the greatest assets to an organisations performance, agility and responsiveness. Without really understanding the skills in your team, your organisation and your people may be missing an opportunity to shine.</p>
									<Link className="btn btn-default" to={Routes.accountCreate()}>Create Account</Link>

								</div>
							</div>
						</div>
						<a className="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
							<span className="carousel-control-prev-icon" aria-hidden="true"></span>
							<span className="sr-only">Previous</span>
						</a>
						<a className="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
							<span className="carousel-control-next-icon" aria-hidden="true"></span>
							<span className="sr-only">Next</span>
						</a>
					</div>

					<nav>
						<button className="logo" title="Logo"><div></div></button>
						<ul className="splash-menu">
							{SessionStore.isAuthenticated() ? <li><Link to={Routes.home()} >Home</Link></li> : null}
							<li><Link to={Routes.login()} >Sign-in</Link></li>
							<li><Link to={Routes.accountCreate()} >Create Account</Link></li>
						</ul>
					</nav>

					<div className="marketing">

						<ValueProp className="shade">
							<div className="col-md-6">
								<img src="/media/favicon@8x.png" style={{width: "50px"}} alt="Share Knowledge" />
								<small>Your Talent</small>
								<h2>Understand your competitive advantage</h2>
								<p className="quote">"Knowledge has to be improved, challenged, and increased constantly, or it vanishes."</p>
								<Link className="btn btn-default" to={Routes.accountCreate()}>Create Account</Link>
							</div>
							<div className="col-md-6">
								<p>If a part of your competitive advantage is the talent within your team, you need the tools to understand, model, map and manage this talent to ensure your organisation is fulfilling its potential.</p>
								<p>As your organisation grows, keeping track of the abilities in your team can be difficult. For individuals, being able to promote new skills is also important to help grow and build your teams experience and careers. Talented Team lets you search, rank and categorise your team's skills and ability giving quick and effective access to your organisation's talents.</p>
								<p><a href="http://site.talentedteam.com/">Learn more...</a></p>
							</div>
						</ValueProp>

						<ValueProp>
							<div className="col">

								<div className="key-features">
									<div className="key-feature">
										<Glyphicon name="id-badge" className="huge blue" />
										<h3>Profiles</h3>
										<div className="info">Mantain consistent and high quality professional staff profiles. Ideal for proposals and tender documents.</div>
									</div>

									<div className="key-feature">
										<Glyphicon name="settings" className="huge blue" />
										<h3>Skills</h3>
										<div className="info">Understand where you have pockets of skills and where you need up-lift and developent.</div>
									</div>

									<div className="key-feature">
										<Glyphicon name="education" className="huge blue" />
										<h3>Knowledge</h3>
										<div className="info">Share knowledge and raise the profile of throught and capability leaders in your business.</div>
									</div>

									<div className="key-feature">
										<Glyphicon name="tasks" className="huge blue" />
										<h3>Projects</h3>
										<div className="info">Keep track of project assignments, staff availability and project spend.</div>
									</div>

									<div className="key-feature">
										<Glyphicon name="calendar" className="huge blue" />
										<h3>Timesheets</h3>
										<div className="info">Centralise timesheet management with staff assignments, availability and resource management.</div>
									</div>

									<div className="key-feature">
										<Glyphicon name="briefcase-alt" className="huge blue" />
										<h3>Jobs</h3>
										<div className="info">Promote work to your internal workforce to encourage growth, development and opportunity from within.</div>
									</div>

									<div className="key-feature">
										<Glyphicon name="stats-circle" className="huge blue" />
										<h3>Insight</h3>
										<div className="info">Create insight, intelligence and confidence into your workforce and it's ability. An inventory of skill, capability and talent.</div>
									</div>
									
									<div className="key-feature">
										<Glyphicon name="cluster" className="huge blue" />
										<h3>Integration</h3>
										<div className="info">Integrate with anything. Full and simple integration support. No data islands for your ERP, HRMS and other data sources.</div>
									</div>


								</div>

							</div>
						</ValueProp>

						<ValueProp className="shade">
							<div className="col-md-6">
								<img src="/media/favicon@8x.png" style={{width: "50px"}} alt="Share Knowledge" />
								<small>Your Talent</small>
								<h2>Professional and consulting services profile management</h2>
								<p className="quote">"Knowledge has to be improved, challenged, and increased constantly, or it vanishes."</p>
								<Link className="btn btn-default" to={Routes.accountCreate()}>Create Account</Link>
							</div>
							<div className="col-md-6">
								<p>Managing and communicating the professional profile of your staff in consulting and professional services environments doesn't need to be hard anymore.</p>
								<p>TalentedTeam profiles a simple, self-service interface to allow staff to manage and update their skills and profile themeselves as they complete jobs and as their skills develop.</p>
								<p>Customisable output templates ensure the profile output is consistent and always aligned to your organisations brand making it an ideal solition for professional tender and proposal responses.</p>
								<p>Support for multiple templates also means you can easily maintain a variety of profiles looks. A one pager profile, a long form profile or a protfolio style profile, for example, can all be generated from the same data.</p>
								<p><a href="http://site.talentedteam.com/">Learn more...</a></p>
							</div>
						</ValueProp>

						<ValueProp className="well-padded">

							<div className="col-md-6 well-padded">
								<img src="/media/favicon@8x.png" style={{width: "50px"}} alt="Share Knowledge" />
								<small>Your Knowledge</small>
								<h2>Share the knowledge in your business</h2>
								<p>Allow your team to grow and learn from the community of incredible talent and knowledge the exists within your organisation. Identify experts and empower them to be thought-leaders. Build a culture of collaboration and sharing to lift the capability of everyone.</p>
								<p><a href="http://site.talentedteam.com/">Learn more...</a></p>
							</div>

						</ValueProp>

						<ValuePropWithTitle className="shade" title="Search skills, find experts, present talent" subtitle="Your skills" quote="“An investment in knowledge always pays the best interest.” Benjamin Franklin">
							<div className="col-md-6">
								<p>Talented Team indexes your organisation's talent, skills, experience and education such that you can profile, map and search the data to find pockets of brilliance. Find the experts, asks them questions. Find the talent you need for a particular project. Explore the domains where your organisation needs development.</p>
							</div>
							<div className="col-md-6">
								<p>Present the skills our your team in a consistent and professional way using a range of pre-built and custom templates. Your customers will appreciate the difference with a standardised and easy to read set of team profiles. Take the pain out of managing staff profiles for customer proposals and offerings.</p>
							</div>
						</ValuePropWithTitle>

						<ValueProp className="well-padded">
							<div className="col-md-6 well-padded">
								<img src="/media/favicon@8x.png" style={{width: "50px"}} alt="Testimonials" />
								<h2>Testimonials</h2>
								<p className="quote">"Never before have we been able to so effectively align the skills within our team to the needs of our projects and clients." Pina Starchenko, Business Analysis Practice Manager - Consulting Services, RXP</p>
							</div>

						</ValueProp>

					</div>

				</section>

				<Footer />

			</div>
		);
	}

}


export function ValueProp(props) {
	return (
		<div className={"value-prop row" + (props.className ? " " + props.className : "")}>
			{props.children}
		</div>
	);
}

function ValuePropWithTitle(props) {
	return (
		<div >
			<div className={"value-prop row title" + (props.className ? " " + props.className : "")}>
				<div className="col">
					<img src="/media/favicon@8x.png" style={{width: "50px"}} alt="Share Knowledge" />
					{props.subtitle ? <small>{props.subtitle}</small> : null }
					<h2>{props.title}</h2>
					{props.quote ? <p className="quote">{props.quote}</p> : ""}
				</div>
			</div>
			<div className={"value-prop row" + (props.className ? " " + props.className : "")}>
				{props.children}
			</div>
		</div>
	);
}