import React from 'react';

import SessionStore from "store/SessionStore"
import ErrorMessages from "components/general/ErrorMessages"
import * as TalentActions from "actions/TalentActions"
import * as FormFields from "components/forms/FormFields"
import Spinner from "components/general/Spinner"
import ProfileSearchResult from "./ProfileSearchResult"
import { TopMenu } from '../layouts/navigation/TopMenu';


export default class SearchResults extends React.Component {

	constructor(props) {
		super();

		this.state = { accountId: SessionStore.getAccountId(), results: null, searching: false, errors: null };

		//this.handleResultsChangeListener = this.handleResultsChange.bind(this);
	}

	componentWillMount() {

		if (this.props.location != null && this.props.location.state != null && this.props.location.state.query != null) {
			this.handleSearch(this.props.location.state.query);
		}

	}

	componentWillReceiveProps(nextProps, nextState){
		if (nextProps.location != null && nextProps.location.state != null  && nextProps.location.state.query != null) {
			this.handleSearch(nextProps.location.state.query);
		}
	}

	handleSearch(query) {

		this.setState({searching: true, results: null});
		TalentActions.talentSearch(this.state.accountId, query,
			(results) => this.setState({results: results, searching: false}),
			(errors) => this.setState({errors: errors, searching: false})
		);
	}

	loadPreviousPage() {

		this.setState({searching: true, results: null});
		TalentActions.talentSearchPaged(this.state.accountId, this.state.results.query, this.state.results.page - 1,
			(results) => this.setState({results: results, searching: false}),
			(errors) => this.setState({errors: errors, searching: false})
		);

	}

	loadNextPage() {

		this.setState({searching: true, results: null});
		TalentActions.talentSearchPaged(this.state.accountId, this.state.results.query, this.state.results.page + 1,
			(results) => this.setState({results: results, searching: false}),
			(errors) => this.setState({errors: errors, searching: false})
		);

	}

	renderResults() {

		if (this.state == null || this.state.results == null || this.state.results.profiles.length === 0) return (<tr><td colSpan={4}>none</td></tr>);

		return this.state.results.profiles.map((result) => {

			return (
				<ProfileSearchResult profile={result.profile} summary={result.summary} experience={result.experience} skills={result.skills} />
			);
		});
	}

	render() {
		return(

			<section>


				<div className="row">

					<div className="col">

						<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages} />

						<TopMenu />

						<h1>Search Results</h1>
						{this.state && this.state.results && this.state.results.profiles.length > 0 ?

							<div className="results-table">

								<FormFields.Pagination page={this.state.results.page} total={this.state.results.pages} onPreviousClicked={() => this.loadPreviousPage()} onNextClicked={() => this.loadNextPage()} />

								<table className="table table-striped talents-table">
									<thead>
										<tr>
											<th colSpan="2">

											</th>
											<th width="15%"></th>
										</tr>
									</thead>
									<tbody>
										{this.renderResults()}
									</tbody>
								</table>

								<hr/>
								<FormFields.Pagination page={this.state.results.page} total={this.state.results.pages} onPreviousClicked={() => this.loadPreviousPage()} onNextClicked={() => this.loadNextPage()} />

							</div>
							: null
						}

						{this.state && this.state.results && this.state.results.profiles.length === 0 ?
								<div>
									<h2>No results found</h2>

								</div>
								:null
						}
						{this.state.searching ? <Spinner /> : null}
					</div>

				</div>

			</section>
		);
	}
}
