import React from 'react';

import SessionStore from "store/SessionStore"
import Routes from "actions/Routes"
import ErrorMessages from "components/general/ErrorMessages"
import * as TalentActions from "actions/TalentActions"
import * as FormFields from "components/forms/FormFields"
import Glyphicon from "components/general/Glyphicon"
import Spinner from "components/general/Spinner"
import ProfileSearchResult from "./ProfileSearchResult"
import { TopMenu } from 'components/layouts/navigation/TopMenu';
import ReactGA from 'react-ga';
import logger from "actions/Logger";

export default class AdvancedSearchPage extends React.Component {

	constructor(props) {
		super();

		this.state = {
			accountId: SessionStore.getAccountId(),
			criteria: [this.getEmptyCriteria()],
			results: null,
			searching: false, errors: null
		};

	}

	componentDidMount() {
		window.scrollTo(0, 0);			//scroll to top
		ReactGA.pageview(Routes.talentSearchAdvanced());	//analytics
	}

	componentWillMount() {

		if (this.props.location && this.props.location.state && this.props.location.state.query) {

			var criteria = [{field:'', value: this.props.location.state.query, operator: 'AND'}];
			this.setState({searching: true, results: null, criteria: criteria, query: this.props.location.state.query});
			TalentActions.talentSearchAdvanced(this.state.accountId, criteria, 0,
				(results) => this.setState({results: results, searching: false}),
				(errors) => this.setState({errors: errors, searching: false})
			);

		}

	}

	componentWillUpdate(nextProps, nextState) {

		if (nextProps.location && nextProps.location.state && nextProps.location.state.query) {

			if (this.state.query !== nextProps.location.state.query) {

				var criteria = [{field:'', value: nextProps.location.state.query, operator: 'AND'}];
				this.setState({searching: true, results: null, criteria: criteria, query: nextProps.location.state.query});
				TalentActions.talentSearchAdvanced(this.state.accountId, criteria, 0,
					(results) => this.setState({results: results, searching: false}),
					(errors) => this.setState({errors: errors, searching: false})
				);

			}
		}
	}


	getEmptyCriteria() {
		return {field: '', value: null, operator: 'AND'};
	}

	handleCriteriaAdd() {
		var criteria = this.state.criteria;
		criteria.push(this.getEmptyCriteria());
		this.setState({criteria: criteria});
	}

	handleCriteriaDelete(index) {
		var criteria = this.state.criteria;
		criteria.splice(index, 1);
		this.setState({criteria: criteria});
	}

	handleCriteriaFieldChange(index, value) {
		var criteria = this.state.criteria;
		criteria[index].field = value;
		this.setState({criteria: criteria});
	}

	handleCriteriaValueChange(index, value) {
		var criteria = this.state.criteria;
		criteria[index].value = value;
		this.setState({criteria: criteria});
	}

	handleSearch() {
		logger.debug("renderResults.start", this.state);
		this.setState({searching: true, results: null});
		TalentActions.talentSearchAdvanced(this.state.accountId, this.state.criteria, 0,
			(results) => {
				this.setState({results: results, searching: false});
				logger.debug("renderResults.start", this.state);
			},
			(errors) => this.setState({errors: errors, searching: false})
		);
	}

	loadPreviousPage() {

		this.setState({searching: true, results: null});
		TalentActions.talentSearchAdvanced(this.state.accountId, this.state.results.query, this.state.results.page - 1,
			(results) => this.setState({results: results, searching: false}),
			(errors) => this.setState({errors: errors, searching: false})
		);

	}

	loadNextPage() {

		this.setState({searching: true, results: null});
		TalentActions.talentSearchAdvanced(this.state.accountId, this.state.results.query, this.state.results.page + 1,
			(results) => this.setState({results: results, searching: false}),
			(errors) => this.setState({errors: errors, searching: false})
		);

	}
	renderResults() {

		logger.debug("renderResults", this.state);

		if (this.state == null || this.state.results == null || this.state.results.profiles.length === 0) return (<tr><td colSpan={4}>none</td></tr>);

		

		return this.state.results.profiles.map((result, i) => {

			return (
				<ProfileSearchResult key={i} profile={result.profile} summary={result.summary} experience={result.experience} skills={result.skills} />
			);
		});
	}

	render() {

		var criteria = this.state.criteria.map((value, i) => {
			return <SearchCriteria key={i} criteria={value} index={i} size={this.state.criteria.length} onDelete={(index) => this.handleCriteriaDelete(index)}
						onFieldChanged={(index, value) => this.handleCriteriaFieldChange(index, value)}
						onValueChanged={(index, value) => this.handleCriteriaValueChange(index, value)}
						onHandleSearch={(e) => this.handleSearch()}
					/>
		});

		return(

			<section>


				<div className="row">

					<div className="col">

						<TopMenu />

						<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages} />

						<h1>Advanced Search</h1>

						<div className="search-criteria-builder">
							{criteria}


							<Glyphicon name="plus" onClick={() => this.handleCriteriaAdd() } />
							<hr/>

							<button className="btn btn-default" onClick={() => this.handleSearch()}>Search</button> <button className="btn" onClick={() => this.setState({criteria:[this.getEmptyCriteria()]})}>Reset</button>

						</div>

						<hr/>

						{this.state && this.state.results && this.state.results.profiles.length > 0 ?

							<div className="results-table">

								<FormFields.Pagination page={this.state.results.page} total={this.state.results.pages} onPreviousClicked={() => this.loadPreviousPage()} onNextClicked={() => this.loadNextPage()} />

								<table className="table table-striped talents-table">
									<thead>
										<tr>
											<th colSpan="2">

											</th>
											<th width="15%"></th>
										</tr>
									</thead>
									<tbody>
										{this.renderResults()}
									</tbody>
								</table>

								<hr/>
								<FormFields.Pagination page={this.state.results.page} total={this.state.results.pages} onPreviousClicked={() => this.loadPreviousPage()} onNextClicked={() => this.loadNextPage()} />

							</div>
							: null
						}

						{this.state && this.state.results && this.state.results.profiles.length === 0 ?
								<div>
									<h2>No results found</h2>

								</div>
								:null
						}
						{this.state.searching ? <Spinner /> : null}

					</div>

				</div>

			</section>
		);
	}
}


class SearchCriteria extends React.Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {

		var criteriaOptions = [
			{label: 'Any field', value: ''},
			{label: 'Name', value: 'fullname'},
			{label: 'Headline', value: 'headline'},
			{label: 'Summary', value: 'summary'},
			{label: 'Experience', value: 'description'},
			{label: 'Experience Title', value: 'title'},
			{label: 'Experience Organisation', value: 'organisation'},
			{label: 'Education', value: 'education'},
			{label: 'Skills', value: 'skills'}
		]

		return(
			<div className="search-criteria">
				<div className="criteria">

					<div className="criteria-index">{this.props.index + 1}</div>
					<div className="criteria-field">
						<FormFields.Select options={criteriaOptions}
							onChange={(event) => this.props.onFieldChanged(this.props.index, event.target.value)}
							value={this.props.criteria.field} valueSelector={(option) => option.value}
							labelSelector={(option) => option.label}
						/>
					</div>
					<div className="criteria-value">
						<FormFields.Text className="inline" placeholder="search term" onChange={(event) => this.props.onValueChanged(this.props.index, event.target.value)}  value={this.props.criteria.value} onKeyPress={(e) => { if (e.key === 'Enter') this.props.onHandleSearch(e); }} />

					</div>
					<div className="criteria-operator">{this.props.index < this.props.size - 1 && this.props.size > 0 ? "AND" : ""}</div>
					<div className="criteria-actions">
						{this.props.size > 1 ? <Glyphicon name="bin" onClick={() => this.props.onDelete(this.props.index)} /> : null }
					</div>
				</div>

			</div>

		);
	}

}