import React from 'react';
import Routes from "actions/Routes";
import { Link } from "react-router-dom";
//import UserTagsPopup from "./UserTagsPopup";
import SessionStore from "store/SessionStore";
import * as Utils from "components/general/Utils";
import * as Icons from 'react-bootstrap-icons';
import { IconButton } from 'components/general/IconButton';
import * as FormFields from "components/forms/FormFields";
import { Availability } from './application/Availability';
import * as AccountActions from "actions/AccountActions";
import ErrorMessages from "components/general/ErrorMessages";
import * as Dto from "actions/Dto";

const EditTypes = {
	TAGS: "tags",
	AVAILABILITY: "availability"
}

interface TalentProps {
	profile: Dto.ProfileDto
	user: Dto.UserDto
	skill: Dto.SkillDto
	accountId: number
}

interface TalentState {
	profile: Dto.ProfileDto
	user: Dto.UserDto
	edit: Dto.UserDto|null
	skill: Dto.SkillDto
	accountId: number
	editType: string|null
	errors: Dto.ErrorDto[]
	messages: Dto.MessageDto[]
}

export default class Talent extends React.Component<TalentProps, TalentState> {

	constructor(props: TalentProps) {
		super(props);
		this.state = { 
			profile: props.profile, user: props.user, skill: props.skill, accountId: props.accountId,
			editType: null, errors: [], messages: [], edit: null
		};
	}

	unedit() {
		this.setState({editType: null});
	}


	renderTags(tags) {

		if (!tags) return null;

		let output = tags.map((tag) => {
				return <button onClick={() => this.setState({editType: EditTypes.TAGS})} key={tag.id} className="user-tag" style={{backgroundColor: tag.colour}}>{output}{tag.label}</button>;
			}
		);

		return <div className="user-tags">{output}</div>;

	}

	handleAvailabilityDateChange(availability: Date) {

		this.setState({errors:[], messages: []});

		var user = this.state.edit;
		if (user) {
			user.availability.offsetDate = availability; //Utils.formatDate(availability);
			user.availability.offsetDays = undefined;
			this.setState({edit: user});
		}

	}

	handleAvailabilityDaysChange(offsetDays: number) {

		this.setState({errors:[], messages: []});

		var user = this.state.edit;
		if (user) {
			user.availability.offsetDays = offsetDays;
			user.availability.offsetDate = undefined;
			this.setState({edit: user});
		}

	}

	handleAvailabilitySubmit(event) {
		let accountId = SessionStore.getAccountId();
		if (this.state.edit && accountId) {
			AccountActions.userAvailabilityUpdate(accountId, this.state.user.id, this.state.edit.availability,
				(user) => this.setState({user: user, editType: null, edit: null}),
				(errors) => this.setState({errors: errors})
			);
		}
	}

	edit(editType) {
		this.setState({edit: {...this.state.user}, editType: editType, errors: [], messages: []});
	}

	renderAvailabilityModal() {

		if (!this.state.editType) return null;

		return (
			<FormFields.Model title="Edit Availability" isOpen={this.state.editType != null && this.state.editType === EditTypes.AVAILABILITY}
				onHide={(e) => this.unedit()} onCancel={(e) => this.unedit()} onConfirm={(e) => this.handleAvailabilitySubmit(e)} >

				<Availability 
					onOffsetDateChanged={(offsetDate) => this.handleAvailabilityDateChange(offsetDate)} 
					onOffsetDaysChanged={(offsetDays) => this.handleAvailabilityDaysChange(offsetDays)}
					availability={this.state.user.availability} 
				/>

			</FormFields.Model>
		)
	}

	renderAvailability() {
		if (this.state.user.availability.type === Dto.AvailabilityType.NOTICE) {
			return (
				<>
					{this.state.user.availability.date ? <small>Available: <span>{Utils.dateLabel(Utils.parseDateTime(this.state.user.availability.date))}</span> notice</small> : <small>Available now</small>}
				</>
			)
		} else {
			return (
				<>
					{this.state.user.availability.date ? <small>Available: <span>{Utils.dateLabel(Utils.parseDateTime(this.state.user.availability.date))}</span></small> : <small>Available now</small>}
				</>
			)
		}
	}


	render() {

		var props = this.state;

		return(

			<div className="talent-skill">

				<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages} />

				<div className="thumbnail">
					<Link to={Routes.userProfile(props.user.id)}><img className="profile-thumbnail" src={props.user.photoThumbnailUrl ? props.user.photoThumbnailUrl : "/media/user@4x.png"} alt="User"/></Link>

					<div className="info">

						{props.user.status === 'ACTIVE' ?
							<div className="user-status enum-type">&nbsp;</div> :
							<div className={"user-status enum-type " + props.user.status}>{Utils.titleCase(props.user.status)}</div>
						}

					</div>
				</div>

				<div className="details">
					<div className="recent-talent tile" key={"user-" + props.user.id}>

						<h5 className="name" title={props.user.firstname + " " + props.user.surname}>
							<Link to={Routes.userProfile(props.user.id)}>
								<div className="firstname">{props.user.firstname}</div> <div className="surname">{props.user.surname}</div>
							</Link>
						</h5>
						<div className="headline" title={props.profile.headline}>{props.profile.headline}</div>
						<small className="summary" >{props.profile.summary}</small>
					</div>
				</div>

				<div className="extras">
					<div className="available-when" title="Available">
						{this.renderAvailability()}
					</div>
					<div className="tags">
						{ /* TAGS DISABLED

							this.renderTags(props.profile.user.tags) 
						
							<UserTagsPopup
								user={props.profile.user}
								key={props.profile.id} onSubmit={() => this.unedit()}
								accountId={props.accountId}
								isOpen={this.state.editType != null && this.state.editType === EditTypes.TAGS}
								onCancel={() => this.unedit()} />
							*/
						}
					</div>
				</div>

				{SessionStore.isManagerRole() ?
					<div className="tools">
						<Link to={Routes.userProfile(props.profile.user.id)} className="btn btn-icon glyphicon-button"><IconButton icon={<Icons.PersonBadge />} /></Link>
						<Link to={Routes.userEdit(props.profile.user.id)} className="btn btn-icon glyphicon-button"><IconButton icon={<Icons.PersonCircle />} /></Link>
						{this.state.user.status === 'CANDIDATE' || this.state.user.status === 'APPLICANT' || !SessionStore.isProjectsEnabled() ?
							<React.Fragment>
								<IconButton icon={<Icons.Calendar3 />} title="Edit Availability" className="edit-link" onClick={() => this.edit(EditTypes.AVAILABILITY)} />
								{this.renderAvailabilityModal()}
							</React.Fragment>
							: null
						}
						{this.state.user.status === 'ACTIVE' && SessionStore.isProjectsEnabled() ?
							<Link to={Routes.userAssignments(props.profile.user.id)} className="btn btn-icon glyphicon-button"><IconButton icon={<Icons.Briefcase />} /></Link>
							: null
						}
						
						{
						// TAGS DISABLED <IconButton icon="tag" title="Add Tags" className="edit-link" onClick={() => this.edit(EditTypes.TAGS)} />
						}
					</div>
					: null
				}
			</div>

		);
	}

}