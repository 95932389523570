import React from 'react';
import * as FormFields from "components/forms/FormFields"


export default class ContactDetailsStep extends React.Component {

	handleChange(event) {
		var user = this.props.user;
		user[event.target.name] = event.target.value;
		this.props.onChange(user);
	}

	handleChangeContact(event) {
		var user = this.props.user;
		user.contact[event.target.name] = event.target.value;
		this.props.onChange(user);
	}

	render() {

		if (this.props.step !== this.props.index) return null;

		return (

			<React.Fragment>

				<h4>Contact Details</h4>

				<div className="row">
					<div className="col">
					<FormFields.Text name="firstname" title="Firstname" value={this.props.user.firstname} onChange={(e) => this.handleChange(e)} />
					</div>
					<div className="col">
						<FormFields.Text name="surname" title="Surname" value={this.props.user.surname} onChange={(e) => this.handleChange(e)}/>
					</div>
				</div>

				{this.props.hidePassword ? null :
					<FormFields.Password name="password" title="Password" value={this.props.user.password } onChange={(e) => this.handleChange(e)} />
				}

				<hr/>

				<FormFields.Text name="email" title="Email" value={this.props.user.email } onChange={(e) => this.handleChange(e)} />
				<FormFields.Text name="phone" title="Phone" value={this.props.user.contact.phone} onChange={(e) => this.handleChangeContact(e)} />

				{this.props.hideAddress ? null :
					<React.Fragment>
						<hr/>
						<FormFields.Text name="street" title="Home Address" placeholder="123 Some Street" value={this.props.user.contact.address} onChange={(e) => this.handleChangeContact(e)} />
						<FormFields.Text name="city" title="City" placeholder="City" value={this.props.user.contact.city} onChange={(e) => this.handleChangeContact(e)}/>
						<div className="row">
							<div className="col">
								<FormFields.Text name="postCode" title="Post Code" placeholder="Postcode" value={this.props.user.contact.postCode} onChange={(e) => this.handleChangeContact(e)} />
							</div>
							<div className="col">
								<FormFields.Text name="state" title="State" placeholder="State" value={this.props.user.contact.state} onChange={(e) => this.handleChangeContact(e)} />
							</div>
							<div className="col">
								<FormFields.Text name="country" title="Country" placeholder="Country" value={this.props.user.contact.country} onChange={(e) => this.handleChangeContact(e)} />
							</div>
						</div>
					</React.Fragment>
				}

			</React.Fragment>

		);
	}

}