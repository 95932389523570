import React from 'react';

import SessionStore from "store/SessionStore"
import ErrorMessages from "components/general/ErrorMessages"
import * as FormFields from "components/forms/FormFields"
import * as AccountActions from "actions/AccountActions"
import Glyphicon from "components/general/Glyphicon"

import './Settings.css';

export default class Authentication extends React.Component {

	constructor(props) {
		super();
		this.state = {account: SessionStore.getAccount(), authentication: {}, authenticationEdit: {}, edit: null}
	}

	componentDidMount() {
		this.loadAuthenticationProvider();
	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	loadAuthenticationProvider() {
		AccountActions.authenticationSettings(SessionStore.getAccountId(),
			(data) => this.setState({authentication: data}),
			(errors) => this.setState({errors: errors})
		);
	}

	unedit() {
		this.setState({edit: null, authenticationEdit: {}});
	}

	edit() {
		this.setState({edit: "details", authenticationEdit: {...this.state.authentication}});
	}

	handleChange(event) {
		this.setState({errors:null, messages: null});
		var authenticationEdit = this.state.authenticationEdit;
		authenticationEdit[event.target.name] = event.target.value;
		this.setState({authenticationEdit: authenticationEdit});
	}

	handleSubmit(event) {
		AccountActions.authenticationUpdate(SessionStore.getAccountId(), this.state.authenticationEdit,
			(data) => this.setState({authentication: data, authenticationEdit: {}, edit: null, messages: ["Authentication details saved"]}),
			(errors) => this.setState({errors: errors})
		);
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


	render() {

		if (this.state.authentication == null) return (
			<div className="authentication-settings">

				<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages} />

				<div className="settings-tile"></div>

			</div>
		);

		return (

			<div className="authentication-settings">

				<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages} />

				<div className="settings-tile">

					<h4>Authentication</h4>
					<div>Type: <span className="setting-value">{this.state.authentication.description}</span></div>
					{this.state.authentication.type === 'AD' ?
						<div>
							<div>Tenant: <span className="setting-value">{this.state.authentication.tenant}</span></div>
							<div>App Id: <span className="setting-value">{this.state.authentication.clientId}</span></div>
							<div>Client Secret: <span className="setting-value">{this.state.authentication.clientSecret}</span></div>
						</div>
						:
						<div></div>
					}
					<div className="controls">
						<Glyphicon name="pencil" onClick={() => this.edit()} />
					</div>

				</div>

				<FormFields.Model title="Edit Details" isOpen={this.state.edit === "details"}
					onHide={(e) => this.unedit()} onCancel={(e) => this.unedit()} onConfirm={(e) => this.handleSubmit(e)} >

					<FormFields.Select name="type" title="Authentication Provider"  value={this.state.authenticationEdit.type}
						valueSelector={(entry) => entry.value}
						labelSelector={(entry) => entry.label}
						options={[{label: 'Talented Team', value: 'BASIC'}, {label: 'Azure Active Directory', value: 'AD'}]}
						onChange={(e) => this.handleChange(e)} helpText="This defines which provider is the authority for authentication, that is, what is used when users login to TalentedTeam." />

					{this.state.authenticationEdit.type === 'AD' ?
						<div>
							<FormFields.Text name="tenant" title="Tenant" placeholder="tenant.com.au" value={this.state.authenticationEdit.tenant} onChange={(e) => this.handleChange(e)} />
							<FormFields.Text name="clientId" title="App Id" placeholder="Application Id" value={this.state.authenticationEdit.clientId} onChange={(e) => this.handleChange(e)} />
							<FormFields.Text name="clientSecret" title="Client Secret" placeholder="Client Secret" value={this.state.authenticationEdit.clientSecret} onChange={(e) => this.handleChange(e)} />
						</div>
						:
						<div>
							<div>&nbsp;</div>
							<div>&nbsp;</div>
							<div>&nbsp;</div>
						</div>
					}

				</FormFields.Model>


			</div>
		);
	}
}
