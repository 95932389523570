import * as Dto from "actions/Dto";
import * as ProfileActions from "actions/ProfileActions";
import * as FormFields from "components/forms/FormFields";
import ErrorMessages from "components/general/ErrorMessages";
import { FileUploader } from "components/general/FileUploader";
import { IconButton } from 'components/general/IconButton';
import { parseDateTime } from "components/general/Utils";
import React, { useState } from 'react';
import * as Icons from 'react-bootstrap-icons';
import Remarkable from 'remarkable';
//import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const md = new Remarkable();

enum EditTypes {
    ADD = "add-experience",
    EDIT = "edit-experience",
	ADD_ATTACHMENT = "add-attachment",
	EDIT_ATTACHMENT = "edit-attachment"
}


interface ExperiencesProps {
	accountId: number
	profile: Dto.ProfileDto
	onUpdated: (profile: Dto.ProfileDto) => void
}

export const Experiences: React.FunctionComponent<ExperiencesProps> = (props) => {

	//uploadInput: RefObject<HTMLInputElement>;

	const [ editType, setEditType ] = useState<EditTypes>();
	const [ errors, setErrors ] = useState<Dto.ErrorDto[]>();

	const handleAdd = () => {
		setEditType(EditTypes.ADD);
	}

	const handleUnadd = () => {
		setEditType(undefined);
	}


	const handleProfileUpdated = (profile: Dto.ProfileDto) => {
		props.onUpdated(profile);
		handleUnadd();
	}

	return (
		<React.Fragment>

			<div className="profile-tile">

				<h2>Experience</h2>
				<div className="controls"><IconButton icon={<Icons.PlusLg />} onClick={() => handleAdd()} /></div>

				<ErrorMessages errors={errors} />

				{ props.profile.experiences.map((experience: Dto.ExperienceDto) => <Experience accountId={props.accountId} profile={props.profile} experience={experience} onError={setErrors} onUpdated={handleProfileUpdated} />  ) }

				{ editType === EditTypes.ADD ? <ExperienceAddForm accountId={props.accountId} profile={props.profile} onError={setErrors} onUpdated={handleProfileUpdated} onClose={handleUnadd} /> : null }

			</div>
		</React.Fragment>
	);
	

}

interface ExperienceProps {

	accountId: number
	profile: Dto.ProfileDto
	experience: Dto.ExperienceDto
	onUpdated: (profile: Dto.ProfileDto) => void
	onError: (errors: Dto.ErrorDto[]) => void

}

export const Experience: React.FunctionComponent<ExperienceProps> = (props) => {

	
	const [ collapsed, setCollapsed ] = useState(false);
	const [ edit, setEdit ] = useState<Dto.ExperienceForm>();
	const [ editAttachment, setEditAttachment ] = useState<Dto.AttachmentDto>();
	const [ editType, setEditType ] = useState<EditTypes>();
	const [ loading, setLoading ] = useState(false);

	const handleSubmit = () => {

		if (edit) {
			setLoading(true);
			ProfileActions.experienceUpdate(props.accountId, props.profile.id, props.experience.id, edit,
				(profile) => {
					setEdit(undefined);
					setEditType(undefined);
					setLoading(false);
					props.onUpdated(profile);
				},
				props.onError
			);

		}
	}

	const handleChange = (event) => {

		if (edit == null) return;

		

		let value = (event.target.value ? event.target.value : "");
		value = value.replace(/•/g, "-");	//replace bullets with markdown
		value = value.replace(//g, "-");	//replace bullets with markdown
		value = value.replace(/-\t/g, "- ");	//replace bullets-tab with bullet-space

		const _edit = { ...edit };
		_edit[event.target.name] = value;
		setEdit(_edit);

	}

	const handleCheckboxChange = (event) => {

		if (edit == null) return;

		const _edit = { ...edit };
		_edit[event.target.name] = event.target.checked;
		setEdit(_edit);

	}

	const handleEdit = () => {
		const cloneExperience = createExperienceForm(props.experience);
		setEdit(cloneExperience);
		setEditType(EditTypes.EDIT);
	}

	const createExperienceForm = (experience: Dto.ExperienceDto) : Dto.ExperienceForm => {
		return {
			...experience,
			startDate: experience.startDate.toString(),
			endDate: experience.endDate ? experience.endDate.toString() : undefined,
		}
	}
	
	const handleUnedit = () => {
		setEdit(undefined);
		setEditType(undefined);
		setEditAttachment(undefined);
	}

	const handleAddAttachment = (experience: Dto.ExperienceDto) => {
		setEditType(EditTypes.ADD_ATTACHMENT);
	}

	const handleEditAttachment = (attachment: Dto.AttachmentDto) => {
		setEditType(EditTypes.EDIT_ATTACHMENT);
		setEditAttachment(attachment);
	}
	
	const handleDelete = (experience: Dto.ExperienceDto) => {
		if (window.confirm('Delete experience?')) {
			ProfileActions.experienceDelete(props.accountId, props.profile.id, experience.id,
				(profile) => props.onUpdated(profile),
				props.onError
			);
		}
	}

	const handleDemote = (experience: Dto.ExperienceDto) => {
		ProfileActions.experienceDemote(props.accountId, props.profile.id, experience.id,
			(profile) => props.onUpdated(profile),
			props.onError
		);
	}

	const handlePromote = (experience: Dto.ExperienceDto) => {
		ProfileActions.experiencePromote(props.accountId, props.profile.id, experience.id,
			(profile) => props.onUpdated(profile),
			props.onError
		);
	}

	const toggleShowHide = (experience: Dto.ExperienceDto) => {
		ProfileActions.experienceShowHide(props.accountId, props.profile.id, experience.id,
			(profile) => props.onUpdated(profile),
			props.onError
		);
	}

	const renderEdit = () => {

		if (!edit) return null;

		return (

			<FormFields.Model title="Edit Experience" isOpen={editType !== null && editType === EditTypes.EDIT} 
				onHide={() => handleUnedit()} confirmDisabled={loading} confirmLabel={loading ? "Saving" : "Save"}
			 	className="modal-lg" onConfirm={() => handleSubmit()} onCancel={() => handleUnedit()}>

				<FormFields.Text name="organisation" title="Organisation" placeholder="Organisation" mandatory={true} value={edit.organisation} onChange={(e) => handleChange(e)} />
				<FormFields.Text name="title" title="Title" placeholder="Title" mandatory={true} value={edit.title} onChange={(e) => handleChange(e)} />

				<div className="row">
					<div className="col">
						<FormFields.DateMonthYear name="startDate" title="Start Date" value={ parseDateTime(edit.startDate) } mandatory={true} onChange={(e) => handleChange(e)} />
					</div>
					<div className="col">
						<FormFields.DateMonthYear name="endDate" title="End Date" allowCurrent={true} value={ edit.endDate ? parseDateTime(edit.endDate) : undefined } onChange={(e) => handleChange(e)} />
					</div>
				</div>

				<FormFields.TextArea name="description" title="Description" placeholder="Full description of your experience" mandatory={true} value={edit.description} rows={6} onChange={(e) => handleChange(e)} helpText={<small>Use markdown to add formatting. See <a href="https://en.wikipedia.org/wiki/Markdown" target="_blank" rel="noopener noreferrer">here</a> for help on markdown.</small>} />
				<FormFields.TextArea name="summary" title="Summary" placeholder="Short form summary of your experience" value={edit.summary} rows={3} onChange={(e) => handleChange(e)} helpText={<small>Use markdown to add formatting. See <a href="https://en.wikipedia.org/wiki/Markdown" target="_blank" rel="noopener noreferrer">here</a> for help on markdown.</small>} />

				<FormFields.Text name="location" title="Location" value={edit.location} onChange={(e) => handleChange(e)} />

				<FormFields.Checkbox name="breakAfter" title="Break After"
					value={edit.breakAfter} inline
					onChange={(e) => handleCheckboxChange(e)}
				/>

			</FormFields.Model>

		);

	}

	const handleAddUpdated = (profile: Dto.ProfileDto) => {
		setEditType(undefined);
		props.onUpdated(profile);
	}

	const renderDescription = () => {

		if (!props.experience.description || props.experience.description?.length < 200) {
			//No collapse
			return (
				<>
				<div>
					{ props.experience.featureImageUrl ? <img src={props.experience.featureImageUrl} className="feature-image" /> : null }
					<div className="description" dangerouslySetInnerHTML={{ __html: md.render(props.experience.description)}} />
				</div>
				</>
			)
		} else {
			return (
				<>
				<div>
					{ props.experience.featureImageUrl ? <img src={props.experience.featureImageUrl} className="feature-image" /> : null }
					<div className={collapsed ? "description" : "description collapsed"} dangerouslySetInnerHTML={{ __html: md.render(props.experience.description)}} />
				</div>
				<div>
					{collapsed ? <button onClick={() => setCollapsed(false)}>Show less...</button> : <button onClick={() => setCollapsed(true)}>Show more...</button>}
				</div>
				</>
			)
		}

	}

	return (

		<div key={"experience" + props.experience.id} className="">
			<div className={" experience" + (props.experience.hidden ? " experience-hidden" : "") + (props.experience.breakAfter ? " break-after" : "")}>
				<h3>{props.experience.organisation}</h3><h4>{props.experience.title}</h4>
				<h5>{props.experience.location}</h5>
				<div className="experience-date">{props.experience.startDate} to {props.experience.endDate != null ? props.experience.endDate : "Current"}</div>
				
				{renderDescription()}
				<div className="tiles attachments">
					{props.experience.attachments.map(attachment => 
						<div className="tile attachment" onClick={ () => handleEditAttachment(attachment) } >
							<img src={attachment.thumbnailUrl || attachment.imageUrl} />
							{ attachment.category || attachment.height || attachment.width ? <div className="attachment-label">{attachment.category}<br/>{attachment.height} x {attachment.width}px</div> : null }
							{ editType === EditTypes.EDIT_ATTACHMENT && editAttachment === attachment ? <EditAttachmentForm accountId={props.accountId} profile={props.profile} attachment={editAttachment} onClose={handleUnedit} onUpdated={props.onUpdated} onError={props.onError} /> : null }
						</div>
					)}
					<div className="tile add-new">
						<span className="add-label" onClick={(e) => handleAddAttachment(props.experience)}><IconButton icon={<Icons.PlusLg />} className="grey" label="Add Image"/></span>
					</div>
				</div>
				
				<div className="controls">
					<IconButton title="Edit" onClick={() => handleEdit()} icon={<Icons.Pencil />}  />
					<IconButton title="Delete" className="delete-link" onClick={() => handleDelete(props.experience)} icon={<Icons.Trash />}  />
					{ props.experience.hidden ?
						<IconButton title="Hide" className="hide-link" onClick={() => toggleShowHide(props.experience)} icon={<Icons.EyeSlash />}  /> :
						<IconButton title="Hide" className="hide-link" onClick={() => toggleShowHide(props.experience)} icon={<Icons.Eye />}  />
					}
					<IconButton title="Demote" className="hide-link" onClick={() => handleDemote(props.experience)} icon={<Icons.ChevronDown />}  />
					<IconButton title="Promote" className="hide-link" onClick={() => handlePromote(props.experience)} icon={<Icons.ChevronUp />}  />

				</div>
			</div>

			{editType === EditTypes.EDIT ? renderEdit() : null}

			{editType === EditTypes.ADD_ATTACHMENT ? <AddAttachmentForm accountId={props.accountId} profile={props.profile} experience={props.experience} onClose={handleUnedit} onUpdated={handleAddUpdated} onError={props.onError} /> : null}
			
		</div>

	);

}


interface ExperienceAddFormProps {

	accountId: number
	profile: Dto.ProfileDto
	onClose: () => void
	onUpdated: (profile: Dto.ProfileDto) => void
	onError: (errors: Dto.ErrorDto[]) => void

}

export const ExperienceAddForm: React.FunctionComponent<ExperienceAddFormProps> = (props) => {

	const [ add, setAdd ] = useState<Dto.ExperienceForm>({} as Dto.ExperienceForm);
	const [ loading, setLoading ] = useState(false);

	const handleChange = (event) => {
		let _add = { ... add };
		_add[event.target.name] = event.target.value;
		setAdd(_add);
	}

	const handleSubmit = () => {

		ProfileActions.experienceAdd(props.accountId, props.profile.id, add,
			(profile) => {
				props.onUpdated(profile);
			},
			props.onError
		);

	}

	return (

		<FormFields.Model title="Add Experience" isOpen={true} onHide={() => props.onClose()}
			confirmDisabled={loading} confirmLabel={loading ? "Saving" : "Save"}
			className="modal-lg" onConfirm={() => handleSubmit()} onCancel={() => props.onClose()}>

			<FormFields.Text name="organisation" title="Organisation" placeholder="Organisation" mandatory={true} value={add.organisation} onChange={(e) => handleChange(e)} />
			<FormFields.Text name="title" title="Title" placeholder="Title" mandatory={true} value={add.title} onChange={(e) => handleChange(e)} />

			<div className="row">

				<div className="col">
					<FormFields.DateMonthYear name="startDate" title="Start Date" allowCurrent={true} mandatory={true} value={ add.startDate ? parseDateTime(add.startDate) : undefined} onChange={(e) => handleChange(e)} />
				</div>
				<div className="col">
					<FormFields.DateMonthYear name="endDate" title="End Date" allowCurrent={true} mandatory={true} value={ add.endDate ? parseDateTime(add.endDate) : undefined } onChange={(e) => handleChange(e)} />

				</div>
			</div>

			<FormFields.TextArea name="description" title="Description" placeholder="Full description of your experience" mandatory={true} value={add.description} rows={6} onChange={(e) => handleChange(e)} helpText={<small>Use markdown to add formatting. See <a href="https://en.wikipedia.org/wiki/Markdown" target="_blank" rel="noopener noreferrer">here</a> for help on markdown.</small>} />
			<FormFields.TextArea name="summary" title="Summary" placeholder="Short form summary of your experience" value={add.summary} rows={3} onChange={(e) => handleChange(e)} helpText={<small>Use markdown to add formatting. See <a href="https://en.wikipedia.org/wiki/Markdown" target="_blank" rel="noopener noreferrer">here</a> for help on markdown.</small>} />

			<FormFields.Text name="location" title="Location" value={add.location} onChange={(e) => handleChange(e)} />

		</FormFields.Model>
	);

}

interface AddAttachmentFormProps {

	accountId: number
	profile: Dto.ProfileDto
	experience: Dto.ExperienceDto
	onClose: () => void
	onUpdated: (profile: Dto.ProfileDto) => void
	onError: (errors: Dto.ErrorDto[]) => void

}

export const AddAttachmentForm: React.FunctionComponent<AddAttachmentFormProps> = (props) => {

	const [ attachmentForm, setAttachmentForm ] = useState<Dto.AttachmentForm>({ title: "", category: "" } as Dto.AttachmentForm);
	const [ attachmentFile, setAttachmentFile ] = useState<File>();
	const [ loading, setLoading ] = useState(false);

	const handleChange = (event) => {
		let form = {...attachmentForm};
		form[event.target.name] = event.target.value;
		setAttachmentForm(form);
	};

	const handleFileChange = (files: FileList) => {

		if (files.length <= 0) return;
		setAttachmentFile(files[0]);

		//Set Title
		if (!attachmentForm.title) {
			let form = {...attachmentForm};
			form.title = files[0].name;
			setAttachmentForm(form);
		}

	};

	const handleSubmit = () => {

		if (attachmentFile) {

			var fd = new FormData();
			fd.append('file', attachmentFile);
			fd.set("title", attachmentForm.title || attachmentFile.name);
			fd.set("category", attachmentForm.category);
			
			setLoading(true);
			ProfileActions.experienceAttachmentUpload(props.accountId, props.profile.id, props.experience.id, fd,
				(profile) => {
					setLoading(false);
					props.onUpdated(profile);
				},
				(errors) => props.onError(errors)
			);

		}
	}

	return (

		<FormFields.Model title="Add Image" isOpen={true} onHide={() => props.onClose()}
			confirmLabel={attachmentFile && loading ? "Uploading" : "Upload"}
			onConfirm={() => handleSubmit()} onCancel={() => props.onClose()} confirmDisabled={!attachmentFile || loading} >

			<FormFields.Text name="title" title="Title" placeholder="Title" value={attachmentForm.title} onChange={(e) => handleChange(e)} />
			<FormFields.Text name="category" title="Category" placeholder="Category" helpText={<div>The attachment category can help you to categorise items to better customise layouts on profiles. For example, a 'hero' type can be used to define the hero image while 'sample' might be used to define work samples.</div>} value={attachmentForm.category} onChange={(e) => handleChange(e)} />

			<FileUploader onFileChanged={(files) => handleFileChange(files)} />
			{loading && attachmentFile ?
				<div className="progress">
					<div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{width: "100%"}}>
						<span className="sr-only">Uploading</span>
					</div>
				</div>
				: null
			}

		</FormFields.Model>
	);

}

interface EditAttachmentFormProps {

	accountId: number
	profile: Dto.ProfileDto
	attachment: Dto.AttachmentDto
	onClose: () => void
	onUpdated: (profile: Dto.ProfileDto) => void
	onError: (errors: Dto.ErrorDto[]) => void

}

export const EditAttachmentForm: React.FunctionComponent<EditAttachmentFormProps> = (props) => {

	const [ attachmentForm, setAttachmentForm ] = useState<Dto.AttachmentForm>({ title: props.attachment.title, category: props.attachment.category });
	const [ loading, setLoading ] = useState(false);

	const handleChange = (event) => {
		let form = {...attachmentForm};
		form[event.target.name] = event.target.value;
		setAttachmentForm(form);
	}
	
	const handleSubmit = () => {
		setLoading(true);
		ProfileActions.experienceAttachmentUpdate(props.accountId, props.profile.id, props.attachment.id, attachmentForm,
			(profile) => {
				setLoading(false);
				props.onUpdated(profile);
			},
			(errors) => props.onError(errors)
		);
	}

	const handleDelete = () => {

		if (confirm("Delete attachment?")) {
			setLoading(true);
			ProfileActions.experienceAttachmentDelete(props.accountId, props.profile.id, props.attachment.id,
				(profile) => {
					setLoading(false);
					props.onUpdated(profile);
				},
				(errors) => props.onError(errors)
			);

		}

	}


	return (

		<FormFields.Model title="Edit Image" isOpen={true} onHide={() => props.onClose()}
			onConfirm={handleSubmit} onCancel={() => props.onClose()}
			extraButtons={<button className="btn" onClick={handleDelete}>Delete</button>}
			confirmDisabled={loading}
			>

			<FormFields.Text name="title" title="Title" placeholder="Title" value={attachmentForm.title} onChange={(e) => handleChange(e)} />
			<FormFields.Text name="category" title="Category" placeholder="Category" helpText={<p>The attachment category can help you to categorise items to better customise layouts on profiles. For example, a 'hero' type can be used to define the hero image while 'sample' might be used to define work samples.</p>} value={attachmentForm.category} onChange={(e) => handleChange(e)} />

			<div className="attachment-preview">
				<img src={props.attachment.imageUrl} />
			</div>

			<div>
			<FormFields.Text disabled value={props.attachment.imageUrl} suffix={<Icons.Copy className="clickable" onClick={() => navigator.clipboard.writeText(props.attachment.imageUrl)}/>} />
			</div>

		</FormFields.Model>
	);

}