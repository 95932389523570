import React from 'react';
import { Route, Link, Switch } from "react-router-dom"

import Routes from "actions/Routes";
import ApplicationPage from "../pages/users/application/ApplicationPage"
import Footer from './Footer'

import './Layout.css';

export default class UnauthenticatedSplashLayout extends React.Component {

	render() {
		return (
			<React.Fragment>

				<section className="splash">

				<nav>
					<Link className="logo" to={Routes.splash()} ></Link>
				</nav>

				<div className="container">

					<div className="splash-panel">
						<Switch>
							<Route path={Routes.accountJobApplication(":token")} component={ApplicationPage} />
						</Switch>
					</div>

				</div>

				</section>

				<Footer />

			</React.Fragment>

		);
	}

}