import React from 'react';

import Routes from "actions/Routes"
import ReactGA from 'react-ga';
import { TopMenu } from 'components/layouts/navigation/TopMenu';


export default class CommunityPage extends React.Component {

	constructor(props) {
		super();

	}

	componentDidMount() {

		window.scrollTo(0, 0);	//scroll to top
		ReactGA.pageview(Routes.community());	//analytics


	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


	render() {

		return(


				<section>

					<TopMenu />
					<br/>

					<div className="row justify-content-md-center">
						<div className="col-md-4">
							<h4>Communities coming soon...</h4>
						</div>
					</div>

				</section>

		);
	}
}