import React, { useEffect, useState } from 'react';

import * as Dto from "actions/Dto";
import * as Utils from "components/general/Utils"
import * as Const from "components/general/Constants"
import SessionStore from "store/SessionStore";
import { TIME_RANGE_WARNING, TIME_RANGE_ERROR } from "./Timesheet";


interface TimesheetDayProps {
	timesheet: Dto.TimesheetDto
	assignment?: Dto.TimesheetAssignmentDto
	leaveType?: Dto.LeaveTypeDto
	entry?: Dto.TimesheetEntryDto
	billable: boolean
	day: number
	date: Date
	onUpdate: (entry: Dto.TimesheetEntryDto) => void
}

export const TimesheetDay: React.FunctionComponent<TimesheetDayProps> = (props) => {

	const [ quantity, setQuantity ] = useState<number|string>( 0 );
	const [ entry, setEntry ] = useState<Dto.TimesheetEntryDto>();	//entry: {...this.getEntry()},

	useEffect(() => {

		setQuantity( props.entry ? parseFloat(Utils.formatNumber(props.entry.quantity, "#.####")) : 0 );
		setEntry( { ...getEntry() });

	}, [props.entry])


	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setQuantity(e.target.value);	//don't parseFloat here, do it onBlur
	}

	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {

		let _entry = getEntry();
		let quantity = parseFloat(e.target.value);
		if (isNaN(quantity) || quantity <= 0) quantity = 0;
		_entry.quantity = quantity;
		setQuantity(_entry.quantity);
		setEntry(_entry);
		props.onUpdate(_entry);

	}

	const getEntry = () =>  {	//props: TimesheetDayProps
		return (props.entry ? props.entry : createEntry(props));
	}

	const createEntry = (props: TimesheetDayProps): Dto.TimesheetEntryDto => {
		
		return {
			assignment: props.assignment, 
			leaveType: props.leaveType, 
			date: props.date, 
			quantity: undefined, 
			billable: props.billable, 
			dayOfWeek: props.day
		} as unknown as Dto.TimesheetEntryDto;

	}

	//If submitted OR approved OR is for a fixed holiday
	if (props.timesheet.status === Const.TimesheetStatus.SUBMITTED || 
		props.timesheet.status === Const.TimesheetStatus.APPROVED || 
		(props.entry && props.entry.holiday && !SessionStore.isAdminRole())	//Is a fixed holiday and not admin
	) {
		return (
			<div className={"timesheet-cell day day" + props.day}>
				<div className="time" title={props.entry ? props.entry.notes : ""}>
					{quantity ? quantity : "-"}
					{props.entry && props.entry.notes ? <div className="notes">{props.entry.notes}</div> : null}
					<div className="units">day</div>
				</div>
			</div>
		)
	}

	let rangeWarning = isTimesheetEntryRangeWarning(quantity as number);
	let rangeError = isTimesheetEntryRangeError(quantity as number);


	return (
		<div className={"timesheet-cell day day" + props.day + (rangeWarning ? " range-warning" : "") + (rangeError ? " range-error" : "")}>
			<div className={"time" } title={props.entry ? props.entry.notes : ""}>
				<input type="text" value={quantity ? quantity : ""} placeholder="-" onChange={(e) => handleChange(e)}  onBlur={(e) => handleBlur(e)} />
				<div className="units">days</div>
			</div>
		</div>
	)

}



/*export default class TimesheetDayOLD extends React.Component<TimesheetDayProps, TimesheetDayState> {

	constructor(props: TimesheetDayProps) {
		super(props);
		this.state = {
			quantity: (props.entry ? parseFloat(Utils.formatNumber(props.entry.quantity, "#.#")) : 0),
			entry: {...this.getEntry(props)},
			date: props.date
		};
	}

	//called just before every render
	static getDerivedStateFromProps(props: TimesheetDayProps, state: TimesheetDayState) {


		if (props.date !== state.date) {

			//Reset for a new day
			return {
				hours: (props.entry ? parseFloat(Utils.formatNumber(props.entry.quantity, "#.#")) : 0),
				entry: (props.entry ? props.entry : {assignment: props.assignment, leaveType: props.leaveType, date: props.date, minutes: 0, billable: props.billable}),
				date: props.date
			}

		} else {

			if ((props.entry && (props.entry.id !== state.entry.id || props.entry.quantity !== state.entry.quantity)) || !props.entry || !state.entry) {

				// BUG TO WATCH FOR: this is called after handleChange so will reset hours to nuffen

				let hours = props.entry ? parseFloat(Utils.formatNumber(props.entry.quantity, "#.#")) : state.quantity;

				//if entry exists in props, update entry. If hours is not set, update from props.
				return {
					entry: (props.entry ? props.entry : {assignment: props.assignment, leaveType: props.leaveType, date: props.date, minutes: 0, billable: props.billable}),
					hours: hours
				};
			}

		}

		return null;

	}

	


	render() {

		//if (this.props.day === 0) console.log("Entry: " + this.props.assignment.title + " / " + this.props.day + " - " + this.props.date, this.props.entry);

		



	}
}
*/

export function isTimesheetEntryRangeWarning(quantity: number) {
	return quantity > TIME_RANGE_WARNING;
}
export function isTimesheetEntryRangeError(quantity: number) {
	return quantity < 0 || quantity > TIME_RANGE_ERROR;
}

/*
{ rangeWarning && !rangeError ? <div className="units">Range warning</div> : null }
{ rangeError ? <div className="units">Range error</div> : null }
*/