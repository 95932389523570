import React from 'react';
import { Link } from "react-router-dom"

import Routes from "actions/Routes"
import * as TalentActions from "actions/TalentActions"
import * as FormFields from "components/forms/FormFields"
import ErrorMessages from "components/general/ErrorMessages"
import SessionStore from "store/SessionStore"
import ReactGA from 'react-ga';
import { TopMenu } from 'components/layouts/navigation/TopMenu';

export default class UserAddPage extends React.Component {

	constructor(props) {
		super();
		this.state = {
						user: {firstname: null, surname: null, email: null, template: null, password: null, invite: false },
						autoFillEmail: true, errors: null
					};
	}

	componentWillMount() {

		window.scrollTo(0, 0);	//scroll to top
		ReactGA.pageview(Routes.userAdd());

	}

	handleChange(event) {

		var user = this.state.user;
		var autoFillEmail = this.state.autoFillEmail;
		user[event.target.name] = event.target.value;

		if ("email" === event.target.name) autoFillEmail = false;

		if (autoFillEmail) {
			//if the email is not set, has no @ or appears to be calculated, re-calculate
			user.email = this.buildEmail(user.firstname, user.surname, SessionStore.getAccount().domain);
		}

		this.setState({user: user, autoFillEmail: autoFillEmail});

	}

	handleCheckboxChange(event) {

		var user = this.state.user;

		user[event.target.name] = event.target.checked;

		this.setState({user: user});

	}

	buildEmail(firstname, surname, domain) {
		return (firstname != null ? firstname + "." : "").toLowerCase() + (surname != null ? surname : "").toLowerCase() + '@' + domain;
	}

	handleSubmit(event) {
		TalentActions.talentAdd(SessionStore.getAccountId(), this.state.user,
			(profile) => this.props.history.push("/talent/" + profile.user.id + "/profile"),
			(errors) => this.setState({errors: errors})
		);
	}

	renderTitle() {
		if (this.state.user.firstname == null && this.state.user.surname == null) return (<h1>Talent Profile</h1>);
		return (<h1>{this.state.user.firstname != null ? this.state.user.firstname : ""}{this.state.user.surname != null ? " " + this.state.user.surname : ""}</h1>);
	}

	render() {
		return(

			<section>

				<TopMenu />

				<ErrorMessages errors={this.state.errors} component={this} />

				<div className="row justify-content-md-center">
					<div className="col-6">
						{this.renderTitle()}

						<div className="card">
							<div className="card-body">To add new talent to your team, simply enter their first name, surname and email below. Choose 'Send Invite' to send an email invite with a link for your new team member to access TalentedTeam so they can update their own profile.</div>
						</div>

						<FormFields.Text title="First Name" name="firstname" value={this.state.user.firstname} onChange={(e) => this.handleChange(e)} />
						<FormFields.Text title="Surname" name="surname" value={this.state.user.surname} onChange={(e) => this.handleChange(e)} />

						<FormFields.Text title="Email" name="email" value={this.state.user.email} onChange={(e) => this.handleChange(e)} />

						<FormFields.Text title="Department" name="department" value={this.state.user.department} onChange={(e) => this.handleChange(e)} />

						<FormFields.Checkbox title="Send Invite" name="invite" value={this.state.user.invite} onChange={(e) => this.handleCheckboxChange(e)} />

						<button className="btn btn-default" onClick={(e) => this.handleSubmit(e)}>Save</button>
						<Link className="btn" to="/">Cancel</Link>

					</div>
				</div>
			</section>

		);
	}
}
