import * as Dto from "actions/Dto";
import * as ProfileActions from "actions/ProfileActions";
import * as FormFields from "components/forms/FormFields";
import ErrorMessages from "components/general/ErrorMessages";
import SectionBody from "components/layouts/SectionBody";
import React from 'react';
import { Link } from "react-router-dom";
import SessionStore from "../../../store/SessionStore";
import { SettingsContextMenu } from "./SettingsContextMenu";
import { CONTENT_TYPES } from "./Templates";

import './Settings.css';

export default class TemplateEdit extends React.Component {

	constructor(props) {
		super();
		this.state = {template: null, errors: null, messages: null, templateId: props.match.params.templateId}
		
	}

	componentWillMount() {

		//get values from store
		ProfileActions.templateLoad(SessionStore.getAccountId(), this.state.templateId,
			(template) => this.setState({template: template}) ,
			(errors) => {this.setState({errors: errors})}
		);
		

	}


	handleChange(event) {
		this.setState({errors:null, messages: null});
		var template = this.state.template;
		template[event.target.name] = event.target.value;
		this.setState({template: template});

	}

	handleSubmit() {
		ProfileActions.templateUpdate(SessionStore.getAccountId(), this.state.templateId, this.state.template,
				(template) => this.setState({template: template, errors: null, messages: ["Template saved."]}),
				(errors) => this.setState({errors: errors})
			);
	}

	getPdfUrl() {
		var template = SessionStore.getTemplateById(parseInt(this.state.templateId));
		if (template != null) {
			return template.pdfUrl + "?userId=0&templateId=" + this.state.templateId;
		}
		return "none";
	}

	render() {

		let contentTypes = [
			{label: CONTENT_TYPES["PROFILE_PDF"], value: "PROFILE_PDF"},
			{label: CONTENT_TYPES["PROFILE_HTML"], value: "PROFILE_HTML"},
			{label: CONTENT_TYPES["PROFILE_EMAIL"], value: "PROFILE_EMAIL"},
			{label: CONTENT_TYPES["INVITE_EMAIL"], value: "INVITE_EMAIL"}
		];

		if (this.state.template == null) return null;

		let topMenuItems = <SettingsContextMenu path={this.props.location.pathname} />;

		return (

			<SectionBody className="settings"
				onNotificationClose={() => this.setState({errors: null, messages: null})}
				errors={this.state.errors} messages={this.state.messages} 
				topMenuItems={topMenuItems}
				>

				<h1>Template</h1>
				<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages} />

				<div className="row">
					<div className="col-9">
						<FormFields.Text name="title" title="Title" value={this.state.template.title} onChange={ (e) => this.handleChange(e) }/>
					</div>
					<div className="col">
						<FormFields.Select name="state" title="State" value={this.state.template.state} onChange={ (e) => this.handleChange(e) } options={['DRAFT', 'ACTIVE', 'DELETED']} />
					</div>
				</div>

				<FormFields.Text name="description" title="Description" value={this.state.template.description} onChange={ (e) => this.handleChange(e) }/>
				<FormFields.TextArea name="content" title="Content" supportTab rows={20} value={this.state.template.content} className="code" onChange={ (e) => this.handleChange(e) }/>
				<div className="row">
					<div className="col">
						<FormFields.Text name="priority" title="Priority" value={this.state.template.priority} onChange={ (e) => this.handleChange(e) } />
					</div>
					<div className="col">
						<FormFields.Select name="contentType" title="Content Type"
							value={this.state.template.contentType} valueSelector={(option) => option.value} labelSelector={(option) => option.label}
							onChange={ (e) => this.handleChange(e) } options={contentTypes} />
					</div>
					<div className="col">
						<FormFields.Select name="mode" title="Template Mode"
							value={this.state.template.mode} valueSelector={(option) => option} 
							labelSelector={(option) => option}
							onChange={ (e) => this.handleChange(e) } options={Object.keys(Dto.TemplateMode)} />
					</div>
				</div>
				<hr/>
				<button className="btn btn-default" onClick={(e) => this.handleSubmit(e)}>Save</button>
				<a className="btn" target="_blank" href={this.getPdfUrl()} rel="noopener noreferrer">Demo</a>
				<Link to={"/settings"} className="btn">Close</Link>

			</SectionBody>
		);
	}
}
