import * as Dto from "actions/Dto";
import * as Http from "./Http";

/*export function profilesLoad(accountId, page, success, failure) {
	Http.get( "/api/accounts/" + accountId + "/profiles/page/" + page).then(success).catch(failure);
}*/


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

	P R O F I L E S

* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export function profiles(accountId, page, filter, success, failure) {
	Http.get( "/api/accounts/" + accountId + "/profiles/page/" + page + (filter ? "?filter=" + encodeURIComponent(filter) : "")).then(success).catch(failure);
}

export function profilesByFilter(accountId, page, filter, filterType, success, failure) {
	Http.get( "/api/accounts/" + accountId + "/profiles/page/" + page + "?filter=" + encodeURIComponent(filter) + "&filterType=" + filterType).then(success).catch(failure);
}

export function profileLoad(accountId: number, userId: number, success: Http.SuccessResponse<Dto.ProfileDto>, failure: Http.FailureResponse) {

	Http.get<Dto.ProfileDto>( "/api/accounts/" + accountId + "/talent/" + userId + "/profile").then(success).catch(failure);

}

export function profileByEmail(accountId, email, success, failure) {

	Http.get( "/api/profiles/" + email).then(success).catch(failure);

}

export function profileUpdate(accountId, profile, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/profiles/" + profile.id, profile).then(success).catch(failure);

}

export function profileStateUpdate(accountId: number, profile: Dto.ProfileDto, state: Dto.ProfileState, success, failure) {

	Http.post<Dto.ProfileStateForm, Dto.ProfileDto>( "/api/accounts/" + accountId + "/profiles/" + profile.id + "/state", {state: state}).then(success).catch(failure);

}

export function profileHistory(accountId: number, profile: Dto.ProfileDto, success: Http.SuccessResponse<Dto.PagedListDto<Dto.PublishedProfileDto>>, failure: Http.FailureResponse) {

	Http.get<Dto.PagedListDto<Dto.PublishedProfileDto>>( "/api/accounts/" + accountId + "/profiles/" + profile.id + "/history").then(success).catch(failure);

}

export function profileSend(accountId, profile, details, success, failure) {

	//details to include:
	// { subject, toAddress, templateId }
	Http.post( "/api/accounts/" + accountId + "/profiles/" + profile.id + "/send", details).then(success).catch(failure);

}

export function profileDownloadLink(accountId: number, userId: number, templateId: number, success: Http.SuccessResponse<Dto.ProfileLinkDto>, failure: Http.FailureResponse) {

	Http.get<Dto.ProfileLinkDto>( "/api/accounts/" + accountId + "/users/" + userId + "/profile/" + templateId + "/link").then(success).catch(failure);

}

export function profileExtractFromResume(accountId: number, userId: number, success: Http.SuccessResponse<Dto.ProfileDto>, failure: Http.FailureResponse) {

	Http.get<Dto.ProfileDto>( `/api/accounts/${accountId}/users/${userId}/resume-extract`).then(success).catch(failure);

}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

	E X P E R I E N C E

* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export function experienceUpdate(accountId: number, profileId: number, experienceId: number, form: Dto.ExperienceForm, success: Http.SuccessResponse<Dto.ProfileDto>, failure: Http.FailureResponse) {

	Http.post<Dto.ExperienceForm, Dto.ProfileDto>( "/api/accounts/" + accountId + "/profiles/" + profileId + "/experiences/" + experienceId, form).then(success).catch(failure);

}

export function experienceAdd(accountId, profileId, experience, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/profiles/" + profileId + "/experiences/add", experience).then(success).catch(failure);

}

export function experienceDelete(accountId, profileId, experienceId, success, failure) {

	Http.deleteElement("/api/accounts/" + accountId + "/profiles/" + profileId + "/experiences/" + experienceId + "/delete").then(success).catch(failure);

}


export function experienceShowHide(accountId, profileId, experienceId, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/profiles/" + profileId + "/experiences/" + experienceId + "/toggle", {}).then(success).catch(failure);

}

export function experienceDemote(accountId, profileId, experienceId, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/profiles/" + profileId + "/experiences/" + experienceId + "/demote", {}).then(success).catch(failure);

}

export function experiencePromote(accountId, profileId, experienceId, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/profiles/" + profileId + "/experiences/" + experienceId + "/promote", {}).then(success).catch(failure);

}

export function experienceAttachmentUpload(accountId: number, profileId: number, experienceId: number, file: FormData, success: Http.SuccessResponse<Dto.ProfileDto>, failure: Http.FailureResponse) {
	Http.postUpload<Dto.ProfileDto>("/api/accounts/" + accountId + "/profiles/" + profileId + "/experiences/" + experienceId + "/attachments", file).then(success).catch(failure);

}

export function experienceAttachmentDelete(accountId: number, profileId: number, attachmentId: number, success: Http.SuccessResponse<Dto.ProfileDto>, failure: Http.FailureResponse) {
	Http.deleteElement<Dto.ProfileDto>("/api/accounts/" + accountId + "/profiles/" + profileId + "/attachments/" + attachmentId).then(success).catch(failure);

}

export function experienceAttachmentUpdate(accountId: number, profileId: number, attachmentId: number, form: Dto.AttachmentForm, success: Http.SuccessResponse<Dto.ProfileDto>, failure: Http.FailureResponse) {
	Http.post<Dto.AttachmentForm, Dto.ProfileDto>("/api/accounts/" + accountId + "/profiles/" + profileId + "/attachments/" + attachmentId, form).then(success).catch(failure);
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

	E D U C A T I O N

* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


export function educationUpdate(accountId, profileId, educationId: number, form: Dto.EducationForm, success, failure) {

	Http.post<Dto.EducationForm, Dto.ProfileDto>( "/api/accounts/" + accountId + "/profiles/" + profileId + "/educations/" + educationId, form).then(success).catch(failure);

}

export function educationAdd(accountId, profileId, education, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/profiles/" + profileId + "/educations/add", education).then(success).catch(failure);

}

export function educationDelete(accountId, profileId, educationId, success, failure) {

	Http.deleteElement("/api/accounts/" + accountId + "/profiles/" + profileId + "/educations/" + educationId + "/delete").then(success).catch(failure);

}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

	S K I L L S

* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export function skillAdd(accountId, profileId, skills, success, failure) {

	//map skills to a http-form array
	/*var skillsForm = {};
	var index = 0;
	skills.forEach((skill) => {
			skillsForm["skills[" + index + "].id"] = skill.id;
			skillsForm["skills[" + index + "].title"] = skill.title;
			skillsForm["skills[" + index + "].strength"] = skill.strength;
			index++;
		}
	);*/

	Http.post( "/api/accounts/" + accountId + "/profiles/" + profileId + "/skills/add", {skills:skills}).then(success).catch(failure);

}

export function skillAddValidate(accountId, profileId, skills, success, failure) {

	//map skills to a http-form array
	/*var skillsForm = {};
	var index = 0;
	skills.forEach((skill) => {
			skillsForm["skills[" + index + "].id"] = skill.id;
			skillsForm["skills[" + index + "].title"] = skill.title;
			skillsForm["skills[" + index + "].strength"] = skill.strength;
			index++;
		}
	);*/

	Http.post( "/api/accounts/" + accountId + "/profiles/" + profileId + "/skills/add-validate", {skills:skills, validate: true}).then(success).catch(failure);

}

export function skillUpdate(accountId, profileId, skill, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/profiles/" + profileId + "/skills/" + skill.id, skill).then(success).catch(failure);

}

export function skillDelete(accountId, profileId, skillId, success, failure) {

	Http.deleteElement("/api/accounts/" + accountId + "/profiles/" + profileId + "/skills/" + skillId + "/delete").then(success).catch(failure);

}

export function skillsSearch(accountId, query, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/skills/search", {q: query}).then(success).catch(failure);

}


export function skills(accountId, page, filter, success, failure) {

	Http.get( "/api/accounts/" + accountId + "/skills/page/" + page + (filter ? "?filter=" + encodeURIComponent(filter) : "")).then(success).catch(failure);

}

/*
 * expects a filter in {name: value, name: value} format
 */
export function skillsAdvanceFilter(accountId, page, filter, success, failure) {

	if (!filter) skills(accountId, page, filter, success, failure);

	var params = "";
	for (var key in filter) {
		if (params) params = params + "&";
		params = params + key + "=" + encodeURIComponent(filter[key]);
		//console.log(key + "=" + filter[key]);
	}

	Http.get( "/api/accounts/" + accountId + "/skills/page/" + page + "?" + params).then(success).catch(failure);

}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

	F E A T U R E S

* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export function featureAdd(accountId, profileId, feature, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/profiles/" + profileId + "/features/add", feature).then(success).catch(failure);

}


export function featureUpdate(accountId, profileId, feature, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/profiles/" + profileId + "/features/" + feature.id, feature).then(success).catch(failure);

}

export function featureDelete(accountId, profileId, featureId, success, failure) {

	Http.deleteElement("/api/accounts/" + accountId + "/profiles/" + profileId + "/features/" + featureId + "/delete").then(success).catch(failure);

}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

T E M P L A T E S

* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function templateLoad(accountId, templateId, success, failure) {

	Http.get( "/api/accounts/" + accountId + "/templates/" + templateId).then(success).catch(failure);

}


export function templateUpdate(accountId, templateId, template, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/templates/" + templateId, template).then(success).catch(failure);

}

export function templateDuplicate(accountId, template, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/templates/create", template).then(success).catch(failure);

}

export function templateDelete(accountId, templateId, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/templates/" + templateId + "/state", {state: 'DELETED'}).then(success).catch(failure);

}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

G E N E R A L

* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

