import React from 'react';
import * as FormFields from "components/forms/FormFields"
import SessionStore from "store/SessionStore"

export default class TalentDetailsStep extends React.Component {

	constructor(props) {
		super();
		this.state = {autoFillEmail: true }
	}


	handleChange(event) {

		var user = this.props.user;
		var autoFillEmail = this.state.autoFillEmail;

		user[event.target.name] = event.target.value;

		if ("email" === event.target.name) autoFillEmail = false;

		if (autoFillEmail) {
			//if the email is not set, has no @ or appears to be calculated, re-calculate
			user.email = this.buildEmail(user.firstname, user.surname, SessionStore.getAccount().domain);
		}

		this.setState({autoFillEmail: autoFillEmail});

		this.props.onChange(user);

	}

	buildEmail(firstname, surname, domain) {
		return (firstname ? firstname.toLowerCase() : "") + (firstname && surname ? "." : "") + (surname != null ? surname.toLowerCase() : "") + '@' + domain;
	}

	handleChangeContact(event) {
		var user = this.props.user;
		user.contact[event.target.name] = event.target.value;
		this.props.onChange(user);
	}

	handleCheckboxChange(event) {

		var user = this.props.user;
		user[event.target.name] = event.target.checked;
		this.props.onChange(user);

	}

	render() {

		if (this.props.step !== this.props.index) return null;

		return (

			<React.Fragment>

				<h4>Contact Details</h4>

				<div className="row">
					<div className="col">
						<FormFields.Text name="firstname" title="Firstname" value={this.props.user.firstname} onChange={(e) => this.handleChange(e)} />
					</div>
					<div className="col">
						<FormFields.Text name="surname" title="Surname" value={this.props.user.surname} onChange={(e) => this.handleChange(e)}/>
					</div>
				</div>

				<hr/>

				<FormFields.Text name="email" title="Email" value={this.props.user.email } onChange={(e) => this.handleChange(e)} />

				<FormFields.Text name="department" title="Department" value={this.props.user.department} onChange={(e) => this.handleChange(e)} />

				<FormFields.Checkbox title="Send Invite" name="invite" value={this.props.user.invite} onChange={(e) => this.handleCheckboxChange(e)} />

			</React.Fragment>

		);
	}

}