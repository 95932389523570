import React, { useState } from 'react';
import { Route, Switch, Link, RouteComponentProps } from "react-router-dom"

//import Glyphicon from '../../general/Glyphicon';
import * as Icons from 'react-bootstrap-icons';
import SessionStore from 'store/SessionStore';
import Routes from 'actions/Routes';
import { TopMenuItem } from './TopMenuItem';

interface TopMenuProps {
	
}

//export default class TopMenu extends React.Component {
export const TopMenu: React.FunctionComponent<RouteComponentProps<TopMenuProps>> = (props) => {

	return (

		<nav className="console-top-nav">

			<ul className={"nav-top-menu"}>

				<Switch>
					<Route path="/skills" component={SkillsMenu} />
					<Route path="/projects" component={ProjectsMenu} />
					<Route path="/talent" component={TalentMenu} />
				</Switch>

				{props.children}

			</ul>

			<ul className={"nav-top-menu right"}>
				<Switch>
					<Route component={RightMenu} />
				</Switch>
			</ul>

		</nav>

	);

}

interface MenuItemProps {
	path: string
}


const isActive = (path, ...matchPaths: string[]) => {

	var active = false;
	matchPaths.forEach((nextMatchPath) => {
		if (path === nextMatchPath) {
			active = true;
			return;
		}
	});
	return active;

}


export const RightMenu: React.FunctionComponent<RouteComponentProps<MenuItemProps>> = (props) => {

	const handleSearchSubmit = (query) => {
		props.history.push("/talent/search/advanced", {query: query});
	}

	if (!SessionStore.user) return null;
	
	return(
		<React.Fragment>
			{ SessionStore.isUserRole() ?
				<li className="search">
					<Link to={Routes.talentSearchAdvanced()}><Icons.Search /></Link>
					<SearchBox onSubmit={(query) => handleSearchSubmit(query)} />
				</li>
				: null
			}
			{ SessionStore.isAdminRole() ?
				<React.Fragment>
					<TopMenuItem icon={<Icons.GearFill />} label="Settings" to={Routes.settings()} />
				</React.Fragment>
				: null
			}

			<TopMenuItem icon={<Icons.PersonCircle />} className="dropdown"
				label={SessionStore.user.firstname} to={Routes.userEdit(SessionStore.getUserId())}>

				{
				/* Keeps getting hidden!
				<div class="dropdown-menu" aria-labelledby="navbarDropdown">
					<a class="dropdown-item" href="#">Action</a>
					<a class="dropdown-item" href="#">Another action</a>
					<div class="dropdown-divider"></div>
					<a class="dropdown-item" href="#">Something else here</a>
				</div>
				*/
				}
			</TopMenuItem>

		</React.Fragment>
	);
	
}


export const SkillsMenu: React.FunctionComponent<MenuItemProps> = (props) => {

	return (
		<React.Fragment>
			<TopMenuItem label="Skills" to={Routes.skills()} active={isActive("/skills")}/>
		</React.Fragment>
	)
	
}

export const TalentMenu: React.FunctionComponent<MenuItemProps> = (props) => {

	if (SessionStore.isUserRole()) {

		return (
			<React.Fragment>
				<TopMenuItem label="Talent" to={Routes.talent()} active={isActive("/talent")} />
				{
					//<TopMenuItem label="Advanced Search" to={Routes.talentSearchAdvanced()} active={this.isActive("/talent/search/advanced")}/>
				}
			</React.Fragment>
		)

	}

	return null;

}

//class ProjectsMenu extends MenuItem {
export const ProjectsMenu: React.FunctionComponent<MenuItemProps> = (props) => {

	return (
		<React.Fragment>
			<TopMenuItem label="Projects" to={Routes.projects()} active={isActive("/projects")} />
		</React.Fragment>
	)
	
}

interface SearchBoxProps {
	onSubmit: (query: string|null) => void
}

export const SearchBox: React.FunctionComponent<SearchBoxProps> = (props) => {


	const [search, setSearch] = useState<string|null>(null)

	const handleChange = (event) => {
		setSearch(event.target.value);
	}

	const handleSubmit = (event) => {
		props.onSubmit(search);
	}

	
	return(
		<input type="text" placeholder="Advanced search" className="search" onChange={(e) => handleChange(e)} onKeyPress={(e) => { if (e.key === 'Enter') handleSubmit(e); }} />
	);
	
}