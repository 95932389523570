import * as AccountActions from "actions/AccountActions";
import * as Dto from "actions/Dto";
import * as FormFields from "components/forms/FormFields";
import * as Utils from "components/general/Utils";
import React from 'react';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import SessionStore from "store/SessionStore";

interface AssignmentEditFormProps {
	title: string
	isOpen: boolean
	assignment: Dto.AssignmentDto
	project: Dto.ProjectDto
	onConfirm: (assignment: Dto.AssignmentDto) => void
	onCancel: () => void
}

interface AssignmentEditFormState {
	accountId: number|null
	assignment: Dto.AssignmentDto
	project: Dto.ProjectDto
	effortDays: number
	assignedTalent: Dto.UserDto|null
	isSearchLoading: boolean
	search: any[]
	errors: Dto.ErrorDto[]
	messages: Dto.MessageDto[]
}

export default class AssignmentEditForm extends React.Component<AssignmentEditFormProps, AssignmentEditFormState> {

	private typeahead = React.createRef();

	constructor(props: AssignmentEditFormProps) {
		super(props);

		let assignment = (props.assignment ? props.assignment : this.emptyAssignment(props.project));
		let effortDays = this.calculateEffortDays(assignment);

		this.state = { accountId: SessionStore.getAccountId(),
			assignment: assignment,
			project: props.project, assignedTalent: null, isSearchLoading: false, search: [],
			effortDays: effortDays,
			errors: [], messages: []
		};

		
	}
	
	calculateEffortDays(assignment: Dto.AssignmentDto) {
		return assignment.effort;
	}

	emptyAssignment(project): Dto.AssignmentDto {

		let startDate = new Date();
		let endDate = new Date(startDate);
		endDate.setDate(endDate.getDate() + 28);

		let assignment: Dto.AssignmentDto = {} as any;

		assignment.effort = 0;
		assignment.startDate  = startDate;
		assignment.endDate  = endDate;
		assignment.effort = Utils.workingDaysBetweenDates(startDate, endDate);
		assignment.externalId = "";
		assignment.brief = {
			author: Dto.AssignmentBriefAuthor.SELF
		} as any;

		return assignment;

	}

	/*componentWillUpdate(nextProps, nextState) {

		//assignment
		if (nextProps.assignment && nextProps.assignment.id !== this.state.assignment.id) {
			this.setState({ assignment: {...nextProps.assignment} });
		}

		//project
		if (nextProps.project && nextProps.project.id !== this.state.project.id) {
			var assignment = {...this.state.assignment};
			assignment.startDate = nextProps.project.startDate;
			assignment.endDate = nextProps.project.endDate;
			this.setState({ project: {...nextProps.project}, assignment: assignment });
		}

	}*/

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	handleAssignmentChange(event) {

		var assignment = this.state.assignment;
		assignment[event.target.name] = event.target.value;
		this.setState({assignment: assignment});

	}

	handleChange(e) {
		this.setState({[e.target.name]: e.target.value} as Pick<AssignmentEditFormState, keyof AssignmentEditFormState>);
	}

	handleBriefChange(event) {

		var assignment = this.state.assignment;
		assignment.brief[event.target.name] = event.target.value;
		this.setState({assignment: assignment});

	}

	handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
		var assignment = this.state.assignment;
		assignment[event.target.name] = event.target.checked;
		this.setState({assignment: assignment});
	}


	handleDateChange(name, date) {

		var assignment = this.state.assignment;
		assignment[name] = Utils.formatDateISO(date);

		//update effort based on new dates

		assignment.effort = Utils.workingDaysBetweenDates(new Date(assignment.startDate), new Date(assignment.endDate));

		
		this.setState({assignment: assignment, effortDays: this.calculateEffortDays(assignment)});

	}

	/*handleEffortChange(effortDays) {

		let hoursPerDay = SessionStore.getHoursPerDay();

		var assignment = this.state.assignment;
		assignment.effortDays = effortDays;
		assignment.effort = effortDays * hoursPerDay;
		this.setState({assignment: assignment});

	}*/



	handleTalentSearchChange(value: Dto.UserDto[]) {

		if (!value) return;
		let assignment = this.state.assignment;
		if (!assignment.user) assignment.user = {} as any;
		assignment.user.id = value[0].id;

		this.setState({assignedTalent: value[0], assignment: assignment});

	}

	handleSave() {

		let assignment = this.state.assignment;
		assignment.effort = this.state.effortDays;

		this.props.onConfirm(assignment);

	}

	getTodaysDate() {
		return Utils.formatDate(new Date());
	}




	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	getAuthorSelectLabel(key :Dto.AssignmentBriefAuthor) {
		switch (key) {
			case Dto.AssignmentBriefAuthor.SELF:
				return "Team Member";
			case Dto.AssignmentBriefAuthor.MANAGER:
				return "Project Manager";
			case Dto.AssignmentBriefAuthor.NONE:
				return "(none)";
		}
	}

	renderTypeAhead() {

		return (
			<AsyncTypeahead isLoading={this.state.isSearchLoading} minLength={3}
				inputProps={{name: "title", maxLength: 60}}
				placeholder="Select Talent" autoFocus={true} allowNew={false}
				caseSensitive={false} filterBy={["fullname"]}
				onSearch={query => {
					if (this.state.accountId) {
						this.setState({isSearchLoading: true});
						AccountActions.userSearch(this.state.accountId, query,
							(data) => {
								this.setState({search: data, isSearchLoading: false});
							},
							(errors) => this.setState({errors: errors})
						);
					}
				}}
				onChange={(value) => this.handleTalentSearchChange(value as any[])}
				labelKey="fullname" multiple={false}
				options={this.state.search}
				renderMenu={(results, menuProps) => (
					<Menu {...menuProps}>
					{results.map((result, index) => (
						<MenuItem option={result} position={index}>
						<img alt="mention" className="profile-thumbnail mini" src={((result as Dto.UserDto).photoThumbnailUrl ? (result as Dto.UserDto).photoThumbnailUrl : "/media/user@4x.png")} /> {(result as Dto.UserDto).fullname}
						</MenuItem>
					))}
					</Menu>
				)}

			/>
		);
	}

	render() {

		let assignedTalent = (this.state.assignedTalent ? this.state.assignedTalent : this.state.assignment.user);

		return(

			<FormFields.Model title={this.props.title} isOpen={this.props.isOpen}
				className="modal-lg" onConfirm={(e) => this.handleSave()}
				onCancel={(e) => this.props.onCancel()}
				onOpened={(e) => this.setState({assignedTalent: null, assignment: (this.props.assignment ? this.props.assignment : this.emptyAssignment(this.state.project))})}
				>

					<div className="form-group">

						<label>Select Team Member</label>

						{assignedTalent ?


							<div className="typeahead-selected">
								<img alt="mention" className="profile-thumbnail mini" src={(assignedTalent.photoThumbnailUrl ? assignedTalent.photoThumbnailUrl : "/media/user@4x.png")} />
								<span className="full-name">{assignedTalent.fullname}</span>
							</div>

							:

							this.renderTypeAhead()
							
						}
					</div>

				<FormFields.Text name="title" title="Title" value={this.state.assignment.title}
						onChange={(e) => this.handleAssignmentChange(e)} placeholder="The role title for the assignment"/>

				<div className="row">
					<div className="col">
						<FormFields.DatePick name="startDate" title="Start Date" value={new Date(this.state.assignment.startDate)} mandatory={true} placeholder="dd/MM/yyyy" onChange={(date) => this.handleDateChange("startDate", date)} />
					</div>
					<div className="col">
						<FormFields.DatePick name="endDate" title="End Date" value={new Date(this.state.assignment.endDate)} mandatory={true} placeholder="dd/MM/yyyy" onChange={(date) => this.handleDateChange("endDate", date)} />
					</div>
				</div>

				<FormFields.Number name="effortDays" title="Effort" format="0.###" suffix="days" value={this.state.effortDays} onChange={(e) => this.handleChange(e)} helpText="The number of days of work" />

				<FormFields.Money name="chargeRate" title="Daily Rate" prefix="$" format="0.00##" value={this.state.assignment.chargeRate ? this.state.assignment.chargeRate : "0.00"} onChange={(e) => this.handleAssignmentChange(e)} helpText="Billable rate to clients, per day" />

				<FormFields.Text name="externalId" title="External Id" value={this.state.assignment.externalId} onChange={(e) => this.handleAssignmentChange(e)} placeholder="This is an optional ID or reference."/>

				<FormFields.TextArea title="Assignment Brief" name="content"
					value={this.state.assignment.brief.content} onChange={(e) => this.handleBriefChange(e)} rows={5}
					placeholder="Describe the job description, accountabilities and responsibilities or the assignment."/>

				<FormFields.Select title="Assignment Brief Author" name="author"
					helpText="Define the role responsibility for completing the assignment brief"
					options={Object.keys(Dto.AssignmentBriefAuthor)} onChange={(e) => this.handleBriefChange(e)}
					value={this.state.assignment.brief.author}
					valueSelector={(key) => key} labelSelector={(key) => this.getAuthorSelectLabel(key)} 
				/>

				<FormFields.Select title="Assignment Brief State" name="state"
					options={Object.keys(Dto.AssignmentBriefState)} onChange={(e) => this.handleBriefChange(e)}
					value={this.state.assignment.brief.state}
					valueSelector={(key) => key} labelSelector={(key) => Utils.titleCase(Dto.AssignmentBriefState[key])} 
				/>

				<FormFields.Checkbox title="Project Manager" name="projectManagerRole"
					value={this.state.assignment.projectManagerRole} inline
					onChange={(e) => this.handleCheckboxChange(e)}
				/>

			</FormFields.Model>

		);
	}
}
