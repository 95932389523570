import * as Http from "./Http"
import * as Dto from "./Dto"

export function talentsLoad(accountId, page, success, failure) {
	Http.get( "/api/accounts/" + accountId + "/talent/page/" + page).then(success).catch(failure);
}

export function talentsBySkillLoad(accountId, skillId, userStatusFilter, page, success, failure) {
	Http.get( "/api/accounts/" + accountId + "/skills/" + skillId + "/talent/page/" + page + (userStatusFilter ? "?filter=" + userStatusFilter : "")).then(success).catch(failure);
}

export function talentsAvailabilityBySkillLoad(accountId, skillId, page, success, failure) {
	Http.get( "/api/accounts/" + accountId + "/skills/" + skillId + "/talent/availability/page/" + page).then(success).catch(failure);
}

export function skillReleatedLoad(accountId, skillId, page, success, failure) {
	Http.get( "/api/accounts/" + accountId + "/skills/" + skillId + "/related/page/" + page).then(success).catch(failure);
}

export function talentsStatisticsLoad(accountId, success, failure) {
	Http.get( "/api/accounts/" + accountId + "/talent/statistics").then(success).catch(failure);
}

export function talentLoad(accountId: number, userId, success: Http.SuccessResponse<Dto.UserDetailedDto>, failure: Http.FailureResponse) {
	Http.get<Dto.UserDetailedDto>( "/api/accounts/" + accountId + "/talent/" + userId).then(success).catch(failure);
}

export function talentAdd(accountId, user, success, failure) {
	Http.post( "/api/accounts/" + accountId + "/talent/add", user).then(success).catch(failure);
}

export function talentResumeUpload(accountId, file, success, failure) {

	Http.postUpload("/api/accounts/" + accountId + "/talent/upload", file).then(success).catch(failure);
}

export function talentOnboard(accountId: number, data: Dto.UserOnboardingForm, success: Http.SuccessResponse<Dto.UserDetailedDto>, failure: Http.FailureResponse) {

	Http.post<Dto.UserOnboardingForm, Dto.UserDetailedDto>( "/api/accounts/" + accountId + "/talent/onboard", data).then(success).catch(failure);

}

export function talentSearch(accountId, query, success, failure) {
	Http.post( "/api/accounts/" + accountId + "/talent/search", {q: query}).then(success).catch(failure);
}

export function talentSearchPaged(accountId, query, page, success, failure) {
	Http.post( "/api/accounts/" + accountId + "/talent/search/page/" + page, {q: query}).then(success).catch(failure);
}

export function talentSearchAdvanced(accountId, criteria: string|any[], page, success, failure) {

	if (!Array.isArray(criteria)) {

		//search with a string query
		Http.post( "/api/accounts/" + accountId + "/talent/search-structured/page/" + page, {query: criteria}).then(success).catch(failure);

	} else {

		var fields: string[] = [];
		var values: string[] = [];
		var operators: string[] = [];

		criteria.forEach((entry) => {
			fields.push(entry.field);
			values.push(entry.value);
			operators.push(entry.operator);
		});


		Http.post(
			"/api/accounts/" + accountId + "/talent/search-structured/page/" + page, 
			{fields: fields, values: values, operators: operators}
		).then(success).catch(failure);


	}
}

export function talentUpdate(accountId, user, success, failure) {
	Http.post( "/api/accounts/" + accountId + "/users/" + user.id, user).then(success).catch(failure);
}

export function talentContactUpdate(accountId, user, success, failure) {
	Http.post( "/api/accounts/" + accountId + "/users/" + user.id + "/contact", user.contact).then(success).catch(failure);
}

export function talentOrganisationUpdate(accountId, user: Dto.UserDetailedDto, success, failure) {
	Http.post<Dto.UserOrganisationForm, Dto.UserDetailedDto>( "/api/accounts/" + accountId + "/users/" + user.id + "/organisation", {department: user.department, managerId: (user.manager ? user.manager.id : undefined ), office: user.office}).then(success).catch(failure);
}


export function talentPreferences(accountId: number, userId: number, success, failure) {
	Http.get<Dto.UserPreferencesDto>( "/api/accounts/" + accountId + "/users/" + userId + "/preferences").then(success).catch(failure);
}

export function talentPreferencesUpdate(accountId, userId: number, preferences: Dto.UserPreferencesForm, success, failure) {
	Http.post<Dto.UserPreferencesForm, Dto.UserPreferencesDto>( "/api/accounts/" + accountId + "/users/" + userId + "/preferences", preferences).then(success).catch(failure);
}