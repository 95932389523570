import SessionStore from "../store/SessionStore";
import * as Dto from "./Dto";
import * as Http from "./Http";


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

	L O G I N

* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


export function login(email, password, success: Http.SuccessResponse<Dto.AuthResultDto>, failure: Http.FailureResponse) {

	Http.post<Dto.LoginForm, Dto.AuthResultDto>("/api/authenticate", {email: email, password: password})
		.then( data => processAuthSuccess(data, success) )
		.catch( errors => processAuthError(errors, failure) );

	//Http.post( "/api/login", {email: email, password: password}).then(success).catch(failure);
}

function processAuthSuccess(data: Dto.AuthResultDto, success: Http.SuccessResponse<Dto.AuthResultDto>) {
	if (data.authenticated) {
		SessionStore.load(data.settings);	//store auth in session
		SessionStore.authToken = data.accessToken;
	} else {
		SessionStore.clear();
	}

	success(data);
}

function processAuthError(errors, failure: Http.FailureResponse) {
	SessionStore.clear();
	failure(errors);
}

export function oauth2Verify(provider: string, code: string, state: string, success: Http.SuccessResponse<Dto.AuthResultDto>, failure: Http.FailureResponse) {

	Http.post<Dto.OAuth2VerifyForm, Dto.AuthResultDto>("/api/oauth2/" + provider + "/verify", {code: code, state: state})
		.then( data => processAuthSuccess(data, success) ) 
		.catch( errors => processAuthError(errors, failure) );

	//Http.post( "/api/login", {email: email, password: password}).then(success).catch(failure);
}

export function logout(success, failure) {
	Http.get( "/api/logout").then(success).catch(failure);
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

	S E T T I N G S

* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


export function settingsLoad(success: Http.SuccessResponse<Dto.SettingsDto>, failure: Http.FailureResponse) {
	let accountId = SessionStore.getAccountId();
	if (accountId) {
		settingsForAccount(accountId, success, failure);
	} else {
		Http.get<Dto.SettingsDto>( "/api/settings")
			.then(data => processSessionSuccess(data, success))
			.catch(error => processSessionError(error, failure));
	}
}

export function settingsForAccount(accountId: number, success: Http.SuccessResponse<Dto.SettingsDto>, failure: Http.FailureResponse) {
	Http.get<Dto.SettingsDto>( "/api/accounts/" + accountId + "/settings")
		.then(data => processSessionSuccess(data, success))
		.catch(error => processSessionError(error, failure));
}

function processSessionSuccess(data: Dto.SettingsDto, success: Http.SuccessResponse<Dto.SettingsDto>) {
	SessionStore.load(data);	//store auth in session
	success(data);
}
function processSessionError(errors, failure: Http.FailureResponse) {
	//SessionStore.clear();
	failure(errors);
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

	O T H E R

* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export function authenticationInfo(email, domain, success, failure) {
	Http.post( "/api/authentication/info", {email: email, domain: domain}).then(success).catch(failure);
}

export function authenticationSettings(accountId: number, success, failure) {
	Http.get( "/api/accounts/" + accountId + "/authentication").then(success).catch(failure);
}

export function authenticationUpdate(accountId: number, authenticationProvider, success, failure) {
	Http.post( "/api/accounts/" + accountId + "/authentication", authenticationProvider).then(success).catch(failure);
}

export function forgotten(email, success, failure) {
	Http.post( "/api/forgotten", {email: email}).then(success).catch(failure);
}

export function passwordUpdate(accountId: number, userId: number, password, success, failure) {
	Http.post( "/api/accounts/" + accountId + "/users/" + userId + "/password", {password: password}).then(success).catch(failure);
}

export function passwordReset(token, password, success, failure) {
	Http.post( "/api/accounts/password/reset/" + token, {token: token, password: password}).then(success).catch(failure);
}

export function join(token, password, success, failure) {
	Http.post( "/api/accounts/join/" + token, {token: token, password: password}).then(success).catch(failure);
}

export function userInvite(accountId: number, userId: number, success, failure) {
	Http.post( "/api/accounts/" + accountId + "/users/" + userId + "/invite", null).then(success).catch(failure);
}

export function userRoleUpdate(accountId: number, userId: number, roles: Dto.UserRoleType[], success: Http.SuccessResponse<Dto.UserDetailedDto>, failure: Http.FailureResponse) {
	Http.post<Dto.UserRolesForm, Dto.UserDetailedDto>( "/api/accounts/" + accountId + "/users/" + userId + "/roles", {roles: roles}).then(success).catch(failure);
}

export function userDisable(accountId: number, userId: number, success, failure) {
	Http.post( "/api/accounts/" + accountId + "/users/" + userId + "/disable", null).then(success).catch(failure);
}

export function userEnable(accountId: number, userId: number, success, failure) {
	Http.post( "/api/accounts/" + accountId + "/users/" + userId + "/enable", null).then(success).catch(failure);
}

export function userStatusUpdate(accountId: number, userId: number, status, success, failure) {
	Http.post( "/api/accounts/" + accountId + "/users/" + userId + "/status", {status: status}).then(success).catch(failure);
}

export function userAvailabilityUpdate(accountId: number, userId: number, availabilityForm: Dto.UserAvailabilityForm, success, failure) {
	Http.post( "/api/accounts/" + accountId + "/users/" + userId + "/availability", availabilityForm).then(success).catch(failure);
}

export function userSearch(accountId: number, q: string, success: Http.SuccessResponse<Dto.UserDto[]>, failure: Http.FailureResponse) {
	Http.post<{q: string}, Dto.UserDto[]>( "/api/accounts/" + accountId + "/users/search", {q: q}).then(success).catch(failure);
}

export function tags(accountId: number, query, success, failure) {
	Http.post( "/api/accounts/" + accountId + "/taxonomy/tags/search", {q: query}).then(success).catch(failure);
}

export function tagsUpdate(accountId: number, userId: number, tags: Dto.TagDto[], success, failure) {
	//reduce tags to list of ids
	var tagIds: any = [];
	tags.forEach((tag) => tagIds.push(tag.id));

	Http.post( "/api/accounts/" + accountId + "/users/" + userId + "/tags/update", {tags: tagIds}).then(success).catch(failure);
}

export function photoUpload(accountId: number, userId: number, file, success, failure) {
	Http.postUpload<Dto.UserDetailedDto>("/api/accounts/" + accountId + "/users/" + userId + "/photo", file).then(success).catch(failure);

}

export function photoDelete(accountId: number, userId: number, success, failure) {
	Http.deleteElement("/api/accounts/" + accountId + "/users/" + userId + "/photo").then(success).catch(failure);

}

export function resumeUpload(accountId: number, userId: number, file, success, failure) {
	Http.postUpload("/api/accounts/" + accountId + "/users/" + userId + "/files/resume", file).then(success).catch(failure);

}

export function resumeUrl(accountId: number, userId: number, filename, success?, failure?) {
	return "/api/accounts/" + accountId + "/users/" + userId + "/resume/" + filename;

}

export function accountUpdate(accountId: number, account, success, failure) {

	Http.post( "/api/accounts/" + accountId, account).then(success).catch(failure);

}

export function accountFeaturesUpdate(accountId: number, features: string[], success: Http.SuccessResponse<Dto.SettingsDto>, failure: Http.FailureResponse) {

	Http.post<Dto.ListForm, Dto.SettingsDto>( "/api/accounts/" + accountId + "/features", {list: features}).then(success).catch(failure);

}

export function accountCreate(form: Dto.AccountCreateForm, success: Http.SuccessResponse<Dto.AuthResultDto>, failure: Http.FailureResponse) {

	Http.post<Dto.AccountCreateForm, Dto.AuthResultDto>("/api/accounts/create", form)
		.then( data => processAuthSuccess(data, success) )
		.catch( errors => processAuthError(errors, failure) );

}

export function accountJoin(data, success, failure) {

	Http.post( "/api/accounts/join", data).then(success).catch(failure);

}

export function job(token, success, failure) {

	Http.get( "/api/jobs/" + token).then(success).catch(failure);

}

export function jobApply(token, application, success, failure) {

	Http.post( "/api/jobs/" + token + "/apply", application).then(success).catch(failure);

}

export function jobResumeUpload(token, file, success, failure) {

	Http.postUpload("/api/jobs/" + token + "/upload", file).then(success).catch(failure);

}