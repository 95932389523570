import * as Dto from "actions/Dto";
import Routes from "actions/Routes";
import * as Utils from "components/general/Utils";
import React from 'react';
import * as Icons from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import SessionStore from "store/SessionStore";


//export default class Assignment extends React.Component<AssignmentProps, {}> {

interface AssignmentProps {
	index: number
	assignment: Dto.AssignmentDto
	project: Dto.ProjectDto
	timeEntrySummaries: Dto.TimeEntrySummaryDto[]
	onEdit: (assignmentId: number) => void
	onEditBrief: (assignmentId: number) => void
	onDelete: (assignmentId: number) => void
	onEditSubscribers: (assignmentId: number) => void
}

export default function Assignment(props: AssignmentProps) {

	const handleOnEdit = (assignmentId: number) => {
		if (SessionStore.isWriteAccessToProject(props.project)) {
			props.onEdit(assignmentId)
		}
	}

	const handleOnEditBrief = (assignmentId: number) => {

		if (SessionStore.isWriteAccessToProject(props.project) || props.assignment.user.id === SessionStore.userId) {
			props.onEditBrief(assignmentId);
		}
	}

	const handleOnDelete = (assignmentId: number) => {
		if (SessionStore.isWriteAccessToProject(props.project)) {
			if (window.confirm("Delete this assignment?")) {
				props.onDelete(assignmentId);
			}
		}
	}

	const handleOnEditSubscribers = (assignmentId: number) => {
		if (SessionStore.isWriteAccessToProject(props.project)) {
			props.onEditSubscribers(assignmentId);
		}
	}

	let timeEntrySummary = (props.timeEntrySummaries ?
		props.timeEntrySummaries.find((entry) => {
			//console.log(entry.assignment.id, entry.assignment.id === props.assignment.id);
			return entry.assignment.id === props.assignment.id;
		})
		:null
	);

	const renderSimpleState = () => {
		return <div className={"badge badge-pill status " + props.assignment.state}>{props.assignment.state}</div>
	}

	const renderState = () => {

		// Assignment is cancelled or finished
		if ( props.assignment.state === Dto.AssignmentState.CANCELLED || props.assignment.state === Dto.AssignmentState.FINISHED ) {
			return renderSimpleState();
		}

		// User doesn't have access to edit project brief
		if (!SessionStore.isWriteAccessToProject(props.project) && props.assignment.user.id !== SessionStore.userId) {
			return renderSimpleState();
		}

		//If brief is complete, then just show assignment status
		if ( props.assignment.brief.state === Dto.AssignmentBriefState.APPROVED || props.assignment.brief.state === Dto.AssignmentBriefState.NONE ) {
			return renderSimpleState();
		}

		//Brief is not complete, so show brief status
		switch (props.assignment.brief.state) {
			case Dto.AssignmentBriefState.DRAFT:
				return <div className={"badge badge-pill status status-brief DRAFT" } onClick={() => handleOnEditBrief(props.assignment.id)}>Draft Brief</div>
			case Dto.AssignmentBriefState.SUBMITTED:
				return <div className={"badge badge-pill status status-brief SUBMITTED" } onClick={() => handleOnEditBrief(props.assignment.id)}>Brief submitted</div>
			case Dto.AssignmentBriefState.REJECTED:
				return <div className={"badge badge-pill status status-brief REJECTED" } onClick={() => handleOnEditBrief(props.assignment.id)}>Brief rejected</div>
			default:
				return renderSimpleState();
		}

	}

	return(

		<div className={"assignment assigned status-" + props.assignment.state}>

			<div className="index" title={props.assignment.id + ""}>
				{props.index}
			</div>

			<div className={props.assignment.projectManagerRole ? "thumbnail project-manager" : "thumbnail"}>
				
					<Link to={Routes.userProfile(props.assignment.user.id)}>
						<img className="profile-thumbnail" src={props.assignment.user.photoThumbnailUrl ? props.assignment.user.photoThumbnailUrl : "/media/user@4x.png"} alt="User"/>
					</Link>
				
			</div>
			
			<div className="name">
				
					<h5 title={props.assignment.user.firstname + " " + props.assignment.user.surname}>
						<Link to={Routes.userProfile(props.assignment.user.id)}>
							<span className="firstname">{props.assignment.user.firstname}</span> <span className="surname">{props.assignment.user.surname}</span>
						</Link>
					</h5>
					
				<div className="headline" title={props.assignment.title}>{props.assignment.title}{props.assignment.projectManagerRole ? "/ Project Manager" : ""}</div>
				
			</div>
			<div className="status-icons">
				{props.assignment.subscriberCount > 0 ? <Icons.BellFill className="clickable"  onClick={() => handleOnEditSubscribers(props.assignment.id)} /> : ""}
			</div>

			<div className={"status-pills"}>
				{renderState()}
			</div>

			<div className="start-date">{Utils.formatDateWithMonth(props.assignment.startDate)}</div>
			<div className="end-date">{Utils.formatDateWithMonth(props.assignment.endDate)}</div>
			<div className="duration">{props.assignment.durationDays > 7 ? <React.Fragment>{Math.floor(props.assignment.durationDays / 7)} <span className="units">weeks</span></React.Fragment> : <React.Fragment>{props.assignment.durationDays} <span>days</span></React.Fragment>}</div>
			<div className="booked">{timeEntrySummary && timeEntrySummary.billableQuantity ? Utils.formatNumber(timeEntrySummary.billableQuantity, "#,##0.##") : "-"}<span className="units">days</span></div>
			<div className="effort">{props.assignment.effort ? Utils.formatNumber(props.assignment.effort, "#,##0.###") : "-"}<span className="units">days</span></div>
			{SessionStore.isWriteAccessToProject(props.project) ?

				<>
					<div className="rate">{props.assignment.chargeRate ? <>{Utils.formatMoney(props.assignment.chargeRate)}<span className="units">per day</span></> : "-"}</div>
					<div className="billable">{timeEntrySummary && timeEntrySummary.billableAmount ? Utils.formatMoney(timeEntrySummary.billableAmount) : "-"}</div>
					<div className="tools">
					
						<button className="btn btn-icon glyphicon-button" onClick={() => handleOnEditSubscribers(props.assignment.id)} title="Edit Timesheet Subscribers">{props.assignment.subscriberCount > 0 ? <Icons.BellFill /> : <Icons.Bell />}</button>
						<button className="btn btn-icon glyphicon-button" onClick={() => handleOnEditBrief(props.assignment.id)} title="Edit Assignment Brief"><Icons.FileEarmarkText /></button>
						<button className="btn btn-icon glyphicon-button" onClick={() => handleOnEdit(props.assignment.id)} title="Edit Assignment"><Icons.Pencil /></button>
						<button className="btn btn-icon glyphicon-button" onClick={() => handleOnDelete(props.assignment.id)} title="Delete Assignment"><Icons.Trash /></button>
					</div>
				</>
				: 
				(
					props.assignment.user.id === SessionStore.userId ?
					<div className="tools">
						<button className="btn btn-icon glyphicon-button" onClick={() => handleOnEditBrief(props.assignment.id)} title="Edit Assignment Brief"><Icons.Pencil /></button>
					</div>
					: null
				)
			}
		</div>

	);
	

}