import { ErrorDto, LeaveTypeDto, LeaveTypeForm } from "actions/Dto";
import * as TimesheetsActions from "actions/TimesheetsActions";
import { IconButton } from "components/general/IconButton";
import React, { useEffect, useState } from "react";
import * as Icons from 'react-bootstrap-icons';
import SessionStore from "store/SessionStore";
import * as FormFields from "components/forms/FormFields";

interface LeaveTypesProps {
	onError: (errors: ErrorDto[]) => void
}

export const LeaveTypes: React.FunctionComponent<LeaveTypesProps> = (props) => {

	const [ leaveTypes, setLeaveTypes] = useState<LeaveTypeDto[]|null>(null);
	
	const [ editLeaveType, setEditLeaveType ] = useState<LeaveTypeDto>();
	const [ addLeaveType, setAddLeaveType ] = useState<LeaveTypeDto>();

	useEffect(() => {

		if (SessionStore.accountId) {

			TimesheetsActions.leaveTypes(SessionStore.accountId,
				(leaveTypes) => setLeaveTypes(leaveTypes.values),
				(errors) => props.onError(errors)
			);
			
		}


	}, []);




		/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


	const handleAddLeaveType = () => {
		setAddLeaveType({} as LeaveTypeDto);
	}


	const handleSave = () => {


		if (editLeaveType && SessionStore.accountId) {

			const form = { ...editLeaveType } as LeaveTypeForm;

			TimesheetsActions.leaveTypesSave(SessionStore.accountId, editLeaveType.id, form,
				(leaveTypes) => {
					setLeaveTypes(leaveTypes.values);
					setEditLeaveType(undefined);
				},
				(errors) => props.onError(errors)
			);
			
		}

	}

	const handleAdd = () => {


		if (addLeaveType && SessionStore.accountId) {

			const form = { ...addLeaveType } as LeaveTypeForm;

			TimesheetsActions.leaveTypeCreate(SessionStore.accountId, form,
				(leaveTypes) => {
					setLeaveTypes(leaveTypes.values);
					setAddLeaveType(undefined);
				},
				(errors) => props.onError(errors)
			);
			
		}

	}

	const handleEditChange = (e) => {
		
		let form = {...editLeaveType};
		form[e.target.name] = e.target.value;
		setEditLeaveType(form as LeaveTypeDto);

	}

	const handleAddChange = (e) => {
		
		let form = {...addLeaveType};
		form[e.target.name] = e.target.value;
		setAddLeaveType(form as LeaveTypeDto);

	}

	const handleDelete = (leaveTypeId: number) => {
		if (window.confirm("Delete holiday?") && SessionStore.accountId) {
			TimesheetsActions.leaveTypeDelete(SessionStore.accountId, leaveTypeId,
				(leaveTypes) => setLeaveTypes(leaveTypes.values),
				props.onError
			);
		}
 	}
	
	
	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
		*
		*      R E N D E R E R S
		*
		* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	return (

		<>
			<div className="row">
				<div className="col-md-8">
					<h2>Leave Types</h2>
				</div>
				<div className="col-md-4">
					<div className="controls"><IconButton icon={<Icons.PlusLg />} onClick={handleAddLeaveType} /></div>
				</div>
			</div>
			<br />
			<table className="table table-striped">
				<thead>
					<tr >
						<td>Name</td>
						<td>Description</td>
						<td>Code</td>
						<td>Action</td>
					</tr>
				</thead>
				<tbody>
					{leaveTypes && leaveTypes.length > 0 ?
						leaveTypes.map((leaveType, index) => {
							return (
								<tr className="settings-tile" key={leaveType.id}>
									<td>{leaveType.title}</td>
									<td>{leaveType.description}</td>
									<td>{leaveType.code}</td>
									<td>
										<div className="controls">
											<IconButton onClick={() => setEditLeaveType({...leaveType})} icon={<Icons.Pencil />} />
											<IconButton onClick={() => handleDelete(leaveType.id)} icon={<Icons.Trash />} />
										</div>
									</td>
								</tr>
							);
						})
						: <tr className="placeholder"><td colSpan={4}>(No leave types have been defined</td></tr>
					}
				</tbody>
			</table>

			<FormFields.Model title="Edit Leave Type" isOpen={!!editLeaveType}
				onHide={(e) => setEditLeaveType(undefined)} onCancel={(e) => setEditLeaveType(undefined)} onConfirm={(e) => handleSave()} >
				
				<FormFields.Text name="title" title="Title" placeholder="Title" value={editLeaveType?.title} onChange={(e) => handleEditChange(e)} />
				<FormFields.Text name="code" title="Code" placeholder="CODE" value={editLeaveType?.code} onChange={(e) => handleEditChange(e)} />
				<FormFields.Text name="description" title="Descriptiom" placeholder="Description" value={editLeaveType?.description} onChange={(e) => handleEditChange(e)} />

			</FormFields.Model>

			<FormFields.Model title="Add Leave Type" isOpen={!!addLeaveType}
				onHide={(e) => setAddLeaveType(undefined)} onCancel={(e) => setAddLeaveType(undefined)} onConfirm={(e) => handleAdd()} >
				
				<FormFields.Text name="title" title="Title" placeholder="Title" value={editLeaveType?.title} onChange={(e) => handleAddChange(e)} />
				<FormFields.Text name="code" title="Code" placeholder="CODE" value={editLeaveType?.code} onChange={(e) => handleAddChange(e)} />
				<FormFields.Text name="description" title="Descriptiom" placeholder="Description" value={editLeaveType?.description} onChange={(e) => handleAddChange(e)} />

			</FormFields.Model>
	</>

	);

}