import React, { useEffect, useState } from 'react';
import * as FormFields from "components/forms/FormFields"
import * as Dto from "actions/Dto"
import SessionStore from "store/SessionStore"
import * as Utils from "components/general/Utils"
import * as ProjectActions from "actions/ProjectActions"

interface AssignmentBriefEditFormProps {
	title: string
	isOpen: boolean
	assignment: Dto.AssignmentDto
	onUpdated: (assignment: Dto.AssignmentDto) => void
	onCancel: () => void
	onError: (errors: Dto.ErrorDto[]) => void
}


export const AssignmentBriefEditForm: React.FunctionComponent<AssignmentBriefEditFormProps> = (props) => {
	
	//private typeahead = React.createRef();

	const [ form, setForm ] = useState<Dto.AssignmentBriefForm>({} as Dto.AssignmentBriefForm);

	useEffect(() => {
		
		setForm(props.assignment.brief)

	}, [props.assignment]);

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


	const handleBriefChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

		var _form = {...form} as any;
		_form[event.target.name] = event.target.value;
		setForm(_form);

	}


	const handleSave = (state: Dto.AssignmentBriefState) => {

		if (!SessionStore.accountId) return;

		var _form = { ...form } ;
		_form.state = state;
		setForm(_form);

		ProjectActions.assignmentBriefUpdate(SessionStore.accountId, props.assignment.id, _form,
			(assignment) => {
				props.onUpdated(assignment);
			},
			(errors) => props.onError(errors)
		);

	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */



	const extraButtons = () => {

		switch (confirmButtonAction()) {

			case Dto.AssignmentBriefState.DRAFT:
				//No extra buttons if main button is save
				return <></>;
			default:
				//Add reject and save if main button is not already save
				return (
					<>
						<button className="btn" onClick={() => handleSave(Dto.AssignmentBriefState.REJECTED)}>Reject</button>
						<button className="btn" onClick={() => handleSave(Dto.AssignmentBriefState.DRAFT)}>Save</button>
					</>
				);
		}

	} 

	const confirmLabel = () => {
		switch (confirmButtonAction()) {
			case Dto.AssignmentBriefState.APPROVED:
				return "Approve";
			case Dto.AssignmentBriefState.DRAFT:
				return "Save";
			default:
				return "Submit";
		}
	}

	const confirmButtonAction = () => {
		if (props.assignment.brief.state === Dto.AssignmentBriefState.APPROVED) {
			return Dto.AssignmentBriefState.DRAFT;
		}
		if (canApprove()) {
			return Dto.AssignmentBriefState.APPROVED;
		}
		return Dto.AssignmentBriefState.SUBMITTED;
	}

	const canApprove = () => {
		return props.assignment.brief.state === Dto.AssignmentBriefState.SUBMITTED && SessionStore.isWriteAccessToProject(props.assignment.project);
	}

	return(

		<FormFields.Model title="Assignment Brief" isOpen={props.isOpen} commitClass={canApprove() ? "btn btn-success" : undefined}
			className="modal-lg" onConfirm={() => handleSave(confirmButtonAction())}
			onCancel={(e) => props.onCancel()} confirmLabel={confirmLabel()}
			extraButtons={extraButtons()}
			>
			
			<div className="form-group">

				<label>Assignment Brief</label>

				<div className="typeahead-selected readonly">
					<img alt="mention" className="profile-thumbnail mini" src={(props.assignment.user.photoThumbnailUrl ? props.assignment.user.photoThumbnailUrl : "/media/user@4x.png")} />
					<span className="full-name">{props.assignment.user.fullname}</span>
				</div>

			</div>

			<FormFields.Text name="title" title="Title" readOnly={true}  value={props.assignment.title} />

			<div className="row">
				<div className="col">
					<FormFields.Text readOnly={true} name="startDate" title="Start Date" value={Utils.formatDate(props.assignment.startDate)}  />
				</div>
				<div className="col">
					<FormFields.Text readOnly={true} name="endDate" title="End Date" value={Utils.formatDate(props.assignment.endDate)}  />
				</div>
			</div>

			<FormFields.Text name="status" title="Status" readOnly={true}  value={props.assignment.brief.state} />
			<FormFields.TextArea title="Brief details" name="content"
				value={form.content} onChange={(e) => handleBriefChange(e)} rows={10}
				placeholder="Describe the job description, accountabilities and responsibilities or the assignment."/>

		</FormFields.Model>

	);

}
