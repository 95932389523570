import React from 'react';
import * as Icons from 'react-bootstrap-icons';
import { IconButton } from 'components/general/IconButton';
import * as FormFields from "components/forms/FormFields";
import * as AccountActions from "actions/AccountActions"
import * as TalentActions from "actions/TalentActions";
import ErrorMessages from "components/general/ErrorMessages";

export default class UploadStep extends React.Component {

	state = {
		upload: null, uploading: false, file: null
	}

	handleFileDrop(files) {

		if (files.length <= 0) return;

		let file = files[0];

		var form = new FormData();
		form.append("file", file);

		this.setState({file: file.name, uploading: true, errors: null, upload: null});

		if(!this.props.token && this.props.accountId) {

			TalentActions.talentResumeUpload(this.props.accountId,
				form,
				(data) => {
					this.setState({upload: data, uploading: false});
					if (this.props.onChange) this.props.onChange(data.filename);
				},
				(errors) => {this.setState({errors: errors})}
			);

		} else {

			AccountActions.jobResumeUpload(this.props.token,
				form,
				(data) => {
					this.setState({upload: data, uploading: false});
					if (this.props.onChange) this.props.onChange(data.filename);
				},
				(errors) => {this.setState({errors: errors})}
			);

		}

	}

	renderStatus() {

		if (this.state.uploading) {
			return (
				<div>
					<div className="progress">
						<div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{width: "100%"}}>
						</div>
					</div>
					<div>Uploading: {this.state.file}</div>
				</div>
			);
		} else if (this.state.upload) {
			return <span class="pill">Uploaded: {this.state.file}</span>
		}
		return <div>Drag and Drop your resume here to upload</div>;
	}

	handleClick(e) {
		this.refs.fileUploader.click();
	}

	render() {

		if (this.props.step !== this.props.index) return null;

		return (

			<React.Fragment>

				<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages} />

				<input type="file" id="file" ref="fileUploader" style={{display: "none"}} onChange={(e) => this.handleFileDrop(e.target.files)}/>

				<h4>Attach Resume</h4>
				<p>
				{this.props.helpText ?
					this.props.helpText
					: "Upload your resume. PDF and Word documents are supported."
				}
				</p>
				<FormFields.DragAndDrop className="drop-zone" handleDrop={(files) => this.handleFileDrop(files)}>
					<div className="row">
						<div className="col-2">
							<IconButton icon={<Icons.CloudUpload />} className="huge" onClick={(e) => this.handleClick(e)}/>
						</div>
						<div className="col">
							{this.renderStatus()}
						</div>
					</div>


				</FormFields.DragAndDrop>

			</React.Fragment>

		);
	}

}