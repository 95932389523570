import React from 'react';
import { Link } from "react-router-dom"

interface IconProps {
	className?: string
	to?: any
	href?: string|null
	label?: string
	title?: string
	target?: string
	icon: JSX.Element
	onClick?: () => void
}
export const IconButton: React.FunctionComponent<IconProps> = (props) => {

		if (props.to) {
			return (
				<Link className="btn btn-icon glyphicon-button" target={props.target} onClick={props.onClick} title={props.title} to={props.to}>
					<div className={"glyphicon"  + (props.className ? " " + props.className : "")}>{props.icon}</div>
					{props.label ? <label>{props.label}</label> : null}
				</Link>
			);
		} else if (props.href) {
			return (
				<a className="btn btn-icon glyphicon-button" target={props.target} onClick={props.onClick} title={props.title} href={props.href}>
					<div className={"glyphicon"  + (props.className ? " " + props.className : "")}>{props.icon}</div>
					{props.label ? <label>{props.label}</label> : null}
				</a>
			);
		} else if (props.onClick) {
			return (
				<button className="btn btn-icon glyphicon-button" onClick={props.onClick} title={props.title} >
					<div className={"glyphicon"  + (props.className ? " " + props.className : "")}>{props.icon}</div>
					{props.label ? <label> {props.label}</label> : null}
				</button>
			);
		} else {
			if (!props.label) return <div className={"icon"  + (props.className ? " " + props.className : "")}>{props.icon}</div>;
			return (
				<span className="glyphicon-labelled">
					<div className={"glyphicon"  + (props.className ? " " + props.className : "")}>{props.icon}</div>
					{props.label}
				</span>
			);
		}

}
