import React from 'react';
import Routes from "actions/Routes"
import { Link } from "react-router-dom"
import * as FormFields from "components/forms/FormFields"

export default class TalentAvailability extends React.Component {

	constructor(props) {
		super(props);
		this.state = {profile: props.profile, user: props.user, skill: props.skill};
	}

	unedit() {
		this.setState({editType: null});
	}


	render() {

		let props = this.state;

		let assignment = this.props.assignment;

		var availability = null;
		if (assignment) {
			availability = (
				<React.Fragment>
					<div><Link to={Routes.project(assignment.project.id)}>{assignment.project.organisation} / {assignment.project.title}</Link></div>
					<div>Finish: <span className="end-date">{assignment.endDate}</span></div>
				</React.Fragment>
			);
		} else {
			if (this.props.user.availability) {
				availability = "Available: " + this.props.user.availability;
			} else {
				availability = "Available now";
			}
		}

		return(

			<div className="skill-availability">

				<div className="thumbnail">
					<Link to={Routes.userProfile(props.user.id)}><img className="profile-thumbnail" src={props.user.photoThumbnailUrl ? props.user.photoThumbnailUrl : "/media/user@4x.png"} alt="User"/></Link>
					<div className="strength"><FormFields.FiveStarsDisplayOnly value={props.skill.strength} /></div>
				</div>

				<div className="details">
					<div className="recent-talent tile" key={"user-" + props.user.id}>

						<h5 className="name" title={props.user.firstname + " " + props.user.surname}>
							<Link to={Routes.userProfile(props.user.id)}>
								<div className="firstname">{props.user.firstname}</div> <div className="surname">{props.user.surname}</div>
							</Link>
						</h5>
						<div className="headline" title={props.profile.headline}>{props.profile.headline}</div>
						<small className="summary" >{props.profile.summary}</small>
					</div>
				</div>

				<div className="extras">
					<div className="available-when">
						{availability}
					</div>
				</div>

			</div>

		);
	}

}