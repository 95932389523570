import React from 'react';
import { Route, Switch } from "react-router-dom";

import * as Http from "actions/Http";
import Routes from "actions/Routes";
import SessionStore from "store/SessionStore";
import AccountCreatePage from "../pages/AccountCreatePage";
import AccountJoinedPage from '../pages/AccountJoinedPage';
import LoginPage from "../pages/LoginPage";
import CommunityPage from '../pages/community/CommunityPage';
import ProfilePage from "../pages/profile/ProfilePage";
import ProjectPage from '../pages/projects/ProjectPage';
import ProjectsPage from '../pages/projects/ProjectsPage';
import SettingsPage from "../pages/settings/SettingsPage";
import TemplateEdit from "../pages/settings/TemplateEdit";
import TemplateSettingsPage from "../pages/settings/TemplateSettingsPage";
import { TimesheetSettingsPage } from "../pages/settings/TimesheetSettingsPage";
import SkillPage from "../pages/skills/SkillPage";
import SkillsPage from "../pages/skills/SkillsPage";
import TalentAvailabilityBySkillPage from "../pages/skills/TalentAvailabilityBySkillPage";
import TalentBySkillPage from "../pages/skills/TalentBySkillPage";
import TimesheetsPage from '../pages/timesheets/TimesheetsPage';
import UserTimesheetsPage from '../pages/timesheets/UserTimesheetsPage';
import AssignmentsPage from "../pages/users/AssignmentsPage";
import JoinPage from "../pages/users/JoinPage";
import TalentsPage from "../pages/users/TalentsPage";
import UserAddPage from "../pages/users/UserAddPage";
import UserEditPage from "../pages/users/UserEditPage";
import UserOnboardingPage from "../pages/users/UserOnboardingPage";
import AdvancedSearchPage from "../search/AdvancedSearchPage";
import SearchResults from "../search/SearchResults";

import Footer from './Footer';
import { Nav } from './navigation/Nav';



export default class AuthenticatedLayout extends React.Component {

	constructor(props) {
		super(props);
		this.state = {accountId: SessionStore.getAccountId(), menuToggle: false};
	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	render() {

		if (SessionStore.isUserRole()) {

			return (
				<>
					{ SessionStore.getAccountId() ? <link rel="stylesheet" type="text/css" href={Http.withHost(Routes.accountStylesheet(SessionStore.getAccountId()))} /> : null }
					<div className="console-layout authenticated-layout">

						<Route component={Nav} />

						<div className="console-body">

							<Switch>

								<Route path={Routes.userProfile(":userId")} component={ProfilePage} />
								<Route path="/profiles/:email" component={ProfilePage} />
								<Route path={Routes.myProfile()} component={ProfilePage} />

								<Route path={Routes.userEdit(":userId")} component={UserEditPage} />
								<Route path={Routes.userAssignments(":userId")} component={AssignmentsPage} />
								<Route path={Routes.userAdd()} component={UserAddPage} />

								{SessionStore.isManagerRole() ?
									<Route path={Routes.timesheets()} component={TimesheetsPage} />
									: <Route path={Routes.timesheets()} component={UserTimesheetsPage} />
								}
								<Route path={Routes.timesheetsForUser(":userId", ":date")} component={UserTimesheetsPage} />
								<Route path={Routes.timesheetsForUser(":userId")} component={UserTimesheetsPage} />

								<Route path="/talent/onboard" component={UserOnboardingPage} />
								<Route path={Routes.talentSearchAdvanced()} component={AdvancedSearchPage} />
								<Route path={Routes.talentSearch()} component={SearchResults} />
								<Route path={Routes.talent()} component={TalentsPage} />

								<Route path="/settings/templates/:templateId" component={TemplateEdit} />
								<Route path={Routes.settingsForTemplates()} component={TemplateSettingsPage} />
								<Route path={Routes.settingsForTimesheets()} component={TimesheetSettingsPage} />
								<Route path={Routes.settings()} component={SettingsPage} />

								<Route path={Routes.skillsEdit(":skillId")} component={SkillPage} />
								<Route path={Routes.skillsTalentAvailability(":skillTypeId")} component={TalentAvailabilityBySkillPage} />
								<Route path={Routes.skillsTalent(":skillTypeId")} component={TalentBySkillPage} />
								<Route path="/skills/categories/:category" component={SkillsPage} />
								<Route path={Routes.skills()} component={SkillsPage} />

								<Route path={Routes.accountCreate()} component={AccountCreatePage} />

								<Route path={Routes.community()} component={CommunityPage} />

								<Route path={Routes.project(":projectId")} component={ProjectPage} />
								<Route path={Routes.projects()} component={ProjectsPage} />

								<Route path={Routes.accountJoined()} component={AccountJoinedPage} />
								<Route path={Routes.login()} component={LoginPage} />
								<Route path="/join/:token" component={JoinPage} />
								
								<Route component={TalentsPage} />
							</Switch>

						</div>
						<Footer />
					</div>
				</>
			);

		} else {

			return (
				<>
					{ SessionStore.getAccountId() ? <link rel="stylesheet" type="text/css" href={Http.withHost(Routes.accountStylesheet(SessionStore.getAccountId()))} /> : null }
					<div className="console-layout authenticated-layout guest">

						<Route component={Nav} />

						<div className="console-body">

							<Switch>
								<Route path={Routes.userProfile(":userId")} component={ProfilePage} />
								<Route path={Routes.myProfile()} component={ProfilePage} />
								<Route path={Routes.userEdit(":userId")} component={UserEditPage} />
								<Route path={Routes.timesheets()} component={UserTimesheetsPage} />
								<Route path={Routes.timesheetsForUser(":userId", ":date")} component={UserTimesheetsPage} />
								<Route path={Routes.timesheetsForUser(":userId")} component={UserTimesheetsPage} />
								<Route path={Routes.login()} component={LoginPage} />
								<Route path="/join/:token" component={JoinPage} />
								<Route component={ProfilePage} />
							</Switch>

						</div>
						<Footer />
					</div>
				</>
			);

		}
	}

}