import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';

import * as TimesheetsActions from "actions/TimesheetsActions";

import Routes from "actions/Routes";
import SessionStore from "../../../store/SessionStore";
import SectionBody from "components/layouts/SectionBody"
import { SettingsContextMenu } from "./SettingsContextMenu";
import * as Dto from "actions/Dto";
import * as Icons from 'react-bootstrap-icons';

import ReactGA from 'react-ga';

import './Settings.css';
import { IconButton } from "components/general/IconButton";
import { LeaveTypes } from "./LeaveTypes";
import { Holidays } from "./Holidays";



export const TimesheetSettingsPage: React.FunctionComponent<RouteComponentProps> = (props) => {

	const [messages, setMessages] = useState<Dto.MessageDto[]|null>(null);
	const [errors, setErrors] = useState<Dto.ErrorDto[]|null>(null);
	
	

	useEffect(() => {

		if (!SessionStore.isAdminRole()) {
			props.history.push("/");
		}

		ReactGA.pageview(Routes.settingsForTimesheets());	//analytics

		


	}, [props.history]);

	const clearErrors = () => {
		setMessages(null);
		setErrors(null);
	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


	const handleAddHoliday = () => {
		
	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	if (!SessionStore.isAdminRole()) {
		return <section className="settings">No access</section>
	}

	let topMenuItems = <SettingsContextMenu path={props.location.pathname} />;

	return (

		<SectionBody className="settings"
			onNotificationClose={() => clearErrors()}
			errors={errors} messages={messages} 
			topMenuItems={topMenuItems}
			>

			<div className="row justify-content-md-center">

				<div className="col-7">
					<h1>Timesheet Settings</h1>

					<LeaveTypes onError={setErrors}/>
					<hr/>

					<Holidays onError={setErrors}/>
					

				</div>

			</div>


		</SectionBody>
	);

}
