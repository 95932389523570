import React from 'react';
import { Link } from "react-router-dom"

import Routes from "actions/Routes"
import SessionStore from "store/SessionStore"
import * as ProjectActions from "actions/ProjectActions"
import Glyphicon from "components/general/Glyphicon"
import * as Utils from "components/general/Utils"
import AbstractDataPage from './../AbstractDataPage'
import ProjectEditForm from './ProjectEditForm';

import "./Projects.css"

const FILTER_KEY = "project-filter";

export default class ProjectsPage extends AbstractDataPage {

	pagePath() {
		return Routes.projects();
	}

	initialiseState(props) {
		return { 
			...this._defaultState,
			quickFilter: localStorage.getItem(FILTER_KEY)
		}
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	loadData(page) {

		if (page < 0) page = 0;

		ProjectActions.projects(this.state.accountId, page, this.state.quickFilter,
			(data) => this.handleLoadSuccessCallback(data),
			(errors) => this.handleErrorCallback(errors)
		);

	}

	loadMore(page) {

		ProjectActions.projects(this.state.accountId, this.state.data.index + 1, this.state.quickFilter,
			(data) => this.handleLoadMoreSuccessCallback(data),
			(errors) => this.handleErrorCallback(errors)
		);

	}

	handleProjectAddSubmit(project) {
		ProjectActions.projectAdd(SessionStore.getAccountId(), project,
			(project) => this.props.history.push("/projects/" + project.id + "/edit"),
			(errors) => this.setState({errors: errors})
		);
	}

	handleFilterSubmit() {


		//Store in local storage
		if (this.state.quickFilter) {
			localStorage.setItem(FILTER_KEY, this.state.quickFilter);
		} else {
			localStorage.removeItem(FILTER_KEY);
		}

		super.handleFilterSubmit();
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	title() {
		return "Projects";
	}

	renderDataItem(project) {

		return (
			<div key={project.id} className={"project status-" + project.status.toLowerCase() + (project.selected ? " selected" : "")}>


				<div className="details">
					<h5 className="name" title={project.title}><Link to={Routes.project(project.id)}>
						{Utils.splitTwoLines(project.title, " ")}
					</Link></h5>
					<div className="headline" title={project.organisation}>{project.organisation}</div>
					<small className="summary" title={project.description}>{project.description}</small>

				</div>

				<div className="footer">
					{project.status === 'PENDING' && SessionStore.isManagerRole() ? <small className="error">Pending</small> : null}
				</div>

				<div className="tools">
					<Link className="btn btn-icon glyphicon-button" to={Routes.project(project.id)} title="Edit Project"><Glyphicon name="pencil" /></Link>
				</div>

			</div>
		);
	}

	renderAddDataItem() {

		if (SessionStore.isManagerRole()) {
			return (
				<div className="project add-new">
					<button className="add-label" onClick={() => this.setState({edit: "add"})}><Glyphicon name="plus" className="grey" label="Add Project"/></button>
					<ProjectEditForm  title="Add Project" isOpen={this.state.edit === "add"} onCancel={() => this.setState({edit: null})} onConfirm={(project) => this.handleProjectAddSubmit(project)} />
				</div>
			)
		}

		return null;

	}

	renderActionsDropdown() {

		if (SessionStore.isManagerRole()) {
			return (
				<div className="dropdown">
					<button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
						<Glyphicon name="cogwheel" className="invert" />
					</button>
					<div className="dropdown-menu dropdown-menu-right">
						<Link className="dropdown-item disabled" to={"#"}>Add Project</Link>
						<button className="dropdown-item" onClick={()=> this.clearFilter() }>Clear filter</button>
						</div>
				</div>
			);
		} else {
			return null;
		}

	}

}