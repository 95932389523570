import React from 'react';

import SessionStore from "store/SessionStore"
import ErrorMessages from "components/general/ErrorMessages"
import * as FormFields from "components/forms/FormFields"
import * as AccountActions from "actions/AccountActions"
import { IconButton } from "components/general/IconButton"
import * as Icons from 'react-bootstrap-icons';

import './Settings.css';

export default class Company extends React.Component {

	constructor(props) {
		super();
		this.state = {account: SessionStore.getAccount(), edit: null}
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	unedit() {
		this.setState({edit: null});
	}

	handleChange(event) {
		this.setState({errors:null, messages: null});
		var account = this.state.account;
		account[event.target.name] = event.target.value;
		this.setState({account: account});
	}

	handleSubmit(event) {
		AccountActions.accountUpdate(SessionStore.getAccountId(), this.state.account,
			() => this.setState({edit: null, messages: ["Account details saved"]}),
			(errors) => this.setState({errors: errors})
		);
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


	render() {
		
		return (

			<div className="company-settings">

				<ErrorMessages errors={this.state.errors} component={this} messages={this.state.messages} />

				<div className="settings-tile">

					<h4>Company Details</h4>
					<div>Name: <span className="setting-value">{this.state.account.name}</span></div>
					<div>Domain name: <span className="setting-value">{this.state.account.domain != null && this.state.account.domain.length > 0 ? this.state.account.domain : <span className="placeholder">(none)</span>}</span></div>
					{this.state.account.secondaryDomains != null && this.state.account.secondaryDomains.length > 0 ?
						<div>Secondary domains: {this.state.account.secondaryDomains.map((domain, index) => <span className="setting-value">{index > 0 ? ", " : null}{domain}</span>)}</div>
						: null
					}

					<div className="controls">
						<IconButton onClick={() => this.setState({edit: "details"})} icon={<Icons.Pencil />} />
					</div>

				</div>

				<div className="settings-tile">

					<h4>Skills</h4>
					Roles able to create skills: <span className="setting-value">{this.state.account.skillTypeCreateMinimumRole}</span>
					<small>This setting determines which users are able to create new skills.</small>

					<div className="controls">
						<IconButton onClick={() => this.setState({edit: "details"})} icon={<Icons.Pencil />} />
					</div>


				</div>

				<FormFields.Model title="Edit Details" isOpen={this.state.edit === "details"}
					onHide={(e) => this.unedit()} onCancel={(e) => this.unedit()} onConfirm={(e) => this.handleSubmit(e)} >

					<FormFields.Text name="name" title="Company Name" placeholder="Company Name" value={this.state.account.name} onChange={(e) => this.handleChange(e)} />
					<FormFields.Text name="domain" title="Domain" placeholder="Domain" value={this.state.account.domain} onChange={(e) => this.handleChange(e)} />
					<FormFields.Select name="skillTypeCreateMinimumRole" title="Skills Creation"  value={this.state.account.skillTypeCreateMinimumRole}
						options={['GUEST', 'USER', 'MANAGER', 'ADMIN']} onChange={(e) => this.handleChange(e)} helpText="This is the lowest level role that can create new Skills" />

				</FormFields.Model>


				<div className="settings-tile">

					<h4>Plan</h4>
					Enterprise
					<small>Unlimited users, unlimited templates, unlimited skills</small>

					<div className="controls">
						<IconButton onClick={() => this.setState({edit: "plan"})} icon={<Icons.Pencil />} />
					</div>

				</div>


				<FormFields.Model title="Edit Details" isOpen={this.state.edit === "plan"}
					onHide={(e) => this.unedit()} onCancel={(e) => this.unedit()} onConfirm={(e) => this.handleSubmit(e)} >

					<FormFields.Select name="plan" title="Plan" options={["Enterprise", "Starter"]} value={this.state.account.plan} onChange={(e) => this.handleChange(e)} />

				</FormFields.Model>

			</div>
		);
	}
}
