import React from 'react';


import * as AccountActions from "actions/AccountActions";
import * as FormFields from "components/forms/FormFields";
import UserAvatar from "components/pages/users/UserAvatar";
import SessionStore from "../../../store/SessionStore";

export default class UserPhotoUpload extends React.Component {

	constructor(props) {
		super();
		this.state = { uploading: false, editType: null};
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */



	handleImageUploadChange(e) {

		var files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}

		var fd = new FormData();
		fd.append('file', files[0]);

		this.setState({uploading: true});

		AccountActions.photoUpload(SessionStore.getAccountId(), this.props.user.id, fd,
			(user) => {
				this.setState({uploading: false, editType: null});
				if(this.props.onUploadSuccess) this.props.onUploadSuccess(user);
			},
			(errors) => this.setState({uploading: false, editType: null})
		);

	}

	handleImageDelete(e) {
		AccountActions.photoDelete(SessionStore.getAccountId(), this.props.user.id,
			(user) => {
				this.setState({uploading: false, editType: null});
				if(this.props.onDeleteSuccess) this.props.onDeleteSuccess(user);
			},
			(errors) => {
				this.setState({uploading: false, editType: null});
				if(this.props.onDeleteFail) this.props.onDeleteFail(errors);
			}
		);
	}

	edit(type) {
		this.setState({editType: type});
	}

	unedit() {
		this.setState({editType: null});
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	renderProgress() {
		return (
			<div className="progress">
				<div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{width: "100%"}}>
					<span className="sr-only">Uploading</span>
				</div>
			</div>
		);
	}

	render() {

		var photoModelPanel =
			<FormFields.Model title="Edit Photo" isOpen={this.state.editType != null && this.state.editType === "photo"} className="modal-md"
				onCancel={() => this.unedit()} cancelLabel="Close"
				onConfirm={(e) => this.uploadInput.click()}
				extraButtons={<button className="btn" onClick={(e) => this.handleImageDelete(e)}>Delete</button>}
				confirmLabel="Choose file" >

				{this.props.user.photoMediumUrl ?
					<button  onClick={(e) => this.uploadInput.click()}><img className="profile-photo" src={this.props.user.photoMediumUrl} alt="User"/></button>
					: <button onClick={(e) => this.edit("photo")}><img className="profile-photo" src="/media/user@4x.png" alt="User" /></button>
				}

			</FormFields.Model>

		var uploadForm =
			<form className="form-group" style={{display: "none"}} encType="multipart/form-data">
				<input type="file" ref={(input) => this.uploadInput = input} className="form-control" name="file" onChange={(e) => this.handleImageUploadChange(e)} />
			</form>

		return (
			<div>
				{photoModelPanel}
				<div className="text-center">
					<button onClick={(e) => this.edit("photo")}>
						<UserAvatar user={this.props.user} />
					</button>
				</div>

				{uploadForm}

				{this.state.uploading ? this.renderProgress() : null }
			</div>
		);


	}
}