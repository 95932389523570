import React from 'react';
import { Availability } from "./Availability"
import * as Dto from "actions/Dto"

interface AvailabilityStepProps {
	step: number
	index: number
	availability: Dto.Availability|Dto.UserAvailabilityForm
	helpText: string
	onOffsetDateChanged: (offsetDate: Date) => void
	onOffsetDaysChanged: (offsetDays: number) => void
}

export const AvailabilityStep: React.FunctionComponent<AvailabilityStepProps> = (props) => {

	if (props.step !== props.index) return null;

	return (

		<React.Fragment>

			<div className="wizard-body">

				<h4>Availability</h4>

				<p>
				{props.helpText ?
					props.helpText
					: "Please let us know when you will be available to start working with us."
				}
				</p>

				<Availability 
					onOffsetDateChanged={(offsetDate) => props.onOffsetDateChanged(offsetDate)} 
					onOffsetDaysChanged={(offsetDays) => props.onOffsetDaysChanged(offsetDays)}
					availability={props.availability}
				/>

			</div>

		</React.Fragment>

	);

}