import React from 'react';

import Routes from "actions/Routes";
import * as TimesheetsActions from "actions/TimesheetsActions";
import SessionStore from "store/SessionStore";
import SectionBody from "components/layouts/SectionBody";
import { TalentContextMenu } from '../users/TalentContextMenu';
import Timesheet from "./Timesheet";
import * as Utils from "components/general/Utils";

import ReactGA from 'react-ga';

import "./Timesheets.css"

export default class UserTimesheetsPage extends React.Component {

	constructor(props) {

		super();

		var userId = props.match.params.userId;
		var date = props.match.params.date;
		if (!userId) userId = SessionStore.getUserId();

		this.state = {
			user: null, assignments: null, date: date,
			errors: null, changePassword: false, messages: null, uploading: false,
			userId: userId, editType: null, edit: null
		};

		this.path = Routes.timesheetsForUser(userId);

	}

	componentWillMount() {

		window.scrollTo(0, 0);	//scroll to top
		ReactGA.pageview(this.path);	//analytics

		this.reload();

	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *		H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	reload() {
		TimesheetsActions.settings(SessionStore.getAccountId(), this.state.userId,
			(settings) => this.setState({user: settings.user, assignments: settings.assignments, leaveTypes: settings.leaveTypes}),
			(errors) => {this.setState({errors: errors})}
		);
	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	render() {

		if (this.state.user == null || this.state.assignments == null) return null;

		let topMenuItems = <TalentContextMenu user={this.state.user} path={this.props.location.pathname} />;

		return(

			<SectionBody className="timesheets" topMenuItems={topMenuItems}
				onNotificationClose={() => this.setState({errors: null, messages: null})}
				errors={this.state.errors} messages={this.state.messages} >

				<Timesheet
					assignments={this.state.assignments}
					leaveTypes={this.state.leaveTypes}
					user={this.state.user}
					date={this.state.date}
					onError={(errors) => this.setState({errors: errors})}
					onDateChanged={(date) => this.props.history.push(Routes.timesheetsForUser(this.state.user.id, Utils.formatDateISO(date)))}
					onMessage={(messages) => this.setState({messages: messages})}
				/>

			</SectionBody>

		);
	}
}
