import * as Http from "./Http"



/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

	S K I L L S   T Y P E S

* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


export function skillTypeLoad(accountId, skillTypeId, success, failure) {

	Http.get( "/api/accounts/" + accountId + "/skills/" + skillTypeId).then(success).catch(failure);

}

export function skillTypeUpdate(accountId, skillType, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/skills/" + skillType.id, skillType).then(success).catch(failure);

}

export function skillTypeMerge(accountId, skillType, skillTypeMergeTo, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/skills/" + skillTypeMergeTo.id + "/merge", {skillTypeIds:skillType.id}).then(success).catch(failure);

}

export function skillTypesMerge(accountId, skillTypeIds, skillTypeMergeTo, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/skills/" + skillTypeMergeTo.id + "/merge", {skillTypeIds:skillTypeIds}).then(success).catch(failure);

}

export function skillTypeStatus(accountId, skillTypeId, status, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/skills/" + skillTypeId + "/status", {status: status}).then(success).catch(failure);

}

export function skillTypeDelete(accountId, skillTypeId, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/skills/" + skillTypeId + "/status", {status: "DELETED"}).then(success).catch(failure);

}

export function skillTypeAssignCategory(accountId, category, skillTypeIds, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/skills/category", {skillTypeIds:skillTypeIds, category:category}).then(success).catch(failure);

}


export function skillTypeMultipleDelete(accountId, skillTypeIds, success, failure) {

	Http.deleteElement("RECIEVED_SKILLS_REPLACE", "/api/accounts/" + accountId + "/skills", {skillTypeIds:skillTypeIds}, success, failure);

}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

	S K I L L S   C O N V E R S A T I O N S

* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */



export function skillConversationLoad(accountId, skillTypeId, page, success, failure) {

	Http.get( "/api/accounts/" + accountId + "/skills/" + skillTypeId + "/chat/page/" + page).then(success).catch(failure);

}

export function skillConversationPost(accountId, skillTypeId, post, success, failure) {

	Http.post( "/api/accounts/" + accountId + "/skills/" + skillTypeId + "/chat", post).then(success).catch(failure);

}