

export const UserStatus = {
	INACTIVE: "INACTIVE",
	ACTIVE: "ACTIVE",
	CANDIDATE: "CANDIDATE",
	APPLICANT: "APPLICANT",
	DELETED: "DELETED",
}

export const UserInviteStatus = {
	INACTIVE: "NONE",
	ACTIVE: "ACCEPTED",
	CANDIDATE: "PENDING"
}

export const TimesheetStatus = {
	DRAFT: "DRAFT",
	SUBMITTED: "SUBMITTED",
	APPROVED: "APPROVED",
	REJECTED: "REJECTED"
}


export const AssignmentStatus = {
	PROPOSED: "PROPOSED",
	APPROVED: "APPROVED",
	CANCELLED: "CANCELLED"
}