import React from 'react';
import { Link } from "react-router-dom"

import Routes from "actions/Routes"
import SectionBody from 'components/layouts/SectionBody';
import ReactGA from 'react-ga';
import Glyphicon from "components/general/Glyphicon";
import { ValueProp} from "components/pages/SplashPage";

export default class AccountJoinedPage  extends React.Component {

	constructor(props) {
		super(props);
		this.state = {errors: [], messages: []};
	}

	componentDidMount() {

		window.scrollTo(0, 0);	//scroll to top
		ReactGA.pageview(Routes.accountJoined());	//analytics

		document.title = "Welcome to TalentedTeam"

	}


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	render() {


		return(

			<SectionBody className={"account-joined"} 
				onNotificationClose={() => this.setState({errors: null, messages: null})}
				errors={this.state.errors} messages={this.state.messages} >

				<div className="row">

					<div className="col">

						<h1>Welcome to Talented Team</h1>

						<div>

						</div>
						
						<p>
							As your organisation grows, keeping track of the abilities in your team can be difficult. For individuals, being able to promote new skills is also important to help grow and build your teams experience and careers. Talented Team lets you search, rank and categorise your team's skills and ability giving quick and effective access to your organisation's talents.
						</p>
						<ValueProp>
							<div className="col">

								<div className="key-features">
									<div className="key-feature">
										<Glyphicon name="id-badge" className="huge blue" />
										<Link to={Routes.home()}><h3>Profiles</h3></Link>
										<div className="info">Mantain consistent and high quality staff profiles. Ideal for proposals and tender documents.</div>
									</div>

									<div className="key-feature">
										<Glyphicon name="settings" className="huge blue" />
										<Link to={Routes.skills()}><h3>Skills</h3></Link>
										<div className="info">Understand where you have pockets of skills and where you need up-lift and developent.</div>
									</div>

								</div>

							</div>
						</ValueProp>
					</div>

				</div>

			</SectionBody>
		);
	}

}