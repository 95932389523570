import Session from 'store/SessionStore';
import * as Dto from "./Dto";

export type SuccessResponse<T> = (data: T) => void;
export type FailureResponse = (errors: Dto.ErrorDto[]) => void;

export function withHost(url: string) {
	if (url && url.startsWith("/")) return (process.env.REACT_APP_API ? process.env.REACT_APP_API : "") + url;
	return url;
}

export function post<D, T>(url: string, data: D) {
	return httpWithHeader<D, T>("post", url, data, new Headers());
}

export function postWithAuth<D, T>(url: string, data: D, accessToken: string) {
	
	var headers = new Headers();
	headers.append("Authorization", "Bearer " + accessToken);
	return httpWithHeader<D, T>("post", url, data, headers);
}


export function httpWithHeader<D, T>(method: string, url: string, data: D, headers: Headers) : Promise<T> {

	return new Promise((resolve, reject) => {

		if (!headers.has('Accept')) headers.set('Accept', 'application/json');
		if (!headers.has('Content-Type')) headers.set('Content-Type', 'application/json');

		//Insert auth token
		if (Session.authToken && !headers.has("Authorization") ) headers.append("Authorization", "Bearer " + Session.authToken);

		fetch(withHost(url), {method:method, body: JSON.stringify(data), headers: headers, credentials: "include"} )
			.then(response => {

				if (!response.ok) {

					try {
						if (response.status >= 500) {
							reject([{code: "http-error", message: response.statusText}] as Dto.ErrorDto[]);
						} else {
							response.json().then(
								(jsonError) => reject( (Array.isArray(jsonError) ? jsonError as Dto.ErrorDto[] :  [{code: response.status, message: response.statusText}]) as Dto.ErrorDto[] )
							).catch(
								(reason) => reject( [{code: "http-error", message: reason + ""}] as Dto.ErrorDto[])
							);
						}
					} catch(err) {
						reject( [{code: String(response.status), message: response.statusText}] as Dto.ErrorDto[]);
					}
				} else {
					let contentType = response.headers.get("content-type");
					if (contentType && contentType.includes("html")) {
						response.text().then((html: any) => resolve(html));
					} else {
						response.json().then((data: T) => resolve(data));
					}
				}

			}).catch(
				(error) => {
					reject([{code: "http-error", message: error + ""}] as Dto.ErrorDto[]);
				}
			);
	});

}

export function get<T>(url: string) {
	return getWithHeader<T>(url, new Headers());
}

export function getWithAuth<T>(url: string, accessToken: string) {
	var headers = new Headers();
	headers.append("Authorization", "Bearer " + accessToken);
	return getWithHeader<T>(url, headers);
}

export function getWithHeader<T>(url: string, headers: Headers) : Promise<T> {

	return new Promise((resolve, reject) => {

		if (!headers.has('Accept')) headers.set('Accept', 'application/json');
		if (!headers.has('Content-Type')) headers.set('Content-Type', 'application/json');

		//Insert auth token
		if (Session.authToken && !headers.has("Authorization") ) headers.append("Authorization", "Bearer " + Session.authToken);		

		fetch(withHost(url), {method:"get", headers: headers, credentials: "include"} )
		.then(response => {

			if (!response.ok) {

				try {
					if (response.status >= 500) {
						reject( [{code: "http-error", message: response.statusText}] as Dto.ErrorDto[])
					} else {
						response.json().then(
							(jsonError) => reject( (Array.isArray(jsonError) ? jsonError as Dto.ErrorDto[] :  [{code: response.status, message: response.statusText}]) as Dto.ErrorDto[] )
						).catch(
							(reason) => reject( [{code: 'http-error', message: reason + ""}] as Dto.ErrorDto[])
						);
					}
				} catch(err) {
					reject( [{code: String(response.status), message: response.statusText}] as Dto.ErrorDto[]);
				}

			} else {
				let contentType = response.headers.get("content-type");
				if (contentType && contentType.includes("html")) {
					response.text().then((html: any) => resolve(html));
				} else {
					response.json().then((data: T) => resolve(data));
				}
			}

		})
		.catch(
			(error) => {
				reject([{code: "http-error", message: error + ""}])
			}
		);

	});

}

export function deleteElement<T>(url: string) : Promise<T> {

	return new Promise((resolve, reject) => {

		var headers = new Headers();
		headers.set('Accept', 'application/json');
		headers.set('Content-Type', 'application/json');
		
		//Insert auth token
		if (Session.authToken) headers.append("Authorization", "Bearer " + Session.authToken);

		fetch(withHost(url), {method:"delete", headers: headers, credentials: "include"} )
		.then(response => {

			if (!response.ok) {

				try {
					if (response.status >= 500) {
						reject([{code: "http-error", message: response.statusText}])
					} else {
						response.json().then(
							(jsonError) => reject(Array.isArray(jsonError) ? jsonError :  [{code: response.status, message: response.statusText}])
						).catch(
							(reason) => reject([{code: 'http-error', message: reason + ""}])
						);
					}
				} catch(err) {
					reject([{code: String(response.status), message: response.statusText}]);
				}

			} else {
				response.json().then((data: T) => resolve(data));
			}

		})
		.catch(
			(error) => {
				reject([{code: "http-error", message: error + ""}])
			}
		);

	});

}

export function httpDownload<D>(filename: string, method: string, url: string, data: D, headers: Headers) : Promise<string> {

	return new Promise((resolve, reject) => {

		if (!headers.has('Accept')) headers.set('Accept', 'application/json');
		if (!headers.has('Content-Type')) headers.set('Content-Type', 'application/json');

		//Insert auth token
		if (Session.authToken && !headers.has("Authorization") ) headers.append("Authorization", "Bearer " + Session.authToken);

		fetch(withHost(url), {method:method, body: JSON.stringify(data), headers: headers, credentials: "include"} )
		.then(response => response.blob())
		.then(blob => {
			var url = window.URL.createObjectURL(blob);
			var a = document.createElement('a');
			a.href = url;
			a.download = filename;
			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			a.target = "_blank";
			a.click();    

			// For Firefox it is necessary to delay revoking the ObjectURL.
			setTimeout(() => { window.URL.revokeObjectURL(url); }, 250);

			resolve(filename + " downloaded.")
		})
		.catch(error => reject([{code: "http-error", message: error + ""}]) );

	});

}


export function patch<D, T>(url: string, data: D) {
	return httpWithHeader<D, T>("patch",url, data, new Headers());
}

export function patchWithAuth<D, T>(url: string, data: D, accessToken: string) {
	var headers = new Headers();
	headers.append("Authorization", "Bearer " + accessToken);
	return httpWithHeader<D, T>("patch", url, data, headers);
}

export function put<D, T>(url: string, data: D) {
	return httpWithHeader<D, T>("put", url, data, new Headers());
}

export function putWithAuth<D, T>(url: string, data: D, accessToken: string) {
	var headers = new Headers();
	headers.append("Authorization", "Bearer " + accessToken);
	return httpWithHeader<D, T>("put", url, data, headers);
}

export function postUpload<T>(url: string, file: any) : Promise<T> {

	return new Promise((resolve, reject) => {

		let headers = new Headers();

		if (!headers.has('Accept')) headers.set('Accept', 'application/json');
		//if (!headers.has('Content-Type')) headers.set('Content-Type', 'application/json');

		//Insert auth token
		if (Session.authToken && !headers.has("Authorization") ) headers.append("Authorization", "Bearer " + Session.authToken);

		fetch(withHost(url), {method: "post", body: file, headers: headers, credentials: "include"} )
			.then(response => {

				if (!response.ok) {

					try {
						if (response.status >= 500) {
							reject([{code: "http-error", message: response.statusText}] as Dto.ErrorDto[]);
						} else {
							response.json().then(
								(jsonError) => reject( (Array.isArray(jsonError) ? jsonError as Dto.ErrorDto[] :  [{code: response.status, message: response.statusText}]) as Dto.ErrorDto[] )
							).catch(
								(reason) => reject( [{code: "http-error", message: reason + ""}] as Dto.ErrorDto[])
							);
						}
					} catch(err) {
						reject( [{code: String(response.status), message: response.statusText}] as Dto.ErrorDto[]);
					}
				} else {
					let contentType = response.headers.get("content-type");
					if (contentType && contentType.includes("html")) {
						response.text().then((html: any) => resolve(html));
					} else {
						response.json().then((data: T) => resolve(data));
					}
				}

			}).catch(
				(error) => {
					reject([{code: "http-error", message: error + ""}] as Dto.ErrorDto[]);
				}
			);
	});
	
	
}

/*export function postUploadOLD(url, data, success, failure) {

	jquery.ajax({
		url: url,
		data: data,
		xhrFields: {
			withCredentials: true
		},
		processData: false,
		contentType: false,
		type: 'POST'
	}).done(
		function(data) {
			if (success) success(data);
		}
	).fail(
		function(error) {
			try {
				if (failure) failure(JSON.parse(error.responseText));
			} catch(err) {
				console.log("Error:", err);
				if (failure) failure([{code:"error", message:"Error processing request"}]);
			}
		}
	)

}
*/