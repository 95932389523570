import * as AccountActions from "actions/AccountActions";
import * as Dto from "actions/Dto";
import React, { useState } from 'react';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import SessionStore from "store/SessionStore";

interface UserAsyncTypeaheadmProps {
	onError: (errors: Dto.ErrorDto[]) => void
	onSelected: (user: Dto.UserDto) => void
}

export const UserAsyncTypeahead: React.FunctionComponent<UserAsyncTypeaheadmProps> = (props) => {

	const [ loading, setLoading ] = useState(false);
	const [ results, setResults ] = useState<Dto.UserDto[]>();

	const handleUserSelected = (selected: any[]) => {
		props.onSelected(selected[0]);
	}

	const handleSearch = (query: string) => {
		if (SessionStore.accountId) {
			setLoading(true);
			AccountActions.userSearch(SessionStore.accountId, query,
				(users) => {
					setResults(users);
					setLoading(false);
				},
				props.onError
			);
		}
	}

	return (
		
		<AsyncTypeahead isLoading={loading} minLength={3}
			id="user-typeahead"
			inputProps={{name: "title", maxLength: 60}}
			placeholder="User search" autoFocus={true} allowNew={false}
			caseSensitive={false} filterBy={["fullname"]}
			onSearch={handleSearch}
			onChange={(value) => handleUserSelected(value)}
			multiple={false}
			labelKey="fullname"
			options={results || []}
			renderMenu={(results, menuProps) => (
				<Menu {...menuProps}>
				{results.map((result, index) => (
					<MenuItem option={result} position={index}>
					<img alt="mention" className="profile-thumbnail mini" src={((result as Dto.UserDto).photoThumbnailUrl ? (result as Dto.UserDto).photoThumbnailUrl : "/media/user@4x.png")} /> {(result as Dto.UserDto).fullname}
					</MenuItem>
				))}
				</Menu>
			)}

		/>
		
	)

}