import { ErrorDto, HolidayDto, HolidayForm, LeaveTypeDto, TimesheetSettingsDto } from "actions/Dto";
import * as TimesheetsActions from "actions/TimesheetsActions";
import { IconButton } from "components/general/IconButton";
import React, { useEffect, useState } from "react";
import * as Icons from 'react-bootstrap-icons';
import SessionStore from "store/SessionStore";
import * as FormFields from "components/forms/FormFields";
import * as Utils from "components/general/Utils";

interface HolidaysProps {
	onError: (errors: ErrorDto[]) => void
}

export const Holidays: React.FunctionComponent<HolidaysProps> = (props) => {

	const [ holidays, setHolidays ] = useState<HolidayDto[]|null>(null);
	const [ leaveTypes, setLeaveTypes] = useState<LeaveTypeDto[]|null>(null);

	const [ addHoliday, setAddHoliday ] = useState<HolidayDto>();
	
	useEffect(() => {

		if (SessionStore.accountId) {

			TimesheetsActions.holidays(SessionStore.accountId,
				(holidays) => setHolidays(holidays.values),
				(errors) => props.onError(errors)
			);

			TimesheetsActions.leaveTypes(SessionStore.accountId,
				(leaveTypes) => setLeaveTypes(leaveTypes.values),
				(errors) => props.onError(errors)
			);

		}


	}, []);




	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


	const handleAddHoliday = () => {
		//Can't add if leaveTypes are not loaded...
		if (leaveTypes) {
			setAddHoliday({date: new Date, leaveType: leaveTypes[0]} as HolidayDto);
		}
	}
	
	const handleSave = () => {


		if (addHoliday && SessionStore.accountId) {

			const form = { ...addHoliday, date: Utils.formatDate(addHoliday.date), leaveTypeId: addHoliday.leaveType.id } as HolidayForm;

			TimesheetsActions.holidayCreate(SessionStore.accountId, form,
				(holidays) => {
					setHolidays(holidays.values);
					setAddHoliday(undefined);
				},
				(errors) => props.onError(errors)
			);
			
		}

	}

	const handleChange = (e) => {
		
		let form = {...addHoliday};
		form[e.target.name] = e.target.value;
		setAddHoliday(form as HolidayDto);

	}
	

	const handleChangeDate = (date: Date) => {
		
		let form = {...addHoliday} as HolidayDto;
		form.date = date;
		setAddHoliday(form as HolidayDto);

	}

	const handleChangeOffice = (e) => {
		
		let form = {...addHoliday} as HolidayDto;
		form.office = e.target.value;
		setAddHoliday(form as HolidayDto);

	}

	const handleChangeLeaveType = (e) => {
		
		let form = {...addHoliday} as HolidayDto;
		
		const selectedLeaveType = leaveTypes?.find(leaveType => leaveType.id === parseInt(e.target.value));

		if (selectedLeaveType) {
			form.leaveType = selectedLeaveType;
			setAddHoliday(form as HolidayDto)
		}

	}

	const handleDelete = (holidayId: number) => {
		if (window.confirm("Delete holiday?") && SessionStore.accountId) {
			TimesheetsActions.holidayDelete(SessionStore.accountId, holidayId,
				(holidays) => setHolidays(holidays.values),
				props.onError
			);
		}
 	}
	
	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
		*
		*      R E N D E R E R S
		*
		* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	return (

		<>
			<div className="row">
						<div className="col-md-8">
							<h2>Holidays</h2>
						</div>
						<div className="col-md-4">
							<div className="controls"><IconButton icon={<Icons.PlusLg />} onClick={handleAddHoliday} /></div>
						</div>
					</div>
					<br/>
					<div className={"form-list form-group"}>
						<table className="table table-striped">
							<thead>
								<tr >
									<th>Holiday name</th>
									<th>Type</th>
									<th>Date</th>
									<th>Office</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{holidays && holidays.length > 0 ?
									holidays.map((holiday, index) => {
										return (
											<tr className="settings-tile" key={holiday.id}>
												<td>{holiday.title}</td>
												<td>{holiday.leaveType.title}</td>
												<td>{holiday.date}</td>
												<td>{holiday.office ? holiday.office : "(all)"}</td>
												<td>
													<div className="controls">
													<IconButton onClick={() => handleDelete(holiday.id)} icon={<Icons.Trash />} />
													</div>
												</td>
											</tr>
										);
									})
									: <tr className="placeholder"><td colSpan={4}>(none)</td></tr>
								}
							</tbody>
						</table>

						<FormFields.Model title="Add Holiday" isOpen={!!addHoliday}
							onHide={(e) => setAddHoliday(undefined)} onCancel={(e) => setAddHoliday(undefined)} onConfirm={(e) => handleSave()} >
							
							<FormFields.Text name="title" title="Title" placeholder="Title" value={addHoliday?.title} onChange={(e) => handleChange(e)} />
							<FormFields.Text name="description" title="Description" placeholder="Description" value={addHoliday?.description} onChange={(e) => handleChange(e)} />
							<FormFields.Select name="office" title="Office" value={addHoliday?.office} options={[ "(All offices)", ...SessionStore.getOffices() ]} onChange={(office) => handleChangeOffice(office)} />
							<FormFields.Select name="leaveTypeId" title="Type" options={leaveTypes || []} valueSelector={o => o.id} labelSelector={o => o.title} onChange={(e) => handleChangeLeaveType(e)} />
							<FormFields.DatePick name="date" title="Date" value={addHoliday?.date || new Date()} onChange={(date) => handleChangeDate(date)} />


						</FormFields.Model>

					</div>
		</>

	);

}