import React from "react";

import Routes from "actions/Routes"
import AbstractDataPage from "./../AbstractDataPage"
import * as SkillActions from "actions/SkillActions"
import * as TalentActions from "actions/TalentActions"
import TalentSkill from "./TalentSkill"
import * as FormFields from "components/forms/FormFields"
import { UserStatus } from 'components/general/Constants';
import { TopMenuItem } from "components/layouts/navigation/TopMenuItem";
import SessionStore from "store/SessionStore"
import * as Utils from "components/general/Utils"

import Glyphicon from "components/general/Glyphicon";

const EditTypes = {
	FILTER: "filter",
}

export default class TalentBySkillPage extends AbstractDataPage {

	showFilter = false;

	constructor(props) {

		super(props);
		this.state = this.initialiseState(props);

		//load skill
		this.loadSkillType(props.match.params.skillTypeId);

	}

	initialiseState(props) {
		return { 
			...this._defaultState, skillTypeId: props.match.params.skillTypeId, statusFilter: this.defaultStatusFilter()
		}
	}

	defaultStatusFilter() {
		return [UserStatus.CANDIDATE, UserStatus.ACTIVE];
	}

	pagePath() {
		return Routes.skillsTalent(this.state.skillTypeId);
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.skillTypeId !== prevProps.match.params.skillTypeId) {
			this.setState({skillTypeId: this.props.match.params.skillTypeId});
			this.loadSkillType(this.props.match.params.skillTypeId);
			this.loadData(0);
		}
	}

	/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      H A N D L E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	loadSkillType(skillTypeId) {
		//Load Skill
		SkillActions.skillTypeLoad(this.state.accountId, skillTypeId,
			(result) => this.setState({skillTypeId: skillTypeId, skillType: result}),
			(errors) => {this.setState({errors: errors})}
		);
	}

	loadData(page) {

		if (page < 0) page = 0;

		TalentActions.talentsBySkillLoad(this.state.accountId, this.state.skillTypeId, this.state.statusFilter, page,
			(data) => { this.setState({filterLabel: this.state.quickFilter, editType: null}); this.handleLoadSuccessCallback(data)},
			(errors) => {this.handleErrorCallback(errors)}
		);

	}

	loadMore(page) {

		//Load More Talent
		TalentActions.talentsBySkillLoad(this.state.accountId, this.state.skillTypeId, this.state.statusFilter, this.state.data.index + 1,
			(data) => { this.handleLoadMoreSuccessCallback(data) },
			(errors) => {this.handleErrorCallback(errors)}
		);

	}

	handleChangeStatusFilter(event, status) {
		var statusFilter = this.state.statusFilter;
		if (event.target.checked) {
			if (statusFilter.indexOf(status) === -1) {
				statusFilter.push(status);
			}
		} else {
			statusFilter = statusFilter.filter(item => item !== status)
		}
		this.setState({statusFilter: statusFilter});
	}

	handleStatusFilterSubmit() {
		this.setState({quickFilter: ("Filter: status = " + this.state.statusFilter).toLowerCase()});
		this.loadData(0);
	}

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
	 *
	 *      R E N D E R S
	 *
	 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

	title() {
		return "Talent: " + (this.state.skillType ? this.state.skillType.title : "");
	}

	contextMenuItems() {

		if (!this.state.skillType) return null;

		return (
			<React.Fragment>
				<TopMenuItem label={"/ " + this.state.skillType.title} to={Routes.skillsEdit(this.state.skillType.id)} hidden={!this.state.skillType}/>
				<TopMenuItem label="Talent" to={Routes.skillsTalent(this.state.skillType.id)} active={true}/>
				{ SessionStore.getAccount().projectsEnabled ? <TopMenuItem label="Availability" to={Routes.skillsTalentAvailability(this.state.skillType.id)} hidden={!this.state.skillType.title} /> : null }
			</React.Fragment>
		);
	}

	renderActionsDropdown() {

		return (
			<React.Fragment>
				<div className="dropdown">
					<button className="btn btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
						<Glyphicon name="cogwheel" className="invert" />
					</button>
					<div className="dropdown-menu dropdown-menu-right">
						<button className="dropdown-item" onClick={() => this.setState({editType: EditTypes.FILTER})}>Advanced Filter</button>
					</div>
				</div>

				{this.state.showActiveOnly ? <span className="minor-text">Stand-by Talent are hidden</span> : null}
				{this.renderStatusFilter()}
			</React.Fragment>
		)
	}


	renderStatusFilterCheckbox(status) {
		return <FormFields.Checkbox
			name={status} title={Utils.titleCase(status)}
			inline={true} value={this.state.statusFilter.indexOf(status) >= 0 }
			onChange={(e) => this.handleChangeStatusFilter(e, status)}
		/>
	}


	renderStatusFilter() {
		return (
			<FormFields.Model title="Advanced Filter" isOpen={this.state.editType === EditTypes.FILTER} className="modal-normal" confirmLabel="Filter"
				onCancel={(e) => this.setState({editType: null})} onConfirm={(e) => this.handleStatusFilterSubmit(e)} >

				<div>
					<div>Filter talent by status:</div>
					<div className="model-group">
						{this.renderStatusFilterCheckbox(UserStatus.APPLICANT)}
						{this.renderStatusFilterCheckbox(UserStatus.CANDIDATE)}
						{this.renderStatusFilterCheckbox(UserStatus.ACTIVE)}
						{this.renderStatusFilterCheckbox(UserStatus.INACTIVE)}
					</div>
				</div>

			</FormFields.Model>
		);
	}

	renderDataItem(skill) {
		return <TalentSkill profile={skill.profile} user={skill.profile.user} skill={skill} key={skill.profile.user.id} accountId={this.state.accountId} />
	}

}