import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
//import history from "./history"

import Layout from "./components/layouts/Layout";

import './index.css';

//========================================
//<BrowserRouter history={history}>}
ReactDOM.render(
	<BrowserRouter>
		<Route component={Layout} />
	</BrowserRouter>,
  document.getElementById('root')
);